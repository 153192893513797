import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  MenuItem,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Typography,
} from "@material-ui/core";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import { Controller } from "react-hook-form";
import moment from "moment";

import DatePicker from "../../../../components/UI/DatePicker/DatePicker";
import { getStatusJob } from "../../../../store/actions";
import { isEmpty } from "../../../../shared/utility";

const useStyles = makeStyles((theme) => ({
  gridPadding: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  field: {
    marginTop: theme.spacing(1),
  },
  textarea: {
    margin: theme.spacing(2),
  },
  card: {
    margin: theme.spacing(3, 1.25, 1.25),
  },
  cardTitle: {
    display: "flex",
    alignItems: "center",
  },
  iconTitle: {
    margin: theme.spacing(0, 1, 0, 2),
  },
}));

const Identitas = ({
  register,
  control,
  errors,
  getStatusJob,
  auth,
  status,
}) => {
  const classes = useStyles();

  const [startWork, setStartWork] = useState(null);
  const [dateBirth, setDateBirth] = useState(null);

  useEffect(() => {
    if (isEmpty(status.statusJob)) {
      getStatusJob();
    }
  }, [getStatusJob, status.statusJob]);

  return (
    <Card className={classes.card}>
      <CardHeader
        title={
          <div className={classes.cardTitle}>
            <PersonPinIcon className={classes.iconTitle} />
            <Typography variant="h4">Identitas Diri</Typography>
          </div>
        }
      />
      <Divider />
      <CardContent>
        <Grid container>
          <Grid item xs={6} className={classes.gridPadding}>
            <TextField
              fullWidth
              label="Nama Lengkap"
              value={auth.user.name}
              disabled
            />
            <TextField
              type="number"
              className={classes.field}
              fullWidth
              name="tax_number"
              label="Nomor NPWP"
              inputRef={register}
              error={!!errors.tax_number}
              helperText={!!errors.tax_number && errors.tax_number.message}
            />
            <Controller
              name="start_at"
              control={control}
              defaultValue=""
              render={({ onChange, ref }) => (
                <DatePicker
                  value={startWork}
                  label="Mulai Bekerja"
                  name="start_at"
                  inputRef={ref}
                  format="dd-MM-yyyy"
                  onChange={(e) => {
                    setStartWork(e);
                    onChange(moment(e).format("YYYY-MM-DD"));
                  }}
                  error={!!errors.start_at}
                  helperText={!!errors.start_at && errors.start_at.message}
                />
              )}
            />
            <TextField
              className={classes.field}
              fullWidth
              name="place_of_birth"
              label="Tempat Lahir"
              inputRef={register}
              error={!!errors.place_of_birth}
              helperText={
                !!errors.place_of_birth && errors.place_of_birth.message
              }
            />

            <Controller
              name="gender"
              defaultValue=""
              control={control}
              render={({ onChange, ref }) => (
                <TextField
                  className={classes.field}
                  fullWidth
                  select
                  inputRef={ref}
                  defaultValue=""
                  label="Jenis Kelamin"
                  onChange={(e) => onChange(e.target.value)}
                  error={!!errors.gender}
                  helperText={!!errors.gender && errors.gender.message}
                >
                  <MenuItem value="" disabled></MenuItem>
                  <MenuItem value="laki-laki">Laki-Laki</MenuItem>
                  <MenuItem value="perempuan">Perempuan</MenuItem>
                </TextField>
              )}
            />
            <TextField
              className={classes.field}
              fullWidth
              name="ethnic"
              label="Suku"
              inputRef={register}
              error={!!errors.ethnic}
              helperText={!!errors.ethnic && errors.ethnic.message}
            />
            <TextField
              type="number"
              className={classes.field}
              fullWidth
              name="height"
              label="Tinggi Badan"
              inputRef={register}
              error={!!errors.height}
              helperText={!!errors.height && errors.height.message}
            />
            <Controller
              control={control}
              defaultValue=""
              name="marital_status"
              render={({ onChange, ref }) => (
                <TextField
                  className={classes.field}
                  fullWidth
                  select
                  defaultValue=""
                  label="Status Pernikahan"
                  inputRef={ref}
                  onChange={(e) => onChange(e.target.value)}
                  error={!!errors.marital_status}
                  helperText={
                    !!errors.marital_status && errors.marital_status.message
                  }
                >
                  <MenuItem value="" disabled></MenuItem>
                  <MenuItem value="menikah">Menikah</MenuItem>
                  <MenuItem value="belum menikah">Belum Menikah</MenuItem>
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={6} className={classes.gridPadding}>
            <TextField
              type="number"
              fullWidth
              name="id_number"
              label="Nomor KTP"
              inputRef={register}
              error={!!errors.id_number}
              helperText={!!errors.id_number && errors.id_number.message}
            />
            <TextField
              type="number"
              className={classes.field}
              fullWidth
              name="account_number"
              label="Nomor Rekening BSM"
              inputRef={register}
              error={!!errors.account_number}
              helperText={
                !!errors.account_number && errors.account_number.message
              }
            />
            <Controller
              control={control}
              name="status_id"
              defaultValue=""
              render={({ onChange, ref }) => (
                <TextField
                  className={classes.field}
                  fullWidth
                  select
                  defaultValue=""
                  label="Status Pekerjaan"
                  onChange={(e) => onChange(e.target.value)}
                  error={!!errors.status_id}
                  helperText={!!errors.status_id && errors.status_id.message}
                >
                  <MenuItem value="" disabled></MenuItem>
                  {isEmpty(status.statusJob) && (
                    <MenuItem value="" disabled>
                      Loading...
                    </MenuItem>
                  )}
                  {status.statusJob.map((value) => (
                    <MenuItem value={value.id} key={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
            <Controller
              name="date_of_birth"
              control={control}
              defaultValue=""
              render={({ onChange, ref }) => (
                <DatePicker
                  value={dateBirth}
                  label="Tanggal Lahir"
                  inputRef={ref}
                  format="dd MMMM yyyy"
                  onChange={(e) => {
                    setDateBirth(e);
                    onChange(moment(e).format("YYYY-MM-DD"));
                  }}
                  error={!!errors.start_at}
                  helperText={!!errors.start_at && errors.start_at.message}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue=""
              name="blood_type"
              render={({ onChange, ref }) => (
                <TextField
                  className={classes.field}
                  fullWidth
                  select
                  defaultValue=""
                  label="Golongan Darah"
                  inputRef={ref}
                  onChange={(e) => onChange(e.target.value)}
                  error={!!errors.blood_type}
                  helperText={!!errors.blood_type && errors.blood_type.message}
                >
                  <MenuItem value="" disabled></MenuItem>
                  <MenuItem value="A">A</MenuItem>
                  <MenuItem value="B">B</MenuItem>
                  <MenuItem value="AB">AB</MenuItem>
                  <MenuItem value="O">O</MenuItem>
                </TextField>
              )}
            />
            <Controller
              control={control}
              defaultValue=""
              name="religion"
              render={({ onChange, ref }) => (
                <TextField
                  className={classes.field}
                  select
                  fullWidth
                  defaultValue=""
                  label="Agama"
                  inputRef={ref}
                  onChange={(e) => onChange(e.target.value)}
                  error={!!errors.religion}
                  helperText={!!errors.religion && errors.religion.message}
                >
                  <MenuItem value="" disabled></MenuItem>
                  <MenuItem value="islam">Islam</MenuItem>
                  <MenuItem value="kristen">Kristen</MenuItem>
                  <MenuItem value="budha">Budha</MenuItem>
                  <MenuItem value="hindu">Hindu</MenuItem>
                  <MenuItem value="katolik">Katolik</MenuItem>
                </TextField>
              )}
            />
            <Controller
              control={control}
              defaultValue=""
              name="personality"
              render={({ onChange, ref }) => (
                <TextField
                  className={classes.field}
                  select
                  fullWidth
                  label="Personal Genetik STFIN"
                  defaultValue=""
                  inputRef={ref}
                  onChange={(e) => onChange(e.target.value)}
                  error={!!errors.personality}
                  helperText={
                    !!errors.personality && errors.personality.message
                  }
                >
                  <MenuItem value="" disabled></MenuItem>
                  <MenuItem value="Insting">Insting</MenuItem>
                  <MenuItem value="Sensing Introvert">
                    Sensing Introvert
                  </MenuItem>
                  <MenuItem value="Sensing Ekstrovert">
                    Sensing Ekstrovert
                  </MenuItem>
                  <MenuItem value="Thingking Introvert">
                    Thingking Introvert
                  </MenuItem>
                  <MenuItem value="Thingking Ekstrovert">
                    Thingking Ekstrovert
                  </MenuItem>
                  <MenuItem value="Intuiting Introvert">
                    Intuiting Introvert
                  </MenuItem>
                  <MenuItem value="Intuiting Ekstrovert">
                    Intuiting Ekstrovert
                  </MenuItem>
                  <MenuItem value="Feeling Introvert">
                    Feeling Introvert
                  </MenuItem>
                  <MenuItem value="Feeling Ekstrovert">
                    Feeling Ekstrovert
                  </MenuItem>
                </TextField>
              )}
            />
            <TextField
              type="number"
              className={classes.field}
              fullWidth
              name="weight"
              label="Berat Badan"
              inputRef={register}
              error={!!errors.weight}
              helperText={!!errors.weight && errors.weight.message}
            />
          </Grid>
        </Grid>
        <Grid className={classes.textarea}>
          <TextField
            multiline
            fullWidth
            rows={5}
            inputRef={register}
            variant="outlined"
            name="about_me"
            label="Tentang Saya"
            error={!!errors.about_me}
            helperText={!!errors.about_me && errors.about_me.message}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    status: state.status,
  };
};

export default connect(mapStateToProps, { getStatusJob })(Identitas);
