import axios from "../../axios-orders";
import { setAlert } from "./alert";
import { SET_COMPANY } from "./actionTypes";

export const getCompany = () => async (dispatch) => {
  try {
    const res = await axios({
      url: "/staff/company",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    const data = res.data.data.map((value) => ({
      value: value.id,
      label: value.name,
    }));
    dispatch({
      type: SET_COMPANY,
      payload: data,
    });
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};
