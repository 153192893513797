import axios from "../../axios-orders";
import { setAlert } from "./alert";
import {
  SET_LOADING_DOCUMENT,
  SET_LOADING_DOCUMENT_DONE,
  SET_DOCUMENT,
} from "./actionTypes";

export const postDocument = (data, userId, role) => async (dispatch) => {
  dispatch(setLoadingDocument());
  try {
    const res = await axios.post(`/staff/user/employee/document`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch(setLoadingDocumentDone());
    dispatch(setAlert("Dokumen Berhasil Di Upload", "success"));
    dispatch(getDocument(userId, role));
  } catch (error) {
    dispatch(setAlert("Dokumen Gagal Di Upload", "error"));
  }
};

export const deleteDocument = (id, userId, role) => async (dispatch) => {
  dispatch(setLoadingDocument());
  try {
    const res = await axios.delete(`/staff/user/employee/document/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch(setLoadingDocumentDone());
    dispatch(setAlert("Dokumen Berhasil Di Hapus", "success"));
    dispatch(getDocument(userId, role));
  } catch (error) {
    dispatch(setAlert("Dokumen Gagal Di Hapus", "error"));
  }
};

export const setLoadingDocument = () => {
  return {
    type: SET_LOADING_DOCUMENT,
  };
};

export const setLoadingDocumentDone = () => {
  return {
    type: SET_LOADING_DOCUMENT_DONE,
  };
};

export const getDocument = (userId, role) => async (dispatch) => {
  let url;
  if (role === "staff") {
    url = `staff/user/own/employee/document`;
  } else {
    url = `manager/user/${userId}/employee/document`;
  }
  try {
    const res = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_DOCUMENT,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(setAlert("Can't get data document!", "error"));
  }
};
