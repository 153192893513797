import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const headCells = [
  {
    id: "no",
    numeric: false,
    disablePadding: false,
    label: "NO",
  },
  {
    id: "tipe-request",
    numeric: false,
    disablePadding: false,
    label: "Tipe Request",
  },
  {
    id: "deskripsi",
    numeric: false,
    disablePadding: false,
    label: "Deskripsi",
  },
  {
    id: "start_date",
    numeric: false,
    disablePadding: false,
    label: "Start Date",
  },
  {
    id: "end_date",
    numeric: false,
    disablePadding: false,
    label: "End Date",
  },
  {
    id: "durasi",
    numeric: false,
    disablePadding: false,
    label: "Durasi",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "approval",
    numeric: false,
    disablePadding: false,
    label: "Approval By",
  },
  {
    id: "action",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

const EnhancedTableHead = (props) => {
  return (
    <TableHead
      style={{
        backgroundColor: "#285D5F",
      }}
    >
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            style={{color: "#fff"}}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
