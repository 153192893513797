import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";
import { getAllQuotes, deleteQuotes } from "../../../../store/actions";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Button,
  Paper,
  IconButton,
  Tooltip,
  Box,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import { EnhancedTableToolbar } from "./component";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "no",
    numeric: false,
    disablePadding: false,
    label: "No",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Judul Qoutes",
  },
  { id: "action", numeric: false, disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            align="left"
            key={headCell.id}
            padding={headCell.disablePadding ? "none" : "default"}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  btnDelete: {
    color: "#fff",
    color: theme.palette.red.color500,
    "&:hover": {
      color: theme.palette.red.color700,
    },
  },
}));

const TableKehadiran = ({ quotes, getAllQuotes, setDialog, deleteQuotes }) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (id) => {
    deleteQuotes(id);
  };

  useEffect(() => {
    getAllQuotes();
  }, []);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead />
            <TableBody>
              {quotes.data.length === 0 && (
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={3}
                    component="th"
                    scope="row"
                  >
                    Data Kosong
                  </TableCell>
                </TableRow>
              )}
              {stableSort(quotes.data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow>
                      <TableCell align="left" component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left" component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">
                        <Box display="flex">
                          <IconButton
                            color="primary"
                            onClick={() =>
                              setDialog({ open: true, edit: true, value: row })
                            }
                          >
                            <Tooltip title="Edit">
                              <EditIcon />
                            </Tooltip>
                          </IconButton>
                          <IconButton onClick={() => handleDelete(row.id)}>
                            <Tooltip title="Hapus">
                              <DeleteIcon className={classes.btnDelete} />
                            </Tooltip>
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={quotes.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    quotes: state.quotes,
  };
};

export default connect(mapStateToProps, { getAllQuotes, deleteQuotes })(
  TableKehadiran
);
