import React from "react";
import { TextField } from "@material-ui/core";
import NumberFormat from "react-number-format";

const FieldNumberFormat = ({ value, onChange, ...rest }) => (
  <NumberFormat
    customInput={TextField}
    {...rest}
    fullWidth
    thousandSeparator="."
    decimalSeparator=","
    decimalScale={2}
    value={value}
    onValueChange={(target) => {
      onChange(target.floatValue);
    }}
    isNumericString
    prefix="Rp "
  />
);

export default FieldNumberFormat;
