import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import Select from "react-select";
import { connect } from "react-redux";
import moment from "moment";

// React Hook Form
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Component
import DatePicker from "../../components/UI/DatePicker/DatePicker";

import {
  getOverview,
  setChecked,
  sendRequestLeave,
  getCompany,
} from "../../store/actions";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../components/UI/Breadcrumb/Breadcrumb";
import { Backdrop, CircularProgress, MenuItem } from "@material-ui/core";

import {
  Container,
  Paper,
  Box,
  Grid,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  formSelect: {
    width: "100%",
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  mb: {
    marginBottom: theme.spacing(3),
  },
  filterEntitas: {
    "& .css-1uccc91-singleValue": {
      overflow: "visible",
    },
    "& .css-26l3qy-menu": {
      zIndex: 1000,
    },
    marginRight: 5,
  },
  filterTypeRequest: {
    "& .css-1uccc91-singleValue": {
      overflow: "visible",
    },
    marginRight: 5,
    "& .css-26l3qy-menu": {
      zIndex: 1000,
    },
  },
  filterStatus: {
    "& .css-1uccc91-singleValue": {
      overflow: "visible",
    },
    marginRight: 5,
    "& .css-26l3qy-menu": {
      zIndex: 1000,
    },
  },
  filterYear: {
    "& .css-1uccc91-singleValue": {
      overflow: "visible",
    },
    marginRight: 5,
    "& .css-26l3qy-menu": {
      zIndex: 1000,
    },
  },
}));

const validationSchema = yup.object().shape({
  description: yup.string().required("Wajib diisi"),
  start_date: yup.string().required("Wajib diisi").typeError("Wajib diisi"),
  end_date: yup.string().required("Wajib diisi").typeError("Wajib diisi"),
  request_type: yup.string().required("Wajib diisi").typeError("Wajib diisi"),
});

const requestType = [
  { label: "Sakit", value: "sakit" },
  { label: "Izin", value: "izin" },
  { label: "Cuti", value: "cuti" },
];

const statusType = [
  { label: "Waiting", value: "waiting" },
  { label: "Accepted", value: "accepted" },
];

const years = [
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
  { label: "2025", value: "2025" },
  { label: "2026", value: "2026" },
  { label: "2027", value: "2027" },
  { label: "2028", value: "2028" },
  { label: "2029", value: "2029" },
  { label: "2030", value: "2030" },
  { label: "2031", value: "2031" },
  { label: "2032", value: "2032" },
  { label: "2033", value: "2033" },
  { label: "2034", value: "2034" },
  { label: "2035", value: "2035" },
];

const Overview = ({
  requestLeave,
  getOverview,
  setChecked,
  sendRequestLeave,
  auth,
  getCompany,
  company,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const scrumbs = [{ name: "Overview", link: "/" }];

  const [openModal, setOpenModal] = useState(false);
  const [date, setDate] = useState(null);
  const [entitas, setEntitas] = useState(auth.user.company_id);
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [year, setYear] = useState("");

  const handleClose = () => {
    setOpenModal(false);
    setDate(null);
  };

  const { register, handleSubmit, errors, control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  const handleDateClick = (arg) => {
    // Function for Call Form create request leave

    // bind with an arrow function
    // console.log(arg);
    setDate(arg.dateStr);
    setOpenModal(true);
  };

  const onSubmit = (data, e) => {
    const formData = {
      ...data,
      start_date: moment(data.start_date).format("YYYY-MM-DD"),
      end_date: moment(data.end_date).format("YYYY-MM-DD"),
    };

    if (auth.user.role_name === "hcis") {
      sendRequestLeave(formData, true, true);
    } else {
      sendRequestLeave(formData, true);
    }

    e.target.reset();
    setValue("start_date", null);
    setValue("end_date", null);
    setValue("request_type", "");
    setOpenModal(false);
  };

  const handleDetail = (e) => {
    const id = e.event._def.publicId;
    history.push(`/request-leave/detail/${id}`);
  };
  const handleChange = (e) => {
    setChecked(requestLeave.allData, e.target.name, e.target.checked);
  };

  useEffect(() => {
    getCompany();
  }, [getCompany]);

  useEffect(() => {
    if (auth.user.role_name === "hcis") {
      getOverview(true, entitas, status, type, year);
    } else {
      getOverview(false);
    }
  }, [getOverview, entitas, status, type, year]);

  return (
    <Container className={classes.root} maxWidth="xl">
      <Breadcrumb crumb={scrumbs} />
      {auth.user.role_name === "hcis" && (
        <Grid container spacing={2} className={classes.mb}>
          <Grid item md={8} xs={12} sm={10} lg={7}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={4}>
                <Select
                  className={classes.filterEntitas}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Entitas..."
                  options={company.company}
                  onChange={(e) =>
                    e === null
                      ? setEntitas(auth.user.company_id)
                      : setEntitas(e.value)
                  }
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Select
                  className={classes.filterTypeRequest}
                  isClearable={true}
                  isSearchable={true}
                  options={requestType}
                  placeholder="Pilih Tipe Izin..."
                  onChange={(e) =>
                    e === null ? setType("") : setType(e.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Select
                  className={classes.filterStatus}
                  options={statusType}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Status..."
                  onChange={(e) =>
                    e === null ? setStatus("") : setStatus(e.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Select
                  className={classes.filterYear}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Year..."
                  options={years}
                  onChange={(e) =>
                    e === null ? setYear("") : setYear(e.value)
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item lg={9}>
          <Paper elevation={3}>
            <Box p={3}>
              <FullCalendar
                plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
                height="auto"
                defaultView="month"
                eventClick={handleDetail}
                selectable={true}
                displayEventTime={false}
                dateClick={handleDateClick}
                eventBorderColor={"#b4b4b4"}
                eventMinHeight={300}
                allDay={true}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "dayGridMonth,listMonth",
                }}
                events={requestLeave.allData.filter((v) => {
                  return v.checked === true;
                })}
                initialView="dayGridMonth"
                editable={true}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item lg={3}>
          <Paper elevation={3}>
            <Box p={3}>
              <Typography>Employee</Typography>
              <FormControl component="fieldset">
                <FormGroup>
                  {requestLeave.allData
                    .filter(
                      (thing, index, self) =>
                        index ===
                        self.findIndex(
                          (t) =>
                            t.title === thing.title &&
                            t.user_name === thing.user_name
                        )
                    )
                    .map((item, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          control={
                            <Checkbox
                              onChange={handleChange}
                              checked={item.checked ? true : false}
                              name={item.title}
                            />
                          }
                          label={item.title}
                        />
                      );
                    })}
                </FormGroup>
              </FormControl>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <div>
        <Dialog
          open={openModal}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">Request Leave</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Silahkan isi form di bawah ini untuk mengajukan cuti/izin/sakit
            </DialogContentText>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="request_type"
                defaultValue=""
                control={control}
                render={({ onChange, ref, value }) => (
                  <TextField
                    className={classes.field}
                    fullWidth
                    select
                    inputRef={ref}
                    value={value}
                    label="Tipe Perizinan"
                    onChange={(e) => onChange(e.target.value)}
                    error={!!errors.request_type}
                    helperText={
                      !!errors.request_type && errors.request_type.message
                    }
                  >
                    <MenuItem value="" disabled></MenuItem>
                    <MenuItem value="cuti">Cuti</MenuItem>
                    <MenuItem value="izin">Izin</MenuItem>
                    <MenuItem value="sakit">Sakit</MenuItem>
                  </TextField>
                )}
              />
              <Controller
                control={control}
                defaultValue={date}
                name="start_date"
                render={({ onChange, ref }) => (
                  <DatePicker
                    className={classes.field}
                    fullWidth
                    label="Start Date"
                    name="start_date"
                    value={date}
                    format="dd-MM-yyyy"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    inputRef={ref}
                    error={!!errors.start_date}
                    helperText={
                      !!errors.start_date && errors.start_date.message
                    }
                  />
                )}
              />
              <Controller
                control={control}
                defaultValue={null}
                name="end_date"
                render={({ onChange, ref, value }) => (
                  <DatePicker
                    className={classes.field}
                    fullWidth
                    label="End Date"
                    name="end_date"
                    value={value}
                    format="dd-MM-yyyy"
                    onChange={(e) => {
                      onChange(e);
                    }}
                    inputRef={ref}
                    error={!!errors.end_date}
                    helperText={!!errors.end_date && errors.end_date.message}
                  />
                )}
              />
              <TextField
                fullWidth
                label="Deskripsi"
                name="description"
                inputRef={register}
                error={!!errors.description}
                helperText={!!errors.description && errors.description.message}
              />
              <Button
                style={{ marginBottom: 10, marginTop: 15 }}
                type="submit"
                variant="contained"
                fullWidth
                color="primary"
              >
                Submit
              </Button>
              <Button
                type="button"
                variant="contained"
                fullWidth
                color="default"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </form>
          </DialogContent>
        </Dialog>
      </div>
      <Backdrop className={classes.backdrop} open={requestLeave.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

const mapStateTopProps = (state) => {
  return {
    requestLeave: state.requestLeave,
    auth: state.auth,
    company: state.company,
  };
};

export default connect(mapStateTopProps, {
  getOverview,
  setChecked,
  sendRequestLeave,
  getCompany,
})(Overview);
