import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect } from "react-redux";
import { changeStatusReimburse } from "../../../../store/actions";

const DialogAlert = ({ dialog, onClose, changeStatusReimburse, auth }) => {
  const onSubmit = () => {
    let data;

    if (dialog.type === "confirm") {
      data = {
        status: "disetujui",
      };
    } else if (dialog.type === "rejected") {
      data = {
        status: "ditolak",
      };
    } else {
      data = {
        status: "transfer",
      };
    }
    if (
      auth.user.role_name === "hcis" &&
      auth.user.job_name === "Staff Finance"
    ) {
      changeStatusReimburse(dialog.value.id, dialog.type, data, true, true);
    } else if (auth.user.role_name === "hcis" && auth.user.sequence === 1) {
      changeStatusReimburse(dialog.value.id, dialog.type, data, true);
    } else {
      changeStatusReimburse(dialog.value.id, dialog.type, data);
    }
    onClose();
  };
  return (
    <div>
      <Dialog
        open={dialog.open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Apakah anda yakin?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Pastikan data reimburse sudah benar...
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" variant="contained">
            Cancel
          </Button>
          <Button onClick={onSubmit} color="inherit" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { changeStatusReimburse })(DialogAlert);
