import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

import { getIdentitas } from "../../../../store/actions";
import { capitalize, isEmpty } from "../../../../shared/utility";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  text: {
    marginBottom: theme.spacing(2),
  },
}));

function IdentitasDiri({ getIdentitas, auth, userData }) {
  const classes = useStyles();
  const params = useParams();

  useEffect(() => {
    if (auth.user.role_name === "hcis" || auth.user.role_name === "manager") {
      getIdentitas(true, params.userId);
    } else {
      getIdentitas();
    }
  }, [auth.user.role_name, getIdentitas, params.userId]);

  return (
    <Grid container>
      <Grid item xs={12} lg={6}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.text}>
              Nama Lengkap
            </Typography>
            <Typography variant="h6" className={classes.text}>
              Nomor KTP
            </Typography>
            <Typography variant="h6" className={classes.text}>
              Nomor NPWP
            </Typography>
            <Typography variant="h6" className={classes.text}>
              Nomor Rekening BSI
            </Typography>
            <Typography variant="h6" className={classes.text}>
              Tempat, Tanggal Lahir
            </Typography>
            <Typography variant="h6" className={classes.text}>
              Usia
            </Typography>
            <Typography variant="h6" className={classes.text}>
              Jenis Kelamin
            </Typography>
            <Typography variant="h6" className={classes.text}>
              Status Pernikahan
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.text}>
              {!userData.loadingProfile && !isEmpty(userData.profile.name)
                ? `: ${capitalize(userData.profile.name)}`
                : "Data tidak tersedia"}
            </Typography>
            <Typography variant="h6" className={classes.text}>
              {!userData.loadingProfile && !isEmpty(userData.profile.id_number)
                ? `: ${userData.profile.id_number}`
                : "Data tidak tersedia"}
            </Typography>
            <Typography variant="h6" className={classes.text}>
              {!userData.loadingProfile &&
              !isEmpty(userData.profile.tax_number) ? (
                <NumberFormat
                  value={userData.profile.tax_number}
                  displayType="text"
                  format=": ##.###.###.#-###.###"
                />
              ) : (
                "Data tidak tersedia"
              )}
            </Typography>
            <Typography variant="h6" className={classes.text}>
              {!userData.loadingProfile &&
              !isEmpty(userData.profile.account_number)
                ? `: ${userData.profile.account_number}`
                : "Data tidak tersedia"}
            </Typography>
            <Typography variant="h6" className={classes.text}>
              {!userData.loadingProfile &&
              !isEmpty(userData.profile.place_of_birth) &&
              !isEmpty(userData.profile.date_of_birth)
                ? `: ${capitalize(userData.profile.place_of_birth)}, ${
                    userData.profile.date_of_birth
                  }`
                : "Data tidak tersedia"}
            </Typography>
            <Typography variant="h6" className={classes.text}>
              {!userData.loadingProfile
                ? `: ${userData.profile.age} Tahun`
                : "Data tidak tersedia"}
            </Typography>
            <Typography variant="h6" className={classes.text}>
              {!userData.loadingProfile && !isEmpty(userData.profile.gender)
                ? `: ${capitalize(userData.profile.gender)}`
                : "Data tidak tersedia"}
            </Typography>
            <Typography variant="h6" className={classes.text}>
              {!userData.loadingProfile &&
              !isEmpty(userData.profile.marital_status)
                ? `: ${capitalize(userData.profile.marital_status)}`
                : "Data tidak tersedia"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* -------------- */}
      <Grid item xs={12} lg={6}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.text}>
              Agama
            </Typography>
            <Typography variant="h6" className={classes.text}>
              Suku
            </Typography>
            <Typography variant="h6" className={classes.text}>
              Golongan Darah
            </Typography>
            <Typography variant="h6" className={classes.text}>
              Tinggi Badan
            </Typography>
            <Typography variant="h6" className={classes.text}>
              Berat Badan
            </Typography>
            <Typography variant="h6" className={classes.text}>
              Personal Genetik STIFIN
            </Typography>
            <Typography variant="h6" className={classes.text}>
              Tentang Saya
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h6" className={classes.text}>
              {!userData.loadingProfile && !isEmpty(userData.profile.religion)
                ? `: ${capitalize(userData.profile.religion)}`
                : "Data tidak tersedia"}
            </Typography>
            <Typography variant="h6" className={classes.text}>
              {!userData.loadingProfile && !isEmpty(userData.profile.ethnic)
                ? `: ${capitalize(userData.profile.ethnic)}`
                : "Data tidak tersedia"}
            </Typography>
            <Typography variant="h6" className={classes.text}>
              {!userData.loadingProfile && !isEmpty(userData.profile.blood_type)
                ? `: ${capitalize(userData.profile.blood_type)}`
                : "Data tidak tersedia"}
            </Typography>
            <Typography variant="h6" className={classes.text}>
              {!userData.loadingProfile && !isEmpty(userData.profile.height)
                ? `: ${userData.profile.height} cm`
                : "Data tidak tersedia"}
            </Typography>
            <Typography variant="h6" className={classes.text}>
              {!userData.loadingProfile && !isEmpty(userData.profile.weight)
                ? `: ${userData.profile.weight} Kg`
                : "Data tidak tersedia"}
            </Typography>
            <Typography variant="h6" className={classes.text}>
              {!userData.loadingProfile &&
              !isEmpty(userData.profile.personality)
                ? `: ${capitalize(userData.profile.personality)}`
                : "Data tidak tersedia"}
            </Typography>
            <Typography variant="h6" className={classes.text}>
              {!userData.loadingProfile && !isEmpty(userData.profile.about_me)
                ? `: ${userData.profile.about_me}`
                : "Data tidak tersedia"}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    userData: state.detailEmployee,
  };
};

export default connect(mapStateToProps, { getIdentitas })(
  React.memo(IdentitasDiri)
);
