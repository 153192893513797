import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: false,
  data: [],
  randomQuotes: null,
  loadingRandomQuotes: true,
};

const setAllQuotes = (state, action) => {
  return updateObject(state, { loading: false, data: action.payload });
};
const setQuotes = (state, action) => {
  return updateObject(state, {
    loading: false,
    data: [],
    randomQuotes: action.payload,
    loadingRandomQuotes: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ALL_QUOTES:
      return setAllQuotes(state, action);
    case actionTypes.SET_QUOTES:
      return setQuotes(state, action);
    default:
      return state;
  }
};

export default reducer;
