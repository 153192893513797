import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";

import { getProvince } from "../../../store/actions";
import Breadcrumb from "../../../components/UI/Breadcrumb/Breadcrumb";
import {
  EditKTP,
  EditDomisili,
  EditMail,
  KTP,
  Domisili,
  Mail,
} from "./component";
import { isEmpty } from "../../../shared/utility";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  marLeft: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  containerCard: {
    marginTop: theme.spacing(5),
  },
}));

const crumbs = [
  { name: "Dashboard", link: "/" },
  { name: "Detail Employee", link: "/employee" },
  { name: "Edit Alamat", link: "" },
];

const FormEditAlamat = ({ detailEmployee, auth, address, getProvince }) => {
  const classes = useStyles();
  const params = useParams();

  useEffect(() => {
    if (isEmpty(address.province)) {
      getProvince();
    }
  }, [address.province, getProvince]);

  if (
    detailEmployee.loadingProfile ||
    (auth.user.role_name !== "hcis" &&
      auth.user.id !== detailEmployee.profile.user_id)
  ) {
    return <Redirect to={`/detail-employee/${params.id}/${params.userId}`} />;
  }

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.marLeft}>
        Edit Alamat
      </Typography>
      <Breadcrumb crumb={crumbs} />
      <div>
        {detailEmployee.alamat.findIndex((x) => x.type === "ktp") !== -1 ? (
          <EditKTP
            valueEdit={
              detailEmployee.alamat[
                detailEmployee.alamat.findIndex((x) => x.type === "ktp")
              ]
            }
          />
        ) : (
          <KTP />
        )}
      </div>
      <div className={classes.containerCard}>
        {detailEmployee.alamat.findIndex((x) => x.type === "domisili") !==
        -1 ? (
          <EditDomisili
            valueEdit={
              detailEmployee.alamat[
                detailEmployee.alamat.findIndex((x) => x.type === "domisili")
              ]
            }
          />
        ) : (
          <Domisili />
        )}
      </div>
      <div className={classes.containerCard}>
        {detailEmployee.alamat.findIndex((x) => x.type === "mail") !== -1 ? (
          <EditMail
            valueEdit={
              detailEmployee.alamat[
                detailEmployee.alamat.findIndex((x) => x.type === "mail")
              ]
            }
          />
        ) : (
          <Mail />
        )}
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    detailEmployee: state.detailEmployee,
    auth: state.auth,
    address: state.address,
  };
};

export default connect(mapStateToProps, { getProvince })(FormEditAlamat);
