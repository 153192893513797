/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import moment from "moment";

import { getCompany, getAbsenData } from "../../store/actions";
import { TableKehadiran, Filter, DialogImport } from "./component";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("xs")]: {
      padding: 10,
    },
  },
  pag: {
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
  },
}));

const KelolaKehadiran = ({
  company,
  getCompany,
  auth,
  getAbsenData,
  absensi,
}) => {
  const classes = useStyles();

  const [dialogImport, setDialogImport] = useState(false);
  const [filterDate, setFilterDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [filterEntitas, setFilterEntitas] = useState("");
  const [page, setPage] = useState(1);

  const openDialogImport = () => {
    setDialogImport(true);
  };

  const closeDialogImport = () => {
    setDialogImport(false);
  };

  const handlePage = (e, value) => {
    setPage(value);
  };

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    const isManager =
      auth.user.role_name === "hcis" || auth.user.role_name === "manager";
    getAbsenData(isManager, filterDate, filterEntitas, page, 10);
  }, [filterDate, filterEntitas, page]);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3">Kelola Kehadiran</Typography>
      <Grid container style={{ marginBottom: 25, marginTop: 30 }}>
        <Filter
          roleName={auth.user.role_name}
          listEntitas={company}
          openDialogImport={openDialogImport}
          filterEntitas={filterEntitas}
          setFilterDate={setFilterDate}
          setFilterEntitas={setFilterEntitas}
        />
      </Grid>
      <TableKehadiran data={absensi} />
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.pagination}
      >
        <Grid item xs={8}>
          <Pagination
            className={classes.pag}
            count={absensi.last_page}
            page={page}
            onChange={handlePage}
            color="primary"
          />
        </Grid>
      </Grid>
      <DialogImport
        listEntitas={company}
        open={dialogImport}
        onClose={closeDialogImport}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  company: state.company,
  auth: state.auth,
  absensi: state.absensi,
});

export default connect(mapStateToProps, { getCompany, getAbsenData })(
  KelolaKehadiran
);
