import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  token: localStorage.getItem("access_token") || null,
  user: JSON.parse(localStorage.getItem("user")) || null,
  error: {},
  loading: false,
  loadingSwitchAccount: false,
  loadingPassword: false,
  allAccounts: JSON.parse(localStorage.getItem("allAccounts")) || [],
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.tokenId,
    user: action.userData,
    error: null,
    loading: false,
    loadingSwitchAccount: false,
  });
};

const authFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

const getAllAccount = (state, action) => {
  return updateObject(state, { allAccounts: action.payload });
};

const switchStart = (state, action) => {
  return updateObject(state, { loadingSwitchAccount: true });
};

const switchAccount = (state, action) => {
  return updateObject(state, {
    token: action.tokenId,
    user: action.userData,
    error: null,
    loading: false,
    loadingSwitchAccount: false,
  });
};

const changeStatus = (state, action) => {
  return updateObject(state, {
    user: {
      ...state.user,
      status: "old",
    },
  });
};

const setLoadingPassword = (state, action) => {
  return updateObject(state, { loadingPassword: true });
};

const setLoadingPasswordDone = (state, action) => {
  return updateObject(state, { loadingPassword: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.SWITCH_ACCOUNT:
      return switchAccount(state, action);
    case actionTypes.SWITCH_START:
      return switchStart(state, action);
    case actionTypes.GET_ALL_ACCOUNT:
      return getAllAccount(state, action);
    case actionTypes.CHANGE_STATUS:
      return changeStatus(state, action);
    case actionTypes.SET_LOADING_PASSWORD:
      return setLoadingPassword(state, action);
    case actionTypes.SET_LOADING_PASSWORD_DONE:
      return setLoadingPasswordDone(state, action);
    // case actionTypes.AUTH_LOGOUT: return authLogout(state, action)

    default:
      return state;
  }
};

export default reducer;
