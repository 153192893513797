/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Container,
  Button,
} from "@material-ui/core";

import {
  sendAbsentMasuk,
  getAbsenStatus,
  sendAbsentPulang,
} from "../../store/actions";
import Breadscrumb from "../../components/UI/Breadcrumb/Breadcrumb";
import { Dialog, FormAbsen, DialogPulang } from "./component";

const useStyles = makeStyles((theme) => ({
  textTitle: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  card: {
    position: "relative",
  },
  cardHeader: {
    height: "70px",
    width: "100%",
    background: "#285D5F",
  },
  avatar: {
    position: "absolute",
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    border: "5px solid #285D5F",
    left: "50%",
    transform: "translate(-50%, -50px)",
  },
  padTop: {
    marginTop: theme.spacing(2),
  },
  marTop: {
    marginTop: theme.spacing(7),
  },
  image: {
    width: "100%",
    height: "100%",
  },
  fieldLocation: {
    marginTop: theme.spacing(2),
  },
  btnOut: {
    marginTop: theme.spacing(2),
  },
}));

const scrumbs = [
  { name: "Dashboard", link: "/" },
  { name: "Absen", link: "" },
];

const MasukPulang = ({
  auth,
  sendAbsentMasuk,
  getAbsenStatus,
  sendAbsentPulang,
  absensi,
}) => {
  const classes = useStyles();

  const [dialog, setDialog] = useState({ open: false, data: null });
  const [dialogPulang, setDialogPulang] = useState({ open: false });

  const handleAbsenPulang = () => {
    setDialogPulang({ open: true });
  };

  const onSubmit = (value) => {
    const data = { ...value, status: "in" };
    setDialog({ open: true, data: data });
  };

  useEffect(() => {
    getAbsenStatus();
  }, []);

  return (
    <Container maxWidth="xl">
      <Typography variant="h3" className={classes.textTitle}>
        Absen Masuk
      </Typography>
      <Breadscrumb crumb={scrumbs} />
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} md={8} lg={6}>
          <Card className={classes.card}>
            <div className={classes.cardHeader}></div>
            <div className={classes.avatar}>
              <Avatar
                alt="Profile"
                src={auth.user.profile}
                className={classes.image}
              />
            </div>
            <CardContent>
              <Grid container alignItems="center" direction="column">
                <Typography variant="h4" className={classes.marTop}>
                  {auth.user.name}
                </Typography>
                <Typography variant="h3" className={classes.padTop}>
                  Selamat Datang
                </Typography>
                <Grid container justify="center" alignContent="center">
                  <Grid item xs={6}>
                    {absensi.status === "-" && (
                      <FormAbsen onSubmit={onSubmit} />
                    )}
                    {absensi.status === "in" && (
                      <Button
                        className={classes.btnOut}
                        variant="contained"
                        fullWidth
                        onClick={handleAbsenPulang}
                      >
                        Sampai Jumpa
                      </Button>
                    )}
                    {absensi.status === "out" && (
                      <div className={classes.btnOut}>
                        <Typography variant="h5" align="center">
                          Hari ini anda sudah absent
                        </Typography>
                      </div>
                    )}
                  </Grid>
                </Grid>
                <Typography variant="h4" className={classes.padTop}>
                  {moment(new Date()).format("DD MMMM YYYY, HH:mm")}
                </Typography>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        dialog={dialog}
        action={sendAbsentMasuk}
        onClose={() => setDialog({ open: false, id: null })}
      />
      <DialogPulang
        dialog={dialogPulang}
        action={sendAbsentPulang}
        onClose={() => setDialogPulang({ open: false })}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  absensi: state.absensi,
});

export default connect(mapStateToProps, {
  sendAbsentMasuk,
  getAbsenStatus,
  sendAbsentPulang,
})(MasukPulang);
