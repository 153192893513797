import { SET_NEWS, CLEAR_NEWS, SET_ONE_NEWS } from "./actionTypes";
import axios from "../../axios-orders";
import { setAlert } from "./alert";

export const getNews = (id = "", startGet) => async (dispatch) => {
  if (startGet) {
    dispatch(clearNews());
  }
  try {
    const res = await axios.get(
      `/staff/news/latest?item_size=5&news_id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    dispatch({
      type: SET_NEWS,
      payload: res.data.data,
      totalNews: res.data.total,
    });
  } catch (error) {
    dispatch(setAlert("Can't get data news!", "error"));
  }
};

export const getOneNews = (id) => async (dispatch) => {
  dispatch(clearNews());
  try {
    const res = await axios.get(`/staff/news/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch({
      type: SET_ONE_NEWS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(setAlert("Can't get data news!", "error"));
  }
};

export const sendNews = (data, history) => async (dispatch) => {
  let message;
  if (data.status == "publish") {
    message = "Berita berhasil di publish";
  } else {
    message = "Berita berhasil di simpan di draft";
  }
  try {
    await axios.post("/hcis/news", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch(setAlert(message, "success"));
    history.push(`/berita`);
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};

export const clearNews = () => {
  return {
    type: CLEAR_NEWS,
  };
};

export const deleteNews = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`/hcis/news/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch(setAlert("Berita Berhasil Di Hapus", "success"));
    dispatch(clearNews());
    dispatch(getNews());
  } catch (error) {
    dispatch(setAlert("Berita Gagal Di Hapus", "error"));
  }
};

export const editNews = (data, id, history) => async (dispatch) => {
  let message;
  if (data.status == "publish") {
    message = "Berita berhasil di publish";
  } else {
    message = "Berita berhasil di simpan di draft";
  }
  try {
    await axios.put(`/hcis/news/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch(setAlert(message, "success"));
    history.push(`/berita`);
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};
