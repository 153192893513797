import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Paper,
  Tooltip,
  IconButton,
  Grid,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import UpdateIcon from "@material-ui/icons/Update";

const headCells = [
  {
    id: "name",
    align: "left",
    disablePadding: true,
    label: "Nama Karyawan",
  },
  {
    id: "startTime",
    align: "right",
    disablePadding: false,
    label: "Waktu Mulai",
  },
  {
    id: "endTime",
    align: "right",
    disablePadding: false,
    label: "Waktu Akhir",
  },
  {
    id: "location",
    align: "right",
    disablePadding: false,
    label: "Lokasi Lembur",
  },
  {
    id: "detail",
    align: "center",
    disablePadding: false,
    label: "Detail Tugas",
  },
  { id: "status", align: "right", disablePadding: false, label: "Status" },
  { id: "action", align: "center", disablePadding: false, label: "Action" },
];

function EnhancedTableHead(props) {
  return (
    <TableHead
      style={{
        background: "#285D5F",
      }}
    >
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.align} padding="default">
            <Typography style={{ fontWeight: "bold", color: "#fff" }}>
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  iconApprove: {
    color: theme.palette.green.color600,
  },
  iconReject: {
    color: theme.palette.red.color600,
  },
}));

export default function EnhancedTable({
  data,
  handleDialog,
  handleDialogUpdate,
}) {
  const classes = useStyles();
  const history = useHistory();

  const [selected, setSelected] = React.useState([]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={data.length}
            />
            <TableBody>
              {data.data.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      onClick={(event) => handleClick(event, row.id)}
                    >
                      {row.created_by}
                    </TableCell>
                    <TableCell
                      align="right"
                      onClick={(event) => handleClick(event, row.id)}
                    >
                      {row.start}
                    </TableCell>
                    <TableCell
                      align="right"
                      onClick={(event) => handleClick(event, row.id)}
                    >
                      {row.end}
                    </TableCell>
                    <TableCell
                      align="right"
                      onClick={(event) => handleClick(event, row.id)}
                    >
                      {row.location === "home"
                        ? "Rumah"
                        : row.location === "office"
                        ? "Kantor"
                        : ""}
                    </TableCell>
                    <TableCell
                      align="right"
                      onClick={(event) => handleClick(event, row.id)}
                    >
                      {row.detail}
                    </TableCell>
                    <TableCell
                      align="right"
                      onClick={(event) => handleClick(event, row.id)}
                    >
                      <Grid>
                        {row.status}
                        {row.status === "waiting" ||
                        row.status === "progress" ? (
                          <Tooltip title="Update Status">
                            <IconButton
                              onClick={() =>
                                handleDialogUpdate({
                                  open: true,
                                  id: row.id,
                                  status: row.status,
                                })
                              }
                            >
                              <UpdateIcon style={{ color: "#0d83fe" }} />
                            </IconButton>
                          </Tooltip>
                        ) : null}
                      </Grid>
                    </TableCell>
                    <TableCell align="center">
                      <Box display="flex" justifyContent="center">
                        <Tooltip title="Confirm">
                          <IconButton
                            color="secondary"
                            onClick={() =>
                              history.push(`/form-overtime/${row.id}`)
                            }
                            disabled={
                              row.status === "approved" ||
                              row.status === "rejected" ||
                              row.status === "complete"
                            }
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel">
                          <IconButton
                            color="secondary"
                            onClick={() =>
                              handleDialog({ open: true, id: row.id })
                            }
                            disabled={
                              row.status === "approved" ||
                              row.status === "rejected" ||
                              row.status === "complete"
                            }
                          >
                            <DeleteIcon className={classes.btnCancel} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
