import {
  SET_STATUS_JOB,
  SET_STATUS_FAMILY,
  SET_STATUS_ROLE,
  SET_JOB_LIST,
  SET_SUPERVISOR_LIST,
} from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  statusJob: [],
  loadingJob: true,

  statusFamily: [],
  loadingFamily: true,

  statusRole: [],
  loadingRole: true,

  jobList: [],
  loadingJobList: true,

  supervisorList: [],
  loadingSupervisorList: true,
};

const setSupervisorList = (state, action) => {
  return updateObject(state, {
    supervisorList: action.payload,
    loadingSupervisorList: false,
  });
};

const setJobList = (state, action) => {
  return updateObject(state, {
    jobList: action.payload,
    loadingJobList: false,
  });
};

const setStatusRole = (state, action) => {
  return updateObject(state, {
    statusRole: action.payload,
    loadingRole: false,
  });
};

const setStatus = (state, action) => {
  return updateObject(state, { statusJob: action.payload, loadingJob: false });
};

const setStatusFamily = (state, action) => {
  return updateObject(state, {
    statusFamily: action.payload,
    loadingFamily: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATUS_JOB:
      return setStatus(state, action);
    case SET_STATUS_FAMILY:
      return setStatusFamily(state, action);
    case SET_STATUS_ROLE:
      return setStatusRole(state, action);
    case SET_JOB_LIST:
      return setJobList(state, action);
    case SET_SUPERVISOR_LIST:
      return setSupervisorList(state, action);
    default:
      return state;
  }
};

export default reducer;
