import React, { useState } from "react";
import Axios from "../../../../axios-orders";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  MenuItem,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import fileDownload from "js-file-download";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  btn: {
    color: "#fff",
    marginRight: theme.spacing(2),
  },
  fieldEntitas: {
    width: "150px",
    marginRight: theme.spacing(2),
  },
}));

const Filter = ({
  listEntitas,
  openDialogImport,
  roleName,
  setFilterEntitas,
  setFilterDate,
  filterEntitas,
}) => {
  const classes = useStyles();

  const [loadingExport, setLoadingExport] = useState(false);

  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const handleExport = () => {
    setLoadingExport(true);
    const filterDate = moment(date).format("YYYY-MM-DD");
    Axios.get(
      `manager/user/employee/position/attendance/export?date=${filterDate}&company_id=${filterEntitas}`,
      {
        headers: {
          "Content-Type": "application/vnd.ms-excel",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        responseType: 'blob'
      }
    )
      .then((response) => {
        setLoadingExport(false);
        fileDownload(response.data, `Absensi ${filterDate}.xls`);
      })
      .catch((error) => {
        setLoadingExport(false);
      });
  };

  return (
    <div className={classes.root}>
      {(roleName === "hcis" || roleName === "manager") && (
        <>
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={openDialogImport}
          >
            Import
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.btn}
            onClick={handleExport}
          >
            {loadingExport ? (
              <CircularProgress style={{ color: "#fff" }} />
            ) : (
              "Export"
            )}
          </Button>
        </>
      )}
      {roleName === "hcis" && (
        <TextField
          className={classes.fieldEntitas}
          select
          label="Entitas"
          size="small"
          defaultValue=""
          value={filterEntitas}
          onChange={(e) => setFilterEntitas(e.target.value)}
        >
          {listEntitas.company.map((data) => (
            <MenuItem key={data.value} value={data.value}>
              {data.label}
            </MenuItem>
          ))}
        </TextField>
      )}
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          id="date-picker-dialog"
          label="Pilih Tanggal"
          format="MM/dd/yyyy"
          size="small"
          value={date}
          onChange={(e) => {
            setDate(e);
            setFilterDate(moment(e).format("YYYY-MM-DD"));
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default Filter;
