import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Box,
  Divider,
} from "@material-ui/core";

import { getOneNews } from "../../store/actions";

// Components
import Breadcrumb from "../../components/UI/Breadcrumb/Breadcrumb";

const useStyles = makeStyles((theme) => ({
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
}));

const crumbs = [
  { name: "Dashboard", link: "/" },
  { name: "Berita", link: "/berita" },
  { name: "Detail Berita", link: "" },
];

const BeritaDetail = ({ news, getOneNews }) => {
  const classes = useStyles();
  const params = useParams();

  useEffect(() => {
    getOneNews(params.id);
  }, []);

  return (
    <Container maxWidth="xl">
      <Typography variant="h3" className={classes.marginLeft}>
        {}
      </Typography>
      <Breadcrumb crumb={crumbs} />
      <Grid container>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardHeader
              title={
                <Typography variant="h2" align="center">
                  {!news.loadingOne && news.dataOne.title}
                </Typography>
              }
              subheader={
                <Box display="flex" justifyContent="center" alignItems="center">
                  <Typography color="primary" variant="body1">
                    {!news.loadingOne && news.dataOne.user_name}
                  </Typography>
                  <Typography variant="body1" className={classes.marginLeft}>
                    Publish {!news.loadingOne && news.dataOne.created_at}
                  </Typography>
                </Box>
              }
            />
            <Divider />
            <CardContent className={classes.overflow}>
              <Typography variant="body2">
                {!news.loadingOne && news.dataOne.sub_title}
              </Typography>
            </CardContent>
            <Divider />
            <CardContent>
              {!news.loadingOne && (
                <div
                  style={{ lineHeight: 2 }}
                  dangerouslySetInnerHTML={{ __html: news.dataOne.body }}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    news: state.news,
  };
};

export default connect(mapStateToProps, { getOneNews })(BeritaDetail);
