import * as actions from "./actionTypes";
import axios from "../../axios-orders";
import { setAlert } from "./alert";
import { setModalLoginLogout } from "./modal";

export const authStart = () => {
  return {
    type: actions.AUTH_START,
  };
};

export const authSuccess = (token, data) => {
  return {
    type: actions.AUTH_SUCCESS,
    tokenId: token,
    userData: data,
  };
};
export const authSwitchAccount = (token, data) => {
  return {
    type: actions.SWITCH_ACCOUNT,
    tokenId: token,
    userData: data,
  };
};

export const authFail = (error) => {
  return {
    type: actions.AUTH_FAIL,
    error: error,
  };
};

export const getAllAccount = (data) => {
  return {
    type: actions.GET_ALL_ACCOUNT,
    payload: data,
  };
};

export const allAccount = () => async (dispatch) => {
  try {
    const res = await axios.get(`/staff/user/account`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    localStorage.setItem("allAccounts", JSON.stringify(res.data.data));
    dispatch(getAllAccount(res.data.data));
  } catch (error) {
    dispatch(setAlert("Failed get all account", "error"));
  }
};

export const switchStart = () => {
  return {
    type: actions.SWITCH_START,
  };
};

export const switchAccount = (id, history) => async (dispatch) => {
  dispatch(switchStart());
  try {
    const res = await axios.get(`staff/user/account/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    localStorage.setItem("access_token", res.data.data.token.access_token);

    localStorage.setItem("user", JSON.stringify(res.data.data));

    dispatch(
      authSwitchAccount(res.data.data.token.access_token, res.data.data)
    );
    dispatch(allAccount());

    history.push(`/`);
    dispatch(setAlert("Berhasil ganti akun", "success"));
  } catch (error) {
    dispatch(setAlert("Can't switch account", "error"));
  }
};

export const auth = (username, password, history) => {
  return (dispatch) => {
    dispatch(authStart());
    const authData = {
      username: username,
      password: password,
    };

    axios
      .post("/login", authData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        localStorage.setItem(
          "access_token",
          response.data.data.token.access_token
        );
        localStorage.setItem("user", JSON.stringify(response.data.data));

        dispatch(
          authSuccess(response.data.data.token.access_token, response.data.data)
        );
        // dispatch(setModalLoginLogout("بسم الله الرحمن الرحيم"));
        dispatch(allAccount());
        if (response.data.data.status === "new") {
          return history.push("/form-identitas");
        }
        history.push(`/`);
      })
      .catch((err) => {
        dispatch(authFail(null));
        dispatch(setAlert("Email atau Password Salah", "error"));
      });
  };
};

export const changeStatus = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  localStorage.setItem(
    "user",
    JSON.stringify({
      ...user,
      status: "old",
    })
  );
  return {
    type: actions.CHANGE_STATUS,
  };
};

export const changePassword = (data) => async (dispatch) => {
  dispatch({
    type: actions.SET_LOADING_PASSWORD,
  });
  try {
    await axios.patch(`/staff/user/password/change `, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch({
      type: actions.SET_LOADING_PASSWORD_DONE,
    });
    dispatch(setAlert("Password Berhasil Di Ubah", "success"));
  } catch (error) {
    dispatch({
      type: actions.SET_LOADING_PASSWORD_DONE,
    });
    if (
      error.response.data.message ===
      "crypto/bcrypt: hashedPassword is not the hash of the given password"
    ) {
      return dispatch(setAlert("Password Salah", "error"));
    }
    dispatch(setAlert("Password Gagal Di Ubah", "error"));
  }
};
