import axios from "../../axios-orders";
import { setAlert } from "./alert";
import {
  SET_EMPLOYEE,
  SET_TOTAL_EMPLOYEE,
  SET_TOTAL_EMPLOYEE_GENDER,
} from "./actionTypes";

export const getEmployee =
  (
    keyword = "",
    company = "",
    sequence = "",
    gender = "",
    personality = "",
    status = "",
    page = 1,
    size = 12
  ) =>
  async (dispatch) => {
    try {
      let res;
      if (
        keyword === "" &&
        company === "" &&
        sequence === "" &&
        gender === "" &&
        personality === "" &&
        status === ""
      ) {
        res = await axios.get(
          `/manager/user/employee/position?page=${page}&page_size=${size}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
      } else {
        res = await axios.get(
          `/manager/user/employee/position/filter?keyword=${keyword}&company_id=${company}&sequence=${sequence}&gender=${gender}&personality=${personality}&page=${page}&page_size=${size}&status=${status}`,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        );
      }
      dispatch({
        type: SET_EMPLOYEE,
        payload: res.data.data,
        current_page: page,
        has_previous_page: res.data.has_previous_page,
        has_next_page: res.data.has_next_page,
        previous_page: res.data.previous_page,
        next_page: res.data.next_page,
        last_page: res.data.last_page,
        total_item: res.data.total_item,
      });
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const getTotalEmployee = () => async (dispatch) => {
  try {
    const res = await axios.get(`/manager/employee/total`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_TOTAL_EMPLOYEE,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};

export const getTotalEmployeeGender =
  (company_id = "") =>
  async (dispatch) => {
    try {
      const res = await axios.get(
        `/manager/chart?company_id=${company_id}&gender=&stfin=`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (company_id === "") {
        const totalMale = res.data.data.reduce(
          (acc, item) => acc + item.male,
          0
        );
        const totalFemale = res.data.data.reduce(
          (acc, item) => acc + item.female,
          0
        );
        const totalData = {
          male: totalMale,
          female: totalFemale,
          totalEmployee: parseInt(totalMale) + parseInt(totalFemale),
        };
        dispatch({
          type: SET_TOTAL_EMPLOYEE_GENDER,
          payload: totalData,
        });
      } else {
        let totalMale = res.data.data[0].male;
        let totalFemale = res.data.data[0].female;

        const totalData = {
          male: totalMale,
          female: totalFemale,
          totalEmployee: parseInt(totalMale) + parseInt(totalFemale),
        };

        dispatch({
          type: SET_TOTAL_EMPLOYEE_GENDER,
          payload: totalData,
        });
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };
