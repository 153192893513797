/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import { getPendidikan } from "../../../../../store/actions";
import { capitalize } from "../../../../../shared/utility";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  iconAction: {
    cursor: "pointer",
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#285D5F",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const TablePendidikan = ({
  detailEmployee,
  getPendidikan,
  setDialog,
  auth,
  hcis,
}) => {
  const classes = useStyles();
  const params = useParams();

  useEffect(() => {
    if (
      (auth.user.role_name === "hcis" || auth.user.role_name === "manager") &&
      auth.user.id !== parseInt(params.userId)
    ) {
      getPendidikan(true, params.userId);
    } else {
      getPendidikan();
    }
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>No</StyledTableCell>
            <StyledTableCell>Jenjang Pendidikan</StyledTableCell>
            <StyledTableCell>Nama Perguruan Tinggi / Sekolah</StyledTableCell>
            <StyledTableCell>Jurusan / Prodi</StyledTableCell>
            <StyledTableCell>Tahun Masuk</StyledTableCell>
            <StyledTableCell>Tahun Keluar</StyledTableCell>
            <StyledTableCell>IPK</StyledTableCell>
            {auth.user.id === parseInt(params.userId) || hcis ? (
              <StyledTableCell>Action</StyledTableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {detailEmployee.pendidikan.length === 0 && (
            <StyledTableRow>
              <StyledTableCell
                align="center"
                colSpan={
                  auth.user.id === parseInt(params.userId) || hcis ? 9 : 8
                }
              >
                Data Kosong
              </StyledTableCell>
            </StyledTableRow>
          )}
          {detailEmployee.pendidikan.map((row, index) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell>{index + 1}</StyledTableCell>
              <StyledTableCell>{row.educational_stage}</StyledTableCell>
              <StyledTableCell>{capitalize(row.name)}</StyledTableCell>
              <StyledTableCell>{capitalize(row.major)}</StyledTableCell>
              <StyledTableCell>{row.in}</StyledTableCell>
              <StyledTableCell>{row.out}</StyledTableCell>
              <StyledTableCell>{row.gpa}</StyledTableCell>
              {auth.user.id === parseInt(params.userId) || hcis ? (
                <StyledTableCell>
                  <Tooltip title="Edit Data">
                    <IconButton
                      className={classes.iconAction}
                      color="primary"
                      onClick={() =>
                        setDialog({ open: true, edit: true, value: row })
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
              ) : null}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    detailEmployee: state.detailEmployee,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { getPendidikan })(TablePendidikan);
