/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Redirect, useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  TextField,
  MenuItem,
  Divider,
  Button,
} from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  getStatusJob,
  getCompany,
  getStatusRole,
  getJobList,
  getSupervisorList,
  sendInformasiPekerjaan,
  postInformasiPekerjaan,
} from '../../../store/actions';
import Breadcrumb from '../../../components/UI/Breadcrumb/Breadcrumb';
import DatePicker from '../../../components/UI/DatePicker/DatePicker';
import FieldNumberFormat from '../../../components/UI/FieldNumberFormat/FieldNumberFormat';
import { isEmpty } from '../../../shared/utility';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  marLeft: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  card: {
    margin: theme.spacing(3, 1.25, 1.25),
  },
  gridPadding: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  field: {
    marginTop: theme.spacing(1),
  },
}));

const crumbs = [
  { name: 'Dashboard', link: '/' },
  { name: 'Detail Employee', link: '/employee' },
  { name: 'Form Informasi Pekerjaan', link: '' },
];

const validationSchema = yup.object().shape({
  company_id: yup.number().required('wajib diisi'),
  job_id: yup.number().required('wajib diisi'),
  role_id: yup.number().required('wajib diisi'),
  start_at: yup.string().required('wajib diisi'),
  status_id: yup.number().required('wajib diisi'),
  // supervisor_id: yup.number().required('wajib diisi'),
  status: yup.string().required('wajib diisi'),
});

const FormInformasiPekerjaan = ({
  status,
  getStatusJob,
  getCompany,
  company,
  detailEmployee,
  getStatusRole,
  getJobList,
  getSupervisorList,
  sendInformasiPekerjaan,
  auth,
  postInformasiPekerjaan,
}) => {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();

  const { handleSubmit, register, errors, setValue, control } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    const formData = {
      ...data,
      start_at: moment(data.start_at).format('YYYY-MM-DD'),
    };
    // if (isEmpty(detailEmployee.informasiPekerjaan)) {
    //   postInformasiPekerjaan(formData, history)
    // } else {
    sendInformasiPekerjaan(formData, params.id, history);
    // }
  };

  useEffect(() => {
    if (isEmpty(status.statusJob)) {
      getStatusJob();
    }
    if (isEmpty(company.company)) {
      getCompany();
    }
    if (isEmpty(status.statusRole)) {
      getStatusRole();
    }
    if (isEmpty(status.jobList)) {
      getJobList(auth.user.role_name);
    }
    getSupervisorList(params.id);
  }, []);

  useEffect(() => {
    if (!detailEmployee.loadingInformasiPekerjaan) {
      const start_at =
        detailEmployee.informasiPekerjaan.start_at !== '01-01-0001'
          ? moment(detailEmployee.informasiPekerjaan.start_at, 'DD-MM-YYYY')
          : null;
      setValue('user_id', detailEmployee.profile.user_id);
      setValue('start_at', start_at);
      setValue('company_id', detailEmployee.informasiPekerjaan.company_id);
      setValue('base_salary', detailEmployee.informasiPekerjaan.base_salary);
      setValue(
        'take_home_pay',
        detailEmployee.informasiPekerjaan.take_home_pay
      );
      setValue(
        'supervisor_id',
        detailEmployee.informasiPekerjaan.supervisor_id
      );
      setValue('role_id', detailEmployee.informasiPekerjaan.role_id);
      setValue('status_id', detailEmployee.informasiPekerjaan.status_id);
      setValue('job_id', detailEmployee.informasiPekerjaan.job_id);
      setValue('status', detailEmployee.informasiPekerjaan.work_status);
    }
  }, []);

  if (detailEmployee.loadingInformasiPekerjaan) {
    return <Redirect to={`/detail-employee/${params.id}/${params.userId}`} />;
  }

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.marLeft}>
        Form Informasi Pekerjaan
      </Typography>
      <Breadcrumb crumb={crumbs} />
      <Card className={classes.card}>
        <CardHeader
          title={<Typography variant="h5">Form Informasi Pekerjaan</Typography>}
        />
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <Grid container>
              <Grid item xs={6} className={classes.gridPadding}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="company_id"
                  render={({ onChange, ref, value }) => (
                    <TextField
                      fullWidth
                      select
                      label="Entitas"
                      inputRef={ref}
                      defaultValue=""
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      error={!!errors.company_id}
                      helperText={
                        !!errors.company_id && errors.company_id.message
                      }
                    >
                      <MenuItem value="" disabled />
                      {company.loadingCompany && (
                        <MenuItem value="" disabled>
                          Loading....
                        </MenuItem>
                      )}
                      {company.company.map((data) => (
                        <MenuItem key={data.value} value={data.value}>
                          {data.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
                <Controller
                  control={control}
                  defaultValue=""
                  name="job_id"
                  render={({ onChange, ref, value }) => (
                    <TextField
                      fullWidth
                      select
                      className={classes.field}
                      label="Pekerjaan"
                      defaultValue=""
                      inputRef={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      error={!!errors.job_id}
                      helperText={!!errors.job_id && errors.job_id.message}
                    >
                      <MenuItem value="" disabled />
                      {status.jobList.map((data) => (
                        <MenuItem key={data.id} value={data.id}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
                <Controller
                  name="role_id"
                  control={control}
                  defaultValue=""
                  render={({ onChange, ref, value }) => (
                    <TextField
                      className={classes.field}
                      fullWidth
                      select
                      label="Role"
                      defaultValue=""
                      value={value}
                      inputRef={ref}
                      onChange={(e) => onChange(e.target.value)}
                      error={!!errors.role_id}
                      helperText={!!errors.role_id && errors.role_id.message}
                    >
                      <MenuItem value="" disabled />
                      {status.statusRole.map((data) => (
                        <MenuItem key={data.id} value={data.id}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
                <Controller
                  name="start_at"
                  control={control}
                  defaultValue={null}
                  render={({ onChange, ref, value }) => (
                    <DatePicker
                      label="Mulai Bekerja"
                      name="start_at"
                      inputRef={ref}
                      format="dd-MM-yyyy"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      error={!!errors.start_at}
                      helperText={!!errors.start_at && errors.start_at.message}
                    />
                  )}
                />
                <Controller
                  name="status"
                  control={control}
                  defaultValue=""
                  render={({ onChange, ref, value }) => (
                    <TextField
                      className={classes.field}
                      fullWidth
                      select
                      label="Status Bekerja"
                      defaultValue=""
                      inputRef={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      error={!!errors.status}
                      helperText={
                        !!errors.status && errors.status.message
                      }
                    >
                      <MenuItem value="" disabled />
                      <MenuItem value="active">Masih Bekerja</MenuItem>
                      <MenuItem value="inactive">Tidak Bekerja</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6} className={classes.gridPadding}>
                <Controller
                  control={control}
                  name="supervisor_id"
                  defaultValue=""
                  render={({ onChange, ref, value }) => (
                    <TextField
                      fullWidth
                      select
                      defaultValue=""
                      label="Nama Atasan"
                      inputRef={register}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      error={!!errors.supervisor_id}
                      helperText={
                        !!errors.supervisor_id && errors.supervisor_id.message
                      }
                    >
                      <MenuItem value="" />
                      {status.supervisorList.map((data) => (
                        <MenuItem key={data.id} value={data.id}>
                          {data.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
                <Controller
                  control={control}
                  defaultValue=""
                  name="base_salary"
                  render={({ onChange, ref, value }) => (
                    <FieldNumberFormat
                      className={classes.field}
                      label="Gaji Pokok"
                      placeholder="Rp 1.000.000"
                      inputRef={ref}
                      value={value}
                      onChange={(e) => onChange(e)}
                      error={!!errors.base_salary}
                      helperText={
                        !!errors.base_salary && errors.base_salary.message
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  defaultValue=""
                  name="take_home_pay"
                  render={({ onChange, ref, value }) => (
                    <FieldNumberFormat
                      className={classes.field}
                      label="Take Home Pay"
                      placeholder="Rp 1.000.000"
                      inputRef={ref}
                      value={value}
                      onChange={(e) => onChange(e)}
                      error={!!errors.take_home_pay}
                      helperText={
                        !!errors.take_home_pay && errors.take_home_pay.message
                      }
                    />
                  )}
                />
                <Controller
                  name="status_id"
                  control={control}
                  defaultValue=""
                  render={({ onChange, ref, value }) => (
                    <TextField
                      className={classes.field}
                      fullWidth
                      select
                      label="Status Karyawan"
                      defaultValue=""
                      inputRef={ref}
                      value={value}
                      onChange={(e) => onChange(e.target.value)}
                      error={!!errors.status_id}
                      helperText={
                        !!errors.status_id && errors.status_id.message
                      }
                    >
                      <MenuItem value="" disabled />
                      {status.statusJob.map((value) => (
                        <MenuItem key={value.id} value={value.id}>
                          {value.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button fullWidth variant="contained" color="primary" type="submit">
              Simpan
            </Button>
          </CardActions>
        </form>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  status: state.status,
  company: state.company,
  detailEmployee: state.detailEmployee,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getStatusJob,
  getCompany,
  getStatusRole,
  getJobList,
  getSupervisorList,
  sendInformasiPekerjaan,
  postInformasiPekerjaan,
})(FormInformasiPekerjaan);
