import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, MenuItem } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const useStyles = makeStyles((theme) => ({
  padTop: {
    marginTop: theme.spacing(2),
  },
  fieldLocation: {
    marginTop: theme.spacing(2),
  },
}));

const validationSchema = yup.object().shape({
  location: yup.string().required("lokasi harus diisi"),
});

const FormAbsen = ({ onSubmit }) => {
  const classes = useStyles();

  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Button
        fullWidth
        type="submit"
        variant="contained"
        className={classes.padTop}
      >
        Absen Masuk
      </Button>
      <Controller
        control={control}
        defaultValue=""
        name="location"
        render={({ onChange }) => (
          <TextField
            select
            fullWidth
            className={classes.fieldLocation}
            variant="outlined"
            defaultValue=""
            size="small"
            label="Tipe Lokasi"
            onChange={(e) => onChange(e.target.value)}
            error={!!errors.location}
            helperText={errors.location?.message}
          >
            <MenuItem value="" disabled />
            <MenuItem value="Work From Office">
              WFO (Working from Office)
            </MenuItem>
            <MenuItem value="Work From Home">WFH (Working from Home)</MenuItem>
            <MenuItem value="Dinas Luar">Dinas Luar Kota</MenuItem>
          </TextField>
        )}
      />
    </form>
  );
};

export default FormAbsen;
