import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Avatar } from "@material-ui/core";

// import { connect } from 'react-redux'
// import { getProfile } from '../../../../actions/profile'

const nameColorWhite = "#fff";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minHeight: "fit-content",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const Profile = (props) => {
  const { className, username, useremail, ...rest } = props;

  const classes = useStyles();

  return (
    <RouterLink to="/">
      <div {...rest} className={clsx(classes.root, className)}>
        <img
          src={`${process.env.PUBLIC_URL}/images/logo/logo-ehc.svg`}
          alt="logo-ehc"
          width="70%"
          height="auto"
        />
      </div>
    </RouterLink>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
