import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Paper,
  Divider,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import Breadcrumb from "../../components/UI/Breadcrumb/Breadcrumb";
import ImageIcon from "@material-ui/icons/Image";
import DescriptionIcon from "@material-ui/icons/Description";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

//Components
import Backdrop from "../../components/UI/Backdrop/Backdrop";
import { Dropzone } from "./component";

// Redux Actions
import { setAlert } from "../../store/actions/alert";
import {
  postDocument,
  getDocument,
  deleteDocument,
  getDetailInformation,
} from "../../store/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(20),
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  padLeft: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  uploadFile: {
    marginBottom: theme.spacing(3),
  },
}));

const validationSchema = yup.object().shape({
  description: yup.string().required("Deskripsi File Wajib Diisi"),
});

const MasukPulang = (props) => {
  const {
    postDocument,
    setAlert,
    document,
    getDocument,
    deleteDocument,
    getDetailInformation,
    auth,
    detailInformation,
  } = props;
  const params = useParams();
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [reset, setReset] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteDoc, setDeleteDoc] = useState(null);

  // Set Image
  const handleChangeBanner = (event) => {
    setFile(event[0]);
  };

  const handleAlertModal = (id) => {
    setOpen(true);
    setDeleteDoc(id);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setDeleteDoc(null);
  };

  const onDelete = () => {
    deleteDocument(
      deleteDoc,
      detailInformation.data.user_id,
      auth.user.role_name
    );
    setOpen(false);
    setDeleteDoc(null);
  };

  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (value, e) => {
    if (file === null) {
      return setAlert("Dokumen Belum Di Upload", "error");
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("description", value.description);

    postDocument(formData, params.userId, auth.user.role_name);
    e.target.reset();
    setReset(true);
    setFile(null);
  };

  const scrumbs = [
    {
      name: "Detail Employee",
      link: `/detail-employee/${params.id}/${params.userId}`,
    },
    { name: "Dokumen Employee", link: "" },
  ];

  useEffect(() => {
    getDetailInformation(params.id, auth.user.role_name);
    getDocument(params.userId, auth.user.role_name);
  }, []);

  return (
    <>
      <Container className={classes.root} maxWidth="xl">
        <Typography variant="h3" className={classes.padLeft}>
          Dokumen
        </Typography>
        <Breadcrumb crumb={scrumbs} />

        {document.data.length < 8 &&
          detailInformation.data.user_id === auth.user.id && (
            <>
              <Typography variant="subtitle1" className={classes.padLeft}>
                Upload Dokumen
              </Typography>
              <Paper elevation={2} className={classes.uploadFile}>
                <Box p={1}>
                  <Dropzone
                    handleChangeBanner={handleChangeBanner}
                    reset={reset}
                    setReset={setReset}
                    setFile={setFile}
                  />
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                      type="text"
                      name="description"
                      label="Deskripsi File"
                      fullWidth
                      variant="outlined"
                      style={{ marginBottom: 10 }}
                      inputRef={register}
                      error={!!errors.description}
                      helperText={
                        !!errors.description
                          ? errors.description.message
                          : "*wajib diisi"
                      }
                    />
                    <Button
                      type="submit"
                      color="primary"
                      fullWidth
                      variant="contained"
                    >
                      Submit
                    </Button>
                  </form>
                </Box>
              </Paper>
            </>
          )}

        <Paper elevation={2}>
          <Box p={1}>
            <List>
              {document.data.length < 1 && (
                <ListItem>
                  <ListItemText
                    style={{ textAlign: "center" }}
                    primary="Dokumen Tidak Tersedia"
                  />
                </ListItem>
              )}
              {document.data.map((data, index) => {
                let docName = data.path.split("/documents/")[1];
                const icon = (documentName) => {
                  switch (documentName.split(".")[1]) {
                    case "pdf":
                      return <DescriptionIcon />;
                    case "jpeg":
                      return <ImageIcon />;
                    case "png":
                      return <ImageIcon />;
                    case "jpg":
                      return <ImageIcon />;
                    default:
                      return <DescriptionIcon />;
                  }
                };
                return (
                  <div key={index}>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>{icon(docName)}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={docName}
                        secondary={data.description}
                      />
                      <Button
                        style={{ marginRight: 10 }}
                        variant="contained"
                        color="primary"
                        href={data.path}
                      >
                        Download
                      </Button>
                      <Button
                        onClick={() => {
                          handleAlertModal(data.id);
                        }}
                        variant="contained"
                        color="inherit"
                      >
                        Hapus
                      </Button>
                    </ListItem>
                    <Divider />
                  </div>
                );
              })}
            </List>
          </Box>
        </Paper>
        <Backdrop openBackdrop={document.loading} />
        <Dialog
          open={open}
          onClose={handleCloseModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Apakah anda yakin ingin menghapus file?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              File yang sudah di hapus tidak akan bisa di kembalikan
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Disagree
            </Button>
            <Button onClick={onDelete} color="primary" autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    document: state.dokumen,
    auth: state.auth,
    detailInformation: state.detailInformation,
  };
};

export default connect(mapStateToProps, {
  postDocument,
  setAlert,
  getDocument,
  deleteDocument,
  getDetailInformation,
})(MasukPulang);
