import React from "react";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const DateTimePickerComp = ({ value, onChange, label, ...rest }) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        {...rest}
        fullWidth
        autoOk
        ampm={false}
        value={value}
        onChange={onChange}
        label={label}
        format="dd LLLL yyyy,  HH:mm"
      />
    </MuiPickersUtilsProvider>
  );
};

export default DateTimePickerComp;
