/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  MenuItem,
  TextField,
  Card,
  CardHeader,
  Typography,
  Divider,
  CardContent,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import { Controller } from "react-hook-form";

import {
  getCityMail,
  getDistrictMail,
  getVillageMail,
} from "../../../../store/actions";
import { isEmpty } from "../../../../shared/utility";

const useStyles = makeStyles((theme) => ({
  gridPadding: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  field: {
    marginTop: theme.spacing(1),
  },
  card: {
    margin: theme.spacing(0, 1.25, 1.25),
  },
  cardTitle: {
    display: "flex",
    alignItems: "center",
  },
  iconTitle: {
    margin: theme.spacing(0, 1, 0, 2),
  },
}));

const AlamatKTP = (props) => {
  const {
    getCityMail,
    getDistrictMail,
    getVillageMail,
    register,
    control,
    errors,
    address,
    value,
    setValue
  } = props;
  const classes = useStyles();

  const handleProvince = (e) => {
    setValue((prev) => ({ ...prev, province: e }));
    const index = address.province.findIndex((x) => x.name === e);
    getCityMail(address.province[index].id);
  };

  const handleCity = (e) => {
    setValue((prev) => ({ ...prev, city: e }));
    const index = address.city_mail.findIndex((x) => x.name === e);
    getDistrictMail(address.city_mail[index].id);
  };

  const handleDistrict = (e) => {
    setValue((prev) => ({ ...prev, district: e }));
    const index = address.district_mail.findIndex((x) => x.name === e);
    getVillageMail(address.district_mail[index].id);
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        title={
          <div className={classes.cardTitle}>
            <HomeIcon className={classes.iconTitle} />
            <Typography variant="h4">Alamat Surat Menyurat</Typography>
          </div>
        }
      />
      <Divider />
      <CardContent>
        <Grid container>
          <Grid item xs={6} className={classes.gridPadding}>
            {/* Provinsi */}
            <Controller
              control={control}
              name="province_mail"
              defaultValue=""
              render={({ onChange, ref }) => (
                <TextField
                  fullWidth
                  select
                  label="Provinsi"
                  inputRef={ref}
                  defaultValue=""
                  value={value.province}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleProvince(e.target.value);
                  }}
                  disabled={address.loadingProvince}
                  error={!!errors.province_mail}
                  helperText={
                    !!errors.province_mail && errors.province_mail.message
                  }
                >
                  <MenuItem value="" disabled></MenuItem>
                  {address.province.map((value) => (
                    <MenuItem value={value.name} key={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                  {value.province !== "" && isEmpty(address.province) ? (
                    <MenuItem value={value.province}>{value.province}</MenuItem>
                  ) : null}
                </TextField>
              )}
            />

            {/* Kecamatan */}
            <Controller
              control={control}
              name="district_mail"
              defaultValue=""
              render={({ onChange, ref }) => (
                <TextField
                  className={classes.field}
                  fullWidth
                  select
                  label="Kecamatan"
                  inputRef={ref}
                  defaultValue=""
                  value={value.district}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleDistrict(e.target.value);
                  }}
                  disabled={address.loadingDistrict_mail}
                  error={!!errors.district_mail}
                  helperText={
                    !!errors.district_mail && errors.district_mail.message
                  }
                >
                  <MenuItem value="" disabled></MenuItem>
                  {address.district_mail.map((value) => (
                    <MenuItem value={value.name} key={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                  {value.district !== "" && isEmpty(address.district_mail) ? (
                    <MenuItem value={value.district}>{value.district}</MenuItem>
                  ) : null}
                </TextField>
              )}
            />
            <TextField
              className={classes.field}
              fullWidth
              name="postal_code_mail"
              label="Kode Pos"
              inputRef={register}
              value={value.postal_code}
              onChange={(e) =>
                setValue((prev) => ({ ...prev, postal_code: e.target.value }))
              }
              error={!!errors.postal_code_mail}
              helperText={!!errors.postal_code_mail && errors.postal_code_mail.message}
            />
          </Grid>
          <Grid item xs={6} className={classes.gridPadding}>
            {/* Kota/Kabupaten */}
            <Controller
              control={control}
              name="city_mail"
              defaultValue=""
              render={({ onChange, ref }) => (
                <TextField
                  fullWidth
                  select
                  label="Kota/Kabupaten"
                  inputRef={ref}
                  defaultValue=""
                  value={value.city}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleCity(e.target.value);
                  }}
                  disabled={address.loadingCity_mail}
                  error={!!errors.city_mail}
                  helperText={!!errors.city_mail && errors.city_mail.message}
                >
                  <MenuItem value="" disabled></MenuItem>
                  {address.city_mail.map((value) => (
                    <MenuItem value={value.name} key={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                  {value.city !== "" && isEmpty(address.city_mail) ? (
                    <MenuItem value={value.city}>{value.city}</MenuItem>
                  ) : null}
                </TextField>
              )}
            />

            {/* Kelurahan */}
            <Controller
              control={control}
              name="sub_district_mail"
              defaultValue=""
              render={({ onChange, ref }) => (
                <TextField
                  className={classes.field}
                  fullWidth
                  select
                  label="Kelurahan"
                  inputRef={ref}
                  defaultValue=""
                  value={value.village}
                  onChange={(e) => {
                    onChange(e.target.value);
                    setValue(prev => ({...prev, village: e.target.value}))
                  }}
                  disabled={address.loadingVillage_mail}
                  error={!!errors.sub_district_mail}
                  helperText={
                    !!errors.sub_district_mail && errors.sub_district_mail.message
                  }
                >
                  <MenuItem value="" disabled></MenuItem>
                  {address.village_mail.map((value) => (
                    <MenuItem value={value.name} key={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                  {value.village !== "" && isEmpty(address.village_mail) ? (
                    <MenuItem value={value.village}>{value.village}</MenuItem>
                  ) : null}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} className={classes.gridPadding}>
            <TextField
              className={classes.field}
              fullWidth
              multiline
              rows={5}
              variant="outlined"
              name="street_mail"
              label="Alamat Lengkap"
              value={value.street}
              onChange={(e) =>
                setValue((prev) => ({ ...prev, street: e.target.value }))
              }
              inputRef={register}
              error={!!errors.street_mail}
              helperText={!!errors.street_mail && errors.street_mail.message}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    address: state.address,
  };
};

export default connect(mapStateToProps, {
  getCityMail,
  getDistrictMail,
  getVillageMail,
})(AlamatKTP);
