import { combineReducers } from "redux";
import alert from "./alert";
import auth from "./auth";
import modal from "./modal";
import status from "./status";
import address from "./address";
import detailEmployee from "./detailEmployee";
import dokumen from "./dokumen";
import company from "./company";
import employee from "./employee";
import detailInformation from "./detailInformation";
import quotes from "./quotes";
import news from "./news";
import requestLeave from "./requestLeave";
import reimburse from "./reimburse";
import overtime from "./overtime";
import absensi from "./absensi";
import dashboard from "./dashboard";
import task from "./task";
import createUser from "./createUser";
import profilePicture from "./imageUploader";

export default combineReducers({
  alert,
  auth,
  modal,
  status,
  address,
  detailEmployee,
  dokumen,
  company,
  employee,
  detailInformation,
  quotes,
  news,
  requestLeave,
  reimburse,
  overtime,
  absensi,
  dashboard,
  task,
  createUser,
  profilePicture,
});
