import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';
import Select from 'react-select';
import { connect } from 'react-redux';
import axios from '../../axios-orders';
import fileDownload from 'js-file-download';

//Components
import { TableDaftarReimburse, DialogAlert } from './component';
import { getCompany, setAlert } from '../../store/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(20),
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  btnCreate: {
    marginBottom: theme.spacing(3),
  },
  formSelect: {
    width: '100%',
  },
  mb: {
    marginBottom: theme.spacing(3),
  },
  filterEntitas: {
    '& .css-1uccc91-singleValue': {
      overflow: 'visible',
    },
    marginRight: 5,
  },
  filterTypeRequest: {
    '& .css-1uccc91-singleValue': {
      overflow: 'visible',
    },
    marginRight: 5,
  },
  filterStatus: {
    '& .css-1uccc91-singleValue': {
      overflow: 'visible',
    },
    marginRight: 5,
  },
  filterYear: {
    '& .css-1uccc91-singleValue': {
      overflow: 'visible',
    },
    marginRight: 5,
  },
}));

const statusType = [
  { label: 'Waiting', value: 'waiting' },
  { label: 'Di Setujui', value: 'disetujui' },
  { label: 'Transfer', value: 'transfer' },
  { label: 'Di Tolak', value: 'ditolak' },
];

const years = [
  { label: '2020', value: '2020' },
  { label: '2021', value: '2021' },
  { label: '2022', value: '2022' },
  { label: '2023', value: '2023' },
  { label: '2024', value: '2024' },
  { label: '2025', value: '2025' },
  { label: '2026', value: '2026' },
  { label: '2027', value: '2027' },
  { label: '2028', value: '2028' },
  { label: '2029', value: '2029' },
  { label: '2030', value: '2030' },
  { label: '2031', value: '2031' },
  { label: '2032', value: '2032' },
  { label: '2033', value: '2033' },
  { label: '2034', value: '2034' },
  { label: '2035', value: '2035' },
];

const DaftarReimburse = ({ auth, company, getCompany, setAlert }) => {
  const classes = useStyles();

  const [dialogAlert, setDialogAlert] = useState({
    open: false,
    value: null,
    type: null,
  });

  const [entitas, setEntitas] = useState('');
  const [status, setStatus] = useState('');
  const [year, setYear] = useState('');
  const [loadingDownload, setLoadingDownload] = useState(false);

  const handleAlertClose = () => {
    setDialogAlert({ open: false, value: null, type: null });
  };

  const handleDownload = async () => {
    setLoadingDownload(true);
    try {
      const { data } = await axios.get(
        `/manager/user/employee/position/reimburse/export?companyId=${entitas}&status=${status}&year=${year}`,
        {
          headers: {
            'Content-Type': 'application/vnd.ms-excel',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
          responseType: 'blob',
        }
      );
      fileDownload(data, `daftar-reimburse-${status}-${year}.xls`);
      setLoadingDownload(false);
    } catch (error) {
      setLoadingDownload(false);
      setAlert('something went wrong', 'error');
    }
  };

  useEffect(() => {
    getCompany();
  }, [getCompany]);
  return (
    <>
      <Container maxWidth="xl" className={classes.root}>
        <Typography variant="h3" className={classes.mb}>
          Daftar Reimburse
        </Typography>
        <Grid container spacing={2} className={classes.mb}>
          <Grid item md={8} xs={12} sm={10}>
            <Grid container spacing={1}>
              {auth.user.role_name === 'hcis' && (
                <Grid item xs={12} sm={3}>
                  <Select
                    className={classes.filterEntitas}
                    isClearable={true}
                    isSearchable={true}
                    placeholder="Entitas..."
                    options={company.company}
                    onChange={(e) =>
                      e === null ? setEntitas('') : setEntitas(e.value)
                    }
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={3}>
                <Select
                  className={classes.filterStatus}
                  options={statusType}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Status..."
                  onChange={(e) =>
                    e === null ? setStatus('') : setStatus(e.value)
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Select
                  className={classes.filterYear}
                  options={years}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Year..."
                  onChange={(e) =>
                    e === null ? setYear('') : setYear(e.value)
                  }
                />
              </Grid>
              {(auth.user.role_name === 'hcis' ||
                auth.user.role_name === 'manager') && (
                <Grid item xs={12} sm={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleDownload}
                  >
                    {loadingDownload ? <CircularProgress /> : 'Download'}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <TableDaftarReimburse
          setDialog={setDialogAlert}
          entitas={entitas}
          status={status}
          year={year}
        />
        <DialogAlert dialog={dialogAlert} onClose={handleAlertClose} />
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    company: state.company,
  };
};

export default connect(mapStateToProps, { getCompany, setAlert })(
  DaftarReimburse
);
