import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: false,
  loadingDocument: true,
  data: [],
};

const setDocument = (state, action) => {
  return updateObject(state, { loadingDocument: false, data: action.payload });
};

const setLoadingDocument = (state, action) => {
  return updateObject(state, { loading: true });
};

const setLoadingDocumentDone = (state, action) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DOCUMENT:
      return setDocument(state, action);
    case actionTypes.SET_LOADING_DOCUMENT:
      return setLoadingDocument(state, action);
    case actionTypes.SET_LOADING_DOCUMENT_DONE:
      return setLoadingDocumentDone(state, action);
    default:
      return state;
  }
};

export default reducer;
