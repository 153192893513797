import React, { useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle as MuiDialogTitle,
  TextField,
  IconButton,
  Typography,
  MenuItem,
} from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";

// Icon
import CloseIcon from "@material-ui/icons/Close";

// React Hook Form
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Component
import DatePicker from "../../../../components/UI/DatePicker/DatePicker";

// Redux
import { connect } from "react-redux";
import {
  sendRequestLeave,
  editRequestLeave,
  deleteRequestLeave,
} from "../../../../store/actions";

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
  cardActions: {
    justifyContent: "space-between",
  },
  btnDelete: {
    color: "#fff",
    background: theme.palette.red.color500,
    "&:hover": {
      backgroundColor: theme.palette.red.color700,
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const validationSchema = yup.object().shape({
  description: yup.string().required("Wajib diisi"),
  start_date: yup.string().required("Wajib diisi").typeError("Wajib diisi"),
  end_date: yup.string().required("Wajib diisi").typeError("Wajib diisi"),
  request_type: yup.string().required("Wajib diisi").typeError("Wajib diisi"),
});

const DialogRequestLeave = ({
  dialog,
  onClose,
  sendRequestLeave,
  editRequestLeave,
  deleteRequestLeave,
}) => {
  const classes = useStyles();

  const { register, handleSubmit, errors, control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  const onSubmit = (data, e) => {
    const formData = {
      ...data,
      start_date: moment(data.start_date).format("YYYY-MM-DD"),
      end_date: moment(data.end_date).format("YYYY-MM-DD"),
    };
    if (dialog.edit) {
      editRequestLeave(formData, dialog.value.id);
    } else {
      sendRequestLeave(formData);
    }
    e.target.reset();
    setValue("start_date", null);
    setValue("end_date", null);
    setValue("request_type", "");
    onClose();
  };

  const onDelete = () => {
    deleteRequestLeave(dialog.value.id);
    onClose();
  };

  useEffect(() => {
    if (dialog.edit) {
      const startDate = moment(dialog.value.start_date, "DD-MM-YYYY").format();
      const endDate = moment(dialog.value.end_date, "DD-MM-YYYY").format();
      setValue("start_date", startDate);
      setValue("end_date", endDate);
      setValue("request_type", dialog.value.request_type);
      setValue("description", dialog.value.description);
      return;
    }

    setValue("start_date", null);
    setValue("end_date", null);
    setValue("request_type", "");
    setValue("description", "");
  }, [dialog.edit, setValue]);

  return (
    <Dialog
      open={dialog.open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClose={onClose}>
          {!dialog.edit ? "Buat" : "Edit"} Perizinan
        </DialogTitle>
        <DialogContent dividers>
          <Controller
            name="request_type"
            defaultValue={null}
            control={control}
            render={({ onChange, ref, value }) => (
              <TextField
                className={classes.field}
                fullWidth
                select
                inputRef={ref}
                value={value}
                label="Tipe Perizinan"
                onChange={(e) => onChange(e.target.value)}
                error={!!errors.request_type}
                helperText={
                  !!errors.request_type && errors.request_type.message
                }
              >
                <MenuItem value="" disabled></MenuItem>
                <MenuItem value="cuti">Cuti</MenuItem>
                <MenuItem value="izin">Izin</MenuItem>
                <MenuItem value="sakit">Sakit</MenuItem>
              </TextField>
            )}
          />
          <Controller
            control={control}
            defaultValue={null}
            name="start_date"
            render={({ onChange, ref, value }) => (
              <DatePicker
                className={classes.field}
                fullWidth
                label="Start Date"
                name="start_date"
                value={value}
                format="dd-MM-yyyy"
                onChange={(e) => {
                  onChange(e);
                }}
                inputRef={ref}
                error={!!errors.start_date}
                helperText={!!errors.start_date && errors.start_date.message}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue=""
            name="end_date"
            render={({ onChange, ref, value }) => (
              <DatePicker
                className={classes.field}
                fullWidth
                label="End Date"
                name="end_date"
                value={value}
                format="dd-MM-yyyy"
                onChange={(e) => {
                  onChange(e);
                }}
                inputRef={ref}
                error={!!errors.end_date}
                helperText={!!errors.end_date && errors.end_date.message}
              />
            )}
          />
          <TextField
            fullWidth
            label="Deskripsi"
            name="description"
            inputRef={register}
            error={!!errors.description}
            helperText={!!errors.description && errors.description.message}
          />
        </DialogContent>
        <DialogActions className={clsx({ [classes.cardActions]: dialog.edit })}>
          {dialog.edit && (
            <Button
              onClick={onDelete}
              className={classes.btnDelete}
              variant="contained"
            >
              Delete Data
            </Button>
          )}
          <Button variant="contained" color="primary" type="submit">
            {!dialog.edit ? "Simpan" : "Edit Data"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default connect(null, {
  sendRequestLeave,
  editRequestLeave,
  deleteRequestLeave,
})(DialogRequestLeave);
