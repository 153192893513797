/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Button,
  TextField,
  TableCell,
  TableRow,
  Box,
  TableContainer,
  TableHead,
  TableBody,
  Tooltip,
  IconButton,
  Table,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import EditTugasBerkala from "../DialogEdit/EditTugasBerkala";
import DialogDelete from "../DialogEdit/DialogDelete";
import {
  sendTugasBerkala,
  getTugasBerkala,
  deleteTugasBerkala,
} from "../../../../../../store/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
  boxForm: {
    padding: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#285D5F",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const validationSchema = yup.object().shape({
  name: yup.string().required("Data Wajib diisi"),
  purpose: yup.string().required("Data Wajib diisi"),
  description: yup.string().required("Data Wajib diisi"),
});

const TugasBerkala = ({
  detailEmployee,
  sendTugasBerkala,
  getTugasBerkala,
  deleteTugasBerkala,
  auth,
}) => {
  const classes = useStyles();
  const params = useParams();

  const [checkRole, setCheckRole] = useState(false);
  const [dialog, setDialog] = useState({ open: false, setDialog: null });
  const [dialogDelete, setDialogDelete] = useState({
    open: false,
    setDialog: null,
  });

  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data, e) => {
    if (auth.user.id === parseInt(params.userId)) {
      sendTugasBerkala(data);
    } else {
      sendTugasBerkala(data, params.id);
    }
    e.target.reset();
  };

  useEffect(() => {
    if (!detailEmployee.loadingInformasiPekerjaan) {
      setCheckRole(true);
    }
  }, [detailEmployee.loadingInformasiPekerjaan]);

  useEffect(() => {
    if (auth.user.role_name === "manager" && auth.user.id !== parseInt(params.userId)) {
      getTugasBerkala(true, params.id);
    } else {
      getTugasBerkala();
    }
  }, []);

  return (
    <>
      {checkRole ? (
        auth.user.id === detailEmployee.profile.user_id ||
        (auth.user.role_name === "manager" &&
          auth.user.company_id ===
            detailEmployee.informasiPekerjaan.company_id &&
          auth.user.sequence < detailEmployee.informasiPekerjaan.sequence) ? (
          <Paper elevation={3} className={classes.root}>
            <div className={classes.boxForm}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  className={classes.textField}
                  fullWidth
                  variant="outlined"
                  label={`Nama Tugas Berkala`}
                  inputRef={register}
                  name="name"
                  error={!!errors.name}
                  helperText={!!errors.name && errors.name.message}
                />
                <TextField
                  className={classes.textField}
                  fullWidth
                  variant="outlined"
                  label={`Tujuan Tugas Berkala`}
                  inputRef={register}
                  name="purpose"
                  error={!!errors.purpose}
                  helperText={!!errors.purpose && errors.purpose.message}
                />
                <TextField
                  className={classes.textField}
                  fullWidth
                  variant="outlined"
                  label="Gambaran Proses Mengerjakan"
                  inputRef={register}
                  name="description"
                  error={!!errors.description}
                  helperText={
                    !!errors.description && errors.description.message
                  }
                />

                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                >
                  Simpan
                </Button>
              </form>
            </div>
          </Paper>
        ) : null
      ) : null}

      <Paper elevation={3}>
        <Box p={3}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>No</StyledTableCell>
                  <StyledTableCell>Nama Tugas Berkala</StyledTableCell>
                  <StyledTableCell>Tujuan Tugas Berkala</StyledTableCell>
                  <StyledTableCell>Gambaran Proses Mengerjakan</StyledTableCell>
                  {checkRole ? (
                    auth.user.id === detailEmployee.profile.user_id ||
                    (auth.user.role_name === "manager" &&
                      auth.user.company_id ===
                        detailEmployee.informasiPekerjaan.company_id &&
                      auth.user.sequence <
                        detailEmployee.informasiPekerjaan.sequence) ? (
                      <StyledTableCell align="center">Action</StyledTableCell>
                    ) : null
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {detailEmployee.tugasBerkala.length === 0 && (
                  <StyledTableRow>
                    <StyledTableCell align="center" colSpan={5}>
                      Data Kosong
                    </StyledTableCell>
                  </StyledTableRow>
                )}
                {detailEmployee.tugasBerkala.map((row, index) => (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>{row.name}</StyledTableCell>
                    <StyledTableCell>{row.purpose}</StyledTableCell>
                    <StyledTableCell>{row.description}</StyledTableCell>
                    {checkRole ? (
                      auth.user.id === detailEmployee.profile.user_id ||
                      (auth.user.role_name === "manager" &&
                        auth.user.company_id ===
                          detailEmployee.informasiPekerjaan.company_id &&
                        auth.user.sequence <
                          detailEmployee.informasiPekerjaan.sequence) ? (
                        <StyledTableCell>
                          <Tooltip title="Edit">
                            <IconButton
                              color="primary"
                              aria-label="edit"
                              onClick={() =>
                                setDialog({ open: true, value: row })
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Hapus">
                            <IconButton
                              aria-label="delete"
                              onClick={() =>
                                setDialogDelete({ open: true, value: row })
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                      ) : null
                    ) : null}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <EditTugasBerkala
          dialog={dialog}
          onClose={() => setDialog({ open: false, value: null })}
        />
        <DialogDelete
          dialog={dialogDelete}
          onClose={() => setDialogDelete({ open: false, value: null })}
          action={deleteTugasBerkala}
          manager={auth.user.id === parseInt(params.userId)}
        />
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    detailEmployee: state.detailEmployee,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  sendTugasBerkala,
  getTugasBerkala,
  deleteTugasBerkala,
})(TugasBerkala);
