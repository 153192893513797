import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Button, Typography } from "@material-ui/core";

// Components
import { Table } from "./component";
import CustomRouterLink from "../../components/CustomRouterLink/CustomRouterLink";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  btnCreate: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

const Reimburse = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.marLeft}>
        Reimburse
      </Typography>
      <Button
        className={classes.btnCreate}
        color="primary"
        variant="contained"
        component={CustomRouterLink}
        to="/form-reimburse"
      >
        Create
      </Button>

      <Table />
    </Container>
  );
};

export default Reimburse;
