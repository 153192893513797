import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Typography, Link, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const Footer = (props) => {
  const { className, align, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography variant="body1" align={align}>
        Powered by EHC. &copy;{" "}
        <Link
          component="a"
          href="https://pos-cabang.eoatech.com/"
          target="_blank"
          color="inherit"
        >
          EOA Tech
        </Link>
      </Typography>
      <Grid container item justify={align} xs={12}>
        <Typography variant="caption">
          Crafted with love for "Peradaban EOA"
        </Typography>
      </Grid>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
