import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  data: {},
};

const setDetailInformation = (state, action) => {
  return updateObject(state, { data: action.payload });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DETAIL_INFORMATION:
      return setDetailInformation(state, action);
    default:
      return state;
  }
};

export default reducer;
