import React from 'react'
import {TextField} from "@material-ui/core"
import NumberFormat from "react-number-format"

const FieldNpwp = ({ onChange, value, ...rest }) => {
    return (
      <NumberFormat
        customInput={TextField}
        {...rest}
        fullWidth
        thousandSeparator="."
        format="##.###.###.#-###.###"
        decimalSeparator=","
        decimalScale={0}
        onValueChange={target => {
          onChange(target.value);
        }}
        value={value}
      />
    );
  };
  

export default FieldNpwp
