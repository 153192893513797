/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import { getSocialMedia } from "../../../../../store/actions";
import { capitalize } from "../../../../../shared/utility";

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  iconAction: {
    cursor: "pointer",
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#285D5F",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const TableSocialMedia = ({
  detailEmployee,
  getSocialMedia,
  setDialog,
  auth,
  hcis,
}) => {
  const classes = useStyles();
  const params = useParams();

  useEffect(() => {
    if (
      (auth.user.role_name === "hcis" || auth.user.role_name === "manager") &&
      auth.user.id !== parseInt(params.userId)
    ) {
      getSocialMedia(true, params.userId);
    } else {
      getSocialMedia();
    }
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>No</StyledTableCell>
            <StyledTableCell>Jenis Kontak</StyledTableCell>
            <StyledTableCell>Nomor / URL</StyledTableCell>
            {auth.user.id === parseInt(params.userId) || hcis ? (
              <StyledTableCell>Action</StyledTableCell>
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {!detailEmployee.loadingSocialMedia &&
            detailEmployee.socialMedia.length === 0 && (
              <StyledTableRow>
                <StyledTableCell
                  align="center"
                  colSpan={
                    auth.user.id === parseInt(params.userId) || hcis ? 4 : 3
                  }
                >
                  Data Kosong
                </StyledTableCell>
              </StyledTableRow>
            )}
          {detailEmployee.socialMedia.map((row, index) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell>{index + 1}</StyledTableCell>
              <StyledTableCell>
                {capitalize(row.social_item_name)}
              </StyledTableCell>
              <StyledTableCell>{row.link}</StyledTableCell>
              {auth.user.id === parseInt(params.userId) || hcis ? (
                <StyledTableCell>
                  <Tooltip title="Edit Data">
                    <IconButton
                      className={classes.iconAction}
                      color="primary"
                      onClick={() =>
                        setDialog({ open: true, edit: true, value: row })
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </StyledTableCell>
              ) : null}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    detailEmployee: state.detailEmployee,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { getSocialMedia })(
  React.memo(TableSocialMedia)
);
