import {
  SET_OVERTIME_ASSIGN,
  SET_ALL_OVERTIME,
  SET_STAFF,
} from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  assign: {
    data: [],
    loading: true,
    last_page: 1,
  },
  pagination: {
    data: [],
    lastPage: 1,
    has_next_page: false,
    has_previous_page: false,
    loading: true,
  },
  staff: {
    data: [],
    loading: true,
  },
};

const setOvertimeAssign = (state, action) => {
  return updateObject(state, {
    assign: {
      data: action.payload,
      loading: false,
      last_page: action.last_page,
    },
  });
};

const setAllOvertime = (state, action) => {
  return updateObject(state, {
    pagination: { data: action.payload, loading: false },
  });
};

const setStaff = (state, action) => {
  return updateObject(state, {
    staff: { data: action.payload, loading: false },
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_OVERTIME_ASSIGN:
      return setOvertimeAssign(state, action);
    case SET_ALL_OVERTIME:
      return setAllOvertime(state, action);
    case SET_STAFF:
      return setStaff(state, action);
    default:
      return state;
  }
};

export default reducer;
