import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  CircularProgress,
  colors,
  Divider,
  Tooltip,
  Backdrop,
  Collapse,
} from "@material-ui/core";
import { Link as RouterLink, Redirect, useLocation } from "react-router-dom";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import SwapVertIcon from "@material-ui/icons/SwapVert";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import StorageIcon from "@material-ui/icons/Storage";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import TimerIcon from "@material-ui/icons/Timer";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PeopleIcon from "@material-ui/icons/People";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import PaymentIcon from "@material-ui/icons/Payment";
import ListItemText from "@material-ui/core/ListItemText";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import ContactsIcon from "@material-ui/icons/Contacts";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import TocIcon from "@material-ui/icons/Toc";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import ViewListIcon from "@material-ui/icons/ViewList";
import AddLocationIcon from "@material-ui/icons/AddLocation";

import Hidden from "@material-ui/core/Hidden";

import { Footer, AppBar, AccountName, MobileDrawer } from "./components";

const drawerWidth = 240;
const drawerColorBlue = "linear-gradient(270deg, #FFCB47 0%, #F4BF37 100%)";

const textMenuBlack = "#535353";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    display: "flex",
  },
  button: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  content: {
    position: "relative",
    flexGrow: 1,
    marginTop: 56,
    marginLeft: 0,
    width: "calc(100% - 240px)",
    paddingTop: 40,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(2),
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: drawerColorBlue,
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    background: drawerColorBlue,
    paddingTop: theme.spacing(1.2),
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: drawerColorBlue,
  },
  menus: {
    paddingTop: theme.spacing(3),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  bottomPush: {
    position: "fixed",
    bottom: 0,
    textAlign: "center",
    paddingBottom: 10,
    width: "auto",
  },
  textMenu: {
    color: textMenuBlack,
    paddingLeft: theme.spacing(2),
  },
  textMenuNested: {
    color: textMenuBlack,
    fontSize: "14px",
    paddingLeft: theme.spacing(2),
  },
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },

  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  backdrop: {
    position: "absolute",
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  nested: {
    paddingLeft: theme.spacing(4),
    marginBottom: "-20px",
  },

  nested2: {
    paddingLeft: theme.spacing(7),
    marginBottom: "-20px",
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const Main = (props) => {
  const { children, userData } = props;
  const location = useLocation();
  const classes = useStyles();

  const [open, setOpen] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(false);
  const [openMaster, setOpenMaster] = useState(false);
  const [openEmployeeService, setOpenEmployeeService] = useState(false);
  const [openLeaves, setOpenLeaves] = useState(false);
  const [openExpense, setOpenExpense] = useState(false);
  const [openKehadiran, setOpenKehadiran] = useState(false);

  const handleClickMaster = () => {
    setOpenMaster(!openMaster);
  };
  const handleClickEmployeeService = () => {
    setOpenEmployeeService(!openEmployeeService);
  };
  const handleClickLeaves = () => {
    setOpenLeaves(!openLeaves);
  };
  const handleClickExpense = () => {
    setOpenExpense(!openExpense);
  };
  const handleClickKehadiran = () => {
    setOpenKehadiran(!openKehadiran);
  };

  const drawerHandler = () => {
    setOpen((prev) => !prev);

    setOpenMaster(false);
    setOpenEmployeeService(false);
    setOpenLeaves(false);
    setOpenExpense(false);
    setOpenKehadiran(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerMobileOpen = () => {
    setMobileMenu(true);
  };

  const handleDrawerMobileClose = () => {
    setMobileMenu(false);
  };

  if (
    location.pathname !== "/form-identitas" &&
    userData.user.status === "new"
  ) {
    return <Redirect to="/form-identitas" />;
  }

  return (
    <div className={classes.root}>
      <Hidden mdUp>
        <AppBar
          seq={userData.user.sequence}
          companyName={userData.user.company_name}
          jobName={userData.user.job_name}
          allAccounts={userData.allAccounts}
          handleDrawerMobileOpen={handleDrawerMobileOpen}
        />
        <MobileDrawer onClose={handleDrawerMobileClose} open={mobileMenu} />
      </Hidden>
      <Hidden smDown>
        <AppBar
          seq={userData.user.sequence}
          handleDrawerOpen={drawerHandler}
          open={open}
          setOpen={setOpen}
          username={userData.user.name}
          companyName={userData.user.company_name}
          jobName={userData.user.job_name}
          allAccounts={userData.allAccounts}
        />
        <SwipeableDrawer
          anchor="left"
          variant="permanent"
          open={open}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <AccountName />
          {/* {open && <AccountName />} */}
          <Divider />
          <List className={classes.menus}>
            {/* Menu Dashboard */}
            <ListItem key="dashboard" button className={classes.item}>
              <Button
                className={classes.button}
                component={CustomRouterLink}
                to="/"
              >
                {open ? (
                  <>
                    <div className={classes.icon}>
                      <DashboardIcon style={{ color: textMenuBlack }} />
                    </div>
                    <ListItemText
                      className={classes.textMenu}
                      primary="Dashboard"
                      disableTypography={true}
                    />
                  </>
                ) : (
                  <Tooltip title="Dashboard" placement="right" arrow>
                    <div className={classes.icon}>
                      <DashboardIcon style={{ color: textMenuBlack }} />
                    </div>
                  </Tooltip>
                )}
              </Button>
            </ListItem>
            {/* Menu Master Data */}
            {open && (
              <>
                <ListItem key="masterData" button className={classes.item}>
                  <Button
                    className={classes.button}
                    component={`div`}
                    onClick={handleClickMaster}
                  >
                    <div className={classes.icon}>
                      <StorageIcon style={{ color: textMenuBlack }} />
                    </div>
                    <ListItemText
                      className={classes.textMenu}
                      primary="Master Data"
                      disableTypography={true}
                    />
                    {openMaster ? (
                      <ExpandLess style={{ color: textMenuBlack }} />
                    ) : (
                      <ExpandMore style={{ color: textMenuBlack }} />
                    )}
                  </Button>
                </ListItem>
                <Collapse in={openMaster} timeout="auto" unmountOnExit>
                  <List style={{ marginTop: "-10px" }} disablePadding>
                    <ListItem key="employee" button className={classes.nested}>
                      <Button
                        className={classes.button}
                        component={CustomRouterLink}
                        to="/employee"
                      >
                        <div className={classes.icon}>
                          <PeopleIcon style={{ color: textMenuBlack }} />
                        </div>
                        <ListItemText
                          className={classes.textMenu}
                          primary="EOA Profile"
                          disableTypography={true}
                        />
                      </Button>
                    </ListItem>
                    {/* <ListItem key="entitas" button className={classes.nested}>
                      <Button
                        className={classes.button}
                        component={CustomRouterLink}
                        to="/"
                      >
                        <div className={classes.icon}>
                          <SupervisedUserCircleIcon
                            style={{ color: textMenuBlack }}
                          />
                        </div>
                        <ListItemText
                          className={classes.textMenu}
                          primary="Entitas"
                          disableTypography={true}
                        />
                      </Button>
                    </ListItem> */}
                  </List>
                </Collapse>
                {/* Menu Employee Service */}
                <ListItem key="employeeService" button className={classes.item}>
                  <Button
                    className={classes.button}
                    component={`div`}
                    onClick={handleClickEmployeeService}
                  >
                    <div className={classes.icon}>
                      <AccountBoxIcon style={{ color: textMenuBlack }} />
                    </div>
                    <ListItemText
                      className={classes.textMenu}
                      primary="Self Service"
                      disableTypography={true}
                    />
                    {openEmployeeService ? (
                      <ExpandLess style={{ color: textMenuBlack }} />
                    ) : (
                      <ExpandMore style={{ color: textMenuBlack }} />
                    )}
                  </Button>
                </ListItem>
                <Collapse in={openEmployeeService} timeout="auto" unmountOnExit>
                  <List style={{ marginTop: "-10px" }} disablePadding>
                    <ListItem key="leaves" button className={classes.nested}>
                      <Button
                        className={classes.button}
                        component={`div`}
                        onClick={handleClickLeaves}
                      >
                        <div className={classes.icon}>
                          <ExitToAppIcon style={{ color: textMenuBlack }} />
                        </div>
                        <ListItemText
                          className={classes.textMenu}
                          primary="Leaves"
                          disableTypography={true}
                        />
                        {openLeaves ? (
                          <ExpandLess style={{ color: textMenuBlack }} />
                        ) : (
                          <ExpandMore style={{ color: textMenuBlack }} />
                        )}
                      </Button>
                    </ListItem>
                    <Collapse
                      style={{ marginTop: 25 }}
                      in={openLeaves}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List style={{ marginTop: "-30px" }} disablePadding>
                        <ListItem
                          key="overview"
                          button
                          className={classes.nested2}
                        >
                          <Button
                            className={classes.button}
                            component={CustomRouterLink}
                            to="/overview"
                          >
                            <div className={classes.icon}>
                              <RecentActorsIcon
                                style={{ color: textMenuBlack }}
                              />
                            </div>
                            <ListItemText
                              className={classes.textMenu}
                              primary="Overview"
                              disableTypography={true}
                            />
                          </Button>
                        </ListItem>
                        <ListItem
                          key="requestLeave"
                          button
                          className={classes.nested2}
                        >
                          <Button
                            className={classes.button}
                            component={CustomRouterLink}
                            to="/request-leave"
                          >
                            <div className={classes.icon}>
                              <PersonAddDisabledIcon
                                style={{ color: textMenuBlack }}
                              />
                            </div>
                            <ListItemText
                              className={classes.textMenu}
                              primary="Perizinan"
                              disableTypography={true}
                            />
                          </Button>
                        </ListItem>
                        {userData.user.sequence <= 2 &&
                        (userData.user.role_name === "manager" ||
                          userData.user.role_name === "hcis") ? (
                          <ListItem
                            key="daftarIzin"
                            button
                            className={classes.nested2}
                          >
                            <Button
                              className={classes.button}
                              component={CustomRouterLink}
                              to="/daftar-izin"
                            >
                              <div className={classes.icon}>
                                <PlaylistAddCheckIcon
                                  style={{ color: textMenuBlack }}
                                />
                              </div>
                              <ListItemText
                                className={classes.textMenu}
                                primary="Daftar Izin"
                                disableTypography={true}
                              />
                            </Button>
                          </ListItem>
                        ) : null}
                      </List>
                    </Collapse>
                    <ListItem key="expense" button className={classes.nested}>
                      <Button
                        className={classes.button}
                        component={`div`}
                        onClick={handleClickExpense}
                      >
                        <div className={classes.icon}>
                          <PaymentIcon style={{ color: textMenuBlack }} />
                        </div>
                        <ListItemText
                          className={classes.textMenu}
                          primary="Expense"
                          disableTypography={true}
                        />
                        {openExpense ? (
                          <ExpandLess style={{ color: textMenuBlack }} />
                        ) : (
                          <ExpandMore style={{ color: textMenuBlack }} />
                        )}
                      </Button>
                    </ListItem>
                    <Collapse
                      style={{ marginTop: 25 }}
                      in={openExpense}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List style={{ marginTop: "-30px" }} disablePadding>
                        <ListItem
                          key="reimburse"
                          button
                          className={classes.nested2}
                        >
                          <Button
                            className={classes.button}
                            component={CustomRouterLink}
                            to="/reimburse"
                          >
                            <div className={classes.icon}>
                              <SwapVertIcon style={{ color: textMenuBlack }} />
                            </div>
                            <ListItemText
                              className={classes.textMenu}
                              primary="Reimburse"
                              disableTypography={true}
                            />
                          </Button>
                        </ListItem>
                        {(userData.user.sequence <= 2 &&
                          userData.user.role_name === "manager") ||
                        userData.user.role_name === "hcis" ||
                        userData.user.job_name === "Staff Finance" ? (
                          <ListItem
                            key="daftarReimburse"
                            button
                            className={classes.nested2}
                          >
                            <Button
                              className={classes.button}
                              component={CustomRouterLink}
                              to="/daftar-reimburse"
                            >
                              <div className={classes.icon}>
                                <TocIcon style={{ color: textMenuBlack }} />
                              </div>
                              <ListItemText
                                className={classes.textMenu}
                                primary="Daftar Reimburse"
                                disableTypography={true}
                              />
                            </Button>
                          </ListItem>
                        ) : null}

                        <ListItem
                          key="pengajuan-lembur"
                          button
                          className={classes.nested2}
                        >
                          <Button
                            className={classes.button}
                            component={CustomRouterLink}
                            to="/pengajuan-lembur"
                          >
                            <div className={classes.icon}>
                              <TimerIcon style={{ color: textMenuBlack }} />
                            </div>
                            <ListItemText
                              className={classes.textMenu}
                              primary="Pengajuan Lembur"
                              disableTypography={true}
                            />
                          </Button>
                        </ListItem>

                        {(userData.user.role_name === "hcis" ||
                          userData.user.role_name === "manager") && (
                          <>
                            <ListItem
                              key="daftar-lembur"
                              button
                              className={classes.nested2}
                            >
                              <Button
                                className={classes.button}
                                component={CustomRouterLink}
                                to="/daftar-lembur"
                              >
                                <div className={classes.icon}>
                                  <ListAltIcon
                                    style={{ color: textMenuBlack }}
                                  />
                                </div>
                                <ListItemText
                                  className={classes.textMenu}
                                  primary="Daftar Lembur"
                                  disableTypography={true}
                                />
                              </Button>
                            </ListItem>
                            <ListItem
                              key="lembur-ditugaskan"
                              button
                              className={classes.nested2}
                            >
                              <Button
                                className={classes.button}
                                component={CustomRouterLink}
                                to="/lembur-ditugaskan"
                              >
                                <div className={classes.icon}>
                                  <AssignmentLateIcon
                                    style={{ color: textMenuBlack }}
                                  />
                                </div>
                                <ListItemText
                                  className={classes.textMenu}
                                  primary="Lembur Ditugaskan"
                                  disableTypography={true}
                                />
                              </Button>
                            </ListItem>
                          </>
                        )}

                        <ListItem
                          key="daftar-ditugaskan"
                          button
                          className={classes.nested2}
                        >
                          <Button
                            className={classes.button}
                            component={CustomRouterLink}
                            to="/daftar-ditugaskan"
                          >
                            <div className={classes.icon}>
                              <ViewListIcon style={{ color: textMenuBlack }} />
                            </div>
                            <ListItemText
                              className={classes.textMenu}
                              primary="Daftar Ditugaskan"
                              disableTypography={true}
                            />
                          </Button>
                        </ListItem>
                      </List>
                    </Collapse>
                  </List>
                </Collapse>
                {/* End Of Self service */}
              </>
            )}
            {!open && (
              <>
                <ListItem key="employee" button className={classes.item}>
                  <Button
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/employee"
                  >
                    <Tooltip title="EOA Profile" placement="right" arrow>
                      <div className={classes.icon}>
                        <PeopleIcon style={{ color: textMenuBlack }} />
                      </div>
                    </Tooltip>
                  </Button>
                </ListItem>
                {/* <ListItem key="entitas" button className={classes.item}>
                  <Button
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/"
                  >
                    <Tooltip title="Entitas" placement="right" arrow>
                      <div className={classes.icon}>
                        <SupervisedUserCircleIcon
                          style={{ color: textMenuBlack }}
                        />
                      </div>
                    </Tooltip>
                  </Button>
                </ListItem> */}

                {/* Start Employee Self Service */}
                <ListItem key="overview" button className={classes.item}>
                  <Button
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/overview"
                  >
                    <Tooltip title="Overview" placement="right" arrow>
                      <div className={classes.icon}>
                        <RecentActorsIcon style={{ color: textMenuBlack }} />
                      </div>
                    </Tooltip>
                  </Button>
                </ListItem>
                <ListItem key="requestLeave" button className={classes.item}>
                  <Button
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/request-leave"
                  >
                    <Tooltip title="Perizinan" placement="right" arrow>
                      <div className={classes.icon}>
                        <ExitToAppIcon style={{ color: textMenuBlack }} />
                      </div>
                    </Tooltip>
                  </Button>
                </ListItem>
                {userData.user.sequence <= 2 &&
                (userData.user.role_name === "manager" ||
                  userData.user.role_name === "hcis") ? (
                  <ListItem key="daftarIzin" button className={classes.item}>
                    <Button
                      className={classes.button}
                      component={CustomRouterLink}
                      to="/daftar-izin"
                    >
                      <Tooltip title="Daftar Izin" placement="right" arrow>
                        <div className={classes.icon}>
                          <PlaylistAddCheckIcon
                            style={{ color: textMenuBlack }}
                          />
                        </div>
                      </Tooltip>
                    </Button>
                  </ListItem>
                ) : null}

                <ListItem key="reimburse" button className={classes.item}>
                  <Button
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/reimburse"
                  >
                    <Tooltip title="Reimburse" placement="right" arrow>
                      <div className={classes.icon}>
                        <SwapVertIcon style={{ color: textMenuBlack }} />
                      </div>
                    </Tooltip>
                  </Button>
                </ListItem>

                {(userData.user.sequence <= 2 &&
                  userData.user.role_name === "manager") ||
                userData.user.role_name === "hcis" ||
                userData.user.job_name === "Staff Finance" ? (
                  <ListItem
                    key="daftarReimburse"
                    button
                    className={classes.item}
                  >
                    <Button
                      className={classes.button}
                      component={CustomRouterLink}
                      to="/daftar-reimburse"
                    >
                      <Tooltip title="Daftar Reimburse" placement="right" arrow>
                        <div className={classes.icon}>
                          <TocIcon style={{ color: textMenuBlack }} />
                        </div>
                      </Tooltip>
                    </Button>
                  </ListItem>
                ) : null}

                <ListItem
                  key="pengajuan-lembur"
                  button
                  className={classes.item}
                >
                  <Button
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/pengajuan-lembur"
                  >
                    <Tooltip title="Pengajuan Lembur" placement="right" arrow>
                      <div className={classes.icon}>
                        <TimerIcon style={{ color: textMenuBlack }} />
                      </div>
                    </Tooltip>
                  </Button>
                </ListItem>

                {(userData.user.role_name === "hcis" ||
                  userData.user.role_name === "manager") && (
                  <>
                    <ListItem
                      key="daftar-lembur"
                      button
                      className={classes.item}
                    >
                      <Button
                        className={classes.button}
                        component={CustomRouterLink}
                        to="/daftar-lembur"
                      >
                        <Tooltip title="Daftar Lembur" placement="right" arrow>
                          <div className={classes.icon}>
                            <ListAltIcon style={{ color: textMenuBlack }} />
                          </div>
                        </Tooltip>
                      </Button>
                    </ListItem>
                    <ListItem
                      key="lembur-ditugaskan"
                      button
                      className={classes.item}
                    >
                      <Button
                        className={classes.button}
                        component={CustomRouterLink}
                        to="/lembur-ditugaskan"
                      >
                        <Tooltip
                          title="Lembur Ditugaskan"
                          placement="right"
                          arrow
                        >
                          <div className={classes.icon}>
                            <AssignmentLateIcon
                              style={{ color: textMenuBlack }}
                            />
                          </div>
                        </Tooltip>
                      </Button>
                    </ListItem>
                  </>
                )}

                <ListItem
                  key="daftar-ditugaskan"
                  button
                  className={classes.item}
                >
                  <Button
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/daftar-ditugaskan"
                  >
                    <Tooltip title="Daftar Ditugaskan" placement="right" arrow>
                      <div className={classes.icon}>
                        <ViewListIcon style={{ color: textMenuBlack }} />
                      </div>
                    </Tooltip>
                  </Button>
                </ListItem>
                {/* End Of Employee Service */}
              </>
            )}

            {/* Menu Berita */}
            <ListItem key="berita" button className={classes.item}>
              <Button
                className={classes.button}
                component={CustomRouterLink}
                to="/berita"
              >
                {open ? (
                  <>
                    <div className={classes.icon}>
                      <SpeakerNotesIcon style={{ color: textMenuBlack }} />
                    </div>
                    <ListItemText
                      className={classes.textMenu}
                      primary="Berita"
                      disableTypography={true}
                    />
                  </>
                ) : (
                  <Tooltip title="Berita" placement="right" arrow>
                    <div className={classes.icon}>
                      <SpeakerNotesIcon style={{ color: textMenuBlack }} />
                    </div>
                  </Tooltip>
                )}
              </Button>
            </ListItem>

            {/* Menu Kehadiran */}
            {open ? (
              <>
                <ListItem key="kehadiran" button className={classes.item}>
                  <Button
                    className={classes.button}
                    component={`div`}
                    onClick={handleClickKehadiran}
                  >
                    <div className={classes.icon}>
                      <VerifiedUserIcon style={{ color: textMenuBlack }} />
                    </div>
                    <ListItemText
                      className={classes.textMenu}
                      primary="Kehadiran"
                      disableTypography={true}
                    />
                    {openKehadiran ? (
                      <ExpandLess style={{ color: textMenuBlack }} />
                    ) : (
                      <ExpandMore style={{ color: textMenuBlack }} />
                    )}
                  </Button>
                </ListItem>
                <Collapse in={openKehadiran} timeout="auto" unmountOnExit>
                  <List style={{ marginTop: "-10px" }} disablePadding>
                    <ListItem key="absensi" button className={classes.nested}>
                      <Button
                        className={classes.button}
                        component={CustomRouterLink}
                        to="/absensi"
                      >
                        <div className={classes.icon}>
                          <ContactsIcon style={{ color: textMenuBlack }} />
                        </div>
                        <ListItemText
                          className={classes.textMenu}
                          primary="Absen Masuk/Pulang"
                          disableTypography={true}
                        />
                      </Button>
                    </ListItem>
                    <ListItem
                      key="kelolaKehadiran"
                      button
                      className={classes.nested}
                    >
                      <Button
                        className={classes.button}
                        component={CustomRouterLink}
                        to="/kelola-kehadiran"
                      >
                        <div className={classes.icon}>
                          <PermContactCalendarIcon
                            style={{ color: textMenuBlack }}
                          />
                        </div>
                        <ListItemText
                          className={classes.textMenu}
                          primary="Kelola Kehadiran"
                          disableTypography={true}
                        />
                      </Button>
                    </ListItem>
                    {userData.user.role_name === "hcis" && (
                      <ListItem
                        key="timeLocation"
                        button
                        className={classes.nested}
                      >
                        <Button
                          className={classes.button}
                          component={CustomRouterLink}
                          to="/time-and-location"
                        >
                          <div className={classes.icon}>
                            <AddLocationIcon style={{ color: textMenuBlack }} />
                          </div>
                          <ListItemText
                            className={classes.textMenu}
                            primary="Atur Jam"
                            disableTypography={true}
                          />
                        </Button>
                      </ListItem>
                    )}
                  </List>
                </Collapse>
              </>
            ) : (
              <>
                <ListItem key="masukPulang" button className={classes.item}>
                  <Button
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/absensi"
                  >
                    <Tooltip title="Absen Masuk/Pulang" placement="right" arrow>
                      <div className={classes.icon}>
                        <ContactsIcon style={{ color: textMenuBlack }} />
                      </div>
                    </Tooltip>
                  </Button>
                </ListItem>
                <ListItem key="kelolaKehadiran" button className={classes.item}>
                  <Button
                    className={classes.button}
                    component={CustomRouterLink}
                    to="/kelola-kehadiran"
                  >
                    <Tooltip title="Kelola Kehadiran" placement="right" arrow>
                      <div className={classes.icon}>
                        <PermContactCalendarIcon
                          style={{ color: textMenuBlack }}
                        />
                      </div>
                    </Tooltip>
                  </Button>
                </ListItem>
                {userData.user.role_name === "hcis" && (
                  <ListItem key="time-location" button className={classes.item}>
                    <Button
                      className={classes.button}
                      component={CustomRouterLink}
                      to="/time-and-location"
                    >
                      <Tooltip title="Atur Jam" placement="right" arrow>
                        <div className={classes.icon}>
                          <AddLocationIcon style={{ color: textMenuBlack }} />
                        </div>
                      </Tooltip>
                    </Button>
                  </ListItem>
                )}
              </>
            )}
          </List>
        </SwipeableDrawer>
      </Hidden>
      <main className={classes.content}>
        {children}
        <Backdrop className={classes.backdrop} open={false}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Footer />
      </main>
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth,
  };
};

export default connect(mapStateToProps)(Main);
