import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: true,
  loadingDetail: true,
  data: [],
  allData: [],
  detail: null,
  totalIzin: null,
  totalSakit: null,
  totalCuti: null,
};

const setRequestLeave = (state, action) => {
  return updateObject(state, { loading: false, data: action.payload });
};

const setDetailRequestLeave = (state, action) => {
  return updateObject(state, {
    loading: false,
    loadingDetail: false,
    data: [],
    allData: [],
    detail: action.payload,
  });
};

const setAllRequestLeave = (state, action) => {
  return updateObject(state, {
    loading: false,
    allData: action.payload,
    current_page: action.current_page,
    has_previous_page: action.has_previous_page,
    has_next_page: action.has_next_page,
    previous_page: action.previous_page,
    next_page: action.next_page,
    last_page: action.last_page,
    total_item: action.total_item,
  });
};

const setLoading = (state, action) => {
  return updateObject(state, { loading: true, data: [], allData: [] });
};
const setOverview = (state, action) => {
  return updateObject(state, { loading: false, allData: action.payload });
};

const setChecked = (state, action) => {
  return updateObject(state, { loading: false, allData: action.payload });
};
const setTotalRequestLeaveIzin = (state, action) => {
  return updateObject(state, { totalIzin: action.payload });
};
const setTotalRequestLeaveSakit = (state, action) => {
  return updateObject(state, { totalSakit: action.payload });
};
const setTotalRequestLeaveCuti = (state, action) => {
  return updateObject(state, { totalCuti: action.payload });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_REQUEST_LEAVE:
      return setRequestLeave(state, action);
    case actionTypes.SET_LOADING:
      return setLoading(state, action);
    case actionTypes.SET_ALL_REQUEST_LEAVE:
      return setAllRequestLeave(state, action);
    case actionTypes.SET_OVERVIEW:
      return setOverview(state, action);
    case actionTypes.SET_CHECKED:
      return setChecked(state, action);
    case actionTypes.SET_DETAIL_REQUEST_LEAVE:
      return setDetailRequestLeave(state, action);
    case actionTypes.SET_TOTAL_REQUEST_LEAVE_IZIN:
      return setTotalRequestLeaveIzin(state, action);
    case actionTypes.SET_TOTAL_REQUEST_LEAVE_SAKIT:
      return setTotalRequestLeaveSakit(state, action);
    case actionTypes.SET_TOTAL_REQUEST_LEAVE_CUTI:
      return setTotalRequestLeaveCuti(state, action);
    default:
      return state;
  }
};

export default reducer;
