/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  TextField,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  sendOrganisasi,
  sendEditOrganisasi,
  deleteOrganisasi,
} from "../../../../../store/actions";

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
  cardActions: {
    justifyContent: "space-between",
  },
  btnDelete: {
    color: "#fff",
    background: theme.palette.red.color500,
    "&:hover": {
      backgroundColor: theme.palette.red.color700,
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const validationSchema = yup.object().shape({
  name: yup.string().required("Wajib diisi"),
  job_title: yup.string().required("Wajib diisi"),
  description: yup.string().required("Wajib diisi"),
  in: yup.string().required("Wajib diisi"),
  out: yup.string().required("Wajib diisi"),
});

const DialogOrganisasi = ({
  dialog,
  onClose,
  sendOrganisasi,
  sendEditOrganisasi,
  deleteOrganisasi,
  hcis,
}) => {
  const classes = useStyles();
  const params = useParams();

  const { register, errors, handleSubmit, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  const handleDelete = (id) => {
    if (hcis) {
      deleteOrganisasi(id, params.userId);
    } else {
      deleteOrganisasi(id);
    }
    onClose();
  };

  const onSubmit = (data, e) => {
    if (dialog.edit) {
      if (hcis) {
        sendEditOrganisasi(data, dialog.value.id, params.userId);
      } else {
        sendEditOrganisasi(data, dialog.value.id);
      }
    } else {
      if (hcis) {
        sendOrganisasi(data, params.userId);
      } else {
        sendOrganisasi(data);
      }
    }
    e.target.reset();
    onClose();
  };

  useEffect(() => {
    if (dialog.edit) {
      setValue("name", dialog.value.name);
      setValue("job_title", dialog.value.job_title);
      setValue("description", dialog.value.description);
      setValue("in", dialog.value.in);
      setValue("out", dialog.value.out);
      return;
    }
    setValue("name", "");
    setValue("job_title", "");
    setValue("description", "");
    setValue("in", "");
    setValue("out", "");
  }, [dialog.edit]);

  return (
    <Dialog open={dialog.open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClose={onClose}>
          {!dialog.edit ? "Tambah" : "Edit"} Riwayat Organisasi
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            label="Nama Organisasi"
            name="name"
            inputRef={register}
            error={!!errors.name}
            helperText={!!errors.name && errors.name.message}
          />
          <TextField
            className={classes.field}
            fullWidth
            name="job_title"
            label="Jabatan"
            inputRef={register}
            error={!!errors.job_title}
            helperText={!!errors.job_title && errors.job_title.message}
          />
          <TextField
            className={classes.field}
            fullWidth
            name="description"
            label="Deskripsi Organisasi"
            inputRef={register}
            error={!!errors.description}
            helperText={!!errors.description && errors.description.message}
          />
          <TextField
            className={classes.field}
            type="number"
            fullWidth
            name="in"
            label="Tahun Masuk"
            inputRef={register}
            error={!!errors.in}
            helperText={!!errors.in && errors.in.message}
          />
          <TextField
            className={classes.field}
            type="number"
            fullWidth
            name="out"
            label="Tahun Keluar"
            inputRef={register}
            error={!!errors.out}
            helperText={!!errors.out && errors.out.message}
          />
        </DialogContent>
        <DialogActions className={clsx({ [classes.cardActions]: dialog.edit })}>
          {dialog.edit && (
            <Button
              className={classes.btnDelete}
              variant="contained"
              onClick={() => handleDelete(dialog.value.id)}
            >
              Delete Data
            </Button>
          )}
          <Button variant="contained" color="primary" type="submit">
            {!dialog.edit ? "Simpan" : "Edit Data"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default connect(null, {
  sendOrganisasi,
  sendEditOrganisasi,
  deleteOrganisasi,
})(DialogOrganisasi);
