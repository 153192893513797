import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Box, Paper, Tooltip } from "@material-ui/core";
import {
  RumusanTugas,
  TugasBelumSelesai,
  TugasPokok,
  TugasTambahan,
  TugasBerkala,
  TugasLainnya,
} from "./component";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabTitle: {
    fontSize: "12px",
  },
}));

function JobAnalyst() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Paper>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tooltip
            title={`"Sampaikan dengan uraian singkat yang menggambarkan ruang lingkup tugas sesuai jabatan Anda dan nyatakan dalam satu kalimat. Panjang tulisan maksimal 250 karakter. Contoh: "Admin Keuangan bertugas melaksanakan fungsi pencatatan, pengadministrasian, pengelolaan, dan pelaporan keuangan."`}
          >
            <Tab
              className={classes.tabTitle}
              label="Rumusan Tugas"
              {...a11yProps(0)}
            />
          </Tooltip>

          <Tooltip
            title={`"Adalah tugas-tugas utama yang Anda kerjakan sesuai dengan peran jabatan Anda"`}
          >
            <Tab
              className={classes.tabTitle}
              label="Tugas Pokok"
              {...a11yProps(1)}
            />
          </Tooltip>

          <Tooltip
            title={`"Adalah tugas-tugas yang sebetulnya tidak termasuk tugas jabatan Anda, akan tetapi perlu Anda lakukan karena ada hubungan dengan tugas Anda. "`}
          >
            <Tab
              className={classes.tabTitle}
              label="Tugas Tambahan"
              {...a11yProps(2)}
            />
          </Tooltip>
          <Tooltip
            title={`"Adalah tugas-tugas yang dikerjakan pada waktu-waktu tertentu, misalnya tiap minggu, bulan, triwulan, tahun, dsb. "`}
          >
            <Tab
              className={classes.tabTitle}
              label="Tugas Berkala"
              {...a11yProps(3)}
            />
          </Tooltip>

          <Tooltip
            title={`"Adalah tugas-tugas yang ditugaskan kepada Anda selain yang disebutkan di atas. Berapa hari atau berapa kali Anda menerima tugas yang tidak tertentu waktu dan caranya dalam kurun waktu 1 bulan ini. "`}
          >
            <Tab
              className={classes.tabTitle}
              label="Tugas Lainnya"
              {...a11yProps(4)}
            />
          </Tooltip>
          <Tooltip
            title={`"Adalah tugas-tugas yang ditugaskan kepada Anda selain yang disebutkan di atas. Berapa hari atau berapa kali Anda menerima tugas yang tidak tertentu waktu dan caranya dalam kurun waktu 1 bulan ini. "`}
          >
            <Tab
              className={classes.tabTitle}
              label="Tugas Belum Terlaksana"
              {...a11yProps(5)}
            />
          </Tooltip>
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <RumusanTugas />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TugasPokok />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TugasTambahan />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <TugasBerkala />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <TugasLainnya />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <TugasBelumSelesai />
      </TabPanel>
    </div>
  );
}

export default JobAnalyst;
