/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  TextField,
  DialogContent,
  MenuItem,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DatePicker from "../../../../../components/UI/DatePicker/DatePicker";

import {
  sendSeminar,
  sendEditSeminar,
  deleteSeminar,
} from "../../../../../store/actions";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
  cardActions: {
    justifyContent: "space-between",
  },
  btnDelete: {
    color: "#fff",
    background: theme.palette.red.color500,
    "&:hover": {
      backgroundColor: theme.palette.red.color700,
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const validationSchema = yup.object().shape({
  // link: yup.string().required("Link wajib diisi"),
  // start_date: yup.string().required("Tanggal pelatihan wajib diisi"),
  // end_date: yup.string().required("Tanggal selesai wajib diisi"),
  // organizer: yup.string().required("Penyelenggara wajib diisi"),
  // other_organizer: yup.string().required("Penyelenggara wajib diisi"),
  // name: yup.string().required("Nama pelatihan/seminar wajib diisi"),
  // other_name: yup.string().required("Nama pelatihan/seminar wajib diisi"),
  // as: yup.string().required("Status sebagai wajib diisi"),
  // speaker_name: yup.string().required("Nama pemateri wajib diisi"),
});

const DialogPelatihan = ({
  dialog,
  onClose,
  detailEmployee,
  sendSeminar,
  sendEditSeminar,
  deleteSeminar,
  hcis,
}) => {
  const classes = useStyles();
  const params = useParams();
  const [inputSeminar, setInputSeminar] = useState(false);
  const [inputPenyelenggara, setInputPenyelenggara] = useState(false);

  const { register, errors, handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  const handleDelete = (id) => {
    if (hcis) {
      deleteSeminar(id, params.userId);
    } else {
      deleteSeminar(id);
    }
    onClose();
  };

  const onSubmit = (data, e) => {
    const formData = {
      ...data,
      name: inputSeminar ? data.other_name : data.name,
      organizer: inputPenyelenggara ? data.other_organizer : data.organizer,
      start_date: moment(data.start_date).format("YYYY-MM-DD"),
      end_date: moment(data.end_date).format("YYYY-MM-DD"),
    };
    if (dialog.edit) {
      if (hcis) {
        sendEditSeminar(formData, dialog.value.id, params.userId);
      } else {
        sendEditSeminar(formData, dialog.value.id);
      }
    } else {
      if (hcis) {
        sendSeminar(formData, params.userId);
      } else {
        sendSeminar(formData);
      }
    }
    e.target.reset();
    onClose();
  };

  useEffect(() => {
    setInputSeminar(false);
    setInputPenyelenggara(false);
    if (dialog.edit) {
      setValue("link", dialog.value.link);
      setValue("as", dialog.value.as);
      if (
        dialog.value.name === "Private Class Pola Pertolongan Allah" ||
        dialog.value.name === "Bisnis Langit"
      ) {
        setValue("name", dialog.value.name);
      } else {
        setValue("name", "Lainnya");
        setValue("other_name", dialog.value.name);
        setInputSeminar(true);
      }

      if (
        dialog.value.organizer === "Kampus Pengusaha Muslim" ||
        dialog.value.organizer === "PPA Institute"
      ) {
        setValue("organizer", dialog.value.organizer);
      } else {
        setValue("organizer", "Lainnya");
        setValue("other_organizer", dialog.value.organizer);
        setInputPenyelenggara(true);
      }

      setValue("speaker_name", dialog.value.speaker_name);
      setValue("start_date", dialog.value.start_date);
      setValue("end_date", dialog.value.end_date);

      return;
    }
    setValue("link", "");
    setValue("as", "");
    setValue("name", "");
    setValue("speaker_name", "");
    setValue("organizer", "");
    setValue("start_date", null);
    setValue("end_date", null);
  }, [dialog.edit]);

  return (
    <Dialog open={dialog.open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClose={onClose}>
          {!dialog.edit ? "Tambah" : "Edit"} Pelatihan / Seminar
        </DialogTitle>
        <DialogContent dividers>
          <Controller
            control={control}
            name="name"
            defaultValue=""
            render={({ onChange, value }) => (
              <TextField
                className={classes.field}
                fullWidth
                select
                defaultValue=""
                label="Nama Pelatihan / Seminar"
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                  if (e.target.value === "Lainnya") {
                    setInputSeminar(true);
                  } else {
                    setInputSeminar(false);
                  }
                }}
                error={!!errors.name}
                helperText={!!errors.name && errors.name.message}
              >
                <MenuItem value="" disabled></MenuItem>
                <MenuItem value="Private Class Pola Pertolongan Allah">
                  Private Class Pola Pertolongan Allah
                </MenuItem>
                <MenuItem value="Bisnis Langit">Bisnis Langit</MenuItem>
                <MenuItem value="Lainnya">Lainnya</MenuItem>
              </TextField>
            )}
          />
          {inputSeminar && (
            <TextField
              className={classes.field}
              fullWidth
              name="other_name"
              label="Masukkan Nama Pelatihan / Seminar"
              inputRef={register}
              error={!!errors.other_name}
              helperText={!!errors.other_name && errors.other_name.message}
            />
          )}
          <Controller
            control={control}
            name="as"
            defaultValue=""
            render={({ onChange, value }) => (
              <TextField
                className={classes.field}
                fullWidth
                select
                defaultValue=""
                label="Status Sebagai"
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                }}
                error={!!errors.as}
                helperText={!!errors.as && errors.as.message}
              >
                <MenuItem value="" disabled></MenuItem>
                <MenuItem value="speaker">Pemateri</MenuItem>
                <MenuItem value="participant">Peserta</MenuItem>
              </TextField>
            )}
          />
          <Controller
            control={control}
            name="organizer"
            defaultValue=""
            render={({ onChange, value }) => (
              <TextField
                className={classes.field}
                fullWidth
                select
                defaultValue=""
                label="Penyelenggara"
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                  if (e.target.value === "Lainnya") {
                    setInputPenyelenggara(true);
                  } else {
                    setInputPenyelenggara(false);
                  }
                }}
                error={!!errors.organizer}
                helperText={!!errors.organizer && errors.organizer.message}
              >
                <MenuItem value="" disabled></MenuItem>
                <MenuItem value="PPA Institute">PPA Institute</MenuItem>
                <MenuItem value="Kampus Pengusaha Muslim">
                  Kampus Pengusaha Muslim
                </MenuItem>
                <MenuItem value="Lainnya">Lainnya</MenuItem>
              </TextField>
            )}
          />
          {inputPenyelenggara && (
            <TextField
              className={classes.field}
              fullWidth
              name="other_organizer"
              label="Masukkan Nama Penyelenggara"
              inputRef={register}
              error={!!errors.other_organizer}
              helperText={
                !!errors.other_organizer && errors.other_organizer.message
              }
            />
          )}
          <TextField
            className={classes.field}
            fullWidth
            name="speaker_name"
            label="Nama Pemateri"
            inputRef={register}
            error={!!errors.speaker_name}
            helperText="Jika Pemateri Lebih Dari Satu, Pisahkan dengan Tanda Koma( , ). Example : John, Doe"
          />
          <Controller
            control={control}
            defaultValue={null}
            name="start_date"
            render={({ onChange, ref, value }) => (
              <DatePicker
                value={value}
                className={classes.field}
                fullWidth
                label="Tanggal Pelatihan"
                inputRef={ref}
                format="dd-MM-yyyy"
                onChange={(e) => {
                  onChange(e);
                }}
                error={!!errors.start_date}
                helperText={!!errors.start_date && errors.start_date.message}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={null}
            name="end_date"
            render={({ onChange, ref, value }) => (
              <DatePicker
                value={value}
                className={classes.field}
                fullWidth
                label="Tanggal Selesai"
                inputRef={ref}
                format="dd-MM-yyyy"
                onChange={(e) => {
                  onChange(e);
                }}
                error={!!errors.end_date}
                helperText={!!errors.end_date && errors.end_date.message}
              />
            )}
          />
          <TextField
            className={classes.field}
            fullWidth
            name="link"
            label="Sertifikat"
            inputRef={register}
            error={!!errors.link}
            helperText="Sertifikat Berupa Link URL, example: https://google.com"
          />
        </DialogContent>
        <DialogActions className={clsx({ [classes.cardActions]: dialog.edit })}>
          {dialog.edit && (
            <Button
              className={classes.btnDelete}
              variant="contained"
              onClick={() => handleDelete(dialog.value.id)}
            >
              Delete Data
            </Button>
          )}
          <Button variant="contained" color="primary" type="submit">
            {!dialog.edit ? "Simpan" : "Edit Data"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    detailEmployee: state.detailEmployee,
  };
};

export default connect(mapStateToProps, {
  sendSeminar,
  sendEditSeminar,
  deleteSeminar,
})(DialogPelatihan);
