import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
  timeField: {
    marginTop: theme.spacing(1),
  },
}));

const TimePicker = ({ label, ref, value, onChange, error, helperText }) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        ampm={false}
        variant="inline"
        className={classes.timeField}
        fullWidth
        label={label}
        inputRef={ref}
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
      />
    </MuiPickersUtilsProvider>
  );
};

export default TimePicker;
