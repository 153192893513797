/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { Button } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";

const thumbsContainer = {
  padding: 18,
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const Dropzone = (props) => {
  const { reset, setReset, handleChangeBanner, setFile } = props;

  const [files, setFiles] = useState([]);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    accept: ".xlsx,.xls",
    maxSize: 1230715,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    onDropAccepted: handleChangeBanner,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const thumbs = files.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    setFile(null);
    return (
      <>
        {errors.map((e) => (
          <p style={{ color: "red" }} key={e.code}>
            File harus .xlsx atau .xls
          </p>
        ))}
      </>
    );
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
      setReset(false);
    },
    [files]
  );

  useEffect(() => {
    if (reset) {
      setFiles([]);
    }
  }, [reset]);

  return (
    <section className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p style={{ marginBottom: 15 }}>Drag 'n' drop files xlsx or xls</p>
        <Button startIcon={<PublishIcon />} variant="contained" color="primary">
          Upload File
        </Button>
      </div>
      <aside style={thumbsContainer}>
        {thumbs}
        {fileRejectionItems}
      </aside>
    </section>
  );
};

export default React.memo(Dropzone);
