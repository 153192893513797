/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Grid,
  TextField,
  MenuItem,
  Button,
  Typography,
  Divider,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import {
  getCity,
  getDistrict,
  getVillage,
  sendEditAlamatKTP,
} from "../../../../../store/actions";

import { isEmpty } from "../../../../../shared/utility";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(3, 1.25, 1.25),
  },
  gridPadding: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  field: {
    marginTop: theme.spacing(1),
  },
}));

const EditKTP = ({
  auth,
  address,
  valueEdit,
  getCity,
  getDistrict,
  getVillage,
  sendEditAlamatKTP,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [village, setVillage] = useState("");

  const { handleSubmit, register, errors, setValue, control } = useForm({
    shouldUnregister: false,
  });

  const handleProvince = (e) => {
    setProvince(e);
    const index = address.province.findIndex((x) => x.name === e);
    getCity(address.province[index].id);
  };

  const handleCity = (e) => {
    setCity(e);
    const index = address.city.findIndex((x) => x.name === e);
    getDistrict(address.city[index].id);
  };

  const handleDistrict = (e) => {
    setDistrict(e);
    const index = address.district.findIndex((x) => x.name === e);
    getVillage(address.district[index].id);
  };

  const onSubmit = (data) => {
    if (auth.user.id === valueEdit.UserID) {
      return sendEditAlamatKTP(data, history, valueEdit.ID);
    }
    sendEditAlamatKTP(data, history, valueEdit.ID, valueEdit.UserID);
  };

  useEffect(() => {
    if (!!valueEdit) {
      setValue("province", valueEdit.province);
      setValue("city", valueEdit.city);
      setValue("district", valueEdit.district);
      setValue("sub_district", valueEdit.sub_district);
      setValue("postal_code", valueEdit.postal_code);
      setValue("street", valueEdit.street);
      setValue("type", "ktp");
      setProvince(valueEdit.province);
      setCity(valueEdit.city);
      setDistrict(valueEdit.district);
      setVillage(valueEdit.sub_district);
    }
  }, [setValue,]);

  return (
    <Card className={classes.card}>
      <CardHeader
        title={<Typography variant="h5">Form Alamat KTP</Typography>}
      />
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Grid container>
            <Grid item xs={6} className={classes.gridPadding}>
              {/* Provinsi */}
              <Controller
                control={control}
                name="province"
                defaultValue=""
                render={({ onChange, ref }) => (
                  <TextField
                    fullWidth
                    select
                    label="Provinsi"
                    inputRef={ref}
                    value={province}
                    onChange={(e) => {
                      onChange(e.target.value);
                      handleProvince(e.target.value);
                    }}
                    disabled={address.loadingProvince}
                    error={!!errors.province}
                    helperText={!!errors.province && errors.province.message}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {address.province.map((value) => (
                      <MenuItem value={value.name} key={value.id}>
                        {value.name}
                      </MenuItem>
                    ))}
                    {valueEdit.province !== "" && isEmpty(address.province) ? (
                      <MenuItem value={valueEdit.province}>
                        {valueEdit.province}
                      </MenuItem>
                    ) : null}
                  </TextField>
                )}
              />

              {/* Kecamatan */}
              <Controller
                control={control}
                name="district"
                defaultValue=""
                render={({ onChange, ref }) => (
                  <TextField
                    className={classes.field}
                    fullWidth
                    select
                    label="Kecamatan"
                    inputRef={ref}
                    value={district}
                    onChange={(e) => {
                      onChange(e.target.value);
                      handleDistrict(e.target.value);
                    }}
                    disabled={address.loadingDistrict}
                    error={!!errors.district}
                    helperText={!!errors.district && errors.district.message}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {address.district.map((value) => (
                      <MenuItem value={value.name} key={value.id}>
                        {value.name}
                      </MenuItem>
                    ))}
                    {valueEdit.district !== "" && isEmpty(address.district) ? (
                      <MenuItem value={valueEdit.district}>
                        {valueEdit.district}
                      </MenuItem>
                    ) : null}
                  </TextField>
                )}
              />
              <TextField
                className={classes.field}
                type="number"
                fullWidth
                name="postal_code"
                label="Kode Pos"
                inputRef={register}
                error={!!errors.postal_code}
                helperText={!!errors.postal_code && errors.postal_code.message}
              />
            </Grid>
            <Grid item xs={6} className={classes.gridPadding}>
              {/* Kota/Kabupaten */}
              <Controller
                control={control}
                name="city"
                defaultValue=""
                render={({ onChange, ref }) => (
                  <TextField
                    fullWidth
                    select
                    label="Kota/Kabupaten"
                    inputRef={ref}
                    defaultValue=""
                    value={city}
                    onChange={(e) => {
                      onChange(e.target.value);
                      handleCity(e.target.value);
                    }}
                    disabled={address.loadingCity}
                    error={!!errors.city}
                    helperText={!!errors.city && errors.city.message}
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {address.city.map((value) => (
                      <MenuItem value={value.name} key={value.id}>
                        {value.name}
                      </MenuItem>
                    ))}
                    {valueEdit.city !== "" && isEmpty(address.city) ? (
                      <MenuItem value={valueEdit.city}>
                        {valueEdit.city}
                      </MenuItem>
                    ) : null}
                  </TextField>
                )}
              />

              {/* Kelurahan */}
              <Controller
                control={control}
                name="sub_district"
                defaultValue=""
                render={({ onChange, ref }) => (
                  <TextField
                    className={classes.field}
                    fullWidth
                    select
                    label="Kelurahan"
                    inputRef={ref}
                    defaultValue=""
                    value={village}
                    onChange={(e) => {
                      onChange(e.target.value);
                      setVillage(e.target.value);
                    }}
                    disabled={address.loadingVillage}
                    error={!!errors.sub_district}
                    helperText={
                      !!errors.sub_district && errors.sub_district.message
                    }
                  >
                    <MenuItem value="" disabled></MenuItem>
                    {address.village.map((value) => (
                      <MenuItem value={value.name} key={value.id}>
                        {value.name}
                      </MenuItem>
                    ))}
                    {valueEdit.sub_district !== "" &&
                    isEmpty(address.village) ? (
                      <MenuItem value={valueEdit.sub_district}>
                        {valueEdit.sub_district}
                      </MenuItem>
                    ) : null}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} className={classes.gridPadding}>
              <TextField
                className={classes.field}
                fullWidth
                multiline
                rows={5}
                variant="outlined"
                name="street"
                label="Alamat Lengkap"
                inputRef={register}
                error={!!errors.street}
                helperText={!!errors.street && errors.street.message}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button fullWidth variant="contained" color="primary" type="submit">
            Simpan Alamat KTP
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    address: state.address,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  getCity,
  getDistrict,
  getVillage,
  sendEditAlamatKTP,
})(EditKTP);
