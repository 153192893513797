import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { Typography, Box } from '@material-ui/core';
import CustomRouterLink from '../../../../components/CustomRouterLink/CustomRouterLink';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  card: {
    display: 'flex',
    height: 150,
  },

  media: {
    width: '100%',
  },

  cardDetail: {
    display: 'flex',
    height: 180,
    width: '100%',
  },

  mediaDetail: {
    height: '100%',
    width: '35%',
    objectFit: 'cover',
  },

  images: {
    width: '40%',
  },
  details: {
    width: '60%',
  },
});

const CardEmployee = (props) => {
  const { image, name, company, role, nik, level, id, userId, status, hcis } =
    props;
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea
        component={CustomRouterLink}
        to={`/detail-employee/${id}/${userId}`}
        className={classes.card}
      >
        <Box
          component="div"
          style={{
            backgroundImage: `url(${image})`,
            width: 'auto',
            height: '100%',
            aspectRatio: '1/1',
            backgroundSize: 'cover',
            objectFit: 'cover',
          }}
        />

        <div className={classes.details}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {company}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {role}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {nik}
            </Typography>
            {hcis && (
              <Typography variant="body2" color="textSecondary" component="p">
                {level}
              </Typography>
            )}
            <Typography variant="body2" color="textSecondary" component="p">
              {status === 'active' ? 'Masih Bekerja' : 'Tidak Bekerja'}
            </Typography>
          </CardContent>
        </div>
      </CardActionArea>
    </Card>
  );
};

export default CardEmployee;
