import {
  SET_DASHBOARD_CHART_START,
  SET_DASHBOARD_CHART_SUCCESS,
  SET_DASHBOARD_CHART_FAIL,
} from "./actionTypes";
import axios from "../../axios-orders";
import { setAlert } from "./alert";

export const setDashboardChartStart = () => {
  return {
    type: SET_DASHBOARD_CHART_START,
  };
};

export const getDashboardChart =
  ({ company_id = "", gender = "", stfin = "" }) =>
  async (dispatch) => {
    dispatch(setDashboardChartStart());
    try {
      const res = await axios.get(
        `/manager/chart?company_id=${company_id}&gender=${gender}&stfin=${stfin} `,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      let labels = [];
      let male = [];
      let female = [];
      res.data.data.forEach(function (e) {
        let forLabels = e.company_name;
        let maleTotal = e.male;
        let femaleTotal = e.female;
        labels.push(forLabels);
        male.push(maleTotal);
        female.push(femaleTotal);
      });

      const newData = {
        labels: labels,
        datasets: [
          {
            label: "Ikhwan",
            data: male,
            backgroundColor: "rgb(244, 191, 55)",
            minBarThickness: 20,
            maxBarThickness: 70,
          },
          {
            label: "Akhwat",
            data: female,
            backgroundColor: "rgb(40, 93, 95)",
            minBarThickness: 20,
            maxBarThickness: 70,
          },
        ],
      };

      dispatch({
        type: SET_DASHBOARD_CHART_SUCCESS,
        payload: newData,
      });
    } catch (error) {
      dispatch({
        type: SET_DASHBOARD_CHART_FAIL,
        payload: error.response.message,
      });
      dispatch(setAlert("Can't get chart!", "error"));
    }
  };
