/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  sendRumusanTugas,
  getRumusanTugas,
  getInformasiPekerjaan,
} from "../../../../../../store/actions";
import { isEmpty } from "../../../../../../shared/utility";

import EditRumusanTugas from "../DialogEdit/EditRumusanTugas";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  cardTask: {
    marginBottom: theme.spacing(1),
  },
  formTambahTugas: {
    marginBottom: theme.spacing(2),
  },
}));

const validationSchema = yup.object().shape({
  description: yup.string().required("Data Wajib diisi"),
});

const RumusanTugas = ({
  detailEmployee,
  sendRumusanTugas,
  getRumusanTugas,
  auth,
  getInformasiPekerjaan,
}) => {
  const classes = useStyles();
  const params = useParams();
  const [edit, setEdit] = useState(false);

  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data, e) => {
    sendRumusanTugas(data);
    setEdit(false);
    e.target.reset();
  };

  useEffect(() => {
    if (auth.user.role_name === "hcis" || auth.user.role_name === "manager") {
      getInformasiPekerjaan(true, params.id);
    } else {
      getInformasiPekerjaan();
    }
    if (auth.user.role_name === "hcis" || auth.user.role_name === "manager") {
      getRumusanTugas(true, params.id);
    } else {
      getRumusanTugas();
    }
  }, []);

  return (
    <Grid container>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <Grid item xs={12}>
          <Card className={classes.cardTask}>
            <CardContent>
              {isEmpty(detailEmployee.rumusanTugas) ? (
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Buat Rumusan Tugas"
                  inputRef={register}
                  name="description"
                  error={!!errors.description}
                  helperText={
                    !!errors.description && errors.description.message
                  }
                />
              ) : (
                <Typography variant="body1" align="center">
                  {detailEmployee.rumusanTugas.description || "data kosong"}
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
        {auth.user.id === parseInt(params.userId) && (
          <Grid item xs={12}>
            {isEmpty(detailEmployee.rumusanTugas) ? (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                Simpan
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => setEdit(true)}
              >
                Edit Rumusan Tugas
              </Button>
            )}
          </Grid>
        )}
      </form>
      <EditRumusanTugas
        open={edit}
        onClose={() => setEdit(false)}
        value={detailEmployee.rumusanTugas.description}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    detailEmployee: state.detailEmployee,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  sendRumusanTugas,
  getRumusanTugas,
  getInformasiPekerjaan,
})(RumusanTugas);
