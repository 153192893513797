import React, { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import {
  Drawer,
  Button,
  List,
  ListItem,
  colors,
  ListItemText,
} from "@material-ui/core";
import { connect } from "react-redux";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import PeopleIcon from "@material-ui/icons/People";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import RecentActorsIcon from "@material-ui/icons/RecentActors";
import TimerIcon from "@material-ui/icons/Timer";
import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
import ContactsIcon from "@material-ui/icons/Contacts";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import PersonAddDisabledIcon from "@material-ui/icons/PersonAddDisabled";
import TocIcon from "@material-ui/icons/Toc";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import ViewListIcon from "@material-ui/icons/ViewList";
import AddLocationIcon from "@material-ui/icons/AddLocation";

const textMenuBlack = "#000";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  button: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  menus: {
    paddingTop: theme.spacing(3),
  },
  textMenu: {
    color: textMenuBlack,
    paddingLeft: theme.spacing(2),
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const MobileDrawer = (props) => {
  const { open, onClose, userData } = props;

  const classes = useStyles();

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <div className={classes.fullList}>
        <List className={classes.menus}>
          <ListItem key="dashboard" button className={classes.item}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to="/"
            >
              <div className={classes.icon}>
                <DashboardIcon style={{ color: textMenuBlack }} />
              </div>
              <ListItemText
                className={classes.textMenu}
                primary="Dashboard"
                disableTypography={true}
              />
            </Button>
          </ListItem>
          <ListItem key="employee" button className={classes.item}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to="/employee"
            >
              <div className={classes.icon}>
                <PeopleIcon style={{ color: textMenuBlack }} />
              </div>
              <ListItemText
                className={classes.textMenu}
                primary="EOA Profile"
                disableTypography={true}
              />
            </Button>
          </ListItem>
          {/* <ListItem key="entitas" button className={classes.item}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to="/"
            >
              <div className={classes.icon}>
                <SupervisedUserCircleIcon style={{ color: textMenuBlack }} />
              </div>
              <ListItemText
                className={classes.textMenu}
                primary="Entitas"
                disableTypography={true}
              />
            </Button>
          </ListItem> */}

          {/* Employee Service */}
          <ListItem key="overview" button className={classes.item}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to="/overview"
            >
              <div className={classes.icon}>
                <RecentActorsIcon style={{ color: textMenuBlack }} />
              </div>
              <ListItemText
                className={classes.textMenu}
                primary="Overview"
                disableTypography={true}
              />
            </Button>
          </ListItem>
          <ListItem key="requestLeave" button className={classes.item}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to="/request-leave"
            >
              <div className={classes.icon}>
                <PersonAddDisabledIcon style={{ color: textMenuBlack }} />
              </div>
              <ListItemText
                className={classes.textMenu}
                primary="Perizinan"
                disableTypography={true}
              />
            </Button>
          </ListItem>
          {userData.user.sequence <= 2 &&
          userData.user.role_name === "manager" ? (
            <ListItem key="daftarIzin" button className={classes.item}>
              <Button
                className={classes.button}
                component={CustomRouterLink}
                to="/daftar-izin"
              >
                <div className={classes.icon}>
                  <PlaylistAddCheckIcon style={{ color: textMenuBlack }} />
                </div>
                <ListItemText
                  className={classes.textMenu}
                  primary="Daftar Izin"
                  disableTypography={true}
                />
              </Button>
            </ListItem>
          ) : null}

          <ListItem key="reimburse" button className={classes.item}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to="/reimburse"
            >
              <div className={classes.icon}>
                <SwapVertIcon style={{ color: textMenuBlack }} />
              </div>
              <ListItemText
                className={classes.textMenu}
                primary="Reimburse"
                disableTypography={true}
              />
            </Button>
          </ListItem>

          {(userData.user.sequence <= 2 &&
            userData.user.role_name === "manager") ||
          userData.user.role_name === "hcis" ||
          userData.user.job_name === "Staff Finance" ? (
            <ListItem key="daftarIzin" button className={classes.item}>
              <Button
                className={classes.button}
                component={CustomRouterLink}
                to="/daftar-reimburse"
              >
                <div className={classes.icon}>
                  <TocIcon style={{ color: textMenuBlack }} />
                </div>
                <ListItemText
                  className={classes.textMenu}
                  primary="Daftar Reimburse"
                  disableTypography={true}
                />
              </Button>
            </ListItem>
          ) : null}

          <ListItem key="pengajuan-lembur" button className={classes.item}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to="/pengajuan-lembur"
            >
              <div className={classes.icon}>
                <TimerIcon style={{ color: textMenuBlack }} />
              </div>
              <ListItemText
                className={classes.textMenu}
                primary="Pengajuan Lembur"
                disableTypography={true}
              />
            </Button>
          </ListItem>

          {(userData.user.role_name === "hcis" ||
            userData.user.role_name === "manager") && (
            <>
              <ListItem key="daftar-lembur" button className={classes.item}>
                <Button
                  className={classes.button}
                  component={CustomRouterLink}
                  to="/daftar-lembur"
                >
                  <div className={classes.icon}>
                    <ListAltIcon style={{ color: textMenuBlack }} />
                  </div>
                  <ListItemText
                    className={classes.textMenu}
                    primary="Daftar Lembur"
                    disableTypography={true}
                  />
                </Button>
              </ListItem>
              <ListItem key="lembur-ditugaskan" button className={classes.item}>
                <Button
                  className={classes.button}
                  component={CustomRouterLink}
                  to="/lembur-ditugaskan"
                >
                  <div className={classes.icon}>
                    <AssignmentLateIcon style={{ color: textMenuBlack }} />
                  </div>
                  <ListItemText
                    className={classes.textMenu}
                    primary="Lembur Ditugaskan"
                    disableTypography={true}
                  />
                </Button>
              </ListItem>
            </>
          )}

          <ListItem key="daftar-ditugaskan" button className={classes.item}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to="/daftar-ditugaskan"
            >
              <div className={classes.icon}>
                <ViewListIcon style={{ color: textMenuBlack }} />
              </div>
              <ListItemText
                className={classes.textMenu}
                primary="Daftar Ditugaskan"
                disableTypography={true}
              />
            </Button>
          </ListItem>

          {/* End Of Employee Service */}

          <ListItem key="berita" button className={classes.item}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to="/berita"
            >
              <div className={classes.icon}>
                <SpeakerNotesIcon style={{ color: textMenuBlack }} />
              </div>
              <ListItemText
                className={classes.textMenu}
                primary="Berita"
                disableTypography={true}
              />
            </Button>
          </ListItem>

          {/* Kehadiran */}
          <ListItem key="masukPulang" button className={classes.item}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to="/absensi"
            >
              <div className={classes.icon}>
                <ContactsIcon style={{ color: textMenuBlack }} />
              </div>
              <ListItemText
                className={classes.textMenu}
                primary="Masuk/Pulang"
                disableTypography={true}
              />
            </Button>
          </ListItem>
          <ListItem key="kelolaKehadiran" button className={classes.item}>
            <Button
              className={classes.button}
              component={CustomRouterLink}
              to="/kelola-kehadiran"
            >
              <div className={classes.icon}>
                <PermContactCalendarIcon style={{ color: textMenuBlack }} />
              </div>
              <ListItemText
                className={classes.textMenu}
                primary="Kelola Kehadiran"
                disableTypography={true}
              />
            </Button>
          </ListItem>
          {userData.user.role_name === "hcis" && (
            <ListItem key="timeLocation" button className={classes.item}>
              <Button
                className={classes.button}
                component={CustomRouterLink}
                to="/time-and-location"
              >
                <div className={classes.icon}>
                  <AddLocationIcon style={{ color: textMenuBlack }} />
                </div>
                <ListItemText
                  className={classes.textMenu}
                  primary="Atur Jam"
                  disableTypography={true}
                />
              </Button>
            </ListItem>
          )}
        </List>
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth,
  };
};

export default connect(mapStateToProps)(MobileDrawer);
