/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  CardContent,
  Card,
  Button,
  Typography,
  Grid,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

// Components
import Breadscrumb from "../../components/UI/Breadcrumb/Breadcrumb";
import { Table, DialogApprove, DialogReject } from "./component";
import CustomRouterLink from "../../components/CustomRouterLink/CustomRouterLink";

// Actions
import {
  getOvertimeAssign,
  approveAssignOvertime,
  rejectAssignOvertime,
} from "../../store/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  marLeft: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  card: {
    margin: theme.spacing(1.25),
  },
  pagination: {
    marginTop: theme.spacing(3),
  },
  pag: {
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
  },
}));

const scrumbs = [
  { name: "Dashboard", link: "/" },
  { name: "Lembur ditugaskan", link: "" },
];

const Overtime = ({
  overtime,
  getOvertimeAssign,
  approveAssignOvertime,
  rejectAssignOvertime,
}) => {
  const classes = useStyles();

  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [dialogApprove, setDialogApprove] = useState({
    open: false,
    id: null,
    positionId: null,
  });
  const [dialogReject, setDialogReject] = useState({
    open: false,
    id: null,
    positionId: null,
  });

  const handlePage = (e, value) => {
    setPage(value);
  };

  useEffect(() => {
    getOvertimeAssign(page, rowPerPage);
  }, [page, rowPerPage]);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.marLeft}>
        Lembur Ditugaskan
      </Typography>
      <Breadscrumb crumb={scrumbs} />
      <Button
        color="primary"
        component={CustomRouterLink}
        to="/form-lembur-ditugaskan"
        variant="contained"
        className={classes.marLeft}
      >
        Tugaskan Lembur
      </Button>
      <Card className={classes.card}>
        <CardContent>
          <Grid container item xs={2}>
            <TextField
              select
              fullWidth
              label="Item per halaman"
              variant="outlined"
              size="small"
              value={rowPerPage}
              onChange={(e) => setRowPerPage(e.target.value)}
            >
              <MenuItem value="" disabled />
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
              <MenuItem value={25}>25</MenuItem>
            </TextField>
          </Grid>
          <Table
            data={overtime.assign.data}
            handleApprove={setDialogApprove}
            handleReject={setDialogReject}
          />
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.pagination}
          >
            <Grid item xs={8}>
              <Pagination
                className={classes.pag}
                count={overtime.assign.last_page}
                page={page}
                onChange={handlePage}
                color="primary"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DialogReject
        dialog={dialogReject}
        onClose={() =>
          setDialogReject({ open: false, id: null, positionId: null })
        }
        action={rejectAssignOvertime}
      />
      <DialogApprove
        dialog={dialogApprove}
        onClose={() =>
          setDialogApprove({ open: false, id: null, positionId: null })
        }
        action={approveAssignOvertime}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  overtime: state.overtime,
});

export default connect(mapStateToProps, {
  getOvertimeAssign,
  approveAssignOvertime,
  rejectAssignOvertime,
})(Overtime);
