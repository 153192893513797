import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  CardContent,
  Card,
  Button,
  Typography,
  Grid,
  TextField,
  MenuItem,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

// Components
import Breadscrumb from '../../components/UI/Breadcrumb/Breadcrumb';
import { Table, Dialog, DialogUpdate } from './component';
import CustomRouterLink from '../../components/CustomRouterLink/CustomRouterLink';

// Actions
import {
  getAllOvertimeStaff,
  deleteOvertime,
  updateStatusOvertime,
} from '../../store/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  marLeft: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  card: {
    margin: theme.spacing(1.25),
  },
  pagination: {
    marginTop: theme.spacing(3),
  },
  pag: {
    '& .MuiPagination-ul': {
      justifyContent: 'center',
    },
  },
}));

const scrumbs = [
  { name: 'Dashboard', link: '/' },
  { name: 'Lembur', link: '' },
];

const Overtime = ({
  getAllOvertimeStaff,
  overtime,
  deleteOvertime,
  updateStatusOvertime,
}) => {
  const classes = useStyles();

  const [page, setPage] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [dialog, setDialog] = useState({ open: false, id: null });
  const [dialogUpdate, setDialogUpdate] = useState({
    open: false,
    id: null,
    status: null,
  });

  const handlePage = (e, value) => {
    setPage(value);
  };

  useEffect(() => {
    getAllOvertimeStaff(page, rowPerPage);
  }, [getAllOvertimeStaff, page, rowPerPage]);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.marLeft}>
        Lembur
      </Typography>
      <Breadscrumb crumb={scrumbs} />
      <Button
        color="primary"
        component={CustomRouterLink}
        to="/form-overtime"
        variant="contained"
        className={classes.marLeft}
      >
        Ajukan Lembur
      </Button>
      <Card className={classes.card}>
        <CardContent>
          <Grid container item xs={2}>
            <TextField
              select
              fullWidth
              label="Item per halaman"
              variant="outlined"
              size="small"
              value={rowPerPage}
              onChange={(e) => setRowPerPage(e.target.value)}
            >
              <MenuItem value="" disabled />
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
              <MenuItem value={25}>25</MenuItem>
            </TextField>
          </Grid>
          <Table
            data={overtime.pagination}
            handleDialog={setDialog}
            handleDialogUpdate={setDialogUpdate}
          />
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.pagination}
          >
            <Grid item xs={8}>
              <Pagination
                className={classes.pag}
                count={overtime.pagination.last_page}
                page={page}
                onChange={handlePage}
                color="primary"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Dialog
        dialog={dialog}
        action={deleteOvertime}
        onClose={() => setDialog({ open: false, id: null })}
        page={page}
      />
      <DialogUpdate
        dialog={dialogUpdate}
        action={updateStatusOvertime}
        onClose={() => setDialogUpdate({ open: false, id: null, status: null })}
        page={page}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  overtime: state.overtime,
});

export default connect(mapStateToProps, {
  getAllOvertimeStaff,
  deleteOvertime,
  updateStatusOvertime,
})(Overtime);
