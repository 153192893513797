/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  CardActions,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";

// Actions
import { putOvertime } from "../../../store/actions";

// Components
import Breadcrumb from "../../../components/UI/Breadcrumb/Breadcrumb";
import DatePicker from "../../../components/UI/DatePicker/DatePicker";
import TimePicker from "../../../components/UI/TimePicker/TimePicker";
import { isEmpty } from "../../../shared/utility";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  marLeft: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  card: {
    margin: theme.spacing(5, 1.25, 1.25),
  },
  pad: {
    padding: theme.spacing(1.25),
  },
  jamField: {
    marginTop: theme.spacing(2),
  },
  paddingRight: {
    paddingRight: theme.spacing(1),
  },
  paddingLeft: {
    paddingLeft: theme.spacing(1),
  },
  textArea: {
    padding: theme.spacing(2),
  },
  time: {
    marginTop: theme.spacing(1),
  },
}));

const crumbs = [
  { name: "Dashboard", link: "/" },
  { name: "Pengajuan Lembur", link: "" },
];

const FormOvertimeEdit = ({ putOvertime, overtime }) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();

  const { handleSubmit, register, errors, control, setValue } = useForm();

  const onSubmit = (data, e) => {
    const formData = {
      detail: data.detail,
      start: `${moment(data.start_at).format("YYYY-MM-DD")} ${moment(
        data.start_time
      ).format("HH:mm")}`,
      end: `${moment(data.end_at).format("YYYY-MM-DD")} ${moment(
        data.end_time
      ).format("HH:mm")}`,
    };

    putOvertime(params.id, formData, history);
  };

  useEffect(() => {
    if (isEmpty(overtime.pagination.data)) {
      history.push("/pengajuan-lembur");
    } else {
      const indexData = overtime.pagination.data.findIndex(
        (x) => x.id === parseInt(params.id)
      );
      const start = moment(
        overtime.pagination.data[indexData].start,
        "DD-MM-YYYY HH:mm"
      ).format();
      const end = moment(
        overtime.pagination.data[indexData].end,
        "DD-MM-YYYY HH:mm"
      ).format();
      setValue("detail", overtime.pagination.data[indexData].detail);
      setValue("start_at", start);
      setValue("start_time", start);
      setValue("end_at", end);
      setValue("end_time", end);
    }
  }, []);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.marLeft}>
        Edit Pengajuan Lembur
      </Typography>
      <Breadcrumb crumb={crumbs} />
      <Card className={classes.card}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <Grid container>
              <Grid item xs={12} className={classes.pad}>
                <TextField
                  fullWidth
                  multiline
                  rows={10}
                  label="Detail Pekerjaan"
                  variant="outlined"
                  inputRef={register}
                  name="detail"
                  error={!!errors.detail}
                  helperText={!!errors.detail && errors.detail.message}
                />
                <Grid container>
                  <Grid item xs={6} className={classes.paddingRight}>
                    <Controller
                      control={control}
                      name="start_at"
                      defaultValue={null}
                      render={({ onChange, ref, value }) => (
                        <DatePicker
                          format="dd MMMM yyyy"
                          label="Tanggal Mulai"
                          inputRef={ref}
                          value={value}
                          onChange={(e) => onChange(e)}
                          error={!!errors.start_at}
                          helperText={
                            !!errors.start_at && errors.start_at.message
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.paddingLeft}>
                    <Controller
                      control={control}
                      name="start_time"
                      defaultValue={null}
                      render={({ onChange, ref, value }) => (
                        <TimePicker
                          label="Waktu Mulai"
                          value={value}
                          inputRef={ref}
                          onChange={(e) => onChange(e)}
                          error={!!errors.start_time}
                          helperText={
                            !!errors.start_time && errors.start_time.message
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} className={classes.paddingRight}>
                    <Controller
                      control={control}
                      name="end_at"
                      defaultValue={null}
                      render={({ onChange, ref, value }) => (
                        <DatePicker
                          format="dd MMMM yyyy"
                          label="Tanggal Akhir"
                          inputRef={ref}
                          value={value}
                          onChange={(e) => onChange(e)}
                          error={!!errors.end_at}
                          helperText={!!errors.end_at && errors.end_at.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.paddingLeft}>
                    <Controller
                      control={control}
                      name="end_time"
                      defaultValue={null}
                      render={({ onChange, ref, value }) => (
                        <TimePicker
                          label="Waktu Berakhir"
                          value={value}
                          inputRef={ref}
                          onChange={(e) => onChange(e)}
                          error={!!errors.end_time}
                          helperText={
                            !!errors.end_time && errors.end_time.message
                          }
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6} className={classes.textArea}></Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Simpan
            </Button>
          </CardActions>
        </form>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  overtime: state.overtime,
});

export default connect(mapStateToProps, { putOvertime })(FormOvertimeEdit);
