/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';

import { getInformasiPekerjaan } from '../../../../store/actions';
import { isEmpty } from '../../../../shared/utility';
import NumberFormat from 'react-number-format';

function InformasiPekerjaan({ getInformasiPekerjaan, detailEmployee, auth }) {
  const params = useParams();

  useEffect(() => {
    if (auth.user.role_name === 'hcis' || auth.user.role_name === 'manager') {
      getInformasiPekerjaan(true, params.id);
    } else {
      getInformasiPekerjaan();
    }
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} lg={6}>
        <Grid container>
          {/* Row entitas */}
          <Grid style={{ marginBottom: 20 }} item xs={4}>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              Entitas
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              {' '}
              :{' '}
            </Typography>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              {!detailEmployee.loadingInformasiPekerjaan &&
              !isEmpty(detailEmployee.informasiPekerjaan.company_name)
                ? detailEmployee.informasiPekerjaan.company_name
                : 'Data belum tersedia'}
            </Typography>
          </Grid>
          {/* Row Pekerjaan */}
          <Grid style={{ marginBottom: 20 }} item xs={4}>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              Pekerjaan
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              {' '}
              :{' '}
            </Typography>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              {!detailEmployee.loadingInformasiPekerjaan &&
              !isEmpty(detailEmployee.informasiPekerjaan.job_name)
                ? detailEmployee.informasiPekerjaan.job_name
                : 'Data belum tersedia'}
            </Typography>
          </Grid>
          {/* Row level */}
          {auth.user.role_name === 'hcis' && (
            <>
              <Grid style={{ marginBottom: 20 }} item xs={4}>
                <Typography
                  display="inline"
                  gutterBottom
                  variant="h6"
                  component="h2"
                >
                  Level Jabatan
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <Typography
                  display="inline"
                  gutterBottom
                  variant="h6"
                  component="h2"
                >
                  {' '}
                  :{' '}
                </Typography>
                <Typography
                  display="inline"
                  gutterBottom
                  variant="h6"
                  component="h2"
                >
                  {!detailEmployee.loadingInformasiPekerjaan &&
                  !!detailEmployee.informasiPekerjaan.sequence
                    ? detailEmployee.informasiPekerjaan.sequence
                    : 'Data belum tersedia'}
                </Typography>
              </Grid>
            </>
          )}
          {/* Row mulai kerja */}
          <Grid style={{ marginBottom: 20 }} item xs={4}>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              Mulai Bekerja
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              {' '}
              :{' '}
            </Typography>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              {!detailEmployee.loadingInformasiPekerjaan &&
              detailEmployee.informasiPekerjaan.start_at !== '01-01-0001'
                ? detailEmployee.informasiPekerjaan.start_at
                : 'Data belum tersedia'}
            </Typography>
          </Grid>
          {/* Row Status Karyawan */}
          <Grid style={{ marginBottom: 20 }} item xs={4}>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              Status Karyawan
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              {' '}
              :{' '}
            </Typography>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              {!detailEmployee.loadingInformasiPekerjaan &&
              !isEmpty(detailEmployee.informasiPekerjaan.status)
                ? detailEmployee.informasiPekerjaan.status
                : 'Data belum tersedia'}
            </Typography>
          </Grid>

          {/* Row Status Bekerja */}
          <Grid style={{ marginBottom: 20 }} item xs={4}>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              Status Bekerja
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              {' '}
              :{' '}
            </Typography>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              {!detailEmployee.loadingInformasiPekerjaan &&
              !isEmpty(detailEmployee.informasiPekerjaan.work_status)
                ? detailEmployee.informasiPekerjaan.work_status === 'active'
                  ? 'Masih Bekerja'
                  : 'Tidak Bekerja'
                : 'Data Belum Tersedia'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Grid container>
          {/* Row Nama Atasan */}
          <Grid style={{ marginBottom: 20 }} item xs={4}>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              Nama Atasan
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              {' '}
              :{' '}
            </Typography>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              {!detailEmployee.loadingInformasiPekerjaan &&
              !isEmpty(detailEmployee.informasiPekerjaan.supervisor_name)
                ? detailEmployee.informasiPekerjaan.supervisor_name
                : 'Data belum tersedia'}
            </Typography>
          </Grid>
          {/* Row Jabatan Atasan */}
          <Grid style={{ marginBottom: 20 }} item xs={4}>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              Jabatan Atasan
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              {' '}
              :{' '}
            </Typography>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              {!detailEmployee.loadingInformasiPekerjaan &&
              !isEmpty(detailEmployee.informasiPekerjaan.supervisor_position)
                ? detailEmployee.informasiPekerjaan.supervisor_position
                : 'Data belum tersedia'}
            </Typography>
          </Grid>

          {/* Row Gaji Pokok */}
          {auth.user.role_name === 'hcis' && (
            <>
              <Grid style={{ marginBottom: 20 }} item xs={4}>
                <Typography
                  display="inline"
                  gutterBottom
                  variant="h6"
                  component="h2"
                >
                  Gaji Pokok
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <Typography
                  display="inline"
                  gutterBottom
                  variant="h6"
                  component="h2"
                >
                  {' '}
                  :{' '}
                </Typography>
                <Typography
                  display="inline"
                  gutterBottom
                  variant="h6"
                  component="h2"
                >
                  {!detailEmployee.loadingInformasiPekerjaan &&
                  !!detailEmployee.informasiPekerjaan.base_salary ? (
                    <NumberFormat
                      value={detailEmployee.informasiPekerjaan.base_salary}
                      prefix="Rp. "
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                    />
                  ) : (
                    'Data belum tersedia'
                  )}
                </Typography>
              </Grid>
            </>
          )}

          {/* Row Take Home Pay */}
          {auth.user.role_name === 'hcis' && (
            <>
              <Grid style={{ marginBottom: 20 }} item xs={4}>
                <Typography
                  display="inline"
                  gutterBottom
                  variant="h6"
                  component="h2"
                >
                  Take Home Pay
                </Typography>
              </Grid>

              <Grid item xs={8}>
                <Typography
                  display="inline"
                  gutterBottom
                  variant="h6"
                  component="h2"
                >
                  {' '}
                  :{' '}
                </Typography>
                <Typography
                  display="inline"
                  gutterBottom
                  variant="h6"
                  component="h2"
                >
                  {!detailEmployee.loadingInformasiPekerjaan &&
                  !!detailEmployee.informasiPekerjaan.take_home_pay ? (
                    <NumberFormat
                      value={detailEmployee.informasiPekerjaan.take_home_pay}
                      prefix="Rp. "
                      displayType="text"
                      thousandSeparator="."
                      decimalSeparator=","
                    />
                  ) : (
                    'Data belum tersedia'
                  )}
                </Typography>
              </Grid>
            </>
          )}

          {/* Row Lama Bekerja */}
          <Grid style={{ marginBottom: 20 }} item xs={4}>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              Lama Bekerja
            </Typography>
          </Grid>

          <Grid item xs={8}>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              {' '}
              :{' '}
            </Typography>
            <Typography
              display="inline"
              gutterBottom
              variant="h6"
              component="h2"
            >
              {!detailEmployee.loadingInformasiPekerjaan
                ? `${detailEmployee.informasiPekerjaan.work_experience}`
                : 'Data belum tesedia'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => {
  return {
    detailEmployee: state.detailEmployee,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { getInformasiPekerjaan })(
  InformasiPekerjaan
);
