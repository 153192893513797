import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

import { connect } from "react-redux";
import { getRequestLeave } from "../../../../store/actions";

import { TableHead } from "./component";
import { capitalize } from "../../../../shared/utility";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const TableRequestLeave = ({ requestLeave, getRequestLeave, setDialog }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getRequestLeave();
  }, []);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead />
            <TableBody>
              {requestLeave.loading ? (
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : requestLeave.data.length > 0 ? (
                requestLeave.data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow tabIndex={-1} key={index + 1}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{capitalize(row.request_type)}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        <TableCell>{row.start_date}</TableCell>
                        <TableCell>{row.end_date}</TableCell>
                        <TableCell>{row.duration}</TableCell>
                        <TableCell>{capitalize(row.status)}</TableCell>
                        <TableCell>{row.action_by}</TableCell>
                        <TableCell>
                          {row.status !== "waiting" ? (
                            <IconButton
                              disabled
                              variant="contained"
                              color="primary"
                            >
                              <Tooltip title="Edit">
                                <EditIcon />
                              </Tooltip>
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => {
                                setDialog({
                                  open: true,
                                  edit: true,
                                  value: row,
                                });
                              }}
                              variant="contained"
                              color="primary"
                            >
                              <Tooltip title="Edit">
                                <EditIcon />
                              </Tooltip>
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    Data Kosong
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={requestLeave.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    requestLeave: state.requestLeave,
  };
};

export default connect(mapStateToProps, { getRequestLeave })(TableRequestLeave);
