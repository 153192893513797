import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Box,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  iconApprove: {
    color: theme.palette.green.color600,
  },
  iconReject: {
    color: theme.palette.red.color600,
  },
}));

export default function EnhancedTable({
  data,
  handleDialogUpdate,
  handleApprove,
  handleReject,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead
              style={{
                background: "#285D5F",
              }}
            >
              <TableRow>
                <TableCell align="left" padding="default">
                  <Typography style={{ fontWeight: "bold", color: "#fff" }}>
                    {"Ditugaskan kepada"}
                  </Typography>
                </TableCell>
                <TableCell align="right" padding="default">
                  <Typography style={{ fontWeight: "bold", color: "#fff" }}>
                    Waktu Mulai
                  </Typography>
                </TableCell>
                <TableCell align="right" padding="default">
                  <Typography style={{ fontWeight: "bold", color: "#fff" }}>
                    Waktu Akhir
                  </Typography>
                </TableCell>
                <TableCell align="right" padding="default">
                  <Typography style={{ fontWeight: "bold", color: "#fff" }}>
                    Lokasi Lembur
                  </Typography>
                </TableCell>
                <TableCell align="center" padding="default">
                  <Typography style={{ fontWeight: "bold", color: "#fff" }}>
                    Detail Tugas
                  </Typography>
                </TableCell>
                <TableCell align="right" padding="default">
                  <Typography style={{ fontWeight: "bold", color: "#fff" }}>
                    Status
                  </Typography>
                </TableCell>
                <TableCell align="center" padding="default">
                  <Typography style={{ fontWeight: "bold", color: "#fff" }}>
                    Action
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell component="th" id={labelId} scope="row">
                      {row.user_name}
                    </TableCell>
                    <TableCell align="right">{row.start}</TableCell>
                    <TableCell align="right">{row.end}</TableCell>
                    <TableCell align="right">
                      {row.location === "home"
                        ? "Rumah"
                        : row.location === "office"
                        ? "Kantor"
                        : ""}
                    </TableCell>
                    <TableCell align="right">{row.detail}</TableCell>
                    <TableCell align="right">{row.status}</TableCell>
                    <TableCell>
                      <Box display="flex" justifyContent="center">
                        <Tooltip title="Confirm">
                          <IconButton
                            color="secondary"
                            onClick={() =>
                              handleApprove({
                                open: true,
                                id: row.id,
                                positionId: row.position_id,
                              })
                            }
                            disabled={
                              row.status === "waiting" ||
                              row.status === "approved" ||
                              row.status === "rejected" ||
                              row.status === "progress"
                            }
                          >
                            <CheckCircleIcon
                              style={{
                                color:
                                  row.status === "waiting" ||
                                  row.status === "approved" ||
                                  row.status === "rejected" ||
                                  row.status === "progress"
                                    ? "gray"
                                    : "green",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Cancel">
                          <IconButton
                            color="secondary"
                            onClick={() =>
                              handleReject({
                                open: true,
                                id: row.id,
                                positionId: row.position_id,
                              })
                            }
                            disabled={
                              row.status === "waiting" ||
                              row.status === "approved" ||
                              row.status === "rejected" ||
                              row.status === "progress"
                            }
                          >
                            <CancelIcon
                              style={{
                                color:
                                  row.status === "waiting" ||
                                  row.status === "approved" ||
                                  row.status === "rejected" ||
                                  row.status === "progress"
                                    ? "gray"
                                    : "red",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
