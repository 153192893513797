import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
  Box,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import { TableHead } from "./component";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },

  btnConfirm: {
    color: theme.palette.success.main,
  },

  btnCancel: {
    color: theme.palette.error.main,
  },

  btnDisabled: {
    color: "#b4b4b4",
  },
  pagination: {
    padding: theme.spacing(0.5),
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
  },
}));

const TableTimeLocation = ({ data, setEdit, setDelete }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead />
            <TableBody>
              {data.data.map((row, index) => {
                return (
                  <TableRow tabIndex={-1} key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{row.company_name}</TableCell>
                    <TableCell>{row.in}</TableCell>
                    <TableCell>{row.out}</TableCell>
                    <TableCell>
                      <Box display="flex">
                        <IconButton
                          variant="contained"
                          color="primary"
                          onClick={() => setEdit({ open: true, data: row })}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          variant="contained"
                          style={{ color: "red" }}
                          onClick={() => setDelete({ open: true, id: row.id })}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default TableTimeLocation;
