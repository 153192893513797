/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Grid, Button } from "@material-ui/core";

import {
  TableTimeLocation,
  DialogTambah,
  DialogEdit,
  DialogDelete,
} from "./component";

import {
  getCompany,
  getAbsenRules,
  sendRulesAbsenEntitas,
  updateRulesAbsenEntitas,
  deleteRulesAbsenEntitas,
} from "../../store/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(20),
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  mb: {
    marginBottom: theme.spacing(3),
  },
}));

const TimeLocation = ({
  company,
  getCompany,
  absensi,
  getAbsenRules,
  sendRulesAbsenEntitas,
  updateRulesAbsenEntitas,
  deleteRulesAbsenEntitas,
}) => {
  const classes = useStyles();

  const [dialog, setDialog] = useState(false);
  const [dialogEdit, setDialogEdit] = useState({ open: false, data: null });
  const [dialogDelete, setDialogDelete] = useState({ open: false, id: null });

  const openDialog = () => {
    setDialog(true);
  };

  const closeDialog = () => {
    setDialog(false);
  };

  useEffect(() => {
    getCompany();
    getAbsenRules();
  }, []);

  return (
    <>
      <Container maxWidth="xl" className={classes.root}>
        <Typography variant="h3" className={classes.mb}>
          Atur Jam
        </Typography>
        <Grid container style={{ marginBottom: 25, marginTop: 30 }}>
          <Button variant="contained" color="primary" onClick={openDialog}>
            Tambah +
          </Button>
        </Grid>
        <TableTimeLocation
          data={absensi.rules}
          setEdit={setDialogEdit}
          setDelete={setDialogDelete}
        />
        <DialogTambah
          open={dialog}
          onClose={closeDialog}
          listEntitas={company}
          action={sendRulesAbsenEntitas}
        />
        <DialogEdit
          dialog={dialogEdit}
          onClose={() => setDialogEdit({ open: false, data: null })}
          listEntitas={company}
          action={updateRulesAbsenEntitas}
        />
        <DialogDelete
          dialog={dialogDelete}
          onClose={() => setDialogDelete({ open: false, id: null })}
          action={deleteRulesAbsenEntitas}
        />
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  company: state.company,
  absensi: state.absensi,
});

export default connect(mapStateToProps, {
  getCompany,
  getAbsenRules,
  sendRulesAbsenEntitas,
  updateRulesAbsenEntitas,
  deleteRulesAbsenEntitas,
})(TimeLocation);
