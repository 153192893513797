import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/index";

const ModalAlert = (props) => {
  const { open, payload } = props.modal;

  let modalAlert = null;
  if (open) {
    modalAlert = (
      <div>
        <Dialog
          maxWidth="xs"
          open={true}
          fullWidth
          onClose={props.onCloseModal}
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Typography align="center" variant="h1" component="h1">
                {payload.msg}
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onCloseModal} color="primary">
              Tutup
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  return <div>{modalAlert}</div>;
};

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseModal: () => dispatch(actions.removeModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalAlert);
