import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: false,
  error: false,
  data: [],
};

const setTaskStart = (state, action) => {
  return updateObject(state, { loading: true, error: false });
};
const setTaskFail = (state, action) => {
  return updateObject(state, { loading: false, error: true });
};

const setTaskSuccess = (state, action) => {
  return updateObject(state, {
    data: action.payload,
    loading: false,
    error: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TASK_START:
      return setTaskStart(state, action);
    case actionTypes.SET_TASK_FAIL:
      return setTaskFail(state, action);
    case actionTypes.SET_TASK_SUCCESS:
      return setTaskSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
