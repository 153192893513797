import { CREATE_USER_DONE, CREATE_USER_START } from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: false,
};

const createUserStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const createUserDone = (state, action) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_USER_START:
      return createUserStart(state, action);
    case CREATE_USER_DONE:
      return createUserDone(state, action);

    default:
      return state;
  }
};

export default reducer;
