/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  TextField,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  sendPrestasi,
  sendEditPrestasi,
  deletePrestasi,
} from "../../../../../store/actions";
import DatePicker from "../../../../../components/UI/DatePicker/DatePicker";

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
  cardActions: {
    justifyContent: "space-between",
  },
  btnDelete: {
    color: "#fff",
    background: theme.palette.red.color500,
    "&:hover": {
      backgroundColor: theme.palette.red.color700,
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const validationSchema = yup.object().shape({
  name: yup.string().required("Wajib diisi"),
  scale: yup.string().required("Wajib diisi"),
  grade: yup.string().required("Wajib diisi"),
  description: yup.string().required("Wajib diisi"),
  date: yup.string().required("Wajib diisi"),
});

const DialogPrestasi = ({
  dialog,
  onClose,
  sendPrestasi,
  sendEditPrestasi,
  deletePrestasi,
  hcis,
}) => {
  const classes = useStyles();
  const params = useParams();

  const [date, setDate] = useState(null);

  const { handleSubmit, register, errors, control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  const handleDelete = (id) => {
    if (hcis) {
      deletePrestasi(id, params.userId);
    } else {
      deletePrestasi(id);
    }
    onClose();
  };

  const onSubmit = (data, e) => {
    if (dialog.edit) {
      const formData = { ...data, date: moment(date).format("YYYY-MM") };
      if (hcis) {
        sendEditPrestasi(formData, dialog.value.id, params.userId);
      } else {
        sendEditPrestasi(formData, dialog.value.id);
      }
    } else {
      if (hcis) {
        sendPrestasi(data, params.userId);
      } else {
        sendPrestasi(data);
      }
    }
    e.target.reset();
    setValue("scale", "");
    setValue("date", "");
    setDate(null);
    onClose();
  };

  useEffect(() => {
    if (dialog.edit) {
      const dateEdit = `${dialog.value.month}-01-${dialog.value.year}`;
      setValue("name", dialog.value.name);
      setValue("scale", dialog.value.scale);
      setValue("grade", dialog.value.grade);
      setValue("description", dialog.value.description);
      setValue("date", moment(dateEdit).format("YYYY-MM"));
      setDate(moment(dateEdit).format("ll"));
      return;
    }
    setValue("name", "");
    setValue("scale", "");
    setValue("grade", "");
    setValue("description", "");
    setValue("date", "");
    setDate(null);
  }, [dialog.edit]);

  return (
    <Dialog open={dialog.open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClose={onClose}>
          {!dialog.edit ? "Tambah" : "Edit"} Prestasi
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            label="Nama Kompetisi"
            name="name"
            inputRef={register}
            error={!!errors.name}
            helperText={!!errors.name && errors.name.message}
          />
          <Controller
            control={control}
            defaultValue=""
            name="scale"
            render={({ onChange, ref, value }) => (
              <TextField
                className={classes.field}
                fullWidth
                select
                label="Skala Kompetisi"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                inputRef={ref}
                error={!!errors.scale}
                helperText={!!errors.scale && errors.scale.message}
              >
                <MenuItem value="" disabled></MenuItem>
                <MenuItem value="internasional">Internasional</MenuItem>
                <MenuItem value="nasional">Nasional</MenuItem>
                <MenuItem value="provinsi">Provinsi</MenuItem>
                <MenuItem value="kota">Kota</MenuItem>
              </TextField>
            )}
          />
          <TextField
            className={classes.field}
            fullWidth
            label="Juara"
            name="grade"
            inputRef={register}
            error={!!errors.grade}
            helperText={!!errors.grade && errors.grade.message}
          />
          <TextField
            className={classes.field}
            fullWidth
            label="Deskripsi Prestasi"
            name="description"
            inputRef={register}
            error={!!errors.description}
            helperText={!!errors.description && errors.description.message}
          />
          <Controller
            control={control}
            defaultValue=""
            name="date"
            render={({ onChange, ref }) => (
              <DatePicker
                className={classes.field}
                fullWidth
                label="Tanggal Kompetisi (bulan dan tahun)"
                views={["year", "month"]}
                value={date}
                format="MM-yyyy"
                onChange={(e) => {
                  setDate(e);
                  onChange(moment(e).format("YYYY-MM"));
                }}
                inputRef={ref}
                error={!!errors.date}
                helperText={!!errors.date && errors.date.message}
              />
            )}
          />
        </DialogContent>
        <DialogActions className={clsx({ [classes.cardActions]: dialog.edit })}>
          {dialog.edit && (
            <Button
              className={classes.btnDelete}
              variant="contained"
              onClick={() => handleDelete(dialog.value.id)}
            >
              Delete Data
            </Button>
          )}
          <Button variant="contained" color="primary" type="submit">
            {!dialog.edit ? "Simpan" : "Edit Data"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default connect(null, {
  sendPrestasi,
  sendEditPrestasi,
  deletePrestasi,
})(DialogPrestasi);
