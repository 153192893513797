import {
  SET_PROFILE,
  SET_SKILL,
  SET_PENDIDIKAN,
  SET_ORGANISASI,
  SET_PRESTASI,
  SET_PEKERJAAN,
  SET_KELUARGA,
  SET_ALAMAT,
  SET_TYPE_SOCIAL_MEDIA,
  SET_SOCIAL_MEDIA,
  SET_INFORMASI_PEKERJAAN,
  SET_RUMUSAN_TUGAS,
  SET_TUGAS_POKOK,
  SET_TUGAS_TAMBAHAN,
  SET_TUGAS_BERKALA,
  SET_TUGAS_LAINNYA,
  SET_TUGAS_BELUM_TERLAKSANA,
  SET_ALAMAT_LOADING,
  SET_SEMINAR,
} from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  profile: null,
  skill: [],
  seminar: [],
  pendidikan: [],
  organisasi: [],
  prestasi: [],
  pekerjaan: [],
  keluarga: [],
  alamat: [{}],
  typeSocialMedia: [],
  socialMedia: [],
  loadingProfile: true,
  loadingSkill: true,
  loadingSeminar: true,
  loadingPendidikan: true,
  loadingOrganisasi: true,
  loadingPrestasi: true,
  loadingPekerjaan: true,
  loadingKeluarga: true,
  loadingAlamat: true,
  loadingTypeSocialMedia: true,
  loadingSocialMedia: true,

  // Informasi pekerjaan
  informasiPekerjaan: {},
  loadingInformasiPekerjaan: true,

  // JOB ANALYST
  rumusanTugas: {},
  tugasPokok: [],
  tugasTambahan: [],
  tugasBerkala: [],
  tugasLainnya: [],
  tugasBelumTerlaksana: [],
  loadingRumusanTugas: true,
  loadingTugasPokok: true,
  loadingTugasTambahan: true,
  loadingTugasBerkala: true,
  loadingTugasLainnya: true,
  loadingTugasBelumTerlaksana: true,
};

// ------ INFORMASI PRIBADI -------
const setProfile = (state, action) => {
  return updateObject(state, {
    profile: action.payload,
    loadingProfile: false,
  });
};

const setSkill = (state, action) => {
  return updateObject(state, { skill: action.payload, loadingSkill: false });
};

const setSeminar = (state, action) => {
  return updateObject(state, {
    seminar: action.payload,
    loadingSeminar: false,
  });
};

const setPendidikan = (state, action) => {
  return updateObject(state, {
    pendidikan: action.payload,
    loadingPendidikan: false,
  });
};

const setOrganisasi = (state, action) => {
  return updateObject(state, {
    organisasi: action.payload,
    loadingOrganisasi: false,
  });
};

const setPrestasi = (state, action) => {
  return updateObject(state, {
    prestasi: action.payload,
    loadingPrestasi: false,
  });
};

const setPekerjaan = (state, action) => {
  return updateObject(state, {
    pekerjaan: action.payload,
    loadingPekerjaan: false,
  });
};

const setKeluarga = (state, action) => {
  return updateObject(state, {
    keluarga: action.payload,
    loadingKeluarga: false,
  });
};

const setAlamat = (state, action) => {
  return updateObject(state, {
    alamat: action.payload,
    loadingAlamat: false,
  });
};

const setAlamatLoading = (state, action) => {
  return updateObject(state, {
    loadingAlamat: true,
  });
};

const setTypeSocialMedia = (state, action) => {
  return updateObject(state, {
    typeSocialMedia: action.payload,
    loadingTypeSocialMedia: false,
  });
};

const setSocialMedia = (state, action) => {
  return updateObject(state, {
    socialMedia: action.payload,
    loadingSocialMedia: false,
  });
};

// ------ INFORMASI PEKERJAAN -------
const setInformasiPekerjaan = (state, action) => {
  return updateObject(state, {
    informasiPekerjaan: action.payload,
    loadingInformasiPekerjaan: false,
  });
};

// ------ JOB ANALYST -------
const setRumusanTugas = (state, action) => {
  return updateObject(state, {
    rumusanTugas: action.payload,
    loadingRumusanTugas: false,
  });
};

const setTugasPokok = (state, action) => {
  return updateObject(state, {
    tugasPokok: action.payload,
    loadingTugasPokok: false,
  });
};

const setTugasTambahan = (state, action) => {
  return updateObject(state, {
    tugasTambahan: action.payload,
    loadingTugasTambahan: false,
  });
};

const setTugasBerkala = (state, action) => {
  return updateObject(state, {
    tugasBerkala: action.payload,
    loadingTugasBerkala: false,
  });
};

const setTugasLainnya = (state, action) => {
  return updateObject(state, {
    tugasLainnya: action.payload,
    loadingTugasLainnya: false,
  });
};

const setTugasBelumTerlaksana = (state, action) => {
  return updateObject(state, {
    tugasBelumTerlaksana: action.payload,
    loadingTugasBelumTerlaksana: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROFILE:
      return setProfile(state, action);
    case SET_SKILL:
      return setSkill(state, action);
    case SET_SEMINAR:
      return setSeminar(state, action);
    case SET_PENDIDIKAN:
      return setPendidikan(state, action);
    case SET_ORGANISASI:
      return setOrganisasi(state, action);
    case SET_PRESTASI:
      return setPrestasi(state, action);
    case SET_PEKERJAAN:
      return setPekerjaan(state, action);
    case SET_KELUARGA:
      return setKeluarga(state, action);
    case SET_ALAMAT:
      return setAlamat(state, action);
    case SET_TYPE_SOCIAL_MEDIA:
      return setTypeSocialMedia(state, action);
    case SET_SOCIAL_MEDIA:
      return setSocialMedia(state, action);
    case SET_INFORMASI_PEKERJAAN:
      return setInformasiPekerjaan(state, action);
    case SET_RUMUSAN_TUGAS:
      return setRumusanTugas(state, action);
    case SET_TUGAS_POKOK:
      return setTugasPokok(state, action);
    case SET_TUGAS_TAMBAHAN:
      return setTugasTambahan(state, action);
    case SET_TUGAS_BERKALA:
      return setTugasBerkala(state, action);
    case SET_TUGAS_LAINNYA:
      return setTugasLainnya(state, action);
    case SET_TUGAS_BELUM_TERLAKSANA:
      return setTugasBelumTerlaksana(state, action);
    case SET_ALAMAT_LOADING:
      return setAlamatLoading(state, action);
    default:
      return state;
  }
};

export default reducer;
