/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  TextField,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import DatePicker from "../../../../../components/UI/DatePicker/DatePicker";
import {
  sendPekerjaan,
  sendEditPekerjaan,
  deletePekerjaan,
} from "../../../../../store/actions";

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
  cardActions: {
    justifyContent: "space-between",
  },
  btnDelete: {
    color: "#fff",
    background: theme.palette.red.color500,
    "&:hover": {
      backgroundColor: theme.palette.red.color700,
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const validationSchema = yup.object().shape({
  company_name: yup.string().required("Wajib diisi"),
  position: yup.string().required("Wajib diisi"),
  description: yup.string().required("Wajib diisi"),
  start_at: yup.string().required("Wajib diisi"),
  end_at: yup.string().required("Wajib diisi"),
});

const DialogPekerjaan = ({
  dialog,
  onClose,
  sendPekerjaan,
  sendEditPekerjaan,
  deletePekerjaan,
  hcis,
}) => {
  const classes = useStyles();
  const params = useParams();

  const [start_at, setStart_at] = useState(null);
  const [end_at, setEnd_at] = useState(null);

  const { register, handleSubmit, errors, control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  const handleDelete = (id) => {
    if (hcis) {
      deletePekerjaan(id, params.userId);
    } else {
      deletePekerjaan(id);
    }
    onClose();
  };

  const onSubmit = (data, e) => {
    if (dialog.edit) {
      const formData = {
        ...data,
        start_at: moment(data.start_at).format("YYYY-MM"),
        end_at: moment(data.end_at).format("YYYY-MM"),
      };
      if (hcis) {
        sendEditPekerjaan(formData, dialog.value.id, params.userId);
      } else {
        sendEditPekerjaan(formData, dialog.value.id);
      }
    } else {
      if (hcis) {
        sendPekerjaan(data, params.userId);
      } else {
        sendPekerjaan(data);
      }
    }
    e.target.reset();
    setValue("start_at", null);
    setValue("end_at", null);
    setStart_at(null);
    setEnd_at(null);
    onClose();
  };

  useEffect(() => {
    if (dialog.edit) {
      const start = `${dialog.value.start_month}-01-${dialog.value.start_year}`;
      const end = `${dialog.value.end_month}-01-${dialog.value.end_year}`;
      setStart_at(moment(start).format("ll"));
      setEnd_at(end);
      setValue("company_name", dialog.value.company_name);
      setValue("position", dialog.value.position);
      setValue("description", dialog.value.description);
      setValue("start_at", start);
      setValue("end_at", end);
      return;
    }
    setValue("company_name", "");
    setValue("position", "");
    setValue("description", "");
    setValue("start_at", "");
    setValue("end_at", "");
    setStart_at(null);
    setEnd_at(null);
  }, [dialog.edit]);

  return (
    <Dialog open={dialog.open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClose={onClose}>
          {!dialog.edit ? "Tambah" : "Edit"} Riwayat Pekerjaan
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            label="Nama Perusahaan"
            name="company_name"
            inputRef={register}
            error={!!errors.company_name}
            helperText={!!errors.company_name && errors.company_name.message}
          />
          <TextField
            className={classes.field}
            fullWidth
            label="Jabatan"
            name="position"
            inputRef={register}
            error={!!errors.position}
            helperText={!!errors.position && errors.position.message}
          />
          <TextField
            className={classes.field}
            fullWidth
            label="Deskripsi Pekerjaan"
            name="description"
            inputRef={register}
            error={!!errors.description}
            helperText={!!errors.description && errors.description.message}
          />
          <Controller
            control={control}
            defaultValue=""
            name="start_at"
            render={({ onChange, ref }) => (
              <DatePicker
                value={start_at}
                className={classes.field}
                fullWidth
                label="Awal Kerja"
                views={["year", "month"]}
                inputRef={ref}
                format="MM-yyyy"
                onChange={(e) => {
                  setStart_at(e);
                  onChange(moment(e).format("YYYY-MM"));
                }}
                error={!!errors.start_at}
                helperText={!!errors.start_at && errors.start_at.message}
              />
            )}
          />
          <Controller
            control={control}
            defaultValue=""
            name="end_at"
            render={({ onChange, ref }) => (
              <DatePicker
                className={classes.field}
                fullWidth
                label="Akhir Kerja"
                views={["year", "month"]}
                name="end_at"
                value={end_at}
                format="MM-yyyy"
                onChange={(e) => {
                  setEnd_at(e);
                  onChange(moment(e).format("YYYY-MM"));
                }}
                inputRef={ref}
                error={!!errors.end_at}
                helperText={!!errors.end_at && errors.end_at.message}
              />
            )}
          />
        </DialogContent>
        <DialogActions className={clsx({ [classes.cardActions]: dialog.edit })}>
          {dialog.edit && (
            <Button
              className={classes.btnDelete}
              variant="contained"
              onClick={() => handleDelete(dialog.value.id)}
            >
              Delete Data
            </Button>
          )}
          <Button variant="contained" color="primary" type="submit">
            {!dialog.edit ? "Simpan" : "Edit Data"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default connect(null, {
  sendPekerjaan,
  sendEditPekerjaan,
  deletePekerjaan,
})(DialogPekerjaan);
