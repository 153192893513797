import axios from "../../axios-orders";
import { setAlert } from "./alert";
import moment from "moment";
import {
  SET_REQUEST_LEAVE,
  SET_LOADING,
  SET_ALL_REQUEST_LEAVE,
  SET_OVERVIEW,
  SET_CHECKED,
  SET_DETAIL_REQUEST_LEAVE,
  SET_TOTAL_REQUEST_LEAVE_IZIN,
  SET_TOTAL_REQUEST_LEAVE_SAKIT,
  SET_TOTAL_REQUEST_LEAVE_CUTI,
} from "./actionTypes";

export const setLoading = () => {
  return {
    type: SET_LOADING,
  };
};

export const setChecked = (data, name, checked) => (dispatch) => {
  dispatch(setLoading());

  let newData = data;

  newData.filter((v) => {
    if (v.user_name === name) {
      return (v.checked = checked);
    }
  });
  setTimeout(() => {
    dispatch({
      type: SET_CHECKED,
      payload: newData,
    });
  }, 500);
};

export const getRequestLeave = () => async (dispatch) => {
  try {
    const res = await axios.get(`staff/user/employee/position/own/leaves`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_REQUEST_LEAVE,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(setAlert("Can't get data request leave!", "error"));
  }
};

export const getDataAllRequestLeave =
  (
    isHcis = false,
    company = "",
    type = "",
    status = "",
    year = "",
    page = 1,
    size = 10
  ) =>
  async (dispatch) => {
    dispatch(setLoading());
    let url;
    if (isHcis) {
      url = `hcis/user/employee/position/leaves/all?company_id=${company}&type=${type}&status=${status}&year=${year}&page=${page}&page_size=${size}`;
    } else {
      url = `/manager/user/employee/position/leaves/company?type=${type}&status=${status}&year=${year}&page=${page}&page_size=${size}`;
    }
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      dispatch({
        type: SET_ALL_REQUEST_LEAVE,
        payload: res.data.data,
        current_page: page,
        has_previous_page: res.data.has_previous_page,
        has_next_page: res.data.has_next_page,
        previous_page: res.data.previous_page,
        next_page: res.data.next_page,
        last_page: res.data.last_page,
        total_item: res.data.total_item,
      });
    } catch (error) {
      dispatch(setAlert("Can't get data request leave!", "error"));
    }
  };

export const getOverview =
  (isHcis = false, company = "", status = "", type = "", year = "") =>
  async (dispatch) => {
    dispatch(setLoading());
    let url;
    if (isHcis) {
      url = `/hcis/user/employee/position/leaves/overview?company_id=${company}&status=${status}&type=${type}&year=${year}`;
    } else {
      url = `/staff/user/employee/position/leaves/company`;
    }
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      const color = (item) => {
        switch (item) {
          case "izin":
            return "#ffc764";
          case "sakit":
            return "#f1aa9b";
          default:
            return "#a7c5eb";
        }
      };

      const data = res.data.data.map((item) => {
        return {
          ...item,
          start: moment(item.start_date, "DD-MM-YYYY").format("YYYY-MM-DD"),
          end: `${moment(item.end_date, "DD-MM-YYYY").format(
            "YYYY-MM-DD"
          )}T23:59:59`,
          title: item.user_name,
          backgroundColor: color(item.request_type),
          checked: true,
        };
      });

      dispatch({
        type: SET_OVERVIEW,
        payload: data,
      });
    } catch (error) {
      dispatch(setAlert("Can't get data request leave!", "error"));
    }
  };

export const getDetailRequestLeave =
  (id, history = null) =>
  async (dispatch) => {
    try {
      const res = await axios.get(`staff/user/employee/position/leaves/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      dispatch({
        type: SET_DETAIL_REQUEST_LEAVE,
        payload: res.data.data,
      });
    } catch (error) {
      if (error.response.status === 404) {
        return history.push("/not-found");
      }
      dispatch(setAlert("Can't get data detail request leave!", "error"));
    }
  };

export const sendRequestLeave =
  (data, isOverview = false, isHcis = false) =>
  async (dispatch) => {
    dispatch(setLoading());
    try {
      const res = await axios.post(
        `/staff/user/employee/position/own/leaves `,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      dispatch(setAlert("Perizinan berhasil dibuat", "success"));
      if (isOverview) {
        if (isHcis) {
          dispatch(getOverview(true));
        } else {
          dispatch(getOverview());
        }
      } else {
        dispatch(getRequestLeave());
      }
    } catch (error) {
      dispatch(setAlert("Perizinan gagal dibuat", "error"));
    }
  };

export const editRequestLeave = (data, id) => async (dispatch) => {
  dispatch(setLoading());
  try {
    await axios.put(`/staff/user/employee/position/own/leaves/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch(setAlert("Perizinan berhasil diedit", "success"));
    dispatch(getRequestLeave());
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};

export const deleteRequestLeave = (id) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const res = await axios.delete(
      `staff/user/employee/position/own/leaves/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    dispatch(setAlert("Perizinan Berhasil Di Hapus", "success"));
    dispatch(getRequestLeave());
  } catch (error) {
    dispatch(setAlert("Perizinan Gagal Di Hapus", "error"));
  }
};

export const changeStatus =
  (id, type, data, isHcis = false) =>
  async (dispatch) => {
    let message;
    if (type === "confirm") {
      message = "Berhasil Di Setujui";
    } else {
      message = "Berhasil Di Batalkan";
    }
    dispatch(setLoading());
    try {
      const res = await axios.patch(
        `manager/user/employee/position/leaves/${id}/action`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );
      dispatch(setAlert(message, "success"));
      if (isHcis) {
        dispatch(getDataAllRequestLeave(true));
      } else {
        dispatch(getDataAllRequestLeave());
      }
    } catch (error) {
      dispatch(setAlert("Something Went Wrong", "error"));
    }
  };

export const getTotalRequestLeave = (type) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/manager/user/employee/position/leaves/total?type=${type}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    switch (type) {
      case "izin":
        return dispatch({
          type: SET_TOTAL_REQUEST_LEAVE_IZIN,
          payload: res.data.data,
        });
      case "cuti":
        return dispatch({
          type: SET_TOTAL_REQUEST_LEAVE_CUTI,
          payload: res.data.data,
        });
      case "sakit":
        return dispatch({
          type: SET_TOTAL_REQUEST_LEAVE_SAKIT,
          payload: res.data.data,
        });
      default:
        return dispatch({
          type: SET_TOTAL_REQUEST_LEAVE_IZIN,
          payload: res.data.data,
        });
    }
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};
