import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { Container, Typography, Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { TableQuotes, DialogQuotes } from "./component";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("xs")]: {
      padding: 10,
    },
  },
  button: {
    backgroundColor: "#011747",
  },
  btnCreate: {
    marginBottom: theme.spacing(2),
  },
  txtFieldSearch: {
    marginBottom: theme.spacing(1),
  },
}));

const KelolaQuotes = ({ auth }) => {
  const classes = useStyles();
  const [openDialogQuotes, setOpenDialogQuotes] = useState({
    open: false,
    edit: false,
    value: null,
  });
  if (auth.user.role_name !== "hcis") {
    return <Redirect to="/" />;
  }
  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" style={{ marginBottom: "40px" }}>
        Kelola Quotes
      </Typography>
      <Button
        className={classes.btnCreate}
        variant="contained"
        color="primary"
        size="small"
        startIcon={<AddIcon />}
        onClick={() =>
          setOpenDialogQuotes((prev) => ({
            ...prev,
            open: true,
          }))
        }
      >
        Tambah Quotes
      </Button>
      <TableQuotes setDialog={setOpenDialogQuotes} />

      {/* Dialog Quotes */}
      <DialogQuotes
        dialog={openDialogQuotes}
        onClose={() =>
          setOpenDialogQuotes({ open: false, edit: false, value: null })
        }
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(KelolaQuotes);
