import * as yup from "yup";

const validasi = yup.object().shape({
  id_number: yup
    .string()
    .required("Nomor KTP wajib diisi")
    .test("len", "nomor KTP wajib 16 angka", (val) => val.length === 16),
  tax_number: yup
    .string()
    .required("Nomor NPWP wajib diisi")
    .test("len", "nomor NPWP wajib 20 angka", (val) => val.length === 20),
  account_number: yup.string().required("Nomor Rekening wajib diisi"),
  start_at: yup.string().required("Awal Kerja wajib diisi"),
  status_id: yup.string().required("Status wajib diisi"),
  place_of_birth: yup.string().required("Tempat Lahir wajib diisi"),
  date_of_birth: yup.string().required("Tanggal Lahir wajib diisi"),
  gender: yup.string().required("Jenis Kelamin wajib diisi"),
  blood_type: yup.string().required("Golongan Darah wajib diisi"),
  ethnic: yup.string().required("Suku wajib diisi"),
  religion: yup.string().required("Agama wajib diisi"),
  height: yup
    .number()
    .required("Tinggi badan wajib diisi")
    .test("len", "tinggi badan tidak valid", (val) => val <= 250),
  personality: yup.string().required("STIFIN wajib diisi"),
  marital_status: yup.string().required("Status Menikah wajib diisi"),
  weight: yup
    .number()
    .required("Berat badan wajib diisi")
    .test("len", "berad badan tidak valid", (val) => val <= 200),
  about_me: yup.string().required("Tentang aku wajib diisi"),
  province_ktp: yup.string().required("Provinsi KTP wajib diisi"),
  city_ktp: yup.string().required("Kota /Kabupaten KTP wajib diisi"),
  district_ktp: yup.string().required("Kecamatan KTP wajib diisi"),
  sub_district_ktp: yup.string().required("Kelurahan KTP wajib diisi"),
  postal_code_ktp: yup.string().required("Kode pos KTP wajib diisi"),
  street_ktp: yup.string().required("Alamat KTP wajib diisi"),
  province_domisili: yup.string().required("Provinsi Domisili wajib diisi"),
  city_domisili: yup.string().required("Kota Domisili wajib diisi"),
  district_domisili: yup.string().required("Kecamatan Domisili wajib diisi"),
  sub_district_domisili: yup
    .string()
    .required("Kelurahan Domisili wajib diisi"),
  postal_code_domisili: yup.string().required("Kode pos Domisili wajib diisi"),
  street_domisili: yup.string().required("Alamat jalan Domisili wajib diisi"),
  province_mail: yup.string().required("provinsi surat wajib diisi"),
  city_mail: yup.string().required("Kota surat wajib diisi"),
  district_mail: yup.string().required("Kecamatan surat wajib diisi"),
  sub_district_mail: yup.string().required("Kelurahan surat wajib diisi"),
  postal_code_mail: yup.string().required("Kode pos surat wajib diisi"),
  street_mail: yup.string().required("Alamat surat wajib diisi"),
});

export default validasi;
