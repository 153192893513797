import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import jwt_decode from "jwt-decode";

const RouteWithLayout = (props) => {
  const { layout: Layout, component: Component, ...rest } = props;

  const access_token = localStorage.getItem("access_token");
  if (!!access_token) {
    const decoded = jwt_decode(access_token);
    const expirationTime = decoded.exp * 1000;
    const date = Date.now();

    if (date >= expirationTime) {
      localStorage.clear();
      return <Redirect to="/login" />;
    }
  }

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        access_token ? (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

export default RouteWithLayout;
