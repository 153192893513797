import axios from '../../axios-orders';
import { setAlert } from './alert';
import {
  SET_ALL_OVERTIME,
  SET_STAFF,
  SET_OVERTIME_ASSIGN,
} from './actionTypes';

/*
| ---------------------------------------------------------------
    MANAGER
| ---------------------------------------------------------------
*/

export const getListStaff = () => async (dispatch) => {
  try {
    const response = await axios.get(
      '/manager/user/employee/position/overtime/fetch-staff',
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    );
    dispatch({
      type: SET_STAFF,
      payload: response.data.data,
    });
  } catch (error) {
    dispatch(setAlert('something went wrong', 'error'));
  }
};

export const getOvertimeAssign =
  (page = 1, page_size = 10) =>
  async (dispatch) => {
    try {
      const response = await axios.get(
        `/manager/user/employee/position/overtime/assigned?page=${page}&page_size=${page_size}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch({
        type: SET_OVERTIME_ASSIGN,
        payload: response.data.data,
        last_page: response.data.last_page,
      });
    } catch (error) {
      dispatch(setAlert('something went wrong', 'error'));
    }
  };

export const getAllOvertimeManager =
  (page = 1, page_size = 10, status, year, entitas) =>
  async (dispatch) => {
    try {
      const response = await axios(
        `/manager/user/employee/position/overtime?page=${page}&page_size=${page_size}&status=${status}&year=${year}&company_id=${entitas}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch({
        type: SET_ALL_OVERTIME,
        payload: response.data.data,
      });
    } catch (error) {
      dispatch(setAlert('something went wrong', 'error'));
    }
  };

export const postAssignOvertime = (id, data, history) => async (dispatch) => {
  try {
    await axios.post(`/manager/user/employee/position/${id}/overtime`, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    dispatch(setAlert('Data berhasil di Assign', 'success'));
    history.push('/lembur-ditugaskan');
  } catch (error) {
    dispatch(setAlert('something went wrong', 'error'));
  }
};

export const approveAssignOvertime = (id, positionId) => async (dispatch) => {
  try {
    await axios.patch(
      `/manager/user/employee/position/${positionId}/overtime/${id}`,
      { status: 'approved' },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    );
    dispatch(setAlert('Data berhasil di Approve', 'success'));
    dispatch(getOvertimeAssign());
  } catch (error) {
    dispatch(setAlert('something went wrong', 'error'));
  }
};

export const rejectAssignOvertime = (id, positionId) => async (dispatch) => {
  try {
    await axios.patch(
      `/manager/user/employee/position/${positionId}/overtime/${id}`,
      { status: 'rejected' },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    );
    dispatch(setAlert('Data berhasil di Reject', 'success'));
    dispatch(getOvertimeAssign());
  } catch (error) {
    dispatch(setAlert('something went wrong', 'error'));
  }
};

export const putApproveOvertimeManager =
  (id, positionId, page, entitas, status, year) => async (dispatch) => {
    try {
      await axios.patch(
        `/manager/user/employee/position/${positionId}/overtime/${id}`,
        { status: 'approved' },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(setAlert('Data berhasil di Approve', 'success'));
      dispatch(getAllOvertimeManager(page, 10, status, year, entitas));
    } catch (error) {
      dispatch(setAlert('something went wrong', 'error'));
    }
  };

export const putRejectOvertimeManager =
  (id, positionId, page, entitas, status, year) => async (dispatch) => {
    try {
      await axios.patch(
        `/manager/user/employee/position/${positionId}/overtime/${id}`,
        { status: 'rejected' },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch(setAlert('Data berhasil di Reject', 'success'));
      dispatch(getAllOvertimeManager(page, 10, status, year, entitas));
    } catch (error) {
      dispatch(setAlert('something went wrong', 'error'));
    }
  };

/*
| ---------------------------------------------------------------
    STAFF
| ---------------------------------------------------------------
*/

export const getAllOvertimeStaff =
  (page = 1, page_size = 10) =>
  async (dispatch) => {
    try {
      const response = await axios(
        `/staff/user/employee/position/own/overtime?page=${page}&page_size=${page_size}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch({
        type: SET_ALL_OVERTIME,
        payload: response.data.data,
      });
    } catch (error) {
      dispatch(setAlert('something went wrong', 'error'));
    }
  };

export const getOvertimeAssignStaff =
  (page = 1, page_size = 10) =>
  async (dispatch) => {
    try {
      const response = await axios.get(
        `/staff/user/employee/position/own/overtime/assigned?page=${page}&page_size=${page_size}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }
      );
      dispatch({
        type: SET_OVERTIME_ASSIGN,
        payload: response.data.data,
        last_page: response.data.last_page,
      });
    } catch (error) {
      dispatch(setAlert('something went wrong', 'error'));
    }
  };

export const postOvertime = (data, history) => async (dispatch) => {
  try {
    await axios.post(`/staff/user/employee/position/own/overtime`, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    dispatch(setAlert('Data berhasil dikirim', 'success'));
    history.push('/pengajuan-lembur');
  } catch (error) {
    dispatch(setAlert('something went wrong', 'error'));
  }
};

export const putOvertime = (id, data, history) => async (dispatch) => {
  try {
    await axios.put(`/staff/user/employee/position/own/overtime/${id}`, data, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    dispatch(setAlert('Data berhasil dirubah', 'success'));
    history.push('/pengajuan-lembur');
  } catch (error) {
    dispatch(setAlert('something went wrong', 'error'));
  }
};

export const deleteOvertime = (id, page) => async (dispatch) => {
  try {
    await axios.delete(`/staff/user/employee/position/own/overtime/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    dispatch(setAlert('Data berhasil dihapus', 'success'));
    dispatch(getAllOvertimeStaff(page));
  } catch (error) {
    dispatch(setAlert('something went wrong', 'error'));
  }
};

export const updateStatusOvertime = (id, data, page) => async (dispatch) => {
  try {
    await axios.patch(
      `/staff/user/employee/position/own/overtime/${id}`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }
    );
    dispatch(setAlert('Data berhasil diupdate', 'success'));
    dispatch(getAllOvertimeStaff(page));
    dispatch(getOvertimeAssignStaff(page));
  } catch (error) {
    dispatch(setAlert('something went wrong', 'error'));
  }
};
