import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { deleteNews } from "../../../store/actions";

const DialogAlert = ({ dialog, onClose, deleteNews }) => {
  const onDelete = () => {
    deleteNews(dialog.id);
    onClose();
  };
  return (
    <div>
      <Dialog
        open={dialog.open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Apakah anda yakin ingin menghapus berita ini?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Berita akan terhapus dan tidak akan bisa dikembalikan lagi...
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained" color="primary">
            Batal
          </Button>
          <Button
            onClick={onDelete}
            variant="outlined"
            color="secondary"
            autoFocus
          >
            Hapus
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default connect(null, { deleteNews })(DialogAlert);
