import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import clsx from "clsx";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { DialogCreate, DialogUpdate, DialogDelete } from "./component";
import { connect } from "react-redux";
import {
  getTask,
  createTask,
  editTask,
  deleteTask,
} from "../../../../store/actions";
import { changeStatus } from "../../../../store/actions/auth";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1.25),
    maxHeight: 400,
    overflow: "auto",
  },
  mR: {
    margin: theme.spacing(1.5),
  },

  iconDelete: {
    color: theme.palette.error.main,
  },
  container: {
    maxHeight: 300,
  },

  isCompleted: {
    textDecoration: "line-through",
  },

  changeStatus: {
    "&:hover": {
      cursor: "pointer",
    },
  },

  tableHead: {
    backgroundColor: "#FFF7E4",
  },
}));

const ToAchieveList = ({ task, getTask, deleteTask, createTask, editTask }) => {
  const classes = useStyles();

  const [dialogCreate, setDialogCreate] = useState({ open: false });
  const [dialogUpdate, setDialogUpdate] = useState({ open: false, data: null });
  const [dialogDelete, setDialogDelete] = useState({ open: false, id: null });

  const changeStatus = (value) => {
    let status;
    if (value.status === "pending") {
      status = "completed";
    } else {
      status = "pending";
    }

    const formData = {
      task: value.task,
      status: status,
    };

    editTask(value.ID, formData);
  };

  useEffect(() => {
    getTask();
  }, [getTask]);
  return (
    <>
      <Card className={classes.root}>
        <CardHeader
          title="To Achieve List"
          action={
            <Button
              onClick={() => setDialogCreate({ open: true })}
              color="primary"
              size="small"
              variant="contained"
            >
              Tambah
            </Button>
          }
        />
        <CardContent>
          <TableContainer className={classes.container}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    classes={{
                      root: classes.tableHead,
                    }}
                  >
                    No
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableHead,
                    }}
                  >
                    Uraian
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableHead,
                    }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    classes={{
                      root: classes.tableHead,
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {task.loading ? (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                ) : task.data.length > 0 ? (
                  task.data.map((value, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell
                          onClick={() => {
                            changeStatus(value);
                          }}
                          className={clsx(
                            {
                              [classes.isCompleted]:
                                value.status === "completed",
                            },
                            classes.changeStatus
                          )}
                        >
                          {value.task}
                        </TableCell>
                        <TableCell>{value.status}</TableCell>
                        <TableCell>
                          <IconButton
                            className={classes.mR}
                            onClick={() => {
                              setDialogUpdate({ open: true, data: value });
                            }}
                            size="small"
                            color="primary"
                          >
                            <Tooltip title="Edit">
                              <EditIcon fontSize="small" />
                            </Tooltip>
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setDialogDelete({ open: true, id: value.ID });
                            }}
                            size="small"
                            color="inherit"
                          >
                            <Tooltip title="Hapus">
                              <DeleteIcon
                                className={classes.iconDelete}
                                fontSize="small"
                              />
                            </Tooltip>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      Data Kosong
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
      <DialogCreate
        dialog={dialogCreate}
        onClose={() => setDialogCreate({ open: false })}
        action={createTask}
      />
      <DialogUpdate
        dialog={dialogUpdate}
        onClose={() => setDialogUpdate({ open: false, data: null })}
        action={editTask}
      />
      <DialogDelete
        dialog={dialogDelete}
        onClose={() => setDialogDelete({ open: false, id: null })}
        action={deleteTask}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    task: state.task,
  };
};

export default connect(mapStateToProps, {
  getTask,
  createTask,
  deleteTask,
  editTask,
})(ToAchieveList);
