import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";

import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  Divider,
  CardHeader,
  TextField,
  Button,
} from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { connect } from "react-redux";
import { sendNews, setAlert } from "../../store/actions";

// Components
import Breadscrumb from "../../components/UI/Breadcrumb/Breadcrumb";

const useStyles = makeStyles((theme) => ({
  padLeft: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  card: {
    margin: theme.spacing(1.25),
  },
}));

const scrumbs = [
  { name: "Dashboard", link: "/" },
  { name: "Berita", link: "/berita" },
  { name: "Buat Berita", link: "/" },
];

const validationSchema = yup.object().shape({
  title: yup.string().required("Wajib diisi"),
  sub_title: yup.string().required("Wajib diisi"),
});

const BuatBerita = ({ sendNews, setAlert, auth }) => {
  const classes = useStyles();
  const history = useHistory();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [init, setInit] = useState(EditorState.createEmpty());

  const onEditorStateChange = (e) => {
    setEditorState(e);
  };

  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  const onSubmit = (data) => {
    let draft = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    let initial = draftToHtml(convertToRaw(init.getCurrentContent()));
    const formData = {
      ...data,
      body: draft,
    };

    if (draft === initial) {
      return setAlert("Content Berita Tidak boleh Kosong", "error");
    }

    sendNews(formData, history);
  };

  if (auth.user.role_name !== "hcis") {
    return <Redirect to="/berita" />;
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h3" className={classes.padLeft}>
        Buat Berita
      </Typography>
      <Breadscrumb crumb={scrumbs} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item xs={8}>
            <Card className={classes.card}>
              <CardHeader title="Header Berita" />
              <Divider />
              <CardContent>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Tulis Header Berita..."
                  name="title"
                  inputRef={register}
                  error={!!errors.title}
                  helperText={!!errors.title && errors.title.message}
                />
              </CardContent>
            </Card>
            <Card className={classes.card}>
              <CardHeader title="Sub Title Berita" />
              <Divider />
              <CardContent>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Tulis Sub Title Berita..."
                  name="sub_title"
                  inputRef={register}
                  error={!!errors.sub_title}
                  helperText={!!errors.sub_title && errors.sub_title.message}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card className={classes.card}>
              <CardHeader title="Publish" />
              <Divider />
              <CardContent>
                <Typography>Status : - </Typography>
              </CardContent>
              <CardActions>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Button
                      onClick={() => setValue("status", "publish")}
                      type="submit"
                      name="publish"
                      size="small"
                      color="primary"
                      fullWidth
                      variant="contained"
                    >
                      publish
                    </Button>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Button
                      // onClick={() => setValue("status", "draft")}
                      // type="submit"
                      name="draft"
                      size="small"
                      fullWidth
                      variant="outlined"
                    >
                      save draft
                    </Button>
                  </Grid> */}
                </Grid>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.card}>
              <CardHeader title="Content Berita" />
              <Divider />
              <CardContent>
                {/* <CKEditor data="<p>Hello World</p>" /> */}
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={onEditorStateChange}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

const mapStateTopProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateTopProps, { sendNews, setAlert })(BuatBerita);
