import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import "moment/locale/id"; // without this line it didn't work
import {
  Container,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import Breadcrumb from "../../components/UI/Breadcrumb/Breadcrumb";
import { capitalize } from "../../shared/utility";
import { getDetailRequestLeave } from "../../store/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  btnApprove: {
    marginRight: theme.spacing(1),
  },
  status: {
    [theme.breakpoints.up("md")]: {
      textAlign: "right",
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const DetailRequestLeave = ({ getDetailRequestLeave, requestLeave }) => {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    getDetailRequestLeave(params.id, history);
  }, []);

  const scrumbs = [
    { name: "Overview", link: "/overview" },
    { name: "Detail Request Leave", link: "/" },
  ];
  return (
    <Container maxWidth="xl" className={classes.root}>
      <Breadcrumb crumb={scrumbs} />
      <Grid container>
        <Grid item xs={12} style={{ marginBottom: 15 }}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  {/* <Button
                    className={classes.btnApprove}
                    color="primary"
                    variant="contained"
                  >
                    Setuju
                  </Button>
                  <Button variant="contained">Tolak</Button> */}
                </Grid>
                <Grid item xs={12} md={6} className={classes.status}>
                  <Typography display="inline" variant="subtitle1">
                    Status :{" "}
                  </Typography>
                  <Typography variant="h5" display="inline">
                    {!requestLeave.loadingDetail &&
                      capitalize(requestLeave.detail.status)}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid style={{ marginBottom: 20 }} item xs={2}>
                      <Typography variant="subtitle1">Tipe Request</Typography>
                    </Grid>
                    <Grid style={{ paddingLeft: 20 }} item xs={10}>
                      <Typography variant="subtitle1">
                        :{" "}
                        {!requestLeave.loadingDetail &&
                          capitalize(requestLeave.detail.request_type)}
                      </Typography>
                    </Grid>

                    <Grid style={{ marginBottom: 20 }} item xs={2}>
                      <Typography variant="subtitle1">Durasi</Typography>
                    </Grid>
                    <Grid style={{ paddingLeft: 20 }} item xs={10}>
                      <Typography variant="subtitle1">
                        :{" "}
                        {!requestLeave.loadingDetail &&
                          requestLeave.detail.duration}{" "}
                        Hari
                      </Typography>
                    </Grid>

                    <Grid style={{ marginBottom: 20 }} item xs={2}>
                      <Typography variant="subtitle1">Tanggal</Typography>
                    </Grid>
                    <Grid style={{ paddingLeft: 20 }} item xs={10}>
                      <Typography variant="subtitle1">
                        :
                        {!requestLeave.loadingDetail &&
                          ` ${moment(
                            requestLeave.detail.start_date,
                            "DD-MM-YYYY"
                          )
                            .locale("id")
                            .format("LL")} - ${moment(
                            requestLeave.detail.end_date,
                            "DD-MM-YYYY"
                          )
                            .locale("id")
                            .format("LL")}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Grid container>
                    <Grid style={{ marginBottom: 20 }} item xs={2}>
                      <Typography variant="subtitle1">Nama</Typography>
                    </Grid>
                    <Grid style={{ paddingLeft: 20 }} item xs={10}>
                      <Typography variant="subtitle1">
                        :{" "}
                        {!requestLeave.loadingDetail &&
                          capitalize(requestLeave.detail.user_name)}
                      </Typography>
                    </Grid>

                    <Grid style={{ marginBottom: 20 }} item xs={2}>
                      <Typography variant="subtitle1">Entitas</Typography>
                    </Grid>
                    <Grid style={{ paddingLeft: 20 }} item xs={10}>
                      <Typography variant="subtitle1">
                        :{" "}
                        {!requestLeave.loadingDetail &&
                          capitalize(requestLeave.detail.company_name)}
                      </Typography>
                    </Grid>

                    <Grid style={{ marginBottom: 20 }} item xs={2}>
                      <Typography variant="subtitle1">Keterangan</Typography>
                    </Grid>
                    <Grid style={{ paddingLeft: 20 }} item xs={10}>
                      <Typography variant="subtitle1">
                        :{" "}
                        {!requestLeave.loadingDetail &&
                          requestLeave.detail.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={requestLeave.loadingDetail}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

const mapStateTopProps = (state) => {
  return {
    requestLeave: state.requestLeave,
  };
};

export default connect(mapStateTopProps, { getDetailRequestLeave })(
  DetailRequestLeave
);
