/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Button,
  Typography,
  IconButton,
  Grid,
  TextField,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const validationSchema = yup.object().shape({
  name: yup.string().required("Nama harus diisi"),
  sequence: yup
    .number()
    .typeError("Level harus diisi")
    .required("Level harus diisi"),
});

const DialogAddJob = ({ open, onClose, action }) => {
  const { handleSubmit, register, errors, control } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    action(data);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClose={onClose}>Tambah Job Title Baru</DialogTitle>
        <DialogContent dividers>
          <Grid container>
            <Grid Item xs={6}>
              <TextField
                fullWidth
                variant="filled"
                style={{ paddingRight: "10px" }}
                inputRef={register}
                name="name"
                label="Job Title"
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            </Grid>
            <Grid Item xs={6}>
              <Controller
                control={control}
                name="sequence"
                defaultValue={null}
                render={({ onChange }) => (
                  <TextField
                    select
                    fullWidth
                    variant="filled"
                    style={{ paddingLeft: "10px" }}
                    label="Level"
                    onChange={(e) => onChange(e.target.value)}
                    error={!!errors.sequence}
                    helperText={errors.sequence?.message}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Simpan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogAddJob;
