import React, { useEffect } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  TextField,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { sendQuotes, sendEditQuotes } from "../../../../store/actions";

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
  cardActions: {
    justifyContent: "center",
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const validationSchema = yup.object().shape({
  name: yup.string().required("Wajib diisi"),
});

const DialogQuotes = ({ dialog, onClose, sendQuotes, sendEditQuotes }) => {
  const classes = useStyles();

  const { register, errors, handleSubmit, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  const onSubmit = (data) => {
    if (dialog.edit) {
      sendEditQuotes(data, dialog.value.id);
    } else {
      sendQuotes(data);
      setValue("name", "");
    }
    onClose();
  };

  useEffect(() => {
    if (dialog.edit) {
      setValue("name", dialog.value.name);
      return;
    }
    setValue("name", "");
  }, [dialog.edit]);

  return (
    <Dialog open={dialog.open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClose={onClose}>
          {!dialog.edit ? "Tambah" : "Edit"} Quotes
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            name="name"
            label="Judul Quotes"
            inputRef={register}
            error={!!errors.name}
            helperText={!!errors.name && errors.name.message}
          />
        </DialogContent>
        <DialogActions className={clsx({ [classes.cardActions]: dialog.edit })}>
          <Button variant="contained" color="primary" type="submit">
            {!dialog.edit ? "Simpan" : "Edit Data"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default connect(null, { sendQuotes, sendEditQuotes })(DialogQuotes);
