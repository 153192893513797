import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: false,
  data: null,
};

const setPhoto = (state, action) => {
  return updateObject(state, { loading: false, data: action.payload });
};

const setLoadingPhoto = (state, action) => {
  return updateObject(state, { loading: true });
};

const setLoadingPhotoDone = (state, action) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PHOTO:
      return setPhoto(state, action);
    case actionTypes.SET_LOADING_PHOTO:
      return setLoadingPhoto(state, action);
    case actionTypes.SET_LOADING_PHOTO_DONE:
      return setLoadingPhotoDone(state, action);
    default:
      return state;
  }
};

export default reducer;
