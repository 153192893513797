import { CREATE_USER_DONE, CREATE_USER_START } from "./actionTypes";
import axios from "../../axios-orders";
import { setAlert } from "./alert";

export const createNewUser = (data, history) => async (dispatch) => {
  dispatch({ type: CREATE_USER_START });
  try {
    await axios.post("/hcis/user/add", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({ type: CREATE_USER_DONE });
    dispatch(setAlert("User baru berhasil dibuat", "success"));
    history.push("/employee");
  } catch (error) {
    dispatch(setAlert("something wen't wrongs", "error"));
  }
};
