/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  TextField,
  MenuItem,
  CardActions,
  Button,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { sendEditInformasiPribadi } from "../../../store/actions";
import Breadcrumb from "../../../components/UI/Breadcrumb/Breadcrumb";
import DatePicker from "../../../components/UI/DatePicker/DatePicker";
import FieldNpwp from "./FieldNpwp";
import FieldBsm from "./FieldBsm";
import FieldKtp from "./FieldKtp";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  marLeft: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  card: {
    margin: theme.spacing(3, 1.25, 1.25),
  },
  gridPadding: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  field: {
    marginTop: theme.spacing(1),
  },
  textarea: {
    margin: theme.spacing(2),
  },
}));

const crumbs = [
  { name: "Dashboard", link: "/" },
  { name: "Detail Employee", link: "/employee" },
  { name: "Edit Informasi Pribadi", link: "" },
];

const validationSchema = yup.object().shape({
  id_number: yup
    .string()
    .required("Nomor KTP wajib diisi")
    .test("len", "nomor KTP wajib 16 angka", (val) => val.length === 16),
  tax_number: yup
    .string()
    .required("Nomor NPWP wajib diisi")
    .test("len", "nomor NPWP wajib 20 angka", (val) => val.length === 15),
  account_number: yup
    .string()
    .required("Nomor Rekening wajib diisi")
    .test("len", "nomor Rekening wajib 10 angka", (val) => val.length === 10),
  place_of_birth: yup.string().required("Tempat Lahir wajib diisi"),
  date_of_birth: yup.string().required("Tanggal Lahir wajib diisi"),
  gender: yup.string().required("Jenis Kelamin wajib diisi"),
  blood_type: yup.string().required("Golongan Darah wajib diisi"),
  ethnic: yup.string().required("Suku wajib diisi"),
  religion: yup.string().required("Agama wajib diisi"),
  height: yup
    .string()
    .required("Tinggi badan wajib diisi")
    .test("len", "Tinggi badan 100 - 250", (val) => val <= 250 && val >= 100),
  personality: yup.string().required("STFIn wajib diisi"),
  marital_status: yup.string().required("Status Menikah wajib diisi"),
  weight: yup
    .string()
    .required("Berat badan wajib diisi")
    .test("len", "Berat badan tidak valid", (val) => val <= 250),
  about_me: yup.string().required("Tentang aku wajib diisi"),
});

const FormEditInformasiPribadi = ({
  status,
  detailEmployee,
  auth,
  sendEditInformasiPribadi,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();

  const { handleSubmit, register, errors, setValue, control } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    const formData = {
      ...data,
      date_of_birth: moment(data.date_of_birth).format("YYYY-MM-DD"),
    };

    if (auth.user.id === detailEmployee.profile.user_id) {
      return sendEditInformasiPribadi(formData, null, history);
    } else {
      return sendEditInformasiPribadi(
        formData,
        detailEmployee.profile.user_id,
        history
      );
    }
  };

  useEffect(() => {
    if (!detailEmployee.loadingProfile) {
      setValue("tax_number", detailEmployee.profile.tax_number);
      setValue("place_of_birth", detailEmployee.profile.place_of_birth);
      setValue("gender", detailEmployee.profile.gender);
      setValue("ethnic", detailEmployee.profile.ethnic);
      setValue("height", detailEmployee.profile.height);
      setValue("marital_status", detailEmployee.profile.marital_status);
      setValue("id_number", detailEmployee.profile.id_number);
      setValue("account_number", detailEmployee.profile.account_number);
      setValue(
        "date_of_birth",
        moment(detailEmployee.profile.date_of_birth, "DD-MM-YYYY").format()
      );
      setValue("blood_type", detailEmployee.profile.blood_type);
      setValue("religion", detailEmployee.profile.religion);
      setValue("personality", detailEmployee.profile.personality);
      setValue("weight", detailEmployee.profile.weight);
      setValue("about_me", detailEmployee.profile.about_me);
    }
  }, [setValue, status]);

  if (detailEmployee.loadingProfile || !auth.user.role_name === "hcis") {
    return <Redirect to={`/detail-employee/${params.id}/${params.userId}`} />;
  }

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.marLeft}>
        Edit Informasi Pribadi
      </Typography>
      <Breadcrumb crumb={crumbs} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card className={classes.card}>
          <CardHeader
            title={<Typography variant="h5">Form Informasi Pribadi</Typography>}
          />
          <Divider />
          <CardContent>
            <Grid container>
              <Grid item xs={6} className={classes.gridPadding}>
                <TextField
                  fullWidth
                  label="Nama Lengkap"
                  value={detailEmployee.profile.name}
                  disabled
                />
                <Controller
                  name="tax_number"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FieldNpwp
                      className={classes.field}
                      fullWidth
                      label="Nomor NPWP"
                      placeholder="00.000.000.0-000.000"
                      onChange={onChange}
                      value={value}
                      error={!!errors.tax_number}
                      helperText={errors.tax_number?.message}
                    />
                  )}
                />
                <TextField
                  className={classes.field}
                  fullWidth
                  name="place_of_birth"
                  label="Tempat Lahir"
                  inputRef={register}
                  error={!!errors.place_of_birth}
                  helperText={
                    !!errors.place_of_birth && errors.place_of_birth.message
                  }
                />
                <Controller
                  name="gender"
                  defaultValue=""
                  control={control}
                  render={({ onChange, ref, value }) => (
                    <TextField
                      className={classes.field}
                      fullWidth
                      select
                      inputRef={ref}
                      defaultValue=""
                      value={value}
                      label="Jenis Kelamin"
                      onChange={(e) => onChange(e.target.value)}
                      error={!!errors.gender}
                      helperText={!!errors.gender && errors.gender.message}
                    >
                      <MenuItem value="" disabled></MenuItem>
                      <MenuItem value="laki-laki">Laki-Laki</MenuItem>
                      <MenuItem value="perempuan">Perempuan</MenuItem>
                    </TextField>
                  )}
                />
                <TextField
                  className={classes.field}
                  fullWidth
                  name="ethnic"
                  label="Suku"
                  inputRef={register}
                  error={!!errors.ethnic}
                  helperText={!!errors.ethnic && errors.ethnic.message}
                />
                <TextField
                  type="number"
                  className={classes.field}
                  fullWidth
                  name="height"
                  label="Tinggi Badan"
                  inputRef={register}
                  error={!!errors.height}
                  helperText={!!errors.height && errors.height.message}
                />
                <Controller
                  control={control}
                  defaultValue=""
                  name="marital_status"
                  render={({ onChange, ref, value }) => (
                    <TextField
                      className={classes.field}
                      fullWidth
                      select
                      defaultValue=""
                      value={value}
                      label="Status Pernikahan"
                      inputRef={ref}
                      onChange={(e) => onChange(e.target.value)}
                      error={!!errors.marital_status}
                      helperText={
                        !!errors.marital_status && errors.marital_status.message
                      }
                    >
                      <MenuItem value="" disabled></MenuItem>
                      <MenuItem value="sudah menikah">Menikah</MenuItem>
                      <MenuItem value="belum menikah">Belum Menikah</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={6} className={classes.gridPadding}>
                <Controller
                  name="id_number"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FieldKtp
                      className={classes.field}
                      fullWidth
                      label="Nomor KTP"
                      placeholder="3215829582938445"
                      onChange={onChange}
                      value={value}
                      error={!!errors.id_number}
                      helperText={errors.id_number?.message}
                    />
                  )}
                />
                <Controller
                  name="account_number"
                  control={control}
                  defaultValue=""
                  render={({ onChange, value }) => (
                    <FieldBsm
                      className={classes.field}
                      fullWidth
                      label="Nomor Rekening BSM"
                      placeholder="1234567890"
                      onChange={onChange}
                      value={value}
                      error={!!errors.account_number}
                      helperText={errors.account_number?.message}
                    />
                  )}
                />
                <Controller
                  name="date_of_birth"
                  control={control}
                  defaultValue={new Date()}
                  render={({ onChange, ref, value }) => (
                    <DatePicker
                      label="Tanggal Lahir"
                      inputRef={ref}
                      format="dd-MM-yyyy"
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                      }}
                      error={!!errors.date_of_birth}
                      helperText={
                        !!errors.date_of_birth && errors.date_of_birth.message
                      }
                    />
                  )}
                />
                <Controller
                  control={control}
                  defaultValue=""
                  name="blood_type"
                  render={({ onChange, ref, value }) => (
                    <TextField
                      className={classes.field}
                      fullWidth
                      select
                      defaultValue=""
                      value={value}
                      label="Golongan Darah"
                      inputRef={ref}
                      onChange={(e) => onChange(e.target.value)}
                      error={!!errors.blood_type}
                      helperText={
                        !!errors.blood_type && errors.blood_type.message
                      }
                    >
                      <MenuItem value="" disabled></MenuItem>
                      <MenuItem value="-">-</MenuItem>
                      <MenuItem value="A">A</MenuItem>
                      <MenuItem value="B">B</MenuItem>
                      <MenuItem value="AB">AB</MenuItem>
                      <MenuItem value="O">O</MenuItem>
                    </TextField>
                  )}
                />
                <Controller
                  control={control}
                  defaultValue=""
                  name="religion"
                  render={({ onChange, ref, value }) => (
                    <TextField
                      className={classes.field}
                      select
                      fullWidth
                      defaultValue=""
                      value={value}
                      label="Agama"
                      inputRef={ref}
                      onChange={(e) => onChange(e.target.value)}
                      error={!!errors.religion}
                      helperText={!!errors.religion && errors.religion.message}
                    >
                      <MenuItem value="" disabled></MenuItem>
                      <MenuItem value="islam">Islam</MenuItem>
                      <MenuItem value="kristen">Kristen</MenuItem>
                      <MenuItem value="budha">Budha</MenuItem>
                      <MenuItem value="hindu">Hindu</MenuItem>
                      <MenuItem value="katolik">Katolik</MenuItem>
                    </TextField>
                  )}
                />
                <Controller
                  control={control}
                  defaultValue=""
                  name="personality"
                  render={({ onChange, ref, value }) => (
                    <TextField
                      className={classes.field}
                      select
                      fullWidth
                      label="Personal Genetik STIFIn"
                      defaultValue=""
                      value={value}
                      inputRef={ref}
                      onChange={(e) => onChange(e.target.value)}
                      error={!!errors.personality}
                      helperText={
                        !!errors.personality && errors.personality.message
                      }
                    >
                      <MenuItem value="" disabled></MenuItem>
                      <MenuItem value="Insting">Insting</MenuItem>
                      <MenuItem value="Sensing Introvert">
                        Sensing introvert
                      </MenuItem>
                      <MenuItem value="Sensing Ekstrovert">
                        Sensing ekstrovert
                      </MenuItem>
                      <MenuItem value="Thinking Introvert">
                        Thingking introvert
                      </MenuItem>
                      <MenuItem value="Thinking Ekstrovert">
                        Thingking ekstrovert
                      </MenuItem>
                      <MenuItem value="Intuiting Introvert">
                        Intuiting introvert
                      </MenuItem>
                      <MenuItem value="Intuiting Ekstrovert">
                        Intuiting ekstrovert
                      </MenuItem>
                      <MenuItem value="Feeling Introvert">
                        Feeling introvert
                      </MenuItem>
                      <MenuItem value="Feeling Ekstrovert">
                        Feeling ekstrovert
                      </MenuItem>
                    </TextField>
                  )}
                />
                <TextField
                  className={classes.field}
                  fullWidth
                  name="weight"
                  label="Berat Badan"
                  inputRef={register}
                  error={!!errors.weight}
                  helperText={!!errors.weight && errors.weight.message}
                />
              </Grid>
            </Grid>
            <Grid className={classes.textarea}>
              <TextField
                multiline
                fullWidth
                rows={5}
                inputRef={register}
                variant="outlined"
                name="about_me"
                label="Tentang Saya"
                error={!!errors.about_me}
                helperText={!!errors.about_me && errors.about_me.message}
              />
            </Grid>
          </CardContent>
          <CardActions>
            <Button fullWidth variant="contained" color="primary" type="submit">
              Simpan Perubahan
            </Button>
          </CardActions>
        </Card>
      </form>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    status: state.status,
    detailEmployee: state.detailEmployee,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { sendEditInformasiPribadi })(
  FormEditInformasiPribadi
);
