import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  CircularProgress,
  Box,
  Grid,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

import { connect } from "react-redux";
import { getDataAllRequestLeave } from "../../../../store/actions";

import { TableHead } from "./component";
import { capitalize } from "../../../../shared/utility";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },

  btnConfirm: {
    color: theme.palette.success.main,
  },

  btnCancel: {
    color: theme.palette.error.main,
  },

  btnDisabled: {
    color: "#b4b4b4",
  },
  pagination: {
    padding: theme.spacing(0.5),
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
  },
}));

const TableDaftarIzin = ({
  requestLeave,
  getDataAllRequestLeave,
  setDialog,
  auth,
  entitas,
  type,
  status,
  year,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handlePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (auth.user.role_name === "hcis" && auth.user.sequence === 1) {
      getDataAllRequestLeave(
        true,
        entitas,
        type,
        status,
        year,
        page,
        rowsPerPage
      );
    } else {
      getDataAllRequestLeave(
        false,
        entitas,
        type,
        status,
        year,
        page,
        rowsPerPage
      );
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(1);
    if (
      (auth.user.role_name === "hcis" && auth.user.sequence === 1) ||
      (auth.user.role_name === "hcis" && auth.user.sequence === 2)
    ) {
      getDataAllRequestLeave(
        true,
        entitas,
        type,
        status,
        year,
        page,
        rowsPerPage
      );
    } else {
      getDataAllRequestLeave(
        false,
        entitas,
        type,
        status,
        year,
        page,
        rowsPerPage
      );
    }
  }, [getDataAllRequestLeave, entitas, type, status, year]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <TableHead />
            <TableBody>
              {requestLeave.loading ? (
                <TableRow>
                  <TableCell align="center" colSpan={10}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : requestLeave.allData.length > 0 ? (
                requestLeave.allData.map((row, index) => {
                  return (
                    <TableRow tabIndex={-1} key={index + 1}>
                      <TableCell>
                        {index + 1 + (page - 1) * rowsPerPage}
                      </TableCell>
                      <TableCell>{row.user_name}</TableCell>
                      <TableCell>{row.company_name}</TableCell>
                      <TableCell>{capitalize(row.request_type)}</TableCell>
                      <TableCell>{row.description}</TableCell>
                      <TableCell>{row.start_date}</TableCell>
                      <TableCell>{row.end_date}</TableCell>
                      <TableCell>{row.duration}</TableCell>
                      <TableCell>{capitalize(row.status)}</TableCell>
                      <TableCell>{row.action_by}</TableCell>
                      <TableCell>
                        {row.status === "accepted" ||
                        row.status === "rejected" ? (
                          <Box display="flex">
                            <IconButton
                              disabled
                              variant="contained"
                              color="primary"
                            >
                              <CheckCircleIcon
                                className={classes.btnDisabled}
                              />
                            </IconButton>
                            <IconButton
                              disabled
                              variant="contained"
                              color="secondary"
                            >
                              <CancelIcon className={classes.btnDisabled} />
                            </IconButton>
                          </Box>
                        ) : auth.user.sequence === 1 || row.can_action ? (
                          <Box display="flex">
                            <Tooltip title="Confirm">
                              <IconButton
                                onClick={() => {
                                  setDialog({
                                    open: true,
                                    value: row,
                                    type: "confirm",
                                  });
                                }}
                                variant="contained"
                                color="primary"
                              >
                                <CheckCircleIcon
                                  className={classes.btnConfirm}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Cancel">
                              <IconButton
                                onClick={() => {
                                  setDialog({
                                    open: true,
                                    value: row,
                                    type: "delete",
                                  });
                                }}
                                variant="contained"
                                color="secondary"
                              >
                                <CancelIcon className={classes.btnCancel} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        ) : (
                          <Box display="flex">
                            <IconButton
                              disabled
                              variant="contained"
                              color="primary"
                            >
                              <CheckCircleIcon
                                className={classes.btnDisabled}
                              />
                            </IconButton>
                            <IconButton
                              disabled
                              variant="contained"
                              color="secondary"
                            >
                              <CancelIcon className={classes.btnDisabled} />
                            </IconButton>
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={10}>
                    Data Kosong
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.pagination}
        >
          <Grid item xs={8}>
            <Pagination
              className={classes.pagination}
              count={requestLeave.last_page}
              page={page}
              onChange={handlePage}
              color="primary"
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    requestLeave: state.requestLeave,
  };
};

export default connect(mapStateToProps, { getDataAllRequestLeave })(
  TableDaftarIzin
);
