/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import axios from "../../../axios-orders";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Container,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Divider,
  IconButton,
  Box,
  Button,
} from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import * as yup from "yup";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";

import {
  getCompany,
  createNewUser,
  getStatusRole,
  getJobList,
  getSupervisorByCompany,
  addNewJob,
} from "../../../store/actions";
import Breadcrumb from "../../../components/UI/Breadcrumb/Breadcrumb";
import { DialogConfirm, DialogAddJob } from "./component";
import Logo from "../../../assets/img/logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  marLeft: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  card: {
    margin: theme.spacing(5, 1.25, 1.25),
  },
  header: {
    justifyContent: "center",
    height: "70px",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 0),
    backgroundColor: "#238FFF",
  },
  logo: {
    height: "100%",
    width: "auto",
  },
  marginAtas: {
    marginTop: theme.spacing(3),
  },
  space: {
    marginTop: theme.spacing(7)
  }
}));

const crumbs = [
  { name: "Dashboard", link: "/" },
  { name: "Form Tambah User", link: "" },
];

const validationSchema = yup.object().shape({
  name: yup.string().required("Nama harus diisi"),
  employee_identity: yup
    .string()
    .required("Nik harus diisi")
    .test("len", "Nomor nik harus 10 karakter", (value) => value.length === 10),
  gender: yup.string().required("Jenis kelamin harus diisi"),
  email: yup.string().required("Email harus diisi"),
  personality: yup.string().required("Stifin harus diisi"),
  password: yup.string().required("Password harus diisi"),
  position: yup.array().of(
    yup.object().shape({
      role_id: yup
        .number()
        .required("Role harus diisi")
        .typeError("Role harus diisi"),
      company_id: yup
        .number()
        .required("Entitas harus diisi")
        .typeError("Entitas harus diisi"),
      job_id: yup
        .number()
        .required("Job title harus diisi")
        .typeError("Job title harus diisi"),
      supervisor_id: yup
        .number()
        .required("Atasan harus diisi")
        .typeError("Atasan harus diisi"),
    })
  ),
});

const FormTambahUser = ({
  getCompany,
  company,
  createNewUser,
  getStatusRole,
  status,
  getJobList,
  getSupervisorByCompany,
  addNewJob,
}) => {
  const classes = useStyles();

  const [dialogConfirm, setDialogConfirm] = useState({
    open: false,
    data: null,
  });
  const [dialogAddJob, setDialogAddJob] = useState(false);
  const [dataSupervisor, setDataSupervisor] = useState([
    { entitas: "", data: [] },
  ]);

  const { handleSubmit, register, errors, control } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      position: [
        { role_id: "", company_id: "", job_id: "", supervisor_id: "" },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "position",
  });

  const addDataSupervisor = async (entitas, index) => {
    try {
      const res = await axios({
        url: `hcis/user/employee/position/company/${entitas}/supervisor`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      setDataSupervisor((prev) => {
        const data = [...prev];
        data[index] = { ...prev[index], data: res.data.data };
        return data;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmit = (data) => {
    setDialogConfirm({ open: true, data: data });
  };

  useEffect(() => {
    getCompany();
    getStatusRole();
    getJobList();
  }, []);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.marLeft}>
        Form Tambah User
      </Typography>
      <Breadcrumb crumb={crumbs} />
      <Card className={classes.card}>
        <div className={classes.header}>
          <img alt="logo" src={Logo} className={classes.logo} />
        </div>
        <Typography className={classes.marginAtas} variant="h4" align="center">
          Form Tambah User
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="filled"
                  className={classes.marginAtas}
                  inputRef={register}
                  name="name"
                  label="Nama Lengkap"
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  className={classes.marginAtas}
                  inputRef={register}
                  name="employee_identity"
                  label="NIK"
                  error={!!errors.employee_identity}
                  helperText={errors.employee_identity?.message}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  className={classes.marginAtas}
                  inputRef={register}
                  name="email"
                  label="Email"
                  error={!!errors.email}
                  helperText={errors.email?.message}
                />
                <Controller
                  control={control}
                  name="personality"
                  defaultValue=""
                  render={({ onChange }) => (
                    <TextField
                      fullWidth
                      select
                      variant="filled"
                      className={classes.marginAtas}
                      defaultValue=""
                      label="STIFIN"
                      onChange={(e) => onChange(e.target.value)}
                      error={!!errors.personality}
                      helperText={errors.personality?.message}
                    >
                      <MenuItem value="Insting">Insting</MenuItem>
                      <MenuItem value="Sensing Introvert">
                        Sensing Introvert
                      </MenuItem>
                      <MenuItem value="Sensing Ekstrovert">
                        Sensing Ekstrovert
                      </MenuItem>
                      <MenuItem value="Thingking Introvert">
                        Thingking Introvert
                      </MenuItem>
                      <MenuItem value="Thingking Ekstrovert">
                        Thingking Ekstrovert
                      </MenuItem>
                      <MenuItem value="Intuiting Introvert">
                        Intuiting Introvert
                      </MenuItem>
                      <MenuItem value="Intuiting Ekstrovert">
                        Intuiting Ekstrovert
                      </MenuItem>
                      <MenuItem value="Feeling Introvert">
                        Feeling Introvert
                      </MenuItem>
                      <MenuItem value="Feeling Ekstrovert">
                        Feeling Ekstrovert
                      </MenuItem>
                    </TextField>
                  )}
                />
                <Controller
                  control={control}
                  name="gender"
                  defaultValue=""
                  render={({ onChange }) => (
                    <TextField
                      fullWidth
                      select
                      variant="filled"
                      className={classes.marginAtas}
                      defaultValue=""
                      label="Jenis Kelamin"
                      onChange={(e) => onChange(e.target.value)}
                      error={!!errors.gender}
                      helperText={errors.gender?.message}
                    >
                      <MenuItem value="laki-laki">Laki-Laki</MenuItem>
                      <MenuItem value="perempuan">Perempuan</MenuItem>
                    </TextField>
                  )}
                />
                <Divider className={classes.space} />
                <Typography variant="body1">
                  Jika calon user ini memiliki lebih dari 1 entitas silahkan
                  isi, tekan tombol hijau untuk tambah
                </Typography>
                {fields.map((item, index) => (
                  <Grid item container xs={12} key={item.id}>
                    <Grid item container xs={10}>
                      <Grid item xs={8}>
                        <Controller
                          control={control}
                          name={`position[${index}].company_id`}
                          defaultValue=""
                          render={({ onChange }) => (
                            <TextField
                              fullWidth
                              select
                              variant="filled"
                              className={classes.marginAtas}
                              defaultValue=""
                              label="Entitas"
                              onChange={(e) => {
                                onChange(e.target.value);
                                addDataSupervisor(e.target.value, index);
                              }}
                              error={
                                errors.position
                                  ? errors.position[index]
                                    ? !!errors.position[index].company_id
                                    : false
                                  : false
                              }
                              helperText={
                                errors.position
                                  ? errors.position[index]
                                    ? errors.position[index].company_id
                                      ? errors.position[index].company_id
                                          .message
                                      : false
                                    : false
                                  : false
                              }
                            >
                              {company.company.map((value) => (
                                <MenuItem value={value.value} key={value.value}>
                                  {value.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Controller
                          control={control}
                          name={`position[${index}].role_id`}
                          defaultValue=""
                          render={({ onChange }) => (
                            <TextField
                              fullWidth
                              select
                              variant="filled"
                              className={classes.marginAtas}
                              defaultValue=""
                              label="Role"
                              onChange={(e) => onChange(e.target.value)}
                              error={
                                errors.position
                                  ? errors.position[index]
                                    ? !!errors.position[index].role_id
                                    : false
                                  : false
                              }
                              helperText={
                                errors.position
                                  ? errors.position[index]
                                    ? errors.position[index].role_id
                                      ? errors.position[index].role_id.message
                                      : false
                                    : false
                                  : false
                              }
                            >
                              {status.statusRole.map((value) => (
                                <MenuItem value={value.id} key={value.id}>
                                  {value.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={2} />
                    <Grid container item xs={10}>
                      <Grid item xs={10}>
                        <Controller
                          control={control}
                          name={`position[${index}].job_id`}
                          defaultValue=""
                          render={({ onChange }) => (
                            <TextField
                              fullWidth
                              select
                              variant="filled"
                              className={classes.marginAtas}
                              defaultValue=""
                              label="Job Title - Level"
                              onChange={(e) => onChange(e.target.value)}
                              error={
                                errors.position
                                  ? errors.position[index]
                                    ? !!errors.position[index].job_id
                                    : false
                                  : false
                              }
                              helperText={
                                errors.position
                                  ? errors.position[index]
                                    ? errors.position[index].job_id
                                      ? errors.position[index].job_id.message
                                      : false
                                    : false
                                  : false
                              }
                            >
                              {status.jobList.map((value) => (
                                <MenuItem key={value.id} value={value.id}>
                                  {value.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                        <Typography variant="caption">
                          Jika job title tidak tersedia, silahkan buat job title
                          baru
                        </Typography>
                      </Grid>
                      <Grid container item xs={2} alignItems="center">
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => setDialogAddJob(true)}
                        >
                          Tambah Job title
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        className={classes.marginAtas}
                      >
                        {index === fields.length - 1 && (
                          <IconButton
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              append({
                                role_id: "",
                                company_id: "",
                                job_id: "",
                                supervisor_id: "",
                              });
                              setDataSupervisor((prev) => {
                                const data = [...prev];
                                data.push({ entitas: "", data: [] });
                                return data;
                              });
                            }}
                          >
                            <AddIcon />
                          </IconButton>
                        )}
                        {fields.length > 1 && (
                          <IconButton
                            variant="contained"
                            style={{ color: "red" }}
                            onClick={() => {
                              remove(index);
                              setDataSupervisor((prev) => {
                                const data = [...prev];
                                data.splice(index, 1);
                                return data;
                              });
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={10}>
                      <Controller
                        control={control}
                        name={`position[${index}].supervisor_id`}
                        defaultValue=""
                        render={({ onChange }) => (
                          <TextField
                            fullWidth
                            select
                            variant="filled"
                            className={classes.marginAtas}
                            defaultValue=""
                            label="Atasan"
                            onChange={(e) => {
                              onChange(e.target.value);
                            }}
                            error={
                              errors.position
                                ? errors.position[index]
                                  ? !!errors.position[index].supervisor_id
                                  : false
                                : false
                            }
                            helperText={
                              errors.position
                                ? errors.position[index]
                                  ? errors.position[index].supervisor_id
                                    ? errors.position[index].supervisor_id
                                        .message
                                    : false
                                  : false
                                : false
                            }
                          >
                            {dataSupervisor[index].data.map((value) => (
                              <MenuItem key={value.id} value={value.id}>
                                {value.name}
                              </MenuItem>
                            ))}
                            {dataSupervisor[index].data.length === 0 && (
                              <MenuItem value="" disabled>
                                Tidak ada data
                              </MenuItem>
                            )}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={12}>
                      <Divider className={classes.space} />
                    </Grid>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="password"
                    variant="filled"
                    className={classes.marginAtas}
                    inputRef={register}
                    name="password"
                    label="Password"
                    error={!!errors.password}
                    helperText={errors.password?.message}
                  />
                </Grid>
              </Grid>
              <Button
                fullWidth
                variant="contained"
                className={classes.marginAtas}
                color="primary"
                type="submit"
              >
                Simpan
              </Button>
            </Grid>
          </CardContent>
        </form>
      </Card>
      <DialogConfirm
        dialog={dialogConfirm}
        action={createNewUser}
        onClose={() => setDialogConfirm({ open: false, data: null })}
      />
      <DialogAddJob
        open={dialogAddJob}
        action={addNewJob}
        onClose={() => setDialogAddJob(false)}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  company: state.company,
  status: state.status,
});

export default connect(mapStateToProps, {
  getCompany,
  createNewUser,
  getStatusRole,
  getJobList,
  getSupervisorByCompany,
  addNewJob,
})(FormTambahUser);
