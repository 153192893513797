/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  CardContent,
  Card,
  Typography,
  Grid,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";

// Actions
import {
  getOvertimeAssignStaff,
  approveAssignOvertime,
  rejectAssignOvertime,
  updateStatusOvertime,
} from "../../store/actions";

// Components
import Breadscrumb from "../../components/UI/Breadcrumb/Breadcrumb";
import { Table, DialogApprove, DialogReject, DialogUpdate } from "./component";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  marLeft: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  card: {
    margin: theme.spacing(1.25),
  },
  pagination: {
    marginTop: theme.spacing(3),
  },
  pag: {
    "& .MuiPagination-ul": {
      justifyContent: "center",
    },
  },
}));

const scrumbs = [
  { name: "Dashboard", link: "/" },
  { name: "Daftar Ditugaskan", link: "" },
];

const DaftarDitugaskan = ({
  auth,
  overtime,
  approveAssignOvertime,
  rejectAssignOvertime,
  getOvertimeAssignStaff,
  updateStatusOvertime,
}) => {
  const classes = useStyles();

  const [dialogApprove, setDialogApprove] = useState({
    open: false,
    id: null,
    positionId: null,
  });
  const [dialogReject, setDialogReject] = useState({
    open: false,
    id: null,
    positionId: null,
  });
  const [dialogUpdate, setDialogUpdate] = useState({
    open: false,
    id: null,
    status: null,
  });

  const [page, setPage] = useState(1);
  const page_size = 5;

  const handlePage = (e, value) => {
    setPage(value);
  };

  useEffect(() => {
    getOvertimeAssignStaff(page, page_size);
  }, [page]);

  console.log(overtime);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.marLeft}>
        Daftar Ditugaskan
      </Typography>
      <Breadscrumb crumb={scrumbs} />
      <Card className={classes.card}>
        <CardContent>
          <Table
            data={overtime.assign.data}
            handleApprove={setDialogApprove}
            handleReject={setDialogReject}
            handleDialogUpdate={setDialogUpdate}
            auth={auth}
          />
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.pagination}
          >
            <Grid item xs={8}>
              <Pagination
                className={classes.pag}
                count={overtime.assign.last_page}
                page={page}
                onChange={handlePage}
                color="primary"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DialogReject
        dialog={dialogReject}
        onClose={() =>
          setDialogReject({ open: false, id: null, positionId: null })
        }
        action={rejectAssignOvertime}
      />
      <DialogApprove
        dialog={dialogApprove}
        onClose={() =>
          setDialogApprove({ open: false, id: null, positionId: null })
        }
        action={approveAssignOvertime}
      />
      <DialogUpdate
        dialog={dialogUpdate}
        action={updateStatusOvertime}
        onClose={() => setDialogUpdate({ open: false, id: null, status: null })}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  overtime: state.overtime,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  approveAssignOvertime,
  rejectAssignOvertime,
  getOvertimeAssignStaff,
  updateStatusOvertime,
})(DaftarDitugaskan);
