/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  Container,
  Paper,
  Grid,
  TextField,
  Typography,
  Button,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import Select from 'react-select';
import queryString from 'query-string';
import { isEmpty } from '../../shared/utility';

import { getCompany, getEmployee } from '../../store/actions';
import { CardEmployee } from './component';
import DefaultAvatar from '../../assets/img/default-profile2.png';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('xs')]: {
      padding: 10,
    },
  },
  button: {
    backgroundColor: '#011747',
  },
  btnCreate: {
    marginBottom: theme.spacing(2),
  },
  txtFieldSearch: {
    marginBottom: theme.spacing(1),
  },
  filterEntitas: {
    '& .css-1uccc91-singleValue': {
      overflow: 'visible',
    },
  },
  filterStifin: {
    '& .css-1uccc91-singleValue': {
      overflow: 'visible',
    },
  },
  filterGender: {
    '& .css-1uccc91-singleValue': {
      overflow: 'visible',
    },
  },
  filterLevel: {
    '& .css-1uccc91-singleValue': {
      overflow: 'visible',
    },
  },
  pagination: {
    marginTop: theme.spacing(3),
  },
  pag: {
    '& .MuiPagination-ul': {
      justifyContent: 'center',
    },
  },
}));

const gender = [
  { label: 'Laki-Laki', value: 'laki-laki' },
  { label: 'Perempuan', value: 'perempuan' },
];

const levelEntitas = [
  {
    label: 'Level 1',
    value: '1',
  },
  {
    label: 'Level 2',
    value: '2',
  },
  {
    label: 'Level 3',
    value: '3',
  },
  {
    label: 'Level 4',
    value: '4',
  },
];

const stifins = [
  { label: 'Insting', value: 'Insting' },
  { label: 'Sensing Introvert', value: 'Sensing introvert' },
  { label: 'Sensing Ekstrovert', value: 'Sensing ekstrovert' },
  { label: 'Thinking Introvert', value: 'Thinking introvert' },
  { label: 'Thinking Ekstrovert', value: 'Thinking ekstrovert' },
  { label: 'Intuiting Introvert', value: 'Intuiting introvert' },
  { label: 'Intuiting Ekstrovert', value: 'Intuiting ekstrovert' },
  { label: 'Feeling Introvert', value: 'Feeling introvert' },
  { label: 'Feeling Ekstrovert', value: 'Feeling ekstrovert' },
];

const statusOptions = [
  { label: 'Masih Bekerja', value: 'active' },
  { label: 'Tidak Bekerja', value: 'inactive' },
];

const Employee = (props) => {
  const { auth, getCompany, company, getEmployee, employee } = props;
  const classes = useStyles();
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [level, setLevel] = useState('');
  const [entitas, setEntitas] = useState('');
  const [jk, setJK] = useState('');
  const [keyword, setKeyword] = useState('');
  const [stifin, setStifin] = useState('');
  const [status, setStatus] = useState('');

  const handlePage = (e, value) => {
    setPage(value);
  };

  useEffect(() => {
    getCompany();
  }, []);

  useEffect(() => {
    if (auth.user.role_name === 'staff') {
      return;
    }

    const search = queryString.parse(history.location.search);
    const typeJenisKelamin =
      !jk && !!search.type ? search.type : !!jk ? jk : '';
    if (auth.user.role_name === 'manager') {
      setEntitas(auth.user.company_id);
      getEmployee(
        keyword,
        auth.user.company_id,
        level,
        typeJenisKelamin,
        stifin,
        status,
        page
      );
      return;
    }
    if (auth.user.role_name === 'hcis') {
      getEmployee(
        keyword,
        entitas,
        level,
        typeJenisKelamin,
        stifin,
        status,
        page
      );
      return;
    }
  }, [entitas, getEmployee, jk, keyword, level, page, stifin, status]);

  if (auth.user.role_name === 'staff') {
    return (
      <Redirect
        to={`/detail-employee/${auth.user.position_id}/${auth.user.id}`}
      />
    );
  }

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3">EOA Profile</Typography>
      <Grid container style={{ marginBottom: 25, marginTop: 30 }}>
        <Grid item xs={6}>
          {/* {auth.user.role_name === "hcis" && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              className={clsx(classes.button, classes.btnCreate)}
              startIcon={<AddIcon />}
              onClick={() => history.push("/form-tambah-user")}
            >
              Tambah User
            </Button>
          )} */}
        </Grid>
        <Grid item xs={6}>
          <Grid spacing={1} container>
            <Grid item xs={12}>
              <TextField
                label="Search"
                variant="outlined"
                placeholder="Masukkan Keyword"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.txtFieldSearch}
              />
            </Grid>
            {auth.user.role_name === 'hcis' && (
              <>
                <Grid item xs={12} md={6}>
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    name="entitas"
                    className={classes.filterEntitas}
                    options={company.company}
                    placeholder="Pilih Entitas..."
                    onChange={(e) =>
                      e === null ? setEntitas('') : setEntitas(e.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    isClearable={true}
                    isSearchable={true}
                    name="level"
                    className={classes.filterLevel}
                    options={levelEntitas}
                    placeholder="Pilih Level..."
                    onChange={(e) =>
                      e === null ? setLevel('') : setLevel(e.value)
                    }
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} md={6}>
              <Select
                isClearable={true}
                isSearchable={true}
                name="stifin"
                className={classes.filterStifin}
                placeholder="Pilih Jenis STIFIn..."
                options={stifins}
                onChange={(e) =>
                  e === null ? setStifin('') : setStifin(e.value)
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                isClearable={true}
                isSearchable={true}
                name="gender"
                className={classes.filterGender}
                options={gender}
                placeholder="Pilih Jenis Kelamin..."
                onChange={(e) => (e === null ? setJK('') : setJK(e.value))}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Select
                isClearable={true}
                isSearchable={true}
                name="status"
                className={classes.filterGender}
                options={statusOptions}
                placeholder="Pilih Status Bekerja"
                onChange={(e) =>
                  e === null ? setStatus('') : setStatus(e.value)
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Paper elevation={2} style={{ padding: 20 }}>
        <Grid container spacing={3}>
          {employee.employee.map((value) => (
            <Grid key={value.id} item xs={12} sm={6} lg={4}>
              <CardEmployee
                id={value.id}
                userId={value.user_id}
                image={value.profile === '' ? DefaultAvatar : value.profile}
                name={value.name}
                company={value.company_name}
                role={value.job_name}
                nik={value.employee_identity || '-'}
                level={`LV ${value.sequence}`}
                status={value.status}
                hcis={auth.user.role_name === 'hcis'}
              />
            </Grid>
          ))}
          {employee.employee.length === 0 &&
            employee.loadingEmployee === false && (
              <Grid item xs={12}>
                <Typography variant="body1" align="center">
                  Data Karyawan tidak ditemukan
                </Typography>
              </Grid>
            )}
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.pagination}
        >
          <Grid item xs={8}>
            <Pagination
              className={classes.pag}
              count={employee.last_page}
              page={page}
              onChange={handlePage}
              color="primary"
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    company: state.company,
    employee: state.employee,
  };
};

export default connect(mapStateToProps, { getCompany, getEmployee })(Employee);
