/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  MenuItem,
  TextField,
  Card,
  CardHeader,
  Typography,
  Divider,
  CardContent,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import { Controller } from "react-hook-form";

import {
  getCityDomisili,
  getDistrictDomisili,
  getVillageDomisili,
} from "../../../../store/actions";
import { isEmpty } from "../../../../shared/utility";

const useStyles = makeStyles((theme) => ({
  gridPadding: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  field: {
    marginTop: theme.spacing(1),
  },
  card: {
    margin: theme.spacing(0, 1.25, 1.25),
  },
  cardTitle: {
    display: "flex",
    alignItems: "center",
  },
  iconTitle: {
    margin: theme.spacing(0, 1, 0, 2),
  },
}));

const AlamatKTP = (props) => {
  const {
    getCityDomisili,
    getDistrictDomisili,
    getVillageDomisili,
    register,
    control,
    errors,
    address,
    value,
    setValue,
  } = props;
  const classes = useStyles();

  const handleProvince = (e) => {
    setValue((prev) => ({ ...prev, province: e }));
    const index = address.province.findIndex((x) => x.name === e);
    getCityDomisili(address.province[index].id);
  };

  const handleCity = (e) => {
    setValue((prev) => ({ ...prev, city: e }));
    const index = address.city_domisili.findIndex((x) => x.name === e);
    getDistrictDomisili(address.city_domisili[index].id);
  };

  const handleDistrict = (e) => {
    setValue((prev) => ({ ...prev, district: e }));
    const index = address.district_domisili.findIndex((x) => x.name === e);
    getVillageDomisili(address.district_domisili[index].id);
  };

  return (
    <Card className={classes.card}>
      <CardHeader
        title={
          <div className={classes.cardTitle}>
            <HomeIcon className={classes.iconTitle} />
            <Typography variant="h4">Alamat Domisili</Typography>
          </div>
        }
      />
      <Divider />
      <CardContent>
        <Grid container>
          <Grid item xs={6} className={classes.gridPadding}>
            {/* Provinsi */}
            <Controller
              control={control}
              name="province_domisili"
              defaultValue=""
              render={({ onChange, ref }) => (
                <TextField
                  fullWidth
                  select
                  label="Provinsi"
                  inputRef={ref}
                  defaultValue=""
                  value={value.province}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleProvince(e.target.value);
                  }}
                  disabled={address.loadingProvince}
                  error={!!errors.province_domisili}
                  helperText={
                    !!errors.province_domisili &&
                    errors.province_domisili.message
                  }
                >
                  <MenuItem value="" disabled></MenuItem>
                  {address.province.map((value) => (
                    <MenuItem value={value.name} key={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                  {value.province !== "" && isEmpty(address.province) ? (
                    <MenuItem value={value.province}>{value.province}</MenuItem>
                  ) : null}
                </TextField>
              )}
            />

            {/* Kecamatan */}
            <Controller
              control={control}
              name="district_domisili"
              defaultValue=""
              render={({ onChange, ref }) => (
                <TextField
                  className={classes.field}
                  fullWidth
                  select
                  label="Kecamatan"
                  inputRef={ref}
                  defaultValue=""
                  value={value.district}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleDistrict(e.target.value);
                  }}
                  disabled={address.loadingDistrict_domisili}
                  error={!!errors.district_domisili}
                  helperText={
                    !!errors.district_domisili &&
                    errors.district_domisili.message
                  }
                >
                  <MenuItem value="" disabled></MenuItem>
                  {address.district_domisili.map((value) => (
                    <MenuItem value={value.name} key={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                  {value.district !== "" &&
                  isEmpty(address.district_domisili) ? (
                    <MenuItem value={value.district}>{value.district}</MenuItem>
                  ) : null}
                </TextField>
              )}
            />
            <TextField
              className={classes.field}
              fullWidth
              name="postal_code_domisili"
              label="Kode Pos"
              value={value.postal_code}
              onChange={(e) =>
                setValue((prev) => ({ ...prev, postal_code: e.target.value }))
              }
              inputRef={register}
              error={!!errors.postal_code_domisili}
              helperText={
                !!errors.postal_code_domisili &&
                errors.postal_code_domisili.message
              }
            />
          </Grid>
          <Grid item xs={6} className={classes.gridPadding}>
            {/* Kota/Kabupaten */}
            <Controller
              control={control}
              name="city_domisili"
              defaultValue=""
              render={({ onChange, ref }) => (
                <TextField
                  fullWidth
                  select
                  label="Kota/Kabupaten"
                  inputRef={ref}
                  defaultValue=""
                  value={value.city}
                  onChange={(e) => {
                    onChange(e.target.value);
                    handleCity(e.target.value);
                  }}
                  disabled={address.loadingCity_domisili}
                  error={!!errors.city_domisili}
                  helperText={
                    !!errors.city_domisili && errors.city_domisili.message
                  }
                >
                  <MenuItem value="" disabled></MenuItem>
                  {address.city_domisili.map((value) => (
                    <MenuItem value={value.name} key={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                  {value.city !== "" && isEmpty(address.city_domisili) ? (
                    <MenuItem value={value.city}>{value.city}</MenuItem>
                  ) : null}
                </TextField>
              )}
            />

            {/* Kelurahan */}
            <Controller
              control={control}
              name="sub_district_domisili"
              defaultValue=""
              render={({ onChange, ref }) => (
                <TextField
                  className={classes.field}
                  fullWidth
                  select
                  label="Kelurahan"
                  inputRef={ref}
                  defaultValue=""
                  value={value.village}
                  onChange={(e) => {
                    onChange(e.target.value);
                    setValue((prev) => ({ ...prev, village: e.target.value }));
                  }}
                  disabled={address.loadingVillage_domisili}
                  error={!!errors.sub_district_domisili}
                  helperText={
                    !!errors.sub_district_domisili &&
                    errors.sub_district_domisili.message
                  }
                >
                  <MenuItem value="" disabled></MenuItem>
                  {address.village_domisili.map((value) => (
                    <MenuItem value={value.name} key={value.id}>
                      {value.name}
                    </MenuItem>
                  ))}
                  {value.village !== "" && isEmpty(address.village_domisili) ? (
                    <MenuItem value={value.village}>{value.village}</MenuItem>
                  ) : null}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} className={classes.gridPadding}>
            <TextField
              className={classes.field}
              fullWidth
              multiline
              rows={5}
              variant="outlined"
              name="street_domisili"
              label="Alamat Lengkap"
              value={value.street}
              onChange={(e) =>
                setValue((prev) => ({ ...prev, street: e.target.value }))
              }
              inputRef={register}
              error={!!errors.street_domisili}
              helperText={
                !!errors.street_domisili && errors.street_domisili.message
              }
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    address: state.address,
  };
};

export default connect(mapStateToProps, {
  getCityDomisili,
  getDistrictDomisili,
  getVillageDomisili,
})(AlamatKTP);
