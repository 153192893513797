import React from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import axios from "../../axios-orders";
import { setAlert } from "../../store/actions";
import TypingAnimation from "../../components/UI/TypingAnimation/TypingAnimation";

import qs from "qs";

import {
  Typography,
  Hidden,
  Button,
  TextField,
  Paper,
  Box,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    width: "100%",
  },
  image: {
    background: "linear-gradient(180deg, #FFD56B 0%, #F1B725 100%)",
  },
  contentName: {
    paddingTop: theme.spacing(25),
    paddingLeft: theme.spacing(6),
    color: "#fff",
    fontSize: 40,
  },
  center: {
    color: "#FFFFFF",
    fontSize: 50,
    paddingBottom: 15,
    lineHeight: 1,
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignSelf: "center",
  },
  avatar1: {
    marginTop: theme.spacing(5),
    textAlign: "center",
    fontWeight: "600",
  },
  avatar2: {
    marginTop: theme.spacing(0.5),
    textAlign: "center",
    fontWeight: "600",
  },
  avatarParagraph: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    paddingTop: 30,
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 100,
    },
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: "60px",
    backgroundColor: "#285D5F",
    "&:hover": {
      backgroundColor: "#285D5F",
    },
    color: "#FFFFFF",
    borderRadius: 100,
  },
  formLogin: {
    height: "100%",
    justifyContent: "center",
  },
  linkLogin: {
    marginTop: 10,
  },
}));

const validationSchema = yup.object().shape({
  email: yup.string().required("Email harus diisi"),
});

const ForgotPassword = (props) => {
  const { setAlert } = props;
  const classes = useStyles();

  const access_token = localStorage.getItem("access_token");
  let redirectAuth;
  if (access_token) {
    redirectAuth = <Redirect to="/" />;
  }

  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const formSubmitHandler = (value) => {
    axios
      .post(`/forgot-password`, qs.stringify(value), {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
      .then((response) => {
        setAlert("Silahkan periksa email anda", "success");
      })
      .catch((err) => {
        let message = err.response.data.message;
        if (message === "record not found") {
          message = "Email tidak terdaftar";
        }
        setAlert(message, "error");
      });
  };

  return (
    <>
      {redirectAuth}
      <Grid className={classes.container} container>
        <Hidden only={["xs", "sm"]}>
          <Grid item md={6} lg={7} className={classes.image}>
            <TypingAnimation />
          </Grid>
        </Hidden>
        <Grid
          container
          item
          xs={12}
          md={6}
          lg={5}
          component={Paper}
          square
          className={classes.formLogin}
        >
          <div className={classes.paper}>
            <Grid container>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/logo/logo-ehc.svg`}
                    alt="logo-ehc"
                    width="45%"
                    height="auto"
                  />
                  <img
                    src={`${process.env.PUBLIC_URL}/images/logo/logo-eoa.svg`}
                    alt="logo-eoa"
                    width="20%"
                    height="auto"
                  />
                </Box>
              </Grid>
            </Grid>
            <Typography variant="h4" className={classes.avatar1}>
              Ahlan Wasahlan
            </Typography>
            <Typography variant="h4" className={classes.avatar2}>
              Leader Peradaban EOA
            </Typography>
            <Typography className={classes.avatarParagraph}>
              EOA HCIS 1.0
            </Typography>
            <form
              className={classes.form}
              onSubmit={handleSubmit(formSubmitHandler)}
            >
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                inputRef={register}
                error={!!errors.email}
                helperText={errors.email && errors.email.message}
                className={classes.textField}
              />
              <div className={classes.linkLogin}>
                <Link style={{ textDecoration: "none" }} to={`/login`}>
                  Kembali Ke Login
                </Link>
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Kirim
              </Button>
            </form>
            <Typography variant="body1" align="center">
              HCIS powered by EHC <br />
              Support by Peradaban EOA
            </Typography>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default connect(null, { setAlert })(ForgotPassword);
