import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Button } from "@material-ui/core";

//Components
import Breadcrumb from "../../components/UI/Breadcrumb/Breadcrumb";
import { TableRequestLeave, DialogRequestLeave } from "./component";
// import CustomRouterLink from "../../components/CustomRouterLink/CustomRouterLink";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(20),
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  btnCreate: {
    marginBottom: theme.spacing(3),
  },
  formSelect: {
    width: "100%",
  },
}));

const scrumbs = [{ name: "Request Leave", link: "/" }];

const RequestLeave = () => {
  const classes = useStyles();

  const [dialogRequestLeave, setDialogRequestLeave] = useState({
    open: false,
    edit: false,
    value: null,
  });

  const handleModal = () => {
    setDialogRequestLeave({ open: true, edit: false, value: null });
  };

  const handleModalClose = () => {
    setDialogRequestLeave({ open: false, edit: false, value: null });
  };
  return (
    <>
      <Container maxWidth="xl" className={classes.root}>
        <Breadcrumb crumb={scrumbs} />
        <Button
          onClick={handleModal}
          size="small"
          className={classes.btnCreate}
          variant="contained"
          color="primary"
        >
          Create Request
        </Button>
        <TableRequestLeave setDialog={setDialogRequestLeave} />
        <DialogRequestLeave
          dialog={dialogRequestLeave}
          onClose={handleModalClose}
        />
      </Container>
    </>
  );
};

export default RequestLeave;
