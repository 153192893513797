/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Container,
  Paper,
  Grid,
  Button,
  Tabs,
  Tab,
  Typography,
  Box,
  Divider,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Card,
  CardActionArea,
  CardContent,
} from "@material-ui/core";
import Breadcrumb from "../../components/UI/Breadcrumb/Breadcrumb";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import HomeIcon from "@material-ui/icons/Home";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import WorkIcon from "@material-ui/icons/Work";
import SchoolIcon from "@material-ui/icons/School";
import PeopleIcon from "@material-ui/icons/People";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import DescriptionIcon from "@material-ui/icons/Description";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import CustomRouterLink from "../../components/CustomRouterLink/CustomRouterLink";

import { CardDetailEmployee } from "./component";
import {
  IdentitasDiri,
  Alamat,
  InformasiPekerjaan,
  JobAnalyst,
  DialogChangeProfile,
  DialogSkill,
  DialogPendidikan,
  DialogOrganisasi,
  DialogPekerjaan,
  DialogPrestasi,
  DialogKeluarga,
  DialogSocialMedia,
  DialogPelatihan,
  TableSkill,
  TablePendidikan,
  TableOrganisasi,
  TablePrestasi,
  TablePekerjaan,
  TableKeluarga,
  TableSocialMedia,
  TablePelatihan,
} from "./component";

// Redux
import { getDocument } from "../../store/actions/dokumen";
import { getDetailInformation } from "../../store/actions/detailInformation";
import { uploadPhoto } from "../../store/actions";

import DefaultAvatar from "../../assets/img/default-avatar.png";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  button: {
    backgroundColor: "#011747",
  },
  btnEdit: {
    marginBottom: theme.spacing(3),
  },
  active: {
    "& .Mui-selected": {
      backgroundColor: "#FFA62B",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#FFA62B",
    },
  },
  icon: {
    paddingTop: 0,
    marginRight: 5,
    fontSize: 18,
  },
  accorDetail: {
    flexDirection: "column",
  },
  btnTambah: {
    width: "150px",
    display: "block",
    marginTop: theme.spacing(2),
    marginLeft: "auto",
  },
  field: {
    marginTop: theme.spacing(1),
  },
  iconDocument: {
    marginRight: 5,
    fontSize: 25,
  },
  boxDocumentCuti: {
    display: "flex",
    [theme.breakpoints.only("md")]: {
      display: "block",
    },
  },
}));

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const DetailEmployee = (props) => {
  const classes = useStyles();
  const {
    getDetailInformation,
    getDocument,
    document,
    auth,
    detailInformation,
    detailEmployee,
    uploadPhoto,
  } = props;
  const params = useParams();
  const history = useHistory();

  const scrumbs = [
    { name: "Employee", link: "/employee" },
    { name: detailInformation.data.name, link: "" },
  ];

  const [value, setValue] = useState(0);
  const [openDialogSkill, setOpenDialogSkill] = useState({
    open: false,
    edit: false,
    value: null,
  });
  const [openDialogPendidikan, setOpenDialogPendidikan] = useState({
    open: false,
    edit: false,
    value: null,
  });
  const [openDialogOrganisasi, setOpenDialogOrganisasi] = useState({
    open: false,
    edit: false,
    value: null,
  });
  const [openDialogPekerjaan, setOpenDialogPekerjaan] = useState({
    open: false,
    edit: false,
    value: null,
  });
  const [openDialogPrestasi, setOpenDialogPrestasi] = useState({
    open: false,
    edit: false,
    value: null,
  });
  const [openDialogKeluarga, setOpenDialogKeluarga] = useState({
    open: false,
    edit: false,
    value: null,
  });
  const [openDialogSocialMedia, setOpenDialogSocialMedia] = useState({
    open: false,
    edit: false,
    value: null,
  });
  const [openDialogPelatihan, setOpenDialogPelatihan] = useState({
    open: false,
    edit: false,
    value: null,
  });
  const [expanded, setExpanded] = useState("panelpelatihan");
  const [srcProfile, setSrcProfile] = useState({ open: false, src: null });

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getDetailInformation(params.id, auth.user.role_name);
    getDocument(params.userId, auth.user.role_name);
  }, []);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Breadcrumb crumb={scrumbs} />
      <Paper elevation={2}>
        <Grid container spacing={3} style={{ marginBottom: 10, padding: 20 }}>
          <Grid item xs={12} md={8}>
            <CardDetailEmployee
              image={detailInformation.data.photo || DefaultAvatar}
              name={detailInformation.data.name}
              company={detailInformation.data.company_name}
              role={detailInformation.data.job_name}
              nik={detailInformation.data.employee_identity}
              userId={detailInformation.data.user_id}
              positionId={detailInformation.data.id}
              workStatus={detailInformation.data.status}
              setSrcProfile={setSrcProfile}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Box className={classes.boxDocumentCuti}>
              <Card style={{ width: "200px" }}>
                <CardActionArea
                  component={CustomRouterLink}
                  to={`/dokumen/${detailInformation.data.id}/${detailInformation.data.user_id}`}
                >
                  <CardContent>
                    <DescriptionIcon
                      className={classes.iconDocument}
                      fontSize="large"
                    />
                    <Typography
                      display="inline"
                      variant="h6"
                      style={{ position: "absolute", marginTop: 5 }}
                    >
                      {document.data.length} dari 8 Dokumen
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card style={{ width: "200px" }}>
                <CardActionArea>
                  <CardContent>
                    <DescriptionIcon
                      className={classes.iconDocument}
                      fontSize="large"
                    />
                    <Typography
                      display="inline"
                      variant="h6"
                      style={{ position: "absolute", marginTop: 5 }}
                    >
                      Cuti {detailInformation.data.total_leave} dari 12
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={2}>
        <Paper>
          <Tabs
            className={classes.active}
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Informasi Pribadi" {...a11yProps(0)} />
            <Tab label="Informasi Pekerjaan" {...a11yProps(1)} />
            <Tab label="Job Analyst" {...a11yProps(2)} />
          </Tabs>
        </Paper>
        <TabPanel value={value} index={0}>
          <Box p={3} display="flex" justifyContent="space-between">
            <div>
              <PersonIcon className={classes.icon} fontSize="small" />{" "}
              <Typography display="inline" variant="h6">
                IDENTITAS DIRI :
              </Typography>
            </div>
            {!detailEmployee.loadingProfile ? (
              detailEmployee.profile.edit_profile_information ||
              auth.user.id === parseInt(params.userId) ? (
                <Button
                  startIcon={<EditIcon />}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `/detail-employee/${params.id}/${params.userId}/informasi-pribadi`
                    )
                  }
                >
                  Edit Identitas
                </Button>
              ) : null
            ) : null}
          </Box>
          <Divider />
          <Box p={3}>
            <IdentitasDiri />
          </Box>
          <Divider />
          <Box p={3} display="flex" justifyContent="space-between">
            <div>
              <HomeIcon className={classes.icon} fontSize="small" />{" "}
              <Typography display="inline" paragraph variant="h6">
                ALAMAT :
              </Typography>
            </div>
            {!detailEmployee.loadingProfile ? (
              detailEmployee.profile.edit_profile_information ||
              auth.user.id === parseInt(params.userId) ? (
                <Button
                  startIcon={<EditIcon />}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `/detail-employee/${params.id}/${params.userId}/alamat`
                    )
                  }
                >
                  Edit Alamat
                </Button>
              ) : null
            ) : null}
          </Box>
          <Divider />
          <Box p={3}>
            <Alamat />
          </Box>
          <Divider />

          <Box p={3}>
            <Accordion
              square
              expanded={expanded === "panelpelatihan"}
              onChange={handleChangeAccordion("panelpelatihan")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Box pt={1} display="flex">
                  <LocalLibraryIcon className={classes.icon} fontSize="small" />
                  <Typography display="inline" paragraph variant="h6">
                    PELATIHAN / SEMINAR
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.accorDetail}>
                <TablePelatihan
                  setDialog={setOpenDialogPelatihan}
                  hcis={
                    auth.user.role_name === "hcis" &&
                    auth.user.id !== params.userId
                  }
                />
                {!detailEmployee.loadingProfile ? (
                  detailEmployee.profile.edit_profile_information ||
                  auth.user.id === parseInt(params.userId) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btnTambah}
                      onClick={() =>
                        setOpenDialogPelatihan((prev) => ({
                          ...prev,
                          open: true,
                        }))
                      }
                    >
                      Tambah Baru
                    </Button>
                  ) : null
                ) : null}
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel1"}
              onChange={handleChangeAccordion("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Box pt={1} display="flex">
                  <EmojiObjectsIcon className={classes.icon} fontSize="small" />{" "}
                  <Typography display="inline" paragraph variant="h6">
                    KONTAK / SOCIAL MEDIA
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.accorDetail}>
                <TableSocialMedia
                  setDialog={setOpenDialogSocialMedia}
                  hcis={
                    auth.user.role_name === "hcis" &&
                    auth.user.id !== params.userId
                  }
                />
                {!detailEmployee.loadingProfile ? (
                  detailEmployee.profile.edit_profile_information ||
                  auth.user.id === parseInt(params.userId) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btnTambah}
                      onClick={() =>
                        setOpenDialogSocialMedia((prev) => ({
                          ...prev,
                          open: true,
                        }))
                      }
                    >
                      Tambah Baru
                    </Button>
                  ) : null
                ) : null}
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel2"}
              onChange={handleChangeAccordion("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Box pt={1} display="flex">
                  <EmojiObjectsIcon className={classes.icon} fontSize="small" />{" "}
                  <Typography display="inline" paragraph variant="h6">
                    KEAHLIAN
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.accorDetail}>
                <TableSkill
                  setDialog={setOpenDialogSkill}
                  hcis={
                    auth.user.role_name === "hcis" &&
                    auth.user.id !== params.userId
                  }
                />
                {!detailEmployee.loadingProfile ? (
                  detailEmployee.profile.edit_profile_information ||
                  auth.user.id === parseInt(params.userId) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btnTambah}
                      onClick={() =>
                        setOpenDialogSkill((prev) => ({
                          ...prev,
                          open: true,
                        }))
                      }
                    >
                      Tambah Baru
                    </Button>
                  ) : null
                ) : null}
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel3"}
              onChange={handleChangeAccordion("panel3")}
            >
              <AccordionSummary
                aria-controls="panel3d-content"
                id="panel3d-header"
              >
                <Box pt={1} display="flex">
                  <SchoolIcon className={classes.icon} fontSize="small" />{" "}
                  <Typography display="inline" paragraph variant="h6">
                    RIWAYAT PENDIDIKAN
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.accorDetail}>
                <TablePendidikan
                  setDialog={setOpenDialogPendidikan}
                  hcis={
                    auth.user.role_name === "hcis" &&
                    auth.user.id !== params.userId
                  }
                />
                {!detailEmployee.loadingProfile ? (
                  detailEmployee.profile.edit_profile_information ||
                  auth.user.id === parseInt(params.userId) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btnTambah}
                      onClick={() =>
                        setOpenDialogPendidikan((prev) => ({
                          ...prev,
                          open: true,
                        }))
                      }
                    >
                      Tambah Baru
                    </Button>
                  ) : null
                ) : null}
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel4"}
              onChange={handleChangeAccordion("panel4")}
            >
              <AccordionSummary
                aria-controls="panel4d-content"
                id="panel4d-header"
              >
                <Box pt={1} display="flex">
                  <PeopleIcon className={classes.icon} fontSize="small" />{" "}
                  <Typography display="inline" paragraph variant="h6">
                    RIWAYAT ORGANISASI
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.accorDetail}>
                <TableOrganisasi
                  setDialog={setOpenDialogOrganisasi}
                  hcis={
                    auth.user.role_name === "hcis" &&
                    auth.user.id !== params.userId
                  }
                />
                {!detailEmployee.loadingProfile ? (
                  detailEmployee.profile.edit_profile_information ||
                  auth.user.id === parseInt(params.userId) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btnTambah}
                      onClick={() =>
                        setOpenDialogOrganisasi((prev) => ({
                          ...prev,
                          open: true,
                        }))
                      }
                    >
                      Tambah Baru
                    </Button>
                  ) : null
                ) : null}
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel5"}
              onChange={handleChangeAccordion("panel5")}
            >
              <AccordionSummary
                aria-controls="panel5d-content"
                id="panel5d-header"
              >
                <Box pt={1} display="flex">
                  <WorkIcon className={classes.icon} fontSize="small" />{" "}
                  <Typography display="inline" paragraph variant="h6">
                    RIWAYAT PEKERJAAN
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.accorDetail}>
                <TablePekerjaan
                  setDialog={setOpenDialogPekerjaan}
                  hcis={
                    auth.user.role_name === "hcis" &&
                    auth.user.id !== params.userId
                  }
                />
                {!detailEmployee.loadingProfile ? (
                  detailEmployee.profile.edit_profile_information ||
                  auth.user.id === parseInt(params.userId) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btnTambah}
                      onClick={() =>
                        setOpenDialogPekerjaan((prev) => ({
                          ...prev,
                          open: true,
                        }))
                      }
                    >
                      Tambah Baru
                    </Button>
                  ) : null
                ) : null}
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel6"}
              onChange={handleChangeAccordion("panel6")}
            >
              <AccordionSummary
                aria-controls="panel6d-content"
                id="panel6d-header"
              >
                <Box pt={1} display="flex">
                  <EmojiEventsIcon className={classes.icon} fontSize="small" />{" "}
                  <Typography display="inline" paragraph variant="h6">
                    PRESTASI
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.accorDetail}>
                <TablePrestasi
                  setDialog={setOpenDialogPrestasi}
                  hcis={
                    auth.user.role_name === "hcis" &&
                    auth.user.id !== params.userId
                  }
                />
                {!detailEmployee.loadingProfile ? (
                  detailEmployee.profile.edit_profile_information ||
                  auth.user.id === parseInt(params.userId) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btnTambah}
                      onClick={() =>
                        setOpenDialogPrestasi((prev) => ({
                          ...prev,
                          open: true,
                        }))
                      }
                    >
                      Tambah Baru
                    </Button>
                  ) : null
                ) : null}
              </AccordionDetails>
            </Accordion>
            <Accordion
              square
              expanded={expanded === "panel7"}
              onChange={handleChangeAccordion("panel7")}
            >
              <AccordionSummary
                aria-controls="panel7d-content"
                id="panel7d-header"
              >
                <Box pt={1} display="flex">
                  <PeopleAltIcon className={classes.icon} fontSize="small" />{" "}
                  <Typography display="inline" paragraph variant="h6">
                    KELUARGA
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.accorDetail}>
                <TableKeluarga
                  setDialog={setOpenDialogKeluarga}
                  hcis={
                    auth.user.role_name === "hcis" &&
                    auth.user.id !== params.userId
                  }
                />
                {!detailEmployee.loadingProfile ? (
                  detailEmployee.profile.edit_profile_information ||
                  auth.user.id === parseInt(params.userId) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.btnTambah}
                      onClick={() =>
                        setOpenDialogKeluarga((prev) => ({
                          ...prev,
                          open: true,
                        }))
                      }
                    >
                      Tambah Baru
                    </Button>
                  ) : null
                ) : null}
              </AccordionDetails>
            </Accordion>
          </Box>
          <Divider />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box p={3} display="flex" justifyContent="space-between">
            <div>
              <WorkIcon className={classes.icon} fontSize="small" />{" "}
              <Typography display="inline" variant="h6">
                Pekerjaan
              </Typography>
            </div>
            {!detailEmployee.loadingProfile ? (
              detailEmployee.profile.edit_profile_information ||
              auth.user.role_name === "hcis" ? (
                <Button
                  startIcon={<EditIcon />}
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    history.push(
                      `/detail-employee/${params.id}/${params.userId}/informasi-pekerjaan`
                    )
                  }
                >
                  Edit Pekerjaan
                </Button>
              ) : null
            ) : null}
          </Box>
          <Divider />
          <Box p={3}>
            <InformasiPekerjaan />
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <JobAnalyst />
        </TabPanel>
      </Paper>

      {/* Dialog Change Profile */}
      <DialogChangeProfile
        dialog={srcProfile}
        onClose={() => setSrcProfile({ open: false, src: null })}
        action={uploadPhoto}
        positionId={detailInformation.data.id}
        role={auth.user.role_name}
      />

      {/* Dialo Social Media */}
      <DialogSocialMedia
        dialog={openDialogSocialMedia}
        onClose={() =>
          setOpenDialogSocialMedia({ open: false, edit: false, value: null })
        }
        hcis={
          auth.user.role_name === "hcis" &&
          auth.user.id !== parseInt(params.userId)
        }
      />

      {/* Dialog Skill */}
      <DialogSkill
        dialog={openDialogSkill}
        onClose={() =>
          setOpenDialogSkill({ open: false, edit: false, value: null })
        }
        hcis={
          auth.user.role_name === "hcis" &&
          auth.user.id !== parseInt(params.userId)
        }
      />

      {/* Pendidikan */}
      <DialogPendidikan
        dialog={openDialogPendidikan}
        onClose={() =>
          setOpenDialogPendidikan({ open: false, edit: false, value: null })
        }
        hcis={
          auth.user.role_name === "hcis" &&
          auth.user.id !== parseInt(params.userId)
        }
      />

      {/* Riwayat Organisasi */}
      <DialogOrganisasi
        dialog={openDialogOrganisasi}
        onClose={() =>
          setOpenDialogOrganisasi({ open: false, edit: false, value: null })
        }
        hcis={
          auth.user.role_name === "hcis" &&
          auth.user.id !== parseInt(params.userId)
        }
      />

      {/* Riwayat Pekerjaan */}
      <DialogPekerjaan
        dialog={openDialogPekerjaan}
        onClose={() =>
          setOpenDialogPekerjaan({ open: false, edit: false, value: null })
        }
        hcis={
          auth.user.role_name === "hcis" &&
          auth.user.id !== parseInt(params.userId)
        }
      />

      {/* Prestasi */}
      <DialogPrestasi
        dialog={openDialogPrestasi}
        onClose={() =>
          setOpenDialogPrestasi({ open: false, edit: false, value: null })
        }
        hcis={
          auth.user.role_name === "hcis" &&
          auth.user.id !== parseInt(params.userId)
        }
      />

      {/* Keluarga */}
      <DialogKeluarga
        dialog={openDialogKeluarga}
        onClose={() =>
          setOpenDialogKeluarga({ open: false, edit: false, value: null })
        }
        hcis={
          auth.user.role_name === "hcis" &&
          auth.user.id !== parseInt(params.userId)
        }
      />

      {/* Pelatihan/Seminar */}
      <DialogPelatihan
        dialog={openDialogPelatihan}
        onClose={() =>
          setOpenDialogPelatihan({ open: false, edit: false, value: null })
        }
        hcis={
          auth.user.role_name === "hcis" &&
          auth.user.id !== parseInt(params.userId)
        }
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    document: state.dokumen,
    auth: state.auth,
    detailInformation: state.detailInformation,
    detailEmployee: state.detailEmployee,
  };
};

export default connect(mapStateToProps, {
  getDocument,
  getDetailInformation,
  uploadPhoto,
})(DetailEmployee);
