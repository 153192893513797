import {
  SET_ABSEN_DATA,
  SET_ABSEN_STATUS,
  ABSEN_FINISH,
  ABSEN_LOADING,
  ABSEN_RULES_LOADING,
  SET_ABSEN_RULES,
  ABSEN_RULES_FINISH,
} from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  status: "-",
  loading: false,
  data: [],
  last_page: 1,
  rules: {
    data: [],
    loading: false,
    last_page: 1,
  },
};

const absenLoading = (state, action) => {
  return updateObject(state, { loading: true });
};

const setAbsenData = (state, action) => {
  return updateObject(state, {
    data: action.payload,
    loading: false,
    last_page: action.last_page,
  });
};

const setAbsenStatus = (state, action) => {
  return updateObject(state, { status: action.payload, loading: false });
};

const absenFinish = (state, action) => {
  return updateObject(state, { loading: false });
};

const absenRulesLoading = (state, action) => {
  return updateObject(state, { rules: { ...state.rules, loading: true } });
};

const setAbsenRules = (state, action) => {
  return updateObject(state, {
    rules: {
      ...state.rules,
      data: action.payload,
      loading: false,
      last_page: action.last_page,
    },
  });
};

const absenRulesFinish = (state, action) => {
  return updateObject(state, { rules: { ...state.rules, loading: false } });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ABSEN_LOADING:
      return absenLoading(state, action);
    case SET_ABSEN_DATA:
      return setAbsenData(state, action);
    case SET_ABSEN_STATUS:
      return setAbsenStatus(state, action);
    case ABSEN_FINISH:
      return absenFinish(state, action);
    case ABSEN_RULES_LOADING:
      return absenRulesLoading(state, action);
    case SET_ABSEN_RULES:
      return setAbsenRules(state, action);
    case ABSEN_RULES_FINISH:
      return absenRulesFinish(state, action);
    default:
      return state;
  }
};

export default reducer;
