import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
import SignOutIcon from "@material-ui/icons/Input";
import ExpandMore from "@material-ui/icons/ExpandMore";
import MoreIcon from "@material-ui/icons/MoreVert";
import LockIcon from "@material-ui/icons/Lock";
import { Link } from "react-router-dom";
import { Divider, Typography } from "@material-ui/core";

import { connect } from "react-redux";
import { setModalLoginLogout, switchAccount } from "../../../../store/actions";
import Backdrop from "../../../../components/UI/Backdrop/Backdrop";

const drawerWidth = 240;
const appDrawerBlue = "#FFCB47";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  content: {
    flexGrow: 1,
    paddingTop: 56,
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingTop: 64,
    },
  },
  name_logo: {
    color: "#000000",
    textDecoration: "none",
  },
  profileName: {
    borderRadius: "10%",
  },
  logo: {
    width: "auto",
    height: 50,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  logo_min: {
    width: "auto",
    height: 30,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - 73px)`,
    },
    boxShadow: "none",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: appDrawerBlue,
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
    },
  },
  appBarShift: {
    marginLeft: drawerWidth,
    boxShadow: "none",
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: "100%",
    },
  },
  menuButton: {
    marginRight: 36,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    color: "#535353",
  },
  menuButtonMobile: {
    marginRight: 36,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    color: "#535353",
  },
  flexGrow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  profileMenu: {
    marginTop: theme.spacing(2),
  },
  divider: {
    height: 37,
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    color: "#000",
    width: "fit-content",
    border: `1px solid #535353`,
    borderRadius: theme.shape.borderRadius,
  },
  avatar: {
    width: 30,
    height: 30,
  },
  textNone: {
    textDecoration: "none",
  },
  activeAccount: {
    color: "red",
  },
}));

const Appbar = (props) => {
  const {
    handleDrawerOpen,
    handleDrawerMobileOpen,
    open,
    username,
    companyName,
    jobName,
    allAccounts,
    setModalLoginLogout,
    switchAccount,
    seq,
    auth,
  } = props;

  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const onLogoutHandler = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
    localStorage.removeItem("allAccounts");
    localStorage.clear();
    history.push("/login");
  };

  const onLogout = () => {
    handleMenuClose();
    handleMobileMenuClose();
    onLogoutHandler();
    // setModalLoginLogout("الحمد لله رب العالمين");
  };

  const switchAccountHandle = (id, history) => {
    switchAccount(id, history);
    handleMenuClose();
    handleMobileMenuClose();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className={classes.profileMenu}
    >
      <Typography align="center">Ganti Akun</Typography>
      {allAccounts.map((data) => {
        return (
          <div key={data.id}>
            <MenuItem
              disabled={data.company_name === companyName ? true : false}
              onClick={() => {
                switchAccountHandle(data.id, history);
              }}
            >
              {data.company_name} ({data.job_name})
            </MenuItem>
            <Divider />
          </div>
        );
      })}

      <Link to="/ganti-password" className={classes.textNone}>
        <MenuItem onClick={handleMenuClose}>Ganti Password</MenuItem>
      </Link>
      <MenuItem onClick={onLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Typography align="center">Ganti Akun</Typography>
      {allAccounts.map((data) => {
        return (
          <div key={data.id}>
            <MenuItem
              disabled={data.company_name === companyName ? true : false}
              onClick={() => {
                switchAccountHandle(data.id, history);
              }}
            >
              {data.company_name} ({data.job_name})
            </MenuItem>
            <Divider />
          </div>
        );
      })}
      <Link to="/ganti-password" className={classes.textNone}>
        <MenuItem onClick={handleMobileMenuClose}>
          <IconButton aria-label="change-pw" color="inherit">
            <LockIcon />
          </IconButton>
          <p>Ganti Password</p>
        </MenuItem>
      </Link>
      <MenuItem onClick={onLogout}>
        <IconButton aria-label="logout" color="inherit">
          <SignOutIcon />
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>
        {/* Button for mobile */}
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerMobileOpen}
          edge="start"
          className={classes.menuButtonMobile}
        >
          <MenuIcon />
        </IconButton>
        {/* <Link to="/" className={classes.name_logo}>
          <Typography variant="h5">Setoran System</Typography>
        </Link> */}
        <div className={classes.flexGrow} />
        <Box>
          <Typography
            style={{ fontWeight: "bold", color: "#535353" }}
            variant="subtitle1"
          >
            {companyName}
          </Typography>
          <Typography
            style={{ fontWeight: "500", color: "#535353" }}
            variant="subtitle1"
          >
            {jobName}
          </Typography>
        </Box>
        <div className={classes.sectionDesktop}>
          {/* <IconButton aria-label="show 17 new notifications" color="default">
            <Badge badgeContent={17} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}

          <Divider className={classes.divider} orientation="vertical" />
          <IconButton
            onClick={handleProfileMenuOpen}
            className={classes.profileName}
          >
            <Typography style={{ color: "#535353" }} variant="h5">
              {username}
            </Typography>

            <ExpandMore style={{ color: "#535353" }} />
          </IconButton>
        </div>
        <div className={classes.sectionMobile}>
          <IconButton
            aria-label="show more"
            aria-controls={mobileMenuId}
            aria-haspopup="true"
            onClick={handleMobileMenuOpen}
          >
            <MoreIcon style={{ color: "#fff" }} />
          </IconButton>
        </div>
      </Toolbar>
      {renderMobileMenu}
      {renderMenu}
      <Backdrop openBackdrop={auth.loadingSwitchAccount} />
    </AppBar>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { setModalLoginLogout, switchAccount })(
  Appbar
);
