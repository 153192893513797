import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 50,
    color: "#fff",
  },
}));

const ProgressBackdrop = (props) => {
  const classes = useStyles();
  const { openBackdrop } = props;

  return (
    <Backdrop className={classes.backdrop} open={openBackdrop}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default ProgressBackdrop;
