export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const GET_ALL_ACCOUNT = "GET_ALL_ACCOUNT";
export const SWITCH_START = "SWITCH_START";
export const SWITCH_ACCOUNT = "SWITCH_ACCOUNT";
export const CHANGE_STATUS = "CHANGE_STATUS";
export const SET_LOADING_PASSWORD = "SET_LOADING_PASSWORD";
export const SET_LOADING_PASSWORD_DONE = "SET_LOADING_PASSWORD_DONE";

// Loading
export const SET_LOADING = "SET_LOADING";
export const SET_LOADING_DATA = "SET_LOADING_DATA";
export const SET_LOADING_DONE = "SET_LOADING_DONE";

export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const SET_MODAL_LOGIN_LOGOUT = "SET_MODAL_LOGIN_LOGOUT";
export const REMOVE_MODAL = "REMOVE_MODAL";

export const GET_PROVINCE = "GET_PROVINCE";
export const GET_CITY = "GET_CITY";
export const GET_DISTRICT = "GET_DISTRICT";
export const GET_VILLAGE = "GET_VILLAGE";
export const GET_CITY_DOMISILI = "GET_CITY_DOMISILI";
export const GET_DISTRICT_DOMISILI = "GET_DISTRICT_DOMISILI";
export const GET_VILLAGE_DOMISILI = "GET_VILLAGE_DOMISILI";
export const GET_CITY_MAIL = "GET_CITY_MAIL";
export const GET_DISTRICT_MAIL = "GET_DISTRICT_MAIL";
export const GET_VILLAGE_MAIL = "GET_VILLAGE_MAIL";

// Detail Employee
export const SET_PROFILE = "SET_PROFILE";
export const SET_SKILL = "SET_SKILL";
export const SET_SEMINAR = "SET_SEMINAR";
export const SET_PENDIDIKAN = "SET_PENDIDIKAN";
export const SET_ORGANISASI = "SET_ORGANISASI";
export const SET_PRESTASI = "SET_PRESTASI";
export const SET_PEKERJAAN = "SET_PEKERJAAN";
export const SET_KELUARGA = "SET_KELUARGA";
export const SET_ALAMAT = "SET_ALAMAT";
export const SET_TYPE_SOCIAL_MEDIA = "SET_TYPE_SOCIAL_MEDIA";
export const SET_SOCIAL_MEDIA = "SET_SOCIAL_MEDIA";
export const SET_INFORMASI_PEKERJAAN = "SET_INFORMASI_PEKERJAAN";
export const SET_RUMUSAN_TUGAS = "SET_RUMUSAN_TUGAS";
export const SET_TUGAS_POKOK = "SET_TUGAS_POKOK";
export const SET_TUGAS_TAMBAHAN = "SET_TUGAS_TAMBAHAN";
export const SET_TUGAS_BERKALA = "SET_TUGAS_BERKALA";
export const SET_TUGAS_LAINNYA = "SET_TUGAS_LAINNYA";
export const SET_TUGAS_BELUM_TERLAKSANA = "SET_TUGAS_BELUM_TERLAKSANA";
export const SET_ALAMAT_LOADING = "SET_ALAMAT_LOADING";

// Status
export const SET_STATUS_JOB = "SET_STATUS_JOB";
export const SET_STATUS_FAMILY = "SET_STATUS_FAMILY";
export const SET_STATUS_ROLE = "SET_STATUS_ROLE";
export const SET_JOB_LIST = "SET_JOB_LIST";
export const SET_SUPERVISOR_LIST = "SET_SUPERVISOR_LIST";

// Document
export const SET_LOADING_DOCUMENT = "SET_LOADING_DOCUMENT";
export const SET_LOADING_DOCUMENT_DONE = "SET_LOADING_DOCUMENT_DONE";
export const SET_DOCUMENT = "SET_DOCUMENT";

// Image Uploader
export const SET_PHOTO = "SET_PHOTO";
export const SET_LOADING_PHOTO = "SET_LOADING_PHOTO";
export const SET_LOADING_PHOTO_DONE = "SET_LOADING_PHOTO_DONE";

// Company
export const SET_COMPANY = "SET_COMPANY";

// Employee
export const SET_EMPLOYEE = "SET_EMPLOYEE";
export const SET_TOTAL_EMPLOYEE = "SET_TOTAL_EMPLOYEE";
export const SET_TOTAL_EMPLOYEE_GENDER = "SET_TOTAL_EMPLOYEE_GENDER";

// Detail Information
export const SET_DETAIL_INFORMATION = "SET_DETAIL_INFORMATION";

// QUOTES
export const SET_ALL_QUOTES = "SET_ALL_QUOTES";
export const SET_QUOTES = "SET_QUOTES";

// NEWS
export const SET_NEWS = "SET_NEWS";
export const SET_ONE_NEWS = "SET_ONE_NEWS";
export const CLEAR_NEWS = "CLEAR_NEWS";

// REQUEST LEAVE
export const SET_REQUEST_LEAVE = "SET_REQUEST_LEAVE";
export const SET_ALL_REQUEST_LEAVE = "SET_ALL_REQUEST_LEAVE";
export const SET_OVERVIEW = "SET_OVERVIEW";
export const SET_CHECKED = "SET_CHECKED";
export const SET_DETAIL_REQUEST_LEAVE = "SET_DETAIL_REQUEST_LEAVE";
export const SET_TOTAL_REQUEST_LEAVE_IZIN = "SET_TOTAL_REQUEST_LEAVE_IZIN";
export const SET_TOTAL_REQUEST_LEAVE_CUTI = "SET_TOTAL_REQUEST_LEAVE_CUTI";
export const SET_TOTAL_REQUEST_LEAVE_SAKIT = "SET_TOTAL_REQUEST_LEAVE_SAKIT";

// REIMBURSE
export const SET_REIMBURSE = "SET_REIMBURSE";
export const SET_EDIT_REIMBURSE = "SET_EDIT_REIMBURSE";
export const SET_DAFTAR_REIMBURSE = "SET_DAFTAR_REIMBURSE";

// OVERTIME
export const SET_OVERTIME_ASSIGN = "SET_OVERTIME_ASSIGN";
export const SET_ALL_OVERTIME = "SET_ALL_OVERTIME";
export const SET_STAFF = "SET_STAFF";

// ABSENSI
export const ABSEN_LOADING = "ABSEN_LOADING";
export const SET_ABSEN_DATA = "SET_ABSEN_DATA";
export const SET_ABSEN_STATUS = "SET_ABSEN_STATUS";
export const ABSEN_FINISH = "ABSEN_FINISH";

// DASHBOARD
export const SET_DASHBOARD_CHART_START = "SET_DASHBOARD_CHART_START";
export const SET_DASHBOARD_CHART_SUCCESS = "SET_DASHBOARD_CHART_SUCCESS";
export const SET_DASHBOARD_CHART_FAIL = "SET_DASHBOARD_CHART_FAIL";

// ABSEN
export const ABSEN_RULES_LOADING = "ABSEN_RULES_LOADING";
export const SET_ABSEN_RULES = "SET_ABSEN_RULES";
export const ABSEN_RULES_FINISH = "ABSEN_RULES_FINISH";

// TASK
export const SET_TASK_START = "SET_TASK_START";
export const SET_TASK_SUCCESS = "SET_TASK_SUCCESS";
export const SET_TASK_FAIL = "SET_TASK_FAIL";

// CREATE USER
export const CREATE_USER_START = "CREATE_USER_START";
export const CREATE_USER_DONE = "CREATE_USER_DONE";
