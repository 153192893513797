import React from "react";
import { makeStyles } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
  dateFiled: {
    marginTop: theme.spacing(1),
  },
}));

const DatePicker = (props) => {
  const {
    inputRef,
    helperText,
    name,
    error,
    value,
    onChange,
    label,
    format,
    views,
  } = props;
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        fullWidth
        name={name}
        inputRef={inputRef}
        className={classes.dateFiled}
        value={value}
        onChange={onChange}
        id="date-picker-dialog"
        label={label}
        views={views}
        format={format}
        error={error}
        helperText={helperText}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
