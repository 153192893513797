import {
  ABSEN_LOADING,
  SET_ABSEN_DATA,
  SET_ABSEN_STATUS,
  ABSEN_FINISH,
  ABSEN_RULES_LOADING,
  SET_ABSEN_RULES,
  ABSEN_RULES_FINISH,
} from "./actionTypes";
import axios from "../../axios-orders";
import { setAlert } from "./alert";

export const sendAbsentMasuk = (data) => async (dispatch) => {
  dispatch({ type: ABSEN_LOADING });
  try {
    await axios.post("/staff/user/employee/position/own/attendance", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({ type: ABSEN_FINISH });
    dispatch(setAlert("Selamat bekerja", "success"));
    dispatch(getAbsenStatus());
  } catch (error) {
    dispatch(setAlert("something wen't wrongs", "error"));
  }
};

export const sendAbsentPulang = () => async (dispatch) => {
  dispatch({ type: ABSEN_LOADING });
  try {
    await axios.post(
      "/staff/user/employee/position/own/attendance",
      { status: "out", location: "Work from Office" },
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    dispatch({ type: ABSEN_FINISH });
    dispatch(setAlert("Anda berhasil absent pulang", "success"));
    dispatch(getAbsenStatus());
  } catch (error) {
    dispatch(setAlert("something wen't wrongs", "error"));
  }
};

export const getAbsenStatus = () => async (dispatch) => {
  dispatch({ type: ABSEN_LOADING });
  try {
    const response = await axios.get(
      "/staff/user/employee/position/own/attendance/now",
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );
    dispatch({ type: SET_ABSEN_STATUS, payload: response.data.data });
  } catch (error) {
    dispatch(setAlert("something wen't wrongs", "error"));
  }
};

export const getAbsenData = (
  isManager,
  date = "",
  company_id = "",
  page = "",
  page_size = ""
) => async (dispatch) => {
  let endpoint = `/staff/user/employee/position/own/attendance?date=${date}&company_id=${company_id}&page=${page}&page_size=${page_size}`;
  if (isManager) {
    endpoint = `/manager/user/employee/position/attendance?date=${date}&company_id=${company_id}&page=${page}&page_size=${page_size}`;
  }
  dispatch({ type: ABSEN_LOADING });
  try {
    const response = await axios.get(endpoint, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_ABSEN_DATA,
      payload: response.data.data,
      last_page: response.data.last_page,
    });
  } catch (error) {
    dispatch(setAlert("something wen't wrongs", "error"));
  }
};

// IMPORT
export const sendImportAbsen = (data) => async (dispatch) => {
  try {
    await axios.post("manager/user/employee/position/attendance/import", data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch(setAlert("data success stored", "success"));
  } catch (error) {
    dispatch(setAlert("something wen't wrongs", "error"));
  }
};

// RULES ABSENSI
export const sendRulesAbsenEntitas = (data) => async (dispatch) => {
  dispatch({ type: ABSEN_RULES_LOADING });
  try {
    await axios.post("/hcis/user/employee/attendance/rules", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({ type: ABSEN_RULES_FINISH });
    dispatch(setAlert("data success stored", "success"));
    dispatch(getAbsenRules());
  } catch (error) {
    dispatch(setAlert("something wen't wrongs", "error"));
  }
};

export const updateRulesAbsenEntitas = (data, id) => async (dispatch) => {
  dispatch({ type: ABSEN_RULES_LOADING });
  try {
    await axios.put(`/hcis/user/employee/attendance/rules/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({ type: ABSEN_RULES_FINISH });
    dispatch(setAlert("data success updated", "success"));
    dispatch(getAbsenRules());
  } catch (error) {
    dispatch(setAlert("something wen't wrongs", "error"));
  }
};

export const deleteRulesAbsenEntitas = (id) => async (dispatch) => {
  dispatch({ type: ABSEN_RULES_LOADING });
  try {
    await axios.delete(`/hcis/user/employee/attendance/rules/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({ type: ABSEN_RULES_FINISH });
    dispatch(setAlert("data success deleted", "success"));
    dispatch(getAbsenRules());
  } catch (error) {
    dispatch(setAlert("something wen't wrongs", "error"));
  }
};

export const getAbsenRules = () => async (dispatch) => {
  dispatch({ type: ABSEN_RULES_LOADING });
  try {
    const response = await axios.get("/hcis/user/employee/attendance/rules", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_ABSEN_RULES,
      payload: response.data.data,
      last_page: response.data.last_page,
    });
  } catch (error) {
    dispatch(setAlert("something wen't wrongs", "error"));
  }
};
