/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Dialog,
  DialogTitle,
  TextField,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { sendRumusanTugas } from "../../../../../../../store/actions";

const validationSchema = yup.object().shape({
  description: yup.string().required("Data Wajib diisi"),
});

const DialogEditRumusanTugas = ({ open, onClose, sendRumusanTugas, value }) => {
  const { handleSubmit, register, errors, setValue } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    sendRumusanTugas(data);
    onClose();
  };

  useEffect(() => {
    setValue("description", value);
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClose={onClose}>Edit Rumusan Tugas</DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            label="Rumusan Tugas"
            inputRef={register}
            name="description"
            error={!!errors.description}
            helperText={!!errors.description && errors.description.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" type="submit">
            Simpan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default connect(null, { sendRumusanTugas })(DialogEditRumusanTugas);
