/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { Typography, Grid } from '@material-ui/core';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { connect } from 'react-redux';
import { getPhoto, uploadPhoto } from '../../../../store/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  imageHover: {
    background: `black`,
    width: '100%',
    height: '100%',
    opacity: '0',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      opacity: '0.5',
    },
    cursor: 'pointer',
  },
  input: {
    display: 'none',
  },
  media: {
    width: 160,
    height: 180,
    position: 'relative',
  },
  cardContainer: {
    height: '100%',
    width: '100%',
    marginLeft: theme.spacing(2),
  },
  text: {
    marginBottom: theme.spacing(1),
    fontWeight: 'bold',
  },
  text2: {
    marginBottom: theme.spacing(1),
  },
  imageProfile: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));

const TextData = ({ children }) => (
  <Typography noWrap style={{ marginBottom: '8px' }}>
    {children}
  </Typography>
);

const CardDetailEmployee = (props) => {
  const {
    image,
    name,
    company,
    role,
    nik,
    // uploadPhoto,
    auth,
    userId,
    // positionId,
    setSrcProfile,
    workStatus,
  } = props;
  const classes = useStyles();

  const uploaderTrigger = () => {
    document.getElementById('photoUploader').click();
  };

  const uploaderImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setSrcProfile({ open: true, src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
    // const formData = new FormData();
    // formData.append("file", e.target.files[0]);
    // uploadPhoto(formData, positionId, auth.user.role_name);
  };

  return (
    <Card className={classes.root}>
      <div className={classes.media} title="image profile">
        {auth.user.id === userId && (
          <>
            <div className={classes.imageHover} onClick={uploaderTrigger}>
              <PhotoCameraIcon fontSize="large" />
            </div>
            <input
              accept="image/jpeg,image/png,image/jpg"
              className={classes.input}
              id="photoUploader"
              name="file"
              type="file"
              onChange={uploaderImage}
            />
          </>
        )}
        <img src={image} className={classes.imageProfile} />
      </div>
      <div style={{ display: 'flex', width: 'calc(100% - 180px)' }}>
        <Grid container alignItems="center" className={classes.cardContainer}>
          <Grid item xs={4}>
            <Typography className={classes.text}>NIK</Typography>
            <Typography className={classes.text}>Nama</Typography>
            <Typography className={classes.text}>Entitas</Typography>
            <Typography className={classes.text}>Job Title</Typography>
            <Typography className={classes.text}>Status Bekerja</Typography>
          </Grid>
          <Grid item xs={8}>
            <TextData>{`: ${nik || '-'}`}</TextData>
            <TextData>{`: ${name || '-'}`}</TextData>
            <TextData>{`: ${company || '-'}`}</TextData>
            <TextData>{`: ${role || '-'}`}</TextData>
            <TextData>{`: ${
              workStatus === 'active' ? 'Masih Bekerja' : 'Tidak Bekerja'
            }`}</TextData>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { getPhoto, uploadPhoto })(
  CardDetailEmployee
);
