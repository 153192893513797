/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Button,
  Typography,
  IconButton,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
  actions: {
    justifyContent: "end",
  },
}));

const validationSchema = yup.object().shape({
  task: yup.string().required("Wajib Diisi"),
});

const DialogCreateUser = ({ dialog, onClose, action }) => {
  const classes = useStyles();

  const { register, errors, handleSubmit, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    const formData = {
      task: data.task,
      status: "pending",
    };
    action(formData);
    onClose();
  };

  return (
    <Dialog open={dialog.open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClose={onClose}>Tambah To Achieve List</DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            type="text"
            name="task"
            label="Uraian"
            variant="outlined"
            inputRef={register}
            error={!!errors.task}
            helperText={errors.task?.message}
          />
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button variant="contained" color="inherit" onClick={onClose}>
            Batal
          </Button>
          <Button variant="contained" color="primary" type="submit">
            Simpan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogCreateUser;
