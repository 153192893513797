/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  TextField,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import DatePicker from "../../../../../components/UI/DatePicker/DatePicker";
import {
  sendKeluarga,
  getStatusFamily,
  sendEditKeluarga,
  deleteKeluarga,
} from "../../../../../store/actions";
import { isEmpty } from "../../../../../shared/utility";

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
  cardActions: {
    justifyContent: "space-between",
  },
  btnDelete: {
    color: "#fff",
    background: theme.palette.red.color500,
    "&:hover": {
      backgroundColor: theme.palette.red.color700,
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const validationSchema = yup.object().shape({
  family_status_id: yup
    .number()
    .required("Wajib diisi")
    .typeError("Wajib diisi"),
  name: yup.string().required("Wajib diisi"),
  place_of_birth: yup.string().required("Wajib diisi"),
  date_of_birth: yup.string().required("Wajib diisi"),
  religion: yup.string().required("Wajib diisi"),
  educational_stage: yup.string().required("Wajib diisi"),
  job_name: yup.string().required("Wajib diisi"),
  company_name: yup.string().required("Wajib diisi"),
});

const DialogKeluarga = ({
  dialog,
  onClose,
  sendKeluarga,
  status,
  getStatusFamily,
  sendEditKeluarga,
  deleteKeluarga,
  hcis,
}) => {
  const classes = useStyles();
  const params = useParams();

  const [date, setDate] = useState(null);

  const { handleSubmit, register, errors, control, setValue } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  const handleDelete = (id) => {
    if (hcis) {
      deleteKeluarga(id, params.userId);
    } else {
      deleteKeluarga(id);
    }
    onClose();
  };

  const onSubmit = (data, e) => {
    if (dialog.edit) {
      const dataEdit = {
        ...data,
        date_of_birth: moment(data.date_of_birth).format("YYYY-MM-DD"),
      };
      if (hcis) {
        sendEditKeluarga(dataEdit, dialog.value.id, params.userId);
      } else {
        sendEditKeluarga(dataEdit, dialog.value.id);
      }
    } else {
      if (hcis) {
        sendKeluarga(data, params.userId);
      } else {
        sendKeluarga(data);
      }
    }
    e.target.reset();
    setValue("family_status_id", "");
    setValue("religion", "");
    setValue("educational_stage", "");
    setValue("date_of_birth", "");
    setDate(null);
    onClose();
  };

  useEffect(() => {
    if (isEmpty(status.statusFamily)) {
      getStatusFamily();
    }
  }, [getStatusFamily, status.statusFamily]);

  useEffect(() => {
    if (dialog.edit) {
      setValue("family_status_id", dialog.value.family_status_id);
      setValue("name", dialog.value.name);
      setValue("id_number", dialog.value.id_number);
      setValue("place_of_birth", dialog.value.place_of_birth);
      setValue(
        "date_of_birth",
        moment(dialog.value.date_of_birth, "DD-MM-YYYY").format("YYYY-MM-DD")
      );
      setValue("religion", dialog.value.religion);
      setValue("educational_stage", dialog.value.educational_stage);
      setValue("job_name", dialog.value.job_name);
      setValue("company_name", dialog.value.company_name);
      setDate(moment(dialog.value.date_of_birth, "DD-MM-YYYY").format("ll"));
      return;
    }
    setValue("family_status_id", "");
    setValue("name", "");
    setValue("id_number", "");
    setValue("place_of_birth", "");
    setValue("date_of_birth", "");
    setValue("religion", "");
    setValue("educational_stage", "");
    setValue("job_name", "");
    setValue("company_name", "");
    setDate(null);
  }, [dialog.edit, setValue]);

  return (
    <Dialog open={dialog.open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClose={onClose}>
          {!dialog.edit ? "Tambah" : "Edit"} Keluarga
        </DialogTitle>
        <DialogContent dividers>
          <Controller
            control={control}
            defaultValue=""
            name="family_status_id"
            render={({ onChange, ref, value }) => (
              <TextField
                fullWidth
                select
                label="Hubungan"
                defaultValue=""
                inputRef={ref}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                error={!!errors.family_status_id}
                helperText={
                  !!errors.family_status_id && errors.family_status_id.message
                }
              >
                <MenuItem value="" disabled />
                {isEmpty(status.statusFamily) && (
                  <MenuItem value="" disabled>
                    Loading...
                  </MenuItem>
                )}
                {status.statusFamily.map((data) => (
                  <MenuItem key={data.id} value={data.order}>
                    {data.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <TextField
            className={classes.field}
            fullWidth
            label="Nama"
            name="name"
            inputRef={register}
            error={!!errors.name}
            helperText={!!errors.name && errors.name.message}
          />
          <TextField
            type="number"
            className={classes.field}
            fullWidth
            label="No KTP"
            name="id_number"
            inputRef={register}
            error={!!errors.id_number}
            helperText={!!errors.id_number && errors.id_number.message}
          />
          <TextField
            className={classes.field}
            fullWidth
            label="Tempat Lahir"
            name="place_of_birth"
            inputRef={register}
            error={!!errors.place_of_birth}
            helperText={
              !!errors.place_of_birth && errors.place_of_birth.message
            }
          />
          <Controller
            control={control}
            name="date_of_birth"
            defaultValue=""
            render={({ onChange, ref }) => (
              <DatePicker
                className={classes.field}
                fullWidth
                format="dd-MM-yyyy"
                inputRef={ref}
                label="Tanggal Lahir"
                value={date}
                onChange={(e) => {
                  setDate(e);
                  onChange(moment(e).format("YYYY-MM-DD"));
                }}
                error={!!errors.date_of_birth}
                helperText={
                  !!errors.date_of_birth && errors.date_of_birth.message
                }
              />
            )}
          />
          <Controller
            control={control}
            defaultValue=""
            name="religion"
            render={({ onChange, ref, value }) => (
              <TextField
                className={classes.field}
                select
                fullWidth
                defaultValue=""
                label="Agama"
                inputRef={ref}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                error={!!errors.religion}
                helperText={!!errors.religion && errors.religion.message}
              >
                <MenuItem value="" disabled></MenuItem>
                <MenuItem value="islam">Islam</MenuItem>
                <MenuItem value="kristen">Kristen</MenuItem>
                <MenuItem value="budha">Budha</MenuItem>
                <MenuItem value="hindu">Hindu</MenuItem>
                <MenuItem value="katolik">Katolik</MenuItem>
              </TextField>
            )}
          />
          <Controller
            control={control}
            defaultValue=""
            name="educational_stage"
            render={({ onChange, ref, value }) => (
              <TextField
                className={classes.field}
                select
                fullWidth
                defaultValue=""
                label="Pendidikan Terakhir"
                inputRef={ref}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                error={!!errors.educational_stage}
                helperText={
                  !!errors.educational_stage && errors.educational_stage.message
                }
              >
                <MenuItem value="" disabled></MenuItem>
                <MenuItem value="SD">SD</MenuItem>
                <MenuItem value="SMP">SMP</MenuItem>
                <MenuItem value="SMA">SMA</MenuItem>
                <MenuItem value="Sederajat">Sederajat</MenuItem>
                <MenuItem value="D3">D3</MenuItem>
                <MenuItem value="S1">S1</MenuItem>
                <MenuItem value="S2">S2</MenuItem>
                <MenuItem value="S3">S3</MenuItem>
              </TextField>
            )}
          />
          <TextField
            className={classes.field}
            fullWidth
            label="Pekerjaan"
            name="job_name"
            inputRef={register}
            error={!!errors.job_name}
            helperText={!!errors.job_name && errors.job_name.message}
          />
          <TextField
            className={classes.field}
            fullWidth
            label="Instansi"
            name="company_name"
            inputRef={register}
            error={!!errors.company_name}
            helperText={!!errors.company_name && errors.company_name.message}
          />
        </DialogContent>
        <DialogActions className={clsx({ [classes.cardActions]: dialog.edit })}>
          {dialog.edit && (
            <Button
              className={classes.btnDelete}
              variant="contained"
              onClick={() => handleDelete(dialog.value.id)}
            >
              Delete Data
            </Button>
          )}
          <Button variant="contained" color="primary" type="submit">
            {!dialog.edit ? "Simpan" : "Edit Data"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  status: state.status,
});

export default connect(mapStateToProps, {
  sendKeluarga,
  getStatusFamily,
  sendEditKeluarga,
  deleteKeluarga,
})(DialogKeluarga);
