/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { Button } from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";

const thumbsContainer = {
  padding: 18,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "250px",
  height: "auto",
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const Dropzone = (props) => {
  const { reset, setReset, handleUploadImage, setFile, oldPath } = props;

  const [files, setFiles] = useState([]);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    accept: "image/jpg,image/png,image/jpeg",
    maxSize: 1230715,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    onDropAccepted: handleUploadImage,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const thumbs = files.map((file) => (
    <div style={thumbInner} key={file.path}>
      <img src={file.preview} style={img} />
    </div>
  ));

  if (fileRejections.length > 0) {
    setFile(null);
  }
  const fileRejectionItems = fileRejections.map(({ file, errors }) => {
    return (
      <li key={file.path}>
        <ul>
          {errors.map((e) => (
            <li style={{ color: "red" }} key={e.code}>
              Maksimal ukuran file 1,2mb
            </li>
          ))}
        </ul>
      </li>
    );
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
      setReset(false);
    },
    [files]
  );

  useEffect(() => {
    if (reset) {
      setFiles([]);
    }
  }, [reset]);

  return (
    <section className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p style={{ marginBottom: 15 }}>Drag 'n' drop image atau pdf, disini</p>
        <Button startIcon={<PublishIcon />} variant="contained" color="primary">
          Upload Bukti
        </Button>
      </div>
      <aside style={thumbsContainer}>
        {thumbs}
        {thumbs.length < 1 && (
          <div style={thumbInner}>
            <img src={oldPath} style={img} />
          </div>
        )}
        {fileRejectionItems}
      </aside>
    </section>
  );
};

export default React.memo(Dropzone);
