import * as actions from "./actionTypes";

export const setModalLoginLogout = (msg) => {
  return {
    type: actions.SET_MODAL_LOGIN_LOGOUT,
    payload: { msg },
  };
};

export const removeModal = () => {
  return {
    type: actions.REMOVE_MODAL,
  };
};
