/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Button,
  Typography,
  IconButton,
  TextField,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { useForm, Controller } from "react-hook-form";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogUpdate = ({ dialog, onClose, action, page }) => {
  const { handleSubmit, control, setValue } = useForm({
    shouldUnregister: false
  });

  const onSubmit = (data) => {
    action(dialog.id, data, page);
    onClose();
  };

  useEffect(() => {
    if (dialog.status !== null) {
      setValue("status", dialog.status);
    }
  }, [dialog]);

  return (
    <Dialog open={dialog.open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle onClose={onClose}>Update Status</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent dividers>
          <Typography valiant="body1" align="center">
            Silahkan update status lemburan
          </Typography>
          <Controller
            control={control}
            name="status"
            defaultValue=""
            render={({ onChange, value }) => (
              <TextField
                select
                fullWidth
                style={{marginTop: "16px"}}
                variant="outlined"
                label="Status Lemburan"
                value={value}
                onChange={(e) => onChange(e.target.value)}
              >
                <MenuItem value="" disabled />
                <MenuItem value="waiting">Waiting</MenuItem>
                <MenuItem value="progress">Progress</MenuItem>
                <MenuItem value="complete">Complete</MenuItem>
              </TextField>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" type="submit">
            Simpan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogUpdate;
