import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  DashboardHcis,
  DashboardManager,
  DashboardEmployee,
} from "./component";

import { getQuotes } from "../../store/actions";

const Dashboard = ({ auth, getQuotes }) => {
  useEffect(() => {
    getQuotes();
  }, []);

  switch (auth.user.role_name) {
    case "hcis":
      return <DashboardHcis />;
    case "manager":
      return <DashboardManager />;
    case "staff":
      return <DashboardEmployee />;
    default:
      return <DashboardEmployee />;
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { getQuotes })(Dashboard);
