import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";

import Paper from "@material-ui/core/Paper";

const headCells = [
  {
    id: 1,
    numeric: true,
    disablePadding: false,
    label: "NO",
  },
  {
    id: 2,
    numeric: false,
    disablePadding: false,
    label: "Nama Karyawan",
  },
  { id: 3, numeric: false, disablePadding: false, label: "Entitas" },
  { id: 4, numeric: false, disablePadding: false, label: "Tanggal" },
  { id: 5, numeric: false, disablePadding: false, label: "Jam Masuk" },
  { id: 6, numeric: false, disablePadding: false, label: "Jam Pulang" },
  { id: 7, numeric: false, disablePadding: false, label: "Absen Masuk" },
  { id: 8, numeric: false, disablePadding: false, label: "Absen Pulang" },
  { id: 9, numeric: false, disablePadding: false, label: "Pulang Cepat" },
  { id: 10, numeric: false, disablePadding: false, label: "Terlambat" },
  { id: 11, numeric: false, disablePadding: false, label: "Lembur" },
  { id: 12, numeric: false, disablePadding: false, label: "Jam Hadir" },
  { id: 13, numeric: false, disablePadding: false, label: "Lokasi" },
];

function EnhancedTableHead(props) {
  return (
    <TableHead
      style={{
        background: "#285D5F",
      }}
    >
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} padding={"default"}>
            <TableSortLabel style={{ color: "#fff" }}>
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const TableKehadiran = ({ data }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table}>
            <EnhancedTableHead />
            <TableBody>
              {data.data.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="left" component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.company_name}</TableCell>
                    <TableCell align="left">{row.date}</TableCell>
                    <TableCell align="left">{row.in_rules}</TableCell>
                    <TableCell align="left">{row.out_rules}</TableCell>
                    <TableCell align="left">{row.in}</TableCell>
                    <TableCell align="left">{row.out}</TableCell>
                    <TableCell align="left">{row.out_fast}</TableCell>
                    <TableCell align="left">{row.late}</TableCell>
                    <TableCell align="left">{row.overtime}</TableCell>
                    <TableCell align="left">{row.attend_time}</TableCell>
                    <TableCell align="left">{row.location}</TableCell>
                  </TableRow>
                );
              })}
              {data.data.length === 0 && !data.loading && (
                <TableRow>
                  <TableCell align="center" colSpan={14}>
                    Data tidak tersedia
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};
export default TableKehadiran;
