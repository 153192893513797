/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  TextField,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { sendEditTugasBelumTerlaksana } from "../../../../../../../store/actions";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(3),
  },
}));

const validationSchema = yup.object().shape({
  name: yup.string().required("Data Wajib diisi"),
});

const DialogEditTugasBelumTerlaksana = ({
  dialog,
  onClose,
  sendEditTugasBelumTerlaksana,
  auth,
}) => {
  const classes = useStyles();
  const params = useParams();

  const { handleSubmit, register, errors, setValue } = useForm({
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    if (auth.user.id === parseInt(params.userId)) {
      sendEditTugasBelumTerlaksana(data, dialog.value.id);
    } else {
      sendEditTugasBelumTerlaksana(data, dialog.value.id, params.id);
    }
    onClose();
  };

  useEffect(() => {
    if (dialog.open) {
      setValue("name", dialog.value.name);
    }
  }, [dialog]);

  return (
    <Dialog open={dialog.open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClose={onClose}>Edit Tugas Belum Terlaksana</DialogTitle>
        <DialogContent dividers>
          <TextField
            fullWidth
            className={classes.textField}
            label="Edit Name Tugas Belum Terlaksana"
            inputRef={register}
            name="name"
            error={!!errors.name}
            helperText={!!errors.name && errors.name.message}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" type="submit">
            Save Changes
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { sendEditTugasBelumTerlaksana })(
  DialogEditTugasBelumTerlaksana
);
