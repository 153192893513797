import axios from "../../axios-orders";
import { setAlert } from "./alert";
import {
  SET_LOADING,
  SET_REIMBURSE,
  SET_DAFTAR_REIMBURSE,
  SET_EDIT_REIMBURSE,
  SET_LOADING_DONE,
  SET_LOADING_DATA,
} from "./actionTypes";

export const setLoading = () => {
  return {
    type: SET_LOADING,
  };
};

export const setLoadingData = () => {
  return {
    type: SET_LOADING_DATA,
  };
};

export const setLoadingDone = () => {
  return {
    type: SET_LOADING_DONE,
  };
};

export const getReimburse = (page = 1, size = 10) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/staff/user/employee/position/own/reimburse?page=${page}&page_size=${size}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );

    dispatch({
      type: SET_REIMBURSE,
      payload: res.data.data,
      current_page: page,
      has_previous_page: res.data.has_previous_page,
      has_next_page: res.data.has_next_page,
      previous_page: res.data.previous_page,
      next_page: res.data.next_page,
      last_page: res.data.last_page,
      total_item: res.data.total_item,
    });
  } catch (error) {
    dispatch(setAlert("Can't get data reimburse!", "error"));
  }
};

export const getDaftarReimburse = (
  isHcis = false,
  company = "",
  status = "",
  year = "",
  page = 1,
  size = 10
) => async (dispatch) => {
  dispatch(setLoading());
  let url;
  if (isHcis) {
    url = `/hcis/user/employee/position/all/reimburse?page=${page}&page_size=${size}&company_id=${company}&status=${status}&year=${year}`;
  } else {
    url = `/manager/user/employee/position/reimburse/company?page=${page}&page_size=${size}&status=${status}&year=${year}`;
  }
  try {
    const res = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch(setLoadingDone());
    dispatch({
      type: SET_DAFTAR_REIMBURSE,
      payload: res.data.data,
      current_page: page,
      has_previous_page: res.data.has_previous_page,
      has_next_page: res.data.has_next_page,
      previous_page: res.data.previous_page,
      next_page: res.data.next_page,
      last_page: res.data.last_page,
      total_item: res.data.total_item,
    });
  } catch (error) {
    dispatch(setAlert("Can't get data reimburse!", "error"));
  }
};

export const getEditReimburse = (id) => async (dispatch) => {
  try {
    const res = await axios.get(
      `/staff/user/employee/position/own/reimburse/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );

    dispatch({
      type: SET_EDIT_REIMBURSE,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(setAlert("Can't get data reimburse!", "error"));
  }
};

export const sendReimburse = (data, history) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const res = await axios.post(
      `/staff/user/employee/position/own/reimburse `,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );

    dispatch(setAlert("Form Reimburse Berhasil Di Buat", "success"));
    history.push("/reimburse");
    dispatch(setLoadingDone());
  } catch (error) {
    dispatch(setAlert("Form Reimburse Gagal Di Buat", "error"));
  }
};

export const sendEditReimburse = (id, data, history) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const res = await axios.put(
      `/staff/user/employee/position/own/reimburse/${id}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );

    dispatch(setAlert("Form Reimburse Berhasil Di Edit", "success"));
    history.push("/reimburse");
    dispatch(setLoadingDone());
  } catch (error) {
    dispatch(setAlert("Form Reimburse Gagal Di Edit", "error"));
  }
};

export const deleteReimburse = (id) => async (dispatch) => {
  dispatch(setLoadingData());
  try {
    const res = await axios.delete(
      `/staff/user/employee/position/own/reimburse/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      }
    );

    dispatch(setAlert("Data Berhasil Di Hapus", "success"));
    dispatch(getReimburse());
  } catch (error) {
    dispatch(setAlert("Data Gagal Di Hapus", "error"));
  }
};

export const changeStatusReimburse = (
  id,
  type,
  data,
  isHcis = false,
  isFinance = false
) => async (dispatch) => {
  let message;
  let url;
  if (type === "confirm") {
    message = "Berhasil Di Setujui";
  } else if (type === "rejected") {
    message = "Berhasil Di Batalkan";
  } else {
    message = "Status Berhasil Di Ubah Menjadi Transfer";
  }

  if (isFinance) {
    url = `/hcis/user/employee/position/reimburse/${id}`;
  } else {
    url = `/manager/user/employee/position/reimburse/${id}/action`;
  }
  dispatch(setLoading());
  try {
    const res = await axios.patch(url, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch(setLoadingDone());
    dispatch(setAlert(message, "success"));
    if (isHcis) {
      dispatch(getDaftarReimburse(true));
    } else {
      dispatch(getDaftarReimburse());
    }
  } catch (error) {
    dispatch(setAlert("Something Went Wrong", "error"));
  }
};
