import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { getNews } from "../../store/actions";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardActions,
  CardContent,
  Button,
  CardHeader,
  Box,
  Divider,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
} from "@material-ui/core";
import BorderColorRoundedIcon from "@material-ui/icons/BorderColorRounded";
import InfiniteScroll from "react-infinite-scroll-component";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";

//Components
import CustomRouterLink from "../../components/CustomRouterLink/CustomRouterLink";
import { capitalize } from "../../shared/utility";
import { DialogAlert } from "./component";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(30),
  },
  card: {
    margin: theme.spacing(1.25),
  },
  iconBuatBerita: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: "15px",
  },
  small: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: theme.spacing(0.5),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  overflow: {
    height: "50px",
    overflow: "hidden",
  },
  btnHapus: {
    color: theme.palette.error.main,
  },
}));

const Berita = ({ auth, news, getNews }) => {
  const classes = useStyles();

  const [hasMore, setHasMore] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialogAlert, setOpenDialogAlert] = useState({
    open: false,
    id: null,
  });
  const [menuItemId, setMenuItemId] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuItemId(null);
  };

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem component={CustomRouterLink} to={`/edit-berita/${menuItemId}`}>
        Edit
      </MenuItem>
      <MenuItem
        className={classes.btnHapus}
        onClick={() =>
          setOpenDialogAlert({
            open: true,
            id: menuItemId,
          })
        }
      >
        Hapus
      </MenuItem>
    </Menu>
  );

  const fetchMoreData = () => {
    if (news.data.length === news.totalNews) {
      setHasMore(false);
      return;
    }

    let id = news.data[news.data.length - 1].id;

    if (id === -1) {
      id = "";
    }

    getNews(id);
  };

  useEffect(() => {
    getNews("", true);
  }, []);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3">Kabar EOA</Typography>
      <Grid container justify="center">
        <Grid item xs={8}>
          {auth.user.role_name === "hcis" && (
            <Card className={classes.card}>
              <CardContent>
                <Button
                  fullWidth
                  startIcon={<BorderColorRoundedIcon />}
                  variant="text"
                  component={CustomRouterLink}
                  to="/buat-berita"
                >
                  Buat Berita
                </Button>
              </CardContent>
            </Card>
          )}
          {news.data.length === 0 && !news.loading ? (
            <Card className={classes.card}>
              <CardContent>
                <Typography align="center" variant="h4">
                  Berita Kosong
                </Typography>
              </CardContent>
            </Card>
          ) : news.loading ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <InfiniteScroll
              style={{ overflow: "none" }}
              dataLength={news.data.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={
                <div style={{ textAlign: "center" }}>
                  <CircularProgress />
                </div>
              }
            >
              {news.data.map((i, index) => (
                <Card className={classes.card} key={index}>
                  <CardHeader
                    title={
                      <Typography variant="h3">
                        {capitalize(i.title)}
                      </Typography>
                    }
                    subheader={
                      <Box>
                        <Typography color="primary" variant="caption">
                          {i.created_at}
                        </Typography>
                      </Box>
                    }
                    action={
                      auth.user.role_name === "hcis" && (
                        <IconButton
                          aria-label="options"
                          onClick={(event) => {
                            handleClick(event);
                            setMenuItemId(i.id);
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      )
                    }
                  />
                  <Divider />
                  <CardContent className={classes.overflow}>
                    <HTMLEllipsis
                      unsafeHTML={i.body}
                      maxLine="6"
                      ellipsis="..."
                      basedOn="letters"
                    />{" "}
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      color="primary"
                      component={CustomRouterLink}
                      to={`/berita/${i.id}`}
                    >
                      read more...
                    </Button>
                  </CardActions>
                </Card>
              ))}
            </InfiniteScroll>
          )}
        </Grid>
      </Grid>
      <DialogAlert
        dialog={openDialogAlert}
        onClose={() => {
          setOpenDialogAlert({ open: false, id: null });
          setAnchorEl(null);
        }}
      />
      {renderMenu}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    news: state.news,
  };
};

export default connect(mapStateToProps, { getNews })(Berita);
