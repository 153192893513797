import {
  SET_EMPLOYEE,
  SET_TOTAL_EMPLOYEE,
  SET_TOTAL_EMPLOYEE_GENDER,
} from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  employee: [],
  totalEmployee: null,
  totalMale: null,
  totalFemale: null,

  loadingEmployee: true,

  current_page: 1,
  has_previous_page: false,
  has_next_page: false,
  previous_page: 0,
  next_page: 2,
  last_page: 1,
  total_item: 0,
};

const setEmployee = (state, action) => {
  return updateObject(state, {
    employee: action.payload,
    loadingEmployee: false,
    current_page: action.current_page,
    has_previous_page: action.has_previous_page,
    has_next_page: action.has_next_page,
    previous_page: action.previous_page,
    next_page: action.next_page,
    last_page: action.last_page,
    total_item: action.total_item,
  });
};
const setTotalEmployee = (state, action) => {
  return updateObject(state, {
    totalEmployee: action.payload,
  });
};
const setTotalEmployeeGender = (state, action) => {
  return updateObject(state, {
    totalMale: action.payload.male,
    totalFemale: action.payload.female,
    totalEmployee: action.payload.totalEmployee,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_EMPLOYEE:
      return setEmployee(state, action);
    case SET_TOTAL_EMPLOYEE:
      return setTotalEmployee(state, action);
    case SET_TOTAL_EMPLOYEE_GENDER:
      return setTotalEmployeeGender(state, action);
    default:
      return state;
  }
};

export default reducer;
