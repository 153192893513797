/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  CardContent,
  Card,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Select from 'react-select';
import axios from '../../axios-orders';
import fileDownload from 'js-file-download';

// actions
import {
  getAllOvertimeManager,
  getCompany,
  putApproveOvertimeManager,
  putRejectOvertimeManager,
  setAlert,
} from '../../store/actions';

// Components
import Breadscrumb from '../../components/UI/Breadcrumb/Breadcrumb';
import { Table, DialogApprove, DialogReject } from './component';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  marLeft: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  card: {
    margin: theme.spacing(1.25),
  },
  filterEntitas: {
    '& .css-1uccc91-singleValue': {
      overflow: 'visible',
    },
    marginRight: 5,
  },
  filterTypeRequest: {
    '& .css-1uccc91-singleValue': {
      overflow: 'visible',
    },
    marginRight: 5,
  },
  filterStatus: {
    '& .css-1uccc91-singleValue': {
      overflow: 'visible',
    },
    marginRight: 5,
  },
  filterYear: {
    '& .css-1uccc91-singleValue': {
      overflow: 'visible',
    },
    marginRight: 5,
  },
  pagination: {
    marginTop: theme.spacing(3),
  },
  pag: {
    '& .MuiPagination-ul': {
      justifyContent: 'center',
    },
  },
}));

const statusType = [
  { label: 'Waiting', value: 'waiting' },
  { label: 'Approve', value: 'approved' },
  { label: 'Reject', value: 'reject' },
  { label: 'Complete', value: 'complete' },
];

const years = [
  { label: '2020', value: '2020' },
  { label: '2021', value: '2021' },
  { label: '2022', value: '2022' },
  { label: '2023', value: '2023' },
  { label: '2024', value: '2024' },
  { label: '2025', value: '2025' },
  { label: '2026', value: '2026' },
  { label: '2027', value: '2027' },
  { label: '2028', value: '2028' },
  { label: '2029', value: '2029' },
  { label: '2030', value: '2030' },
  { label: '2031', value: '2031' },
  { label: '2032', value: '2032' },
  { label: '2033', value: '2033' },
  { label: '2034', value: '2034' },
  { label: '2035', value: '2035' },
];

const scrumbs = [
  { name: 'Dashboard', link: '/' },
  { name: 'Daftar Lembur', link: '' },
];

const DaftarLembur = ({
  overtime,
  getAllOvertimeManager,
  auth,
  getCompany,
  company,
  putApproveOvertimeManager,
  putRejectOvertimeManager,
  setAlert,
}) => {
  const classes = useStyles();

  const [dialogApprove, setDialogApprove] = useState({
    open: false,
    id: null,
    positionId: null,
  });
  const [dialogReject, setDialogReject] = useState({
    open: false,
    id: null,
    positionId: null,
  });
  const [page, setPage] = useState(1);
  const [entitas, setEntitas] = useState('');
  const [status, setStatus] = useState('');
  const [year, setYear] = useState('');
  const [loadingDownload, setLoadingDownload] = useState(false);

  const handlePage = (e, value) => {
    setPage(value);
  };

  const handleDownload = async () => {
    setLoadingDownload(true);
    try {
      const { data } = await axios.get(
        `/manager/user/employee/position/overtime/export?companyId=${entitas}&status=${status}&year=${year}`,
        {
          headers: {
            'Content-Type': 'application/vnd.ms-excel',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
          responseType: 'blob',
        }
      );
      fileDownload(data, `daftar-lembur-${status}-${year}.xls`);
      setLoadingDownload(false);
    } catch (error) {
      setLoadingDownload(false);
      setAlert('something went wrong', 'error');
    }
  };

  useEffect(() => {
    getAllOvertimeManager(page, 10, status, year, entitas);
  }, [page]);

  useEffect(() => {
    getAllOvertimeManager(1, 10, status, year, entitas);
  }, [status, year, entitas]);

  useEffect(() => {
    getCompany();
  }, [getCompany]);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.marLeft}>
        Daftar Lembur
      </Typography>
      <Breadscrumb crumb={scrumbs} />
      <Grid container spacing={2} style={{ marginLeft: '1px' }}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            {auth.user.role_name === 'hcis' && (
              <Grid item xs={12} sm={3}>
                <Select
                  className={classes.filterEntitas}
                  isClearable={true}
                  isSearchable={true}
                  placeholder="Entitas..."
                  options={company.company}
                  onChange={(e) =>
                    e === null ? setEntitas('') : setEntitas(e.value)
                  }
                />
              </Grid>
            )}

            <Grid item xs={12} sm={3}>
              <Select
                className={classes.filterStatus}
                options={statusType}
                isClearable={true}
                isSearchable={true}
                placeholder="Status..."
                onChange={(e) =>
                  e === null ? setStatus('') : setStatus(e.value)
                }
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Select
                className={classes.filterYear}
                options={years}
                isClearable={true}
                isSearchable={true}
                placeholder="Year..."
                onChange={(e) => (e === null ? setYear('') : setYear(e.value))}
              />
            </Grid>
            {(auth.user.role_name === 'hcis' ||
              auth.user.role_name === 'manager') && (
              <Grid item xs={12} sm={3}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleDownload}
                >
                  {loadingDownload ? <CircularProgress /> : 'Download'}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Card className={classes.card}>
        <CardContent>
          <Table
            data={overtime.pagination.data}
            handleApprove={setDialogApprove}
            handleReject={setDialogReject}
          />
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.pagination}
          >
            <Grid item xs={8}>
              <Pagination
                className={classes.pag}
                count={overtime.pagination.last_page}
                page={page}
                onChange={handlePage}
                color="primary"
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DialogReject
        dialog={dialogReject}
        onClose={() =>
          setDialogReject({ open: false, id: null, positionId: null })
        }
        action={putRejectOvertimeManager}
        page={page}
        entitas={entitas}
        status={status}
        year={year}
      />
      <DialogApprove
        dialog={dialogApprove}
        onClose={() =>
          setDialogApprove({ open: false, id: null, positionId: null })
        }
        action={putApproveOvertimeManager}
        page={page}
        entitas={entitas}
        status={status}
        year={year}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  overtime: state.overtime,
  auth: state.auth,
  company: state.company,
});

export default connect(mapStateToProps, {
  getAllOvertimeManager,
  getCompany,
  putApproveOvertimeManager,
  putRejectOvertimeManager,
  setAlert,
})(DaftarLembur);
