/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import clsx from "clsx";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  TextField,
  DialogContent,
  MenuItem,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  getTypeSocialMedia,
  sendSocialMedia,
  sendEditSocialMedia,
  deleteSocialMedia,
} from "../../../../../store/actions";

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
  cardActions: {
    justifyContent: "space-between",
  },
  btnDelete: {
    color: "#fff",
    background: theme.palette.red.color500,
    "&:hover": {
      backgroundColor: theme.palette.red.color700,
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const validationSchema = yup.object().shape({
  social_item_id: yup
    .number()
    .required("Sosial media wajib diisi")
    .typeError("Sosial media wajib diisi"),
  link: yup.string().required("Link wajib diisi"),
});

const DialogSocialMedia = ({
  dialog,
  onClose,
  getTypeSocialMedia,
  detailEmployee,
  sendSocialMedia,
  sendEditSocialMedia,
  deleteSocialMedia,
  hcis,
}) => {
  const classes = useStyles();
  const params = useParams();

  const { register, errors, handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  const handleDelete = (id) => {
    if (hcis) {
      deleteSocialMedia(id, params.userId);
    } else {
      deleteSocialMedia(id);
    }
    onClose();
  };

  const onSubmit = (data, e) => {
    if (dialog.edit) {
      if (hcis) {
        sendEditSocialMedia(data, dialog.value.id, params.userId);
      } else {
        sendEditSocialMedia(data, dialog.value.id);
      }
    } else {
      if (hcis) {
        sendSocialMedia(data, params.userId);
      } else {
        sendSocialMedia(data);
      }
    }
    e.target.reset();
    setValue("social_item_id", "");
    onClose();
  };

  useEffect(() => {
    getTypeSocialMedia();
  }, []);

  useEffect(() => {
    if (dialog.edit) {
      setValue("social_item_id", dialog.value.social_item_id);
      setValue("link", dialog.value.link);
      return;
    }
    setValue("social_item_id", "");
    setValue("link", "");
  }, [dialog.edit]);

  return (
    <Dialog open={dialog.open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClose={onClose}>
          {!dialog.edit ? "Tambah" : "Edit"} Kontak /Social Media
        </DialogTitle>
        <DialogContent dividers>
          <Controller
            control={control}
            name="social_item_id"
            defaultValue=""
            render={({ onChange, value }) => (
              <TextField
                className={classes.field}
                fullWidth
                select
                defaultValue=""
                label="Kontak /Social Media"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                error={!!errors.social_item_id}
                helperText={
                  !!errors.social_item_id && errors.social_item_id.message
                }
              >
                <MenuItem value="" disabled></MenuItem>
                {detailEmployee.typeSocialMedia.map((data) => (
                  <MenuItem value={data.id} key={data.id}>
                    {data.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <TextField
            className={classes.field}
            fullWidth
            name="link"
            label="Nomor /URL"
            inputRef={register}
            error={!!errors.link}
            helperText={!!errors.link && errors.link.message}
          />
        </DialogContent>
        <DialogActions className={clsx({ [classes.cardActions]: dialog.edit })}>
          {dialog.edit && (
            <Button
              className={classes.btnDelete}
              variant="contained"
              onClick={() => handleDelete(dialog.value.id)}
            >
              Delete Data
            </Button>
          )}
          <Button variant="contained" color="primary" type="submit">
            {!dialog.edit ? "Simpan" : "Edit Data"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    detailEmployee: state.detailEmployee,
  };
};

export default connect(mapStateToProps, {
  getTypeSocialMedia,
  sendSocialMedia,
  sendEditSocialMedia,
  deleteSocialMedia,
})(DialogSocialMedia);
