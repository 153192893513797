import axios from "../../axios-orders";
import { setAlert } from "./alert";
import {
  SET_STATUS_JOB,
  SET_STATUS_FAMILY,
  SET_STATUS_ROLE,
  SET_JOB_LIST,
  SET_SUPERVISOR_LIST,
} from "./actionTypes";

export const getSupervisorList = (position_id) => async (dispatch) => {
  try {
    const res = await axios({
      url: `/manager/user/employee/position/${position_id}/supervisor-list`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_SUPERVISOR_LIST,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};

export const getSupervisorByCompany = (company_id) => async (dispatch) => {
  try {
    const res = await axios({
      url: `hcis/user/employee/position/company/${company_id}/supervisor`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_SUPERVISOR_LIST,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};

export const getJobList = (role) => async (dispatch) => {
  try {
    const res = await axios({
      url: `/manager/job`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_JOB_LIST,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};

export const addNewJob = (data) => async (dispatch) => {
  try {
    await axios.post(`/hcis/job`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch(setAlert("Job Title berhasil ditambahkan", "success"));
    dispatch(getJobList());
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};

export const getStatusRole = () => async (dispatch) => {
  try {
    const res = await axios({
      url: "/manager/role",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_STATUS_ROLE,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};

export const getStatusJob = () => async (dispatch) => {
  try {
    const res = await axios({
      url: "/staff/employee/status",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch(setStatusJob(res.data.data));
  } catch (err) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};

const setStatusJob = (data) => {
  return {
    type: SET_STATUS_JOB,
    payload: data,
  };
};

export const getStatusFamily = () => async (dispatch) => {
  try {
    const res = await axios({
      url: "/staff/employee/family/status",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_STATUS_FAMILY,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};
