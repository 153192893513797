import axios from "../../axios-orders";
import { setAlert } from "./alert";
import { SET_DETAIL_INFORMATION } from "./actionTypes";

export const getDetailInformation = (idPosition, role) => async (dispatch) => {
  let url;
  if (role === "staff") {
    url = `/staff/user/employee/position/own/information/basic`;
  } else {
    url = `staff/user/employee/position/${idPosition}/information/basic`;
  }
  try {
    const res = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch({
      type: SET_DETAIL_INFORMATION,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(setAlert("Can't get data detail information", "error"));
  }
};
