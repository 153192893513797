import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: false,
  loadingData: false,
  loadingEdit: false,
  data: [],
  dataEdit: null,
  allData: [],
};

const setLoading = (state, action) => {
  return updateObject(state, { loading: true });
};
const setLoadingData = (state, action) => {
  return updateObject(state, { loadingData: true });
};
const setLoadingDone = (state, action) => {
  return updateObject(state, { loading: false });
};

const setReimburse = (state, action) => {
  return updateObject(state, {
    data: action.payload,
    loadingData: false,
    current_page: action.current_page,
    has_previous_page: action.has_previous_page,
    has_next_page: action.has_next_page,
    previous_page: action.previous_page,
    next_page: action.next_page,
    last_page: action.last_page,
    total_item: action.total_item,
  });
};

const setDaftarReimburse = (state, action) => {
  return updateObject(state, {
    allData: action.payload,
    loading: false,
    current_page: action.current_page,
    has_previous_page: action.has_previous_page,
    has_next_page: action.has_next_page,
    previous_page: action.previous_page,
    next_page: action.next_page,
    last_page: action.last_page,
    total_item: action.total_item,
  });
};

const setEditReimburse = (state, action) => {
  return updateObject(state, {
    dataEdit: action.payload,
    loadingEdit: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOADING:
      return setLoading(state, action);
    case actionTypes.SET_LOADING_DATA:
      return setLoadingData(state, action);
    case actionTypes.SET_LOADING_DONE:
      return setLoadingDone(state, action);
    case actionTypes.SET_REIMBURSE:
      return setReimburse(state, action);
    case actionTypes.SET_EDIT_REIMBURSE:
      return setEditReimburse(state, action);
    case actionTypes.SET_DAFTAR_REIMBURSE:
      return setDaftarReimburse(state, action);
    default:
      return state;
  }
};

export default reducer;
