import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  CardActions,
  MenuItem,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Actions
import { postOvertime } from "../../../store/actions";

// Components
import Breadcrumb from "../../../components/UI/Breadcrumb/Breadcrumb";
import DateTimePicker from "../../../components/UI/DateTimePicker/DateTimePicker";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  marLeft: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  card: {
    margin: theme.spacing(5, 1.25, 1.25),
  },
  pad: {
    padding: theme.spacing(1.25),
  },
  jamField: {
    marginTop: theme.spacing(2),
  },
  paddingRight: {
    paddingRight: theme.spacing(1),
  },
  paddingLeft: {
    paddingLeft: theme.spacing(1),
  },
  textArea: {
    padding: theme.spacing(2),
  },
  time: {
    marginTop: theme.spacing(1),
  },
}));

const crumbs = [
  { name: "Dashboard", link: "/" },
  { name: "Pengajuan Lembur", link: "" },
];

const validationSchema = yup.object().shape({
  location: yup.string().required("lokasi lembur harus dipilih"),
  detail: yup.string().required("Detail pekerjaan harus diisi"),
  start: yup.string().required("Tanggal harus diisi"),
  end: yup.string().required("Tanggal harus diisi"),
});

const FormOvertime = ({ postOvertime }) => {
  const classes = useStyles();
  const history = useHistory();

  const { handleSubmit, register, errors, control } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    const formData = {
      detail: data.detail,
      location: data.location,
      start: `${moment(data.start).format("YYYY-MM-DD HH:mm")}`,
      end: `${moment(data.end).format("YYYY-MM-DD HH:mm")}`,
    };

    postOvertime(formData, history);
  };

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.marLeft}>
        Pengajuan Lembur
      </Typography>
      <Breadcrumb crumb={crumbs} />
      <Card className={classes.card}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <Grid container>
              <Grid item xs={12} className={classes.pad}>
                <TextField
                  fullWidth
                  multiline
                  rows={10}
                  label="Detail Pekerjaan"
                  variant="outlined"
                  inputRef={register}
                  name="detail"
                  error={!!errors.detail}
                  helperText={!!errors.detail && errors.detail.message}
                />
                <Grid container>
                  <Grid item xs={6} className={classes.paddingRight}>
                    <Controller
                      control={control}
                      name="start"
                      defaultValue={null}
                      render={({ onChange, value }) => (
                        <DateTimePicker
                          className={classes.datetime}
                          value={value}
                          onChange={onChange}
                          label="Mulai Lembur"
                        />
                      )}
                    />
                    {!!errors.start && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        *Waktu lembur harus diisi
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={6} className={classes.paddingLeft}>
                    <Controller
                      control={control}
                      name="end"
                      defaultValue={null}
                      render={({ onChange, value }) => (
                        <DateTimePicker
                          className={classes.datetime}
                          value={value}
                          onChange={onChange}
                          label="Akhir Lembur"
                        />
                      )}
                    />
                    {!!errors.end && (
                      <Typography variant="caption" style={{ color: "red" }}>
                        *Waktu lembur harus diisi
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.pad}>
                <Controller
                  control={control}
                  name="location"
                  defaultValue=""
                  render={({ onChange, ref }) => (
                    <TextField
                      select
                      fullWidth
                      variant="outlined"
                      label="Lokasi Lembur"
                      defaultValue=""
                      inputRef={ref}
                      onChange={(e) => onChange(e.target.value)}
                      error={!!errors.location}
                      helperText={errors.location?.message}
                    >
                      <MenuItem value="" disabled />
                      <MenuItem value="home">Rumah</MenuItem>
                      <MenuItem value="office">Kantor</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Simpan
            </Button>
          </CardActions>
        </form>
      </Card>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { postOvertime })(FormOvertime);
