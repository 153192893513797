import {
  SET_DASHBOARD_CHART_START,
  SET_DASHBOARD_CHART_SUCCESS,
  SET_DASHBOARD_CHART_FAIL,
} from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loadingChart: false,
  dataChart: {},
  errorChart: false,
};

// Function for dashboard chart

const dashboardChartStart = (state, action) => {
  return updateObject(state, {
    ...state,
    loadingChart: true,
    errorChart: false,
  });
};

const dashboardChartSuccess = (state, action) => {
  return updateObject(state, {
    dataChart: action.payload,
    loadingChart: false,
    errorChart: false,
  });
};

const dashboardChartFail = (state, action) => {
  return updateObject(state, {
    ...state,
    loadingChart: false,
    errorChart: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASHBOARD_CHART_START:
      return dashboardChartStart(state, action);
    case SET_DASHBOARD_CHART_SUCCESS:
      return dashboardChartSuccess(state, action);
    case SET_DASHBOARD_CHART_FAIL:
      return dashboardChartFail(state, action);
    default:
      return state;
  }
};

export default reducer;
