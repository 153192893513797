import {
  GET_PROVINCE,
  GET_CITY,
  GET_DISTRICT,
  GET_VILLAGE,
  GET_CITY_DOMISILI,
  GET_DISTRICT_DOMISILI,
  GET_VILLAGE_DOMISILI,
  GET_CITY_MAIL,
  GET_DISTRICT_MAIL,
  GET_VILLAGE_MAIL,
} from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  province: [],
  city: [],
  district: [],
  village: [],
  loadingProvince: true,
  loadingCity: true,
  loadingDistrict: true,
  loadingVillage: true,

  // DOMISILI
  city_domisili: [],
  district_domisili: [],
  village_domisili: [],
  loadingCity_domisili: true,
  loadingDistrict_domisili: true,
  loadingVillage_domisili: true,

  // MAIL
  city_mail: [],
  district_mail: [],
  village_mail: [],
  loadingCity_mail: true,
  loadingDistrict_mail: true,
  loadingVillage_mail: true,
};

// KTP
const setProvince = (state, action) => {
  return updateObject(state, {
    province: action.payload,
    loadingProvince: false,
  });
};

const setCity = (state, action) => {
  return updateObject(state, { city: action.payload, loadingCity: false });
};

const setDistrict = (state, action) => {
  return updateObject(state, {
    district: action.payload,
    loadingDistrict: false,
  });
};

const setVillage = (state, action) => {
  return updateObject(state, {
    village: action.payload,
    loadingVillage: false,
  });
};

// DOMISILI
const setCityDomisili = (state, action) => {
  return updateObject(state, {
    city_domisili: action.payload,
    loadingCity_domisili: false,
  });
};

const setDistrictDomisili = (state, action) => {
  return updateObject(state, {
    district_domisili: action.payload,
    loadingDistrict_domisili: false,
  });
};

const setVillageDomisili = (state, action) => {
  return updateObject(state, {
    village_domisili: action.payload,
    loadingVillage_domisili: false,
  });
};

// MAIL
const setCityMail = (state, action) => {
  return updateObject(state, {
    city_mail: action.payload,
    loadingCity_mail: false,
  });
};

const setDistrictMail = (state, action) => {
  return updateObject(state, {
    district_mail: action.payload,
    loadingDistrict_mail: false,
  });
};

const setVillageMail = (state, action) => {
  return updateObject(state, {
    village_mail: action.payload,
    loadingVillage_mail: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // KTP
    case GET_PROVINCE:
      return setProvince(state, action);
    case GET_CITY:
      return setCity(state, action);
    case GET_DISTRICT:
      return setDistrict(state, action);
    case GET_VILLAGE:
      return setVillage(state, action);

    // DOMISILI
    case GET_CITY_DOMISILI:
      return setCityDomisili(state, action);
    case GET_DISTRICT_DOMISILI:
      return setDistrictDomisili(state, action);
    case GET_VILLAGE_DOMISILI:
      return setVillageDomisili(state, action);

    // MAIL
    case GET_CITY_MAIL:
      return setCityMail(state, action);
    case GET_DISTRICT_MAIL:
      return setDistrictMail(state, action);
    case GET_VILLAGE_MAIL:
      return setVillageMail(state, action);

    default:
      return state;
  }
};

export default reducer;
