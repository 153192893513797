import axios from "../../axios-orders";
import { setAlert } from "./alert";
import { SET_TASK_START, SET_TASK_FAIL, SET_TASK_SUCCESS } from "./actionTypes";

export const setTaskStart = () => {
  return {
    type: SET_TASK_START,
  };
};

export const getTask = () => async (dispatch) => {
  dispatch(setTaskStart());
  try {
    const res = await axios.get(`/staff/task`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch({
      type: SET_TASK_SUCCESS,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch({
      type: SET_TASK_FAIL,
      payload: error.response.data.message,
    });
    dispatch(setAlert("Can't get data task!", "error"));
  }
};

export const createTask = (data) => async (dispatch) => {
  dispatch(setTaskStart());
  try {
    const res = await axios.post(`/staff/task`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch(setAlert("Task berhasil dibuat", "success"));
    dispatch(getTask());
  } catch (error) {
    dispatch({
      type: SET_TASK_FAIL,
      payload: error.response.data.message,
    });
    dispatch(setAlert("Task Gagal Di Buat", "error"));
  }
};

export const editTask = (id, data) => async (dispatch) => {
  dispatch(setTaskStart());
  try {
    const res = await axios.put(`/staff/task/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch(setAlert("Task Berhasil Di Edit", "success"));
    dispatch(getTask());
  } catch (error) {
    dispatch({
      type: SET_TASK_FAIL,
      payload: error.response.data.message,
    });
    dispatch(setAlert("Task Gagal Di Edit", "error"));
  }
};

export const deleteTask = (id) => async (dispatch) => {
  dispatch(setTaskStart());
  try {
    const res = await axios.delete(`/staff/task/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch(setAlert("Task Berhasil Di Hapus", "success"));
    dispatch(getTask());
  } catch (error) {
    dispatch({
      type: SET_TASK_FAIL,
      payload: error.response.data.message,
    });
    dispatch(setAlert("Task Gagal Di Hapus", "error"));
  }
};
