import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Breadcrumbs, Typography, Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1)
  },
}));

const Breadcrumb = (props) => {
  const { crumb } = props;

  const classes = useStyles();

  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.root}>
      {crumb.map((value, index) => {
        if (index === crumb.length - 1) {
          return (
            <Typography key={index} color="textPrimary">
              {value.name}
            </Typography>
          );
        }
        return (
          <Link
            key={index}
            color="primary"
            component={RouterLink}
            to={value.link}
          >
            {value.name}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
