import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: true,
  loadingOne: true,
  data: [],
  dataOne: null,
  totalNews: null,
};

const setNews = (state, action) => {
  return updateObject(state, {
    loading: false,
    data: state.data.concat(action.payload),
    totalNews: action.totalNews,
  });
};

const setOneNews = (state, action) => {
  return updateObject(state, {
    loading: false,
    loadingOne: false,
    dataOne: action.payload,
    data: [],
    totalNews: null,
  });
};

const clearNews = (state, action) => {
  return updateObject(state, {
    loading: true,
    loadingOne: true,
    data: [],
    totalNews: null,
    dataOne: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NEWS:
      return setNews(state, action);
    case actionTypes.SET_ONE_NEWS:
      return setOneNews(state, action);
    case actionTypes.CLEAR_NEWS:
      return clearNews(state, action);
    default:
      return state;
  }
};

export default reducer;
