import { SET_COMPANY } from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  company: [],
  loadingCompany: true,
};

const setCompany = (state, action) => {
  return updateObject(state, {
    company: action.payload,
    loadingCompany: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY:
      return setCompany(state, action);
    default:
      return state;
  }
};

export default reducer;
