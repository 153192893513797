import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Zoom from "react-reveal/Zoom";

const useStyles = makeStyles((theme) => ({
  contentName: {
    paddingTop: theme.spacing(30),
    paddingLeft: theme.spacing(6),
    color: "#fff",
    fontSize: 40,
  },

  colorWhite: {
    color: "#fff",
  },
  textCore: {
    color: "#fff",
    fontWeight: "600",
    // animation: "$myEffect 2s ease-out ",
  },

  // "@keyframes myEffect": {
  //   "0%": {
  //     transform: "scale(0.5, 0.5)",
  //   },

  //   "100%": {
  //     transform: "scale(1, 1)",
  //   },
  // },
}));

const TypingAnimation = () => {
  const classes = useStyles();

  return (
    <div className={classes.contentName}>
      <Typography gutterBottom variant="h4" className={classes.colorWhite}>
        3 Core Value EOA
      </Typography>
      <div>
        <Zoom duration={4000}>
          <Typography variant="h1" className={classes.textCore} gutterBottom>
            Sincerity{" "}
          </Typography>
          <Typography variant="h1" className={classes.textCore} gutterBottom>
            Humbleness{" "}
          </Typography>
          <Typography variant="h1" className={classes.textCore}>
            Loving Kindness
          </Typography>
        </Zoom>
      </div>
    </div>
  );
};

export default React.memo(TypingAnimation);
