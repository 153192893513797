/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardActions,
  CardContent,
  Button,
  Divider,
  CardHeader,
  Box,
  CardActionArea,
  CircularProgress,
} from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";

import { connect } from "react-redux";
import {
  getNews,
  uploadPhoto,
  getProfilePicture,
} from "../../../../store/actions";

//Components
import CustomRouterLink from "../../../../components/CustomRouterLink/CustomRouterLink";
import DefaultAvatar from "../../../../assets/img/default-avatar.png";
import ToAchieveList from "../ToAchieveList";
import DialogChangeProfile from "../DialogChangeProfile/DialogChangeProfile";

// Utils
import { capitalize } from "../../../../shared/utility";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  padLeft: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  card: {
    margin: theme.spacing(1.25),
  },
  cardQuotes: {
    margin: theme.spacing(1.25),
    backgroundColor: "#285D5F",
    borderRadius: "10px",
    height: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardButton: {
    justifyContent: "center",
    backgroundColor: theme.palette.blue.color50,
    cursor: "pointer",
  },
  cardSticky: {
    position: "sticky",
    top: "20%",
  },
  iconBuatBerita: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: "15px",
  },
  small: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: theme.spacing(0.5),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  overflow: {
    height: "90px",
    overflow: "hidden",
  },
  cardImage: {
    width: "160px",
    height: "160px",
    margin: "8px auto",
    borderRadius: "50%",
  },
  pad: {
    padding: theme.spacing(1.25),
  },
  bismillah: {
    fontFamily: "Amiri",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  input: {
    display: "none",
  },
}));

const Dashboard = ({
  auth,
  quotes,
  getNews,
  news,
  uploadPhoto,
  getProfilePicture,
  profilePicture,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [srcProfile, setSrcProfile] = useState({ open: false, src: null });

  const [hasMore, setHasMore] = useState(true);

  const uploaderTrigger = () => {
    document.getElementById("photoUploader").click();
  };

  const uploaderImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setSrcProfile({ open: true, src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const fetchMoreData = () => {
    if (news.data.length === news.totalNews) {
      setHasMore(false);
      return;
    }

    let id = news.data[news.data.length - 1].id;

    if (id === -1) {
      id = "";
    }

    getNews(id);
  };

  useEffect(() => {
    getNews("", true);
    getProfilePicture();
  }, []);
  return (
    <Container maxWidth="xl" className={classes.root}>
      <Box textAlign="center" mb={2}>
        <Typography variant="h2" gutterBottom className={classes.bismillah}>
          بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيْم
        </Typography>
        <Typography variant="subtitle1">Dashboard</Typography>
      </Box>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12}>
          <Card className={classes.cardQuotes}>
            <CardContent>
              <Typography style={{ color: "#fff" }} variant="h3" align="center">
                {!quotes.loadingRandomQuotes && `"${quotes.randomQuotes.name}"`}
              </Typography>
            </CardContent>
          </Card>

          <Grid container>
            <Grid item xs={12} sm={4} className={classes.pad}>
              <Card
                style={{
                  height: "100%",
                }}
              >
                <CardActionArea
                  onClick={() => {
                    history.push(
                      `/detail-employee/${auth.user.position_id}/${auth.user.id}`
                    );
                  }}
                >
                  <Box
                    component="div"
                    style={{
                      backgroundImage: `url(${
                        profilePicture.data === ""
                          ? DefaultAvatar
                          : profilePicture.data
                      })`,
                      width: 150,
                      height: 150,
                      backgroundSize: "cover",
                      backgroundPosition: "top center",
                      borderRadius: "50%",
                      margin: "auto",
                      marginTop: "10px",
                    }}
                  />

                  <CardContent>
                    <Grid container spacing={1} justify="center">
                      <Grid item container justify="center" xs={12}>
                        <Grid item xs="auto">
                          <Typography variant="h5">Nama : &nbsp;</Typography>
                        </Grid>
                        <Grid item xs="auto">
                          <Typography variant="h5">{auth.user.name}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item container justify="center" xs={12}>
                        <Grid item xs="auto">
                          <Typography variant="h5">NIK : &nbsp;</Typography>
                        </Grid>
                        <Grid item xs="auto">
                          <Typography variant="h5">
                            {auth.user.employee_identity || "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
                <Divider />
                <CardActions style={{ textAlign: "center", display: "block" }}>
                  <input
                    accept="image/jpeg,image/png,image/jpg"
                    className={classes.input}
                    id="photoUploader"
                    name="file"
                    type="file"
                    onChange={uploaderImage}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={uploaderTrigger}
                  >
                    Upload Profile
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ToAchieveList />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={12}>
          {news.data.length === 0 && !news.loading ? (
            <Card className={classes.card}>
              <CardContent>
                <Typography align="center" variant="h4">
                  Berita Kosong
                </Typography>
              </CardContent>
            </Card>
          ) : news.loading ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <InfiniteScroll
              style={{ overflow: "none" }}
              dataLength={news.data.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={
                <div style={{ textAlign: "center" }}>
                  <CircularProgress />
                </div>
              }
            >
              {news.data.map((i, index) => (
                <Card className={classes.card} key={index}>
                  <CardHeader
                    title={
                      <Typography variant="h3">
                        {capitalize(i.title)}
                      </Typography>
                    }
                    subheader={
                      <Box>
                        <Typography color="primary" variant="caption">
                          {i.created_at}
                        </Typography>
                      </Box>
                    }
                  />
                  <Divider />
                  <CardContent className={classes.overflow}>
                    <HTMLEllipsis
                      unsafeHTML={i.body}
                      maxLine="6"
                      ellipsis="..."
                      basedOn="letters"
                    />{" "}
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      color="primary"
                      component={CustomRouterLink}
                      to={`/berita/${i.id}`}
                    >
                      read more...
                    </Button>
                  </CardActions>
                </Card>
              ))}
            </InfiniteScroll>
          )}
        </Grid>
      </Grid>
      <DialogChangeProfile
        dialog={srcProfile}
        onClose={() => setSrcProfile({ open: false, src: null })}
        action={uploadPhoto}
        positionId={auth.user.position_id}
        role={auth.user.role_name}
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    quotes: state.quotes,
    news: state.news,
    profilePicture: state.profilePicture,
  };
};

export default connect(mapStateToProps, {
  getNews,
  uploadPhoto,
  getProfilePicture,
})(Dashboard);
