import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { RouteWithLayout } from "./components";
import { connect } from "react-redux";

import {
  Main as MainLayout,
  Login,
  ForgotPassword,
  ResetPassword,
} from "./layouts";

// Views
import {
  Dashboard as DashboardView,
  GantiPassword as GantiPasswordView,
  Berita as BeritaView,
  BeritaDetail as BeritaDetailView,
  Employee as EmployeeView,
  DetailEmployee as DetailEmployeeView,
  BuatBerita as BuatBeritaView,
  EditBerita as EditBeritaView,
  Overview as OverviewView,
  Reimburse as ReimburseView,
  Overtime as OvertimeView,
  FormOvertime as FormOvertimeView,
  FormOvertimeEdit as FormOvertimeEditView,
  FormReimburse as FormReimburseView,
  FormReimburseEdit as FormReimburseEditView,
  DetailRequestLeave as DetailRequestLeaveView,
  RequestLeave as RequestLeaveView,
  FormIdentitas as FormIdentitasView,
  MasukPulang as MasukPulangView,
  Dokumen as DokumenView,
  KelolaKehadiran as KelolaKehadiranView,
  FormEditInformasiPribadi as FormEditInformasiPribadiView,
  FormEditAlamat as FormEditAlamatView,
  FormInformasiPekerjaan as FormInformasiPekerjaanView,
  KelolaQuotes as KelolaQuotesView,
  DaftarIzin as DaftarIzinView,
  DaftarReimburse as DaftarReimburseView,
  DaftarLembur as DaftarLemburView,
  LemburDitugaskan as LemburDitugaskanView,
  DaftarDitugaskan as DaftarDitugaskanView,
  TimeLocation as TimeLocationView,
  FormTambahUser as FormTambahUserView,
  FormLemburDitugaskan as FormLemburDitugaskanView,
} from "./views";

const Routes = ({ auth }) => {
  return (
    <Switch>
      <RouteWithLayout
        component={DashboardView}
        layout={MainLayout}
        path="/"
        exact
      />

      {/* <Redirect exact from="/" to="/employee" /> */}

      <RouteWithLayout
        component={EmployeeView}
        layout={MainLayout}
        path="/employee"
        exact
      ></RouteWithLayout>

      <RouteWithLayout
        component={DetailEmployeeView}
        layout={MainLayout}
        path="/detail-employee/:id/:userId"
        exact
      ></RouteWithLayout>

      <RouteWithLayout
        component={FormEditInformasiPribadiView}
        layout={MainLayout}
        path="/detail-employee/:id/:userId/informasi-pribadi"
        exact
      ></RouteWithLayout>

      <RouteWithLayout
        component={FormEditAlamatView}
        layout={MainLayout}
        path="/detail-employee/:id/:userId/alamat"
        exact
      ></RouteWithLayout>

      <RouteWithLayout
        component={FormInformasiPekerjaanView}
        layout={MainLayout}
        path="/detail-employee/:id/:userId/informasi-pekerjaan"
        exact
      ></RouteWithLayout>

      <RouteWithLayout
        component={GantiPasswordView}
        layout={MainLayout}
        path="/ganti-password"
        exact
      />

      <RouteWithLayout
        component={BeritaView}
        layout={MainLayout}
        path="/berita"
        exact
      />

      <RouteWithLayout
        component={BeritaDetailView}
        layout={MainLayout}
        path="/berita/:id"
        exact
      />

      <RouteWithLayout
        component={BuatBeritaView}
        layout={MainLayout}
        path="/buat-berita"
        exact
      />

      <RouteWithLayout
        component={EditBeritaView}
        layout={MainLayout}
        path="/edit-berita/:id"
        exact
      />

      <RouteWithLayout
        component={OverviewView}
        layout={MainLayout}
        path="/overview"
        exact
      />

      <RouteWithLayout
        component={ReimburseView}
        layout={MainLayout}
        path="/reimburse"
        exact
      />

      <RouteWithLayout
        component={OvertimeView}
        layout={MainLayout}
        path="/pengajuan-lembur"
        exact
      />

      <RouteWithLayout
        component={FormOvertimeView}
        layout={MainLayout}
        path="/form-overtime"
        exact
      />

      <RouteWithLayout
        component={FormOvertimeEditView}
        layout={MainLayout}
        path="/form-overtime/:id"
        exact
      />

      <RouteWithLayout
        component={FormReimburseView}
        layout={MainLayout}
        path="/form-reimburse"
        exact
      />

      <RouteWithLayout
        component={FormReimburseEditView}
        layout={MainLayout}
        path="/form-reimburse/:id"
        exact
      />

      <RouteWithLayout
        component={FormIdentitasView}
        layout={MainLayout}
        path="/form-identitas"
        exact
      />

      <RouteWithLayout
        component={RequestLeaveView}
        layout={MainLayout}
        path="/request-leave"
        exact
      />

      <RouteWithLayout
        component={DetailRequestLeaveView}
        layout={MainLayout}
        path="/request-leave/detail/:id"
        exact
      />

      <RouteWithLayout
        component={MasukPulangView}
        layout={MainLayout}
        path="/absensi"
        exact
      />

      <RouteWithLayout
        component={DokumenView}
        layout={MainLayout}
        path="/dokumen/:id/:userId"
        exact
      />

      <RouteWithLayout
        component={KelolaKehadiranView}
        layout={MainLayout}
        path="/kelola-kehadiran"
        exact
      />

      <RouteWithLayout
        component={KelolaQuotesView}
        layout={MainLayout}
        path="/kelola-quotes"
        exact
      />

      <RouteWithLayout
        component={DaftarDitugaskanView}
        layout={MainLayout}
        path="/daftar-ditugaskan"
        exact
      />

      <Route exact path="/login" component={Login} />

      {auth.user !== null && auth.user.role_name === "hcis" && (
        <RouteWithLayout
          component={FormTambahUserView}
          layout={MainLayout}
          path="/form-tambah-user"
          exact
        />
      )}

      {auth.user !== null &&
      auth.user.sequence <= 2 &&
      (auth.user.role_name === "manager" || auth.user.role_name === "hcis") ? (
        <RouteWithLayout
          component={DaftarIzinView}
          layout={MainLayout}
          path="/daftar-izin"
          exact
        />
      ) : null}

      {auth.user !== null && auth.user.role_name === "hcis" ? (
        <RouteWithLayout
          component={TimeLocationView}
          layout={MainLayout}
          path="/time-and-location"
          exact
        />
      ) : null}

      {auth.user !== null &&
      ((auth.user.sequence <= 2 && auth.user.role_name === "manager") ||
        auth.user.role_name === "hcis" ||
        auth.user.job_name === "Staff Finance") ? (
        <RouteWithLayout
          component={DaftarReimburseView}
          layout={MainLayout}
          path="/daftar-reimburse"
          exact
        />
      ) : null}

      {auth.user !== null &&
      (auth.user.role_name === "hcis" ||
        ((auth.user.sequence === 1 || auth.user.sequence === 2) &&
          auth.user.role_name === "manager")) ? (
        <>
          <RouteWithLayout
            component={DaftarLemburView}
            layout={MainLayout}
            path="/daftar-lembur"
            exact
          />
          <RouteWithLayout
            component={LemburDitugaskanView}
            layout={MainLayout}
            path="/lembur-ditugaskan"
            exact
          />
          <RouteWithLayout
            component={FormLemburDitugaskanView}
            layout={MainLayout}
            path="/form-lembur-ditugaskan"
            exact
          />
        </>
      ) : null}

      <Route exact path="/reset-password/:token" component={ResetPassword} />
      <Route exact path="/forgot-password" component={ForgotPassword}></Route>
      <Redirect to="/not-found" />
    </Switch>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(Routes);
