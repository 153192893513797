import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  TextField,
  Grid,
  Button,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import { useForm, Controller } from "react-hook-form";

import { setAlert, sendReimburse } from "../../../store/actions";
import Breadcrumb from "../../../components/UI/Breadcrumb/Breadcrumb";
import DatePicker from "../../../components/UI/DatePicker/DatePicker";
import Dropzone from "./component/Dropzone";
import moment from "moment";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  marLeft: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  pad: {
    padding: theme.spacing(1.25),
  },
  totalField: {
    marginTop: theme.spacing(2),
  },
  dropzoneContainer: {
    paddingTop: theme.spacing(4),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const crumbs = [
  { name: "Reimburse", link: "/reimburse" },
  { name: "Form Reimburse", link: "" },
];

const validationSchema = yup.object().shape({
  description: yup.string().required("Wajib diisi"),
  date: yup.string().required("Wajib diisi").typeError("Wajib diisi"),
  total: yup.string().required("Wajib diisi"),
});

const Reimburse = ({ setAlert, sendReimburse, reimburse }) => {
  const classes = useStyles();
  const history = useHistory();

  const { handleSubmit, register, control, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [image, setImage] = useState(null);
  const [reset, setReset] = useState(false);

  const handleUploadImage = (event) => {
    setImage(event[0]);
  };

  const onSubmit = (data, e) => {
    if (image === null) {
      return setAlert("Bukti reimburse belum di upload", "error");
    }
    const newData = {
      ...data,
      date: moment(data.date).format("YYYY-MM-DD"),
    };

    const formData = new FormData();
    formData.append("description", newData.description);
    formData.append("date", newData.date);
    formData.append("total", newData.total);
    formData.append("file", image);

    sendReimburse(formData, history);
    e.target.reset();
    setReset(true);
    setImage(null);
  };

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.marLeft}>
        Form Reimburse
      </Typography>
      <Breadcrumb crumb={crumbs} />
      <Card className={classes.card}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <Grid container>
              <Grid item xs={6} className={classes.pad}>
                <TextField
                  fullWidth
                  label="Keterangan"
                  name="description"
                  inputRef={register}
                  error={!!errors.description}
                  helperText={
                    !!errors.description && errors.description.message
                  }
                />
                <Controller
                  control={control}
                  name="date"
                  defaultValue={null}
                  render={({ onChange, ref, value }) => (
                    <DatePicker
                      format="dd MMMM yyyy"
                      label="Tanggal"
                      inputRef={ref}
                      value={value}
                      onChange={(e) => onChange(e)}
                      error={!!errors.date}
                      helperText={!!errors.date && errors.date.message}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="total"
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ onChange, ref, value }) => (
                    <NumberFormat
                      customInput={TextField}
                      className={classes.totalField}
                      fullWidth
                      label="Total"
                      inputRef={ref}
                      value={value}
                      onValueChange={(e) => {
                        onChange(e.value);
                      }}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix="Rp "
                      placeholder="Rp 100.000"
                      error={!!errors.total}
                      helperText={!!errors.total && errors.total.message}
                      isNumericString
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} className={classes.dropzoneContainer}>
                <Dropzone
                  handleUploadImage={handleUploadImage}
                  reset={reset}
                  setReset={setReset}
                  setFile={setImage}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions>
            <Button fullWidth color="primary" variant="contained" type="submit">
              Simpan
            </Button>
          </CardActions>
        </form>
      </Card>
      <Backdrop className={classes.backdrop} open={reimburse.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    reimburse: state.reimburse,
  };
};

export default connect(mapStateToProps, { setAlert, sendReimburse })(Reimburse);
