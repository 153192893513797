/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import {
  Container,
  Grid,
  Typography,
  Card,
  CardActions,
  CardContent,
  Button,
  Divider,
  CardHeader,
  Box,
  CardActionArea,
  CircularProgress,
} from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";

import Select from "react-select";

import BarChartIcon from "@material-ui/icons/BarChart";
import { HorizontalBar } from "react-chartjs-2";
import { connect } from "react-redux";
import {
  getNews,
  getTotalRequestLeave,
  getDashboardChart,
  uploadPhoto,
  getProfilePicture,
  getTotalEmployeeGender,
} from "../../../../store/actions";

//Components
import CustomRouterLink from "../../../../components/CustomRouterLink/CustomRouterLink";
import DefaultAvatar from "../../../../assets/img/default-avatar.png";
import ToAchieveList from "../ToAchieveList";
import DialogChangeProfile from "../DialogChangeProfile/DialogChangeProfile";

// Utils
import { capitalize } from "../../../../shared/utility";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  padLeft: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  card: {
    margin: theme.spacing(1.25),
  },
  cardQuotes: {
    margin: theme.spacing(1.25),
    backgroundColor: "#285D5F",
    borderRadius: "10px",
    height: "150px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardButton: {
    justifyContent: "center",
    backgroundColor: "#FFF7E4",
    cursor: "pointer",
  },
  cardSticky: {
    position: "sticky",
    top: "20%",
  },
  iconBuatBerita: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    fontSize: "15px",
  },
  small: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginRight: theme.spacing(0.5),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  overflow: {
    height: "90px",
    overflow: "hidden",
  },
  cardImage: {
    width: "160px",
    height: "160px",
    margin: "8px auto",
    borderRadius: "50%",
  },
  pad: {
    padding: theme.spacing(1.25),
  },

  bismillah: {
    fontFamily: "Amiri",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },

  input: {
    display: "none",
  },

  details: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  iconDetails: {
    fontSize: 30,
  },
  textDesc: {
    color: "#3D3D3D",
  },
}));

const gender = [
  {
    label: "Laki-Laki",
    value: "laki-laki",
  },
  {
    label: "Perempuan",
    value: "perempuan",
  },
];

const stifins = [
  { label: "Insting", value: "Insting" },
  { label: "Sensing Introvert", value: "Sensing Introvert" },
  { label: "Sensing Ekstrovert", value: "Sensing Ekstrovert" },
  { label: "Thinking Introvert", value: "Thinking Introvert" },
  { label: "Thinking Ekstrovert", value: "Thinking Ekstrovert" },
  { label: "Intuiting Introvert", value: "Intuiting Introvert" },
  { label: "Intuiting Ekstrovert", value: "Intuiting Ekstrovert" },
  { label: "Feeling Introvert", value: "Feeling Introvert" },
  { label: "Feeling Ekstrovert", value: "Feeling Ekstrovert" },
];

const options = {
  indexAxis: "y",
  scales: {
    yAxes: [
      {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
    xAxes: [
      {
        stacked: true,
      },
    ],
  },
};

const Dashboard = ({
  auth,
  quotes,
  employee,
  getNews,
  news,
  totalIzin,
  totalSakit,
  totalCuti,
  getTotalRequestLeave,
  getDashboardChart,
  dashboard,
  uploadPhoto,
  getProfilePicture,
  profilePicture,
  getTotalEmployeeGender,
}) => {
  const classes = useStyles();
  const [hasMore, setHasMore] = useState(true);
  const [jk, setJk] = useState("");
  const [stifin, setStifin] = useState("");
  const history = useHistory();
  const [srcProfile, setSrcProfile] = useState({ open: false, src: null });

  const fetchMoreData = () => {
    if (news.data.length === news.totalNews) {
      setHasMore(false);
      return;
    }

    let id = news.data[news.data.length - 1].id;

    if (id === -1) {
      id = "";
    }

    getNews(id);
  };

  const uploaderTrigger = () => {
    document.getElementById("photoUploader").click();
  };

  const uploaderImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setSrcProfile({ open: true, src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    getTotalRequestLeave("izin");
    getTotalRequestLeave("sakit");
    getTotalRequestLeave("cuti");
    getNews("", true);
    getProfilePicture();
    getTotalEmployeeGender(auth.user.company_id);
  }, []);

  useEffect(() => {
    getDashboardChart({ gender: jk, stfin: stifin });
  }, [jk, stifin, getDashboardChart]);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Box textAlign="center" mb={2}>
        <Typography variant="h2" gutterBottom className={classes.bismillah}>
          بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيْم
        </Typography>
        <Typography variant="subtitle1">Dashboard</Typography>
      </Box>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12}>
          <Card className={classes.cardQuotes}>
            <CardContent>
              <Typography style={{ color: "#fff" }} variant="h3" align="center">
                {!quotes.loadingRandomQuotes && `"${quotes.randomQuotes.name}"`}
              </Typography>
            </CardContent>
          </Card>

          <Grid container>
            <Grid item xs={12} sm={4} className={classes.pad}>
              <Card
                style={{
                  height: "100%",
                }}
              >
                <CardActionArea
                  onClick={() => {
                    history.push(
                      `/detail-employee/${auth.user.position_id}/${auth.user.id}`
                    );
                  }}
                >
                  <Box
                    component="div"
                    style={{
                      backgroundImage: `url(${
                        profilePicture.data === ""
                          ? DefaultAvatar
                          : profilePicture.data
                      })`,
                      width: 150,
                      height: 150,
                      backgroundSize: "cover",
                      backgroundPosition: "top center",
                      borderRadius: "50%",
                      margin: "auto",
                      marginTop: "10px",
                    }}
                  />

                  <CardContent>
                    <Grid container spacing={1} justify="center">
                      <Grid item container justify="center" xs={12}>
                        <Grid item xs="auto">
                          <Typography variant="h5">Nama : &nbsp;</Typography>
                        </Grid>
                        <Grid item xs="auto">
                          <Typography variant="h5">{auth.user.name}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item container justify="center" xs={12}>
                        <Grid item xs="auto">
                          <Typography variant="h5">NIK : &nbsp;</Typography>
                        </Grid>
                        <Grid item xs="auto">
                          <Typography variant="h5">
                            {auth.user.employee_identity || "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
                <Divider />
                <CardActions style={{ textAlign: "center", display: "block" }}>
                  <input
                    accept="image/jpeg,image/png,image/jpg"
                    className={classes.input}
                    id="photoUploader"
                    name="file"
                    type="file"
                    onChange={uploaderImage}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={uploaderTrigger}
                  >
                    Upload Profile
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ToAchieveList />
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={4}>
            <Card className={classes.card}>
              <div className={classes.details}>
                <CardContent>
                  <i
                    className={clsx(`la la-user-tie`, classes.iconDetails)}
                  ></i>
                </CardContent>
                <CardContent className={classes.content}>
                  <Typography component="h5" variant="h5" align="right">
                    {employee.totalEmployee}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    className={classes.textDesc}
                  >
                    Total Seluruh Karyawan
                  </Typography>
                </CardContent>
              </div>
              <Divider />
              <CardActions align="center" className={classes.cardButton}>
                <Button
                  component={CustomRouterLink}
                  to="/employee"
                  size="small"
                  color="primary"
                >
                  Lihat Data
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card className={classes.card}>
              <div className={classes.details}>
                <CardContent>
                  <i className={clsx(`la la-male`, classes.iconDetails)}></i>
                </CardContent>
                <CardContent className={classes.content}>
                  <Typography component="h5" variant="h5" align="right">
                    {employee.totalMale}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    className={classes.textDesc}
                  >
                    Total Karyawan Ikhwan
                  </Typography>
                </CardContent>
              </div>
              <Divider />
              <CardActions align="center" className={classes.cardButton}>
                <Button
                  component={CustomRouterLink}
                  to="/employee?type=laki-laki"
                  size="small"
                  color="primary"
                >
                  Lihat Data
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card className={classes.card}>
              <div className={classes.details}>
                <CardContent>
                  <i className={clsx(`la la-female`, classes.iconDetails)}></i>
                </CardContent>
                <CardContent className={classes.content}>
                  <Typography component="h5" variant="h5" align="right">
                    {employee.totalFemale}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    className={classes.textDesc}
                  >
                    Total Karyawan Akhwat
                  </Typography>
                </CardContent>
              </div>
              <Divider />
              <CardActions align="center" className={classes.cardButton}>
                <Button
                  component={CustomRouterLink}
                  to="/employee?type=perempuan"
                  size="small"
                  color="primary"
                >
                  Lihat Data
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card className={classes.card}>
              <div className={classes.details}>
                <CardContent>
                  <i
                    className={clsx(`la la-mail-bulk`, classes.iconDetails)}
                  ></i>
                </CardContent>
                <CardContent className={classes.content}>
                  <Typography component="h5" variant="h5" align="right">
                    {totalIzin}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    className={classes.textDesc}
                  >
                    Request Izin
                  </Typography>
                </CardContent>
              </div>
              <Divider />
              <CardActions align="center" className={classes.cardButton}>
                <Button
                  component={CustomRouterLink}
                  to="/daftar-izin?type=izin"
                  size="small"
                  color="primary"
                >
                  Lihat Data
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card className={classes.card}>
              <div className={classes.details}>
                <CardContent>
                  <i
                    className={clsx(`la la-calendar`, classes.iconDetails)}
                  ></i>
                </CardContent>
                <CardContent className={classes.content}>
                  <Typography component="h5" variant="h5" align="right">
                    {totalCuti}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    className={classes.textDesc}
                  >
                    Request Cuti
                  </Typography>
                </CardContent>
              </div>
              <Divider />
              <CardActions align="center" className={classes.cardButton}>
                <Button
                  component={CustomRouterLink}
                  to="/daftar-izin?type=cuti"
                  size="small"
                  color="primary"
                >
                  Lihat Data
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card className={classes.card}>
              <div className={classes.details}>
                <CardContent>
                  <i
                    className={clsx(
                      `la la-briefcase-medical`,
                      classes.iconDetails
                    )}
                  ></i>
                </CardContent>
                <CardContent className={classes.content}>
                  <Typography component="h5" variant="h5" align="right">
                    {totalSakit}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="right"
                    className={classes.textDesc}
                  >
                    Request Sakit
                  </Typography>
                </CardContent>
              </div>
              <Divider />
              <CardActions align="center" className={classes.cardButton}>
                <Button
                  component={CustomRouterLink}
                  to="/daftar-izin?type=sakit"
                  size="small"
                  color="primary"
                >
                  Lihat Data
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.card}>
            <CardHeader
              title={
                <Grid container alignItems="flex-end" spacing={1}>
                  <Grid item xs={1}>
                    <BarChartIcon style={{ marginRight: "10px" }} />
                  </Grid>
                  <Grid item xs={2}>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      name="jk"
                      options={gender}
                      placeholder="Gender..."
                      onChange={(e) => {
                        if (e === null) {
                          setJk("");
                        } else {
                          setJk(e.value);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      isClearable={true}
                      isSearchable={true}
                      name="stfin"
                      options={stifins}
                      placeholder="STIFIn..."
                      onChange={(e) => {
                        if (e === null) {
                          setStifin("");
                        } else {
                          setStifin(e.value);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              }
            />
            <Divider />
            <CardContent>
              <HorizontalBar data={dashboard.dataChart} options={options} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container justify="center">
        <Grid item xs={12}>
          {news.data.length === 0 && !news.loading ? (
            <Card className={classes.card}>
              <CardContent>
                <Typography align="center" variant="h4">
                  Berita Kosong
                </Typography>
              </CardContent>
            </Card>
          ) : news.loading ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <InfiniteScroll
              style={{ overflow: "none" }}
              dataLength={news.data.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={
                <div style={{ textAlign: "center" }}>
                  <CircularProgress />
                </div>
              }
            >
              {news.data.map((i, index) => (
                <Card className={classes.card} key={index}>
                  <CardHeader
                    title={
                      <Typography variant="h3">
                        {capitalize(i.title)}
                      </Typography>
                    }
                    subheader={
                      <Box>
                        <Typography color="primary" variant="caption">
                          {i.created_at}
                        </Typography>
                      </Box>
                    }
                  />
                  <Divider />
                  <CardContent className={classes.overflow}>
                    <HTMLEllipsis
                      unsafeHTML={i.body}
                      maxLine="6"
                      ellipsis="..."
                      basedOn="letters"
                    />
                  </CardContent>
                  <CardActions>
                    <Button
                      variant="contained"
                      color="primary"
                      component={CustomRouterLink}
                      to={`/berita/${i.id}`}
                    >
                      read more...
                    </Button>
                  </CardActions>
                </Card>
              ))}
            </InfiniteScroll>
          )}
        </Grid>
      </Grid>
      <DialogChangeProfile
        dialog={srcProfile}
        onClose={() => setSrcProfile({ open: false, src: null })}
        action={uploadPhoto}
        positionId={auth.user.position_id}
        role={auth.user.role_name}
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    quotes: state.quotes,
    employee: state.employee,
    news: state.news,
    totalIzin: state.requestLeave.totalIzin,
    totalCuti: state.requestLeave.totalCuti,
    totalSakit: state.requestLeave.totalSakit,
    dashboard: state.dashboard,
    profilePicture: state.profilePicture,
  };
};

export default connect(mapStateToProps, {
  getNews,
  getTotalRequestLeave,
  getDashboardChart,
  getProfilePicture,
  uploadPhoto,
  getTotalEmployeeGender,
})(Dashboard);
