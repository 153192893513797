import React from "react";
import Button from "@material-ui/core/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { connect } from "react-redux";
import { deleteReimburse } from "../../../../../store/actions";

const AlertDialog = ({ dialogAlert, onClose, deleteReimburse }) => {
  const onDelete = (id) => {
    deleteReimburse(id);
    onClose();
  };
  return (
    <div>
      <Dialog
        open={dialogAlert.open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Apakah anda yakin ingin menghapus data?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Data akan di hapus secara permanen, dan tidak akan bisa di
            kembalikan lagi...
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained" color="primary">
            Disagree
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onDelete(dialogAlert.id);
            }}
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default connect(null, { deleteReimburse })(AlertDialog);
