/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  TextField,
  DialogContent,
  MenuItem,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(2),
  },
  cardActions: {
    justifyContent: "space-between",
  },
  btnDelete: {
    color: "#fff",
    background: theme.palette.red.color500,
    "&:hover": {
      backgroundColor: theme.palette.red.color700,
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  field: {
    marginTop: theme.spacing(2),
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const validationSchema = yup.object().shape({
  company_id: yup
    .number()
    .required("Entitas wajib diisi")
    .typeError("Entitas wajib diisi"),
  in: yup.string().required("Jam masuk wajib diisi"),
  out: yup.string().required("Jam keluar wajib diisi"),
});

const DialogSocialMedia = ({ open, onClose, listEntitas, action }) => {
  const classes = useStyles();

  const { errors, handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  const onSubmit = (data, e) => {
    const formData = {
      ...data,
      in: `${data.in}:00`,
      out: `${data.out}:00`,
    };
    e.target.reset();
    setValue("company_id", "");
    setValue("in", null);
    setValue("out", null);
    action(formData);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClose={onClose}>Tambah Waktu Absen Entitas</DialogTitle>
        <DialogContent dividers>
          <Controller
            control={control}
            name="company_id"
            defaultValue=""
            render={({ onChange, value }) => (
              <TextField
                fullWidth
                select
                defaultValue=""
                label="Entitas"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                error={!!errors.company_id}
                helperText={!!errors.company_id && errors.company_id.message}
              >
                {listEntitas.company.map((data) => (
                  <MenuItem key={data.value} value={data.value}>
                    {data.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          <Controller
            control={control}
            name="in"
            defaultValue={null}
            render={({ onChange, value }) => (
              <TextField
                fullWidth
                type="time"
                className={classes.field}
                label="Waktu Masuk"
                defaultValue={null}
                value={value}
                onChange={(e) => onChange(e)}
                error={!!errors.in}
                helperText={!!errors.in && errors.in.message}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="out"
            defaultValue={null}
            render={({ onChange, value }) => (
              <TextField
                fullWidth
                type="time"
                className={classes.field}
                label="Waktu Pulang"
                defaultValue={null}
                value={value}
                onChange={(e) => onChange(e)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                error={!!errors.out}
                helperText={!!errors.out && errors.out.message}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button fullWidth variant="contained" color="primary" type="submit">
            Simpan
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogSocialMedia;
