import {
  SET_PROFILE,
  SET_SKILL,
  SET_PENDIDIKAN,
  SET_ORGANISASI,
  SET_PRESTASI,
  SET_PEKERJAAN,
  SET_KELUARGA,
  SET_ALAMAT,
  SET_TYPE_SOCIAL_MEDIA,
  SET_SOCIAL_MEDIA,
  SET_INFORMASI_PEKERJAAN,
  SET_RUMUSAN_TUGAS,
  SET_TUGAS_POKOK,
  SET_TUGAS_TAMBAHAN,
  SET_TUGAS_BERKALA,
  SET_TUGAS_LAINNYA,
  SET_TUGAS_BELUM_TERLAKSANA,
  SET_ALAMAT_LOADING,
  SET_SEMINAR,
} from "./actionTypes";
import axios from "../../axios-orders";
import { setAlert } from "./alert";
import { changeStatus } from "./auth";
import moment from "moment";

// ------------------- GET DATA
// GET INFORMASI PRIBADI

export const getIdentitas =
  (manager = false, id = null) =>
  async (dispatch) => {
    let url = "/staff/user/own/employee/detail";
    if (manager && !!id) {
      url = `/manager/user/${id}/employee/detail`;
    }
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch({
        type: SET_PROFILE,
        payload: { ...res.data.data },
      });
    } catch (error) {
      dispatch(setAlert("Can't get data profile!", "error"));
    }
  };

export const getSkill =
  (manager = false, id = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/skill`;
    if (manager && !!id) {
      url = `/manager/user/${id}/employee/skill`;
    }
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch({
        type: SET_SKILL,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch(setAlert("Can't get data skill!", "error"));
    }
  };

export const getSeminar =
  (manager = false, id = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/seminar`;
    if (manager && !!id) {
      url = `/manager/user/${id}/employee/seminar`;
    }
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch({
        type: SET_SEMINAR,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch(setAlert("Can't get data seminar!", "error"));
    }
  };

export const getPendidikan =
  (manager = false, id = null) =>
  async (dispatch) => {
    let url = "/staff/user/own/employee/education";
    if (manager && !!id) {
      url = `/manager/user/${id}/employee/education`;
    }
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch({
        type: SET_PENDIDIKAN,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch(setAlert("Can't get data education!", "error"));
    }
  };

export const getOrganisasi =
  (manager = false, id = null) =>
  async (dispatch) => {
    let url = "/staff/user/own/employee/organization";
    if (manager && !!id) {
      url = `/manager/user/${id}/employee/organization`;
    }
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch({
        type: SET_ORGANISASI,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch(setAlert("Can't get data organization!", "error"));
    }
  };

export const getPrestasi =
  (manager = false, id = null) =>
  async (dispatch) => {
    let url = "/staff/user/own/employee/achievement";
    if (manager && !!id) {
      url = `/manager/user/${id}/employee/achievement`;
    }
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      const data = res.data.data.map((value) => ({
        ...value,
        bulan: moment(value.month, "MM").format("MMMM"),
      }));
      dispatch({
        type: SET_PRESTASI,
        payload: data,
      });
    } catch (error) {
      dispatch(setAlert("Can't get data archievement!", "error"));
    }
  };

export const getPekerjaan =
  (manager = false, id = null) =>
  async (dispatch) => {
    let url = "/staff/user/own/employee/experience";
    if (manager && !!id) {
      url = `/manager/user/${id}/employee/experience`;
    }
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      const data = res.data.data.map((value) => ({
        ...value,
        awalBulan: moment(value.start_month, "MM").format("MMMM"),
        akhirBulan: moment(value.end_month, "MM").format("MMMM"),
      }));
      dispatch({
        type: SET_PEKERJAAN,
        payload: data,
      });
    } catch (error) {
      dispatch(setAlert("Can't get data working experience!", "error"));
    }
  };

export const getKeluarga =
  (manager = false, id = null) =>
  async (dispatch) => {
    let url = "/staff/user/own/employee/family";
    if (manager && !!id) {
      url = `/manager/user/${id}/employee/family`;
    }
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch({
        type: SET_KELUARGA,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch(setAlert("Can't get data working experience!", "error"));
    }
  };

export const getAlamat =
  (manager = false, id = null) =>
  async (dispatch) => {
    dispatch({
      type: SET_ALAMAT_LOADING,
    });
    let url = "/staff/user/own/employee/address";
    if (manager && !!id) {
      url = `/manager/user/${id}/employee/address`;
    }
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch({
        type: SET_ALAMAT,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch(setAlert("Can't get data address!", "error"));
    }
  };

export const getTypeSocialMedia = () => async (dispatch) => {
  try {
    const res = await axios.get("/staff/social-item", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_TYPE_SOCIAL_MEDIA,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(setAlert("Can't get data address!", "error"));
  }
};

export const getSocialMedia =
  (manager = false, id = null) =>
  async (dispatch) => {
    let url = "/staff/user/own/employee/social-media";
    if (manager && !!id) {
      url = `/manager/user/${id}/employee/social-media`;
    }
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch({
        type: SET_SOCIAL_MEDIA,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch(setAlert("Can't get data Social Media!", "error"));
    }
  };

// GET INFORMASI PEKERJAAN

export const getInformasiPekerjaan =
  (manager = false, id = null) =>
  async (dispatch) => {
    let url = "/staff/user/employee/position/own";
    if (manager && !!id) {
      url = `/manager/user/employee/position/${id}`;
    }
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch({
        type: SET_INFORMASI_PEKERJAAN,
        payload: res.data.data,
      });
    } catch (error) {
      if (error.response.status === 404) {
        dispatch({
          type: SET_INFORMASI_PEKERJAAN,
          payload: {},
        });
      } else {
        dispatch(setAlert("Internet Error", "error"));
      }
    }
  };

// GET JOB ANALYST
export const getRumusanTugas =
  (manager = false, id = null) =>
  async (dispatch) => {
    let url = "/staff/user/employee/job/summary";
    if (manager && !!id) {
      url = `/manager/user/employee/${id}/job/summary`;
    }
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch({
        type: SET_RUMUSAN_TUGAS,
        payload: res.data.data,
      });
    } catch (error) {
      if (error.response.status === 404) {
        dispatch({
          type: SET_RUMUSAN_TUGAS,
          payload: {},
        });
      } else {
        dispatch(setAlert("Can't get working summary", "error"));
      }
    }
  };

export const getTugasPokok =
  (manager = false, id = null) =>
  async (dispatch) => {
    let url = "/staff/user/employee/job/main";
    if (manager && !!id) {
      url = `/manager/user/employee/${id}/job/main`;
    }
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch({
        type: SET_TUGAS_POKOK,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch(setAlert("Can't get working summary", "error"));
    }
  };

export const getTugasTambahan =
  (manager = false, id = null) =>
  async (dispatch) => {
    let url = "/staff/user/employee/job/additional";
    if (manager && !!id) {
      url = `/manager/user/employee/${id}/job/additional`;
    }
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch({
        type: SET_TUGAS_TAMBAHAN,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch(setAlert("Can't get working summary", "error"));
    }
  };

export const getTugasBerkala =
  (manager = false, id = null) =>
  async (dispatch) => {
    let url = "/staff/user/employee/job/periodic";
    if (manager && !!id) {
      url = `/manager/user/employee/${id}/job/periodic`;
    }
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch({
        type: SET_TUGAS_BERKALA,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch(setAlert("Can't get working summary", "error"));
    }
  };

export const getTugasLainnya =
  (manager = false, id = null) =>
  async (dispatch) => {
    let url = "/staff/user/employee/job/other";
    if (manager && !!id) {
      url = `/manager/user/employee/${id}/job/other`;
    }
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch({
        type: SET_TUGAS_LAINNYA,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch(setAlert("Can't get working summary", "error"));
    }
  };

export const getTugasBelumTerlaksana =
  (manager = false, id = null) =>
  async (dispatch) => {
    let url = "/staff/user/employee/job/undone";
    if (manager && !!id) {
      url = `/manager/user/employee/${id}/job/undone`;
    }
    try {
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch({
        type: SET_TUGAS_BELUM_TERLAKSANA,
        payload: res.data.data,
      });
    } catch (error) {
      dispatch(setAlert("Can't get working summary", "error"));
    }
  };

// ------------------------ SEND DATA (POST /PUT)
// POST INFORMASI PRIBADI

export const sendOrganisasi =
  (data, userId = null) =>
  async (dispatch) => {
    let url = "/staff/user/own/employee/organization";
    if (!!userId) {
      url = `/hcis/user/${userId}/employee/organization`;
    }
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil disimpan", "success"));
      if (userId !== null) {
        dispatch(getOrganisasi(true, userId));
      } else {
        dispatch(getOrganisasi());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendSeminar =
  (data, userId = null) =>
  async (dispatch) => {
    let url = "/staff/user/own/employee/seminar";
    if (!!userId) {
      url = `/hcis/user/${userId}/employee/seminar`;
    }
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil disimpan", "success"));
      if (userId !== null) {
        dispatch(getSeminar(true, userId));
      } else {
        dispatch(getSeminar());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendEditSeminar =
  (data, id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/seminar/${id}`;
    if (userId !== null) {
      url = `/staff/user/${userId}/employee/seminar/${id}`;
    }
    try {
      await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil diubah", "success"));
      if (userId !== null) {
        dispatch(getSeminar(true, userId));
      } else {
        dispatch(getSeminar());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendEditOrganisasi =
  (data, id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/organization/${id}`;
    if (userId !== null) {
      url = `/staff/user/${userId}/employee/organization/${id}`;
    }
    try {
      await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil diubah", "success"));
      if (userId !== null) {
        dispatch(getOrganisasi(true, userId));
      } else {
        dispatch(getOrganisasi());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendSkill =
  (data, userId = null) =>
  async (dispatch) => {
    let url = "/staff/user/own/employee/skill";
    if (!!userId) {
      url = `/hcis/user/${userId}/employee/skill`;
    }
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil disimpan", "success"));
      if (userId !== null) {
        dispatch(getSkill(true, userId));
      } else {
        dispatch(getSkill());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendEditSkill =
  (data, id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/skill/${id}`;
    if (userId !== null) {
      url = `/staff/user/${userId}/employee/skill/${id}`;
    }
    try {
      await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil diubah", "success"));
      if (userId !== null) {
        dispatch(getSkill(true, userId));
      } else {
        dispatch(getSkill());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendPendidikan =
  (data, userId = null) =>
  async (dispatch) => {
    let url = "/staff/user/own/employee/education";
    if (userId !== null) {
      url = `/hcis/user/${userId}/employee/education`;
    }
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil disimpan", "success"));
      if (userId !== null) {
        dispatch(getPendidikan(true, userId));
      } else {
        dispatch(getPendidikan());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendEditPendidikan =
  (data, id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/education/${id}`;
    if (userId !== null) {
      url = `/staff/user/${userId}/employee/education/${id}`;
    }
    try {
      await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil diubah", "success"));
      if (userId !== null) {
        dispatch(getPendidikan(true, userId));
      } else {
        dispatch(getPendidikan());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendPrestasi =
  (data, userId = null) =>
  async (dispatch) => {
    let url = "/staff/user/own/employee/achievement";
    if (userId !== null) {
      url = `/hcis/user/${userId}/employee/achievement`;
    }
    const split = data.date.split("-");
    const formData = {
      ...data,
      month: parseInt(split[1]),
      year: parseInt(split[0]),
    };
    try {
      await axios.post(url, formData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil disimpan", "success"));
      if (userId !== null) {
        dispatch(getPrestasi(true, userId));
      } else {
        dispatch(getPrestasi());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendEditPrestasi =
  (data, id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/achievement/${id}`;
    if (userId !== null) {
      url = `/staff/user/${userId}/employee/achievement/${id}`;
    }
    const split = data.date.split("-");
    const formData = {
      ...data,
      month: parseInt(split[1]),
      year: parseInt(split[0]),
    };
    try {
      await axios.put(url, formData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil diubah", "success"));
      if (userId !== null) {
        dispatch(getPrestasi(true, userId));
      } else {
        dispatch(getPrestasi());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendPekerjaan =
  (data, userId = null) =>
  async (dispatch) => {
    let url = "/staff/user/own/employee/experience";
    if (userId !== null) {
      url = `/hcis/user/${userId}/employee/experience`;
    }
    const splitStart = data.start_at.split("-");
    const splitEnd = data.end_at.split("-");
    const formData = {
      ...data,
      start_month: splitStart[1],
      start_year: splitStart[0],
      end_month: splitEnd[1],
      end_year: splitEnd[0],
    };
    try {
      await axios.post(url, formData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil disimpan", "success"));
      if (userId !== null) {
        dispatch(getPekerjaan(true, userId));
      } else {
        dispatch(getPekerjaan());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendEditPekerjaan =
  (data, id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/experience/${id}`;
    if (userId !== null) {
      url = `/staff/user/${userId}/employee/experience/${id}`;
    }
    const splitStart = data.start_at.split("-");
    const splitEnd = data.end_at.split("-");
    const formData = {
      ...data,
      start_month: splitStart[1],
      start_year: splitStart[0],
      end_month: splitEnd[1],
      end_year: splitEnd[0],
    };
    try {
      await axios.put(url, formData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil diubah", "success"));
      if (userId !== null) {
        dispatch(getPekerjaan(true, userId));
      } else {
        dispatch(getPekerjaan());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendKeluarga =
  (data, userId = null) =>
  async (dispatch) => {
    let url = "/staff/user/own/employee/family";
    if (userId !== null) {
      url = `/hcis/user/${userId}/employee/family`;
    }
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil disimpan", "success"));
      if (userId !== null) {
        dispatch(getKeluarga(true, userId));
      } else {
        dispatch(getKeluarga());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendEditKeluarga =
  (data, id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/family/${id}`;
    if (userId !== null) {
      url = `/staff/user/${userId}/employee/family/${id}`;
    }
    try {
      await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil diubah", "success"));
      if (userId !== null) {
        dispatch(getKeluarga(true, userId));
      } else {
        dispatch(getKeluarga());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendSocialMedia =
  (data, userId = null) =>
  async (dispatch) => {
    let url = "/staff/user/own/employee/social-media";
    if (userId !== null) {
      url = `/hcis/user/${userId}/employee/social-media`;
    }
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil disimpan", "success"));
      if (userId !== null) {
        dispatch(getSocialMedia(true, userId));
      } else {
        dispatch(getSocialMedia());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendEditSocialMedia =
  (data, id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/social-media/${id}`;
    if (userId !== null) {
      url = `/staff/user/${userId}/employee/social-media/${id}`;
    }
    try {
      await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil disimpan", "success"));
      if (userId !== null) {
        dispatch(getSocialMedia(true, userId));
      } else {
        dispatch(getSocialMedia());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

// POST INFORMASI PEKERJAAN (HCIS and MANAGER only)

export const postInformasiPekerjaan = (data, history) => async (dispatch) => {
  try {
    await axios.post("/manager/employee/position", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch(setAlert("data berhasil disimpan", "success"));
    history.goBack();
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};

export const sendInformasiPekerjaan =
  (data, id, history) => async (dispatch) => {
    try {
      await axios.put(`manager/user/employee/position/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil disimpan", "success"));
      history.goBack();
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

// POST JOB ANALYST

export const sendRumusanTugas = (data) => async (dispatch) => {
  try {
    await axios.post("/staff/user/employee/job/summary", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch(setAlert("data berhasil disimpan", "success"));
    dispatch(getRumusanTugas());
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};

export const sendTugasPokok =
  (data, id = null) =>
  async (dispatch) => {
    let url = "/staff/user/employee/job/main";
    if (id !== null) {
      url = `/manager/user/employee/${id}/job/main`;
    }
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil disimpan", "success"));
      if (id === null) {
        dispatch(getTugasPokok());
      } else {
        dispatch(getTugasPokok(true, id));
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendEditTugasPokok =
  (data, id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/employee/job/main/${id}`;
    if (userId !== null) {
      url = `/manager/user/employee/${userId}/job/main/${id}`;
    }
    try {
      await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil disimpan", "success"));
      if (userId === null) {
        dispatch(getTugasPokok());
      } else {
        dispatch(getTugasPokok(true, userId));
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendTugasTambahan =
  (data, id = null) =>
  async (dispatch) => {
    let url = "/staff/user/employee/job/additional";
    if (id !== null) {
      url = `/manager/user/employee/${id}/job/additional`;
    }
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil disimpan", "success"));
      if (id === null) {
        dispatch(getTugasTambahan());
      } else {
        dispatch(getTugasTambahan(true, id));
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendEditTugasTambahan =
  (data, id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/employee/job/additional/${id}`;
    if (userId !== null) {
      url = `/manager/user/employee/${userId}/job/additional/${id}`;
    }
    try {
      await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil disimpan", "success"));
      if (userId === null) {
        dispatch(getTugasTambahan());
      } else {
        dispatch(getTugasTambahan(true, userId));
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendTugasBerkala =
  (data, id = null) =>
  async (dispatch) => {
    let url = "/staff/user/employee/job/periodic";
    if (id !== null) {
      url = `/manager/user/employee/${id}/job/periodic`;
    }
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil disimpan", "success"));
      if (id === null) {
        dispatch(getTugasBerkala());
      } else {
        dispatch(getTugasBerkala(true, id));
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendEditTugasBerkala =
  (data, id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/employee/job/periodic/${id}`;
    if (userId !== null) {
      url = `/manager/user/employee/${userId}/job/periodic/${id}`;
    }
    try {
      await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil disimpan", "success"));
      if (userId === null) {
        dispatch(getTugasBerkala());
      } else {
        dispatch(getTugasBerkala(true, userId));
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendTugasLainnya =
  (data, id = null) =>
  async (dispatch) => {
    let url = "/staff/user/employee/job/other";
    if (id !== null) {
      url = `/manager/user/employee/${id}/job/other`;
    }
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil disimpan", "success"));
      if (id === null) {
        dispatch(getTugasLainnya());
      } else {
        dispatch(getTugasLainnya(true, id));
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendEditTugasLainnya =
  (data, id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/employee/job/other/${id}`;
    if (userId !== null) {
      url = `/manager/user/employee/${userId}/job/other/${id}`;
    }
    try {
      await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil disimpan", "success"));
      if (userId === null) {
        dispatch(getTugasLainnya());
      } else {
        dispatch(getTugasLainnya(true, userId));
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendTugasBelumTerlaksana =
  (data, id = null) =>
  async (dispatch) => {
    let url = "/staff/user/employee/job/undone";
    if (id !== null) {
      url = `/manager/user/employee/${id}/job/undone`;
    }
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil disimpan", "success"));
      if (id === null) {
        dispatch(getTugasBelumTerlaksana());
      } else {
        dispatch(getTugasBelumTerlaksana(true, id));
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendEditTugasBelumTerlaksana =
  (data, id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/employee/job/undone/${id}`;
    if (userId !== null) {
      url = `/manager/user/employee/${userId}/job/undone/${id}`;
    }
    try {
      await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil disimpan", "success"));
      if (userId === null) {
        dispatch(getTugasBelumTerlaksana());
      } else {
        dispatch(getTugasBelumTerlaksana(true, userId));
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

// DELETE INFORMASI PRIBADI
export const deleteOrganisasi =
  (id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/organization/${id}`;
    if (userId !== null) {
      url = `/hcis/user/${userId}/employee/organization/${id}`;
    }
    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil dihapus", "success"));
      if (userId !== null) {
        dispatch(getOrganisasi(true, userId));
      } else {
        dispatch(getOrganisasi());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const deleteSkill =
  (id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/skill/${id}`;
    if (userId !== null) {
      url = `/hcis/user/${userId}/employee/skill/${id}`;
    }
    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil dihapus", "success"));
      if (userId !== null) {
        dispatch(getSkill(true, userId));
      } else {
        dispatch(getSkill());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const deleteSeminar =
  (id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/seminar/${id}`;
    if (userId !== null) {
      url = `/hcis/user/${userId}/employee/seminar/${id}`;
    }
    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil dihapus", "success"));
      if (userId !== null) {
        dispatch(getSeminar(true, userId));
      } else {
        dispatch(getSeminar());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const deletePrestasi =
  (id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/achievement/${id}`;
    if (userId !== null) {
      url = `/hcis/user/${userId}/employee/achievement/${id}`;
    }
    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil dihapus", "success"));
      if (userId !== null) {
        dispatch(getPrestasi(true, userId));
      } else {
        dispatch(getPrestasi());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const deletePekerjaan =
  (id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/experience/${id}`;
    if (userId !== null) {
      url = `/hcis/user/${userId}/employee/experience/${id}`;
    }
    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil dihapus", "success"));
      if (userId !== null) {
        dispatch(getPekerjaan(true, userId));
      } else {
        dispatch(getPekerjaan());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const deleteKeluarga =
  (id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/family/${id}`;
    if (userId !== null) {
      url = `/hcis/user/${userId}/employee/family/${id}`;
    }
    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil dihapus", "success"));
      if (userId !== null) {
        dispatch(getKeluarga(true, userId));
      } else {
        dispatch(getKeluarga());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const deleteSocialMedia =
  (id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/social-media/${id}`;
    if (userId !== null) {
      url = `/hcis/user/${userId}/employee/social-media/${id}`;
    }
    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil dihapus", "success"));
      if (userId !== null) {
        dispatch(getSocialMedia(true, userId));
      } else {
        dispatch(getSocialMedia());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const deletePendidikan =
  (id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/education/${id}`;
    if (userId !== null) {
      url = `/hcis/user/${userId}/employee/education/${id}`;
    }
    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("data berhasil dihapus", "success"));
      if (userId !== null) {
        dispatch(getPendidikan(true, userId));
      } else {
        dispatch(getPendidikan());
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

// DELETE JOB ANALYST
export const deleteTugasPokok =
  (id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/employee/job/main/${id}`;
    if (userId !== null) {
      url = `/manager/user/employee/${userId}/job/main/${id}`;
    }
    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("Data berhasil dihapus", "success"));
      if (userId === null) {
        dispatch(getTugasPokok());
      } else {
        dispatch(getTugasPokok(true, userId));
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const deleteTugasTambahan =
  (id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/employee/job/additional/${id}`;
    if (userId !== null) {
      url = `/manager/user/employee/${userId}/job/additional/${id}`;
    }
    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("Data berhasil dihapus", "success"));
      if (userId === null) {
        dispatch(getTugasTambahan());
      } else {
        dispatch(getTugasTambahan(true, userId));
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const deleteTugasBerkala =
  (id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/employee/job/periodic/${id}`;
    if (userId !== null) {
      url = `/manager/user/employee/${userId}/job/periodic/${id}`;
    }
    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("Data berhasil dihapus", "success"));
      if (userId === null) {
        dispatch(getTugasBerkala());
      } else {
        dispatch(getTugasBerkala(true, userId));
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const deleteTugasLainnya =
  (id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/employee/job/other/${id}`;
    if (userId !== null) {
      url = `/manager/user/employee/${userId}/job/other/${id}`;
    }
    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("Data berhasil dihapus", "success"));
      if (userId === null) {
        dispatch(getTugasLainnya());
      } else {
        dispatch(getTugasLainnya(true, userId));
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const deleteTugasBelumTerlaksana =
  (id, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/employee/job/undone/${id}`;
    if (userId !== null) {
      url = `/manager/user/employee/${userId}/job/undone/${id}`;
    }
    try {
      await axios.delete(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("Data berhasil dihapus", "success"));
      if (userId === null) {
        dispatch(getTugasBelumTerlaksana());
      } else {
        dispatch(getTugasBelumTerlaksana(true, userId));
      }
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

// FORM IDENTITAS

export const sendEditInformasiPribadi =
  (data, id = null, history) =>
  async (dispatch) => {
    let url = "/staff/user/own/employee/detail";
    if (id !== null) {
      url = `/hcis/user/${id}/employee/detail`;
    }
    try {
      await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      history.goBack();
      dispatch(setAlert("Data berhasil diubah", "success"));
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendAlamatKTP =
  (data, history, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/address`;
    if (userId !== null) {
      url = `/hcis/user/${userId}/employee/address`;
    }
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (userId !== null) {
        dispatch(getAlamat(true, userId));
      } else {
        dispatch(getAlamat());
      }
      dispatch(setAlert("Data berhasil diubah", "success"));
      history.goBack();
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendEditAlamatKTP =
  (data, history, idAddress, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/address/${idAddress}`;
    if (userId !== null) {
      url = `/hcis/user/${userId}/employee/address/${idAddress}`;
    }
    try {
      await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (userId !== null) {
        dispatch(getAlamat(true, userId));
      } else {
        dispatch(getAlamat());
      }
      dispatch(setAlert("Data berhasil diubah", "success"));
      history.goBack();
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendAlamatDomisili =
  (data, history, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/address`;
    if (userId !== null) {
      url = `/hcis/user/${userId}/employee/address`;
    }
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (userId !== null) {
        dispatch(getAlamat(true, userId));
      } else {
        dispatch(getAlamat());
      }
      dispatch(setAlert("Data berhasil diubah", "success"));
      history.goBack();
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendEditAlamatDomisili =
  (data, history, idAddress, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/address/${idAddress}`;
    if (userId !== null) {
      url = `/hcis/user/${userId}/employee/address/${idAddress}`;
    }
    try {
      await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (userId !== null) {
        dispatch(getAlamat(true, userId));
      } else {
        dispatch(getAlamat());
      }
      dispatch(setAlert("Data berhasil diubah", "success"));
      history.goBack();
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendAlamatMail =
  (data, history, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/address`;
    if (userId !== null) {
      url = `/hcis/user/${userId}/employee/address`;
    }
    try {
      await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (userId !== null) {
        dispatch(getAlamat(true, userId));
      } else {
        dispatch(getAlamat());
      }
      dispatch(setAlert("Data berhasil diubah", "success"));
      history.goBack();
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendEditAlamatMail =
  (data, history, idAddress, userId = null) =>
  async (dispatch) => {
    let url = `/staff/user/own/employee/address/${idAddress}`;
    if (userId !== null) {
      url = `/hcis/user/${userId}/employee/address/${idAddress}`;
    }
    try {
      await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      if (userId !== null) {
        dispatch(getAlamat(true, userId));
      } else {
        dispatch(getAlamat());
      }
      dispatch(setAlert("Data berhasil diubah", "success"));
      history.goBack();
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };

export const sendFormIdentitas =
  (data, userId, history) => async (dispatch) => {
    const dataForm = {
      detail: {
        id_number: data.id_number,
        gender: data.gender,
        place_of_birth: data.place_of_birth,
        date_of_birth: data.date_of_birth,
        start_at: data.start_at,
        religion: data.religion,
        ethnic: data.ethnic,
        blood_type: data.blood_type,
        height: data.height.toString(),
        weight: data.weight.toString(),
        marital_status: data.marital_status,
        about_me: data.about_me,
        tax_number: data.tax_number,
        account_number: data.account_number,
        status_id: data.status_id,
        personality: data.personality,
      },
      identity_address: {
        street: data.street_ktp,
        sub_district: data.sub_district_ktp,
        district: data.district_ktp,
        city: data.city_ktp,
        province: data.province_ktp,
        postal_code: data.postal_code_ktp,
        type: "ktp",
        user_id: userId,
      },
      domicile_address: {
        street: data.street_domisili,
        sub_district: data.sub_district_domisili,
        district: data.district_domisili,
        city: data.city_domisili,
        province: data.province_domisili,
        postal_code: data.postal_code_domisili,
        type: "domisili",
        user_id: userId,
      },
      mail_address: {
        street: data.street_mail,
        sub_district: data.sub_district_mail,
        district: data.district_mail,
        city: data.city_mail,
        province: data.province_mail,
        postal_code: data.postal_code_mail,
        type: "mail",
        user_id: userId,
      },
    };
    try {
      await axios.post("/staff/user/own/employee/all", dataForm, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      dispatch(setAlert("Data berhasil disimpan", "success"));
      dispatch(changeStatus());
      history.push("/");
    } catch (error) {
      dispatch(setAlert("Something went wrong", "error"));
    }
  };
