/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

import { getAlamat } from "../../../../store/actions";
import { capitalize, isEmpty } from "../../../../shared/utility";

const useStyles = makeStyles((theme) => ({
  textCapitalize: {
    textTransform: "capitalize",
  },
}));

const Alamat = ({ userDetail, getAlamat, auth }) => {
  const classes = useStyles();
  const params = useParams();

  const [indexKtp, setIndexKtp] = useState(null);
  const [indexDomisili, setIndexDomisili] = useState(null);
  const [indexMail, setIndexMail] = useState(null);

  useEffect(() => {
    if (
      (auth.user.role_name === "hcis" || auth.user.role_name === "manager") &&
      auth.user.id !== parseInt(params.userId)
    ) {
      getAlamat(true, params.userId);
    } else {
      getAlamat();
    }
  }, []);

  useEffect(() => {
    let indexKtp = userDetail.alamat.findIndex((x) => x.type === "ktp");
    if (indexKtp !== -1) {
      setIndexKtp(indexKtp);
    }
    let indexDomisili = userDetail.alamat.findIndex(
      (x) => x.type === "domisili"
    );
    if (indexDomisili !== -1) {
      setIndexDomisili(indexDomisili);
    }
    let indexMail = userDetail.alamat.findIndex((x) => x.type === "mail");
    if (indexMail !== -1) {
      setIndexMail(indexMail);
    }
  }, [userDetail]);

  return (
    <Grid container>
      {/* Row alamat ktp */}
      <Grid style={{ marginBottom: 20 }} item xs={4}>
        <Typography display="inline" gutterBottom variant="h6" component="h2">
          Alamat KTP
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography display="inline" gutterBottom variant="h6" component="h2">
          {" "}
          :{" "}
        </Typography>
        <Typography
          display="inline"
          gutterBottom
          variant="h6"
          component="h2"
          className={classes.textCapitalize}
        >
          {!userDetail.loadingAlamat &&
          indexKtp !== null &&
          !isEmpty(userDetail.alamat[indexKtp])
            ? `${userDetail.alamat[indexKtp].street}, ${capitalize(
                userDetail.alamat[indexKtp].sub_district
              )}, ${capitalize(
                userDetail.alamat[indexKtp].district
              )}, ${capitalize(userDetail.alamat[indexKtp].city)}, ${capitalize(
                userDetail.alamat[indexKtp].province
              )} - ${capitalize(userDetail.alamat[indexKtp].postal_code)}`
            : "Alamat tidak tersedia"}
        </Typography>
      </Grid>
      {/* Row alamat domisili */}
      <Grid style={{ marginBottom: 20 }} item xs={4}>
        <Typography display="inline" gutterBottom variant="h6" component="h2">
          Alamat Domisili
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <Typography display="inline" gutterBottom variant="h6" component="h2">
          {" "}
          :{" "}
        </Typography>
        <Typography
          display="inline"
          gutterBottom
          variant="h6"
          component="h2"
          className={classes.textCapitalize}
        >
          {!userDetail.loadingAlamat &&
          indexDomisili !== null &&
          !isEmpty(userDetail.alamat[indexDomisili])
            ? `${userDetail.alamat[indexDomisili].street}, ${capitalize(
                userDetail.alamat[indexDomisili].sub_district
              )}, ${capitalize(
                userDetail.alamat[indexDomisili].district
              )}, ${capitalize(
                userDetail.alamat[indexDomisili].city
              )}, ${capitalize(
                userDetail.alamat[indexDomisili].province
              )} - ${capitalize(userDetail.alamat[indexDomisili].postal_code)}`
            : "Alamat tidak tersedia"}
        </Typography>
      </Grid>
      {/* Row alamat ktp */}
      <Grid style={{ marginBottom: 20 }} item xs={4}>
        <Typography display="inline" gutterBottom variant="h6" component="h2">
          Alamat Surat Menyurat
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography display="inline" gutterBottom variant="h6" component="h2">
          {" "}
          :{" "}
        </Typography>
        <Typography
          display="inline"
          gutterBottom
          variant="h6"
          component="h2"
          className={classes.textCapitalize}
        >
          {!userDetail.loadingAlamat &&
          indexMail !== null &&
          !isEmpty(userDetail.alamat[indexMail])
            ? `${userDetail.alamat[indexMail].street}, ${capitalize(
                userDetail.alamat[indexMail].sub_district
              )}, ${capitalize(
                userDetail.alamat[indexMail].district
              )}, ${capitalize(
                userDetail.alamat[indexMail].city
              )}, ${capitalize(
                userDetail.alamat[indexMail].province
              )} - ${capitalize(userDetail.alamat[indexMail].postal_code)}`
            : "Alamat tidak tersedia"}
        </Typography>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    userDetail: state.detailEmployee,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { getAlamat })(Alamat);
