import axios from "axios";
import { setAlert } from "./alert";
import {
  GET_PROVINCE,
  GET_CITY,
  GET_DISTRICT,
  GET_VILLAGE,
  GET_CITY_DOMISILI,
  GET_DISTRICT_DOMISILI,
  GET_VILLAGE_DOMISILI,
  GET_CITY_MAIL,
  GET_DISTRICT_MAIL,
  GET_VILLAGE_MAIL
} from "./actionTypes";

export const getProvince = () => async (dispatch) => {
  const endpoint = `https://api.general.eoatech.com/provinces/search?kata_kunci=`;
  try {
    const res = await axios({
      url: endpoint,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    dispatch({
      type: GET_PROVINCE,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
    console.log(error);
    dispatch({
      type: GET_PROVINCE,
      payload: error,
    });
  }
};

export const getCity = (id_province) => async (dispatch) => {
  const endpoint = `https://api.general.eoatech.com/regencies/search?kata_kunci=&id_province=${id_province}`;
  try {
    const res = await axios({
      url: endpoint,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    dispatch({
      type: GET_CITY,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
    console.log(error);
    dispatch({
      type: GET_CITY,
      payload: error,
    });
  }
};

export const getDistrict = (id_cities) => async (dispatch) => {
  const endpoint = `https://api.general.eoatech.com/districts/search?kata_kunci=&id_regencies=${id_cities}`;
  try {
    const res = await axios({
      url: endpoint,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    dispatch({
      type: GET_DISTRICT,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
    console.log(error);
    dispatch({
      type: GET_DISTRICT,
      payload: error,
    });
  }
};

export const getVillage = (id_district) => async (dispatch) => {
  const endpoint = `https://api.general.eoatech.com/villages/search?kata_kunci=&id_district=${id_district}`;
  try {
    const res = await axios({
      url: endpoint,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    dispatch({
      type: GET_VILLAGE,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
    console.log(error);
    dispatch({
      type: GET_VILLAGE,
      payload: error,
    });
  }
};

// -------------
// DOMISILI

export const getCityDomisili = (id_province) => async (dispatch) => {
  const endpoint = `https://api.general.eoatech.com/regencies/search?kata_kunci=&id_province=${id_province}`;
  try {
    const res = await axios({
      url: endpoint,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    dispatch({
      type: GET_CITY_DOMISILI,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
    console.log(error);
    dispatch({
      type: GET_CITY_DOMISILI,
      payload: error,
    });
  }
};

export const getDistrictDomisili = (id_cities) => async (dispatch) => {
  const endpoint = `https://api.general.eoatech.com/districts/search?kata_kunci=&id_regencies=${id_cities}`;
  try {
    const res = await axios({
      url: endpoint,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    dispatch({
      type: GET_DISTRICT_DOMISILI,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
    console.log(error);
    dispatch({
      type: GET_DISTRICT_DOMISILI,
      payload: error,
    });
  }
};

export const getVillageDomisili = (id_district) => async (dispatch) => {
  const endpoint = `https://api.general.eoatech.com/villages/search?kata_kunci=&id_district=${id_district}`;
  try {
    const res = await axios({
      url: endpoint,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    dispatch({
      type: GET_VILLAGE_DOMISILI,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
    console.log(error);
    dispatch({
      type: GET_VILLAGE_DOMISILI,
      payload: error,
    });
  }
};

// MAIL
// ------

export const getCityMail = (id_province) => async (dispatch) => {
  const endpoint = `https://api.general.eoatech.com/regencies/search?kata_kunci=&id_province=${id_province}`;
  try {
    const res = await axios({
      url: endpoint,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    dispatch({
      type: GET_CITY_MAIL,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
    console.log(error);
    dispatch({
      type: GET_CITY_MAIL,
      payload: error,
    });
  }
};

export const getDistrictMail = (id_cities) => async (dispatch) => {
  const endpoint = `https://api.general.eoatech.com/districts/search?kata_kunci=&id_regencies=${id_cities}`;
  try {
    const res = await axios({
      url: endpoint,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    dispatch({
      type: GET_DISTRICT_MAIL,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
    console.log(error);
    dispatch({
      type: GET_DISTRICT_MAIL,
      payload: error,
    });
  }
};

export const getVillageMail = (id_district) => async (dispatch) => {
  const endpoint = `https://api.general.eoatech.com/villages/search?kata_kunci=&id_district=${id_district}`;
  try {
    const res = await axios({
      url: endpoint,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    dispatch({
      type: GET_VILLAGE_MAIL,
      payload: res.data,
    });
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
    console.log(error);
    dispatch({
      type: GET_VILLAGE_MAIL,
      payload: error,
    });
  }
};