import axios from "../../axios-orders";
import { setAlert } from "./alert";
import { getDetailInformation } from "./detailInformation";
import {
  SET_PHOTO,
  SET_LOADING_PHOTO,
  SET_LOADING_PHOTO_DONE,
} from "./actionTypes";

export const uploadPhoto = (data, positionId, role) => async (dispatch) => {
  dispatch(setLoadingPhoto());
  try {
    const res = await axios.post(`/staff/user/profile/picture`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    dispatch(setLoadingPhotoDone());
    dispatch(getProfilePicture());
    dispatch(setAlert("Photo Berhasil Di Ubah", "success"));
    dispatch(getDetailInformation(positionId, role));
  } catch (error) {
    if (error.response.data.errors.size === "maksimal size file sebesar 2mb.") {
      return dispatch(setAlert("Maksimal ukuran file sebesar 2mb", "error"));
    }
    dispatch(setAlert("Photo Gagal Di Upload", "error"));
  }
};

export const getProfilePicture = () => async (dispatch) => {
  try {
    const res = await axios.get(`/staff/user/own/profile/picture`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    let oldProfile = JSON.parse(localStorage.getItem("user"));
    oldProfile.profile = res.data.data;
    localStorage.setItem("user", JSON.stringify(oldProfile));

    dispatch({
      type: SET_PHOTO,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(setAlert("Can't get profile picture", "error"));
  }
};

export const setLoadingPhoto = () => {
  return {
    type: SET_LOADING_PHOTO,
  };
};

export const setLoadingPhotoDone = () => {
  return {
    type: SET_LOADING_PHOTO_DONE,
  };
};

export const getPhoto = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/manager/user/${id}/profile/picture`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    // dispatch({
    //   type: SET_PHOTO,
    //   payload: res.data.data,
    // });
  } catch (error) {
    dispatch(setAlert("Can't get photo", "error"));
  }
};
