/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { setAlert, sendImportAbsen } from "../../../../store/actions";
import Dropzone from "./Dropzone";

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(2),
  },
  cardActions: {
    justifyContent: "space-between",
  },
  btnDelete: {
    color: "#fff",
    background: theme.palette.red.color500,
    "&:hover": {
      backgroundColor: theme.palette.red.color700,
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const validationSchema = yup.object().shape({
  date: yup.string().required("Tanggal file absensi wajib diisi"),
});

const DialogSocialMedia = ({ open, onClose, setAlert, sendImportAbsen }) => {
  const classes = useStyles();

  const [file, setFile] = useState(null);
  console.log(file)
  const [reset, setReset] = useState(false);

  const { errors, handleSubmit, control, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });

  const handleChangeBanner = (event) => {
    setFile(event[0]);
  };

  const onSubmit = (data, e) => {
    if (file === null) {
      return setAlert("File belum diimport", "error");
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("date", moment(data.date).format("YYYY-MM-DD"));
    sendImportAbsen(formData);

    setValue("date", null);
    setReset(true);
    setFile(null);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle onClose={onClose}>Import Absensi</DialogTitle>
        <DialogContent dividers>
          <Dropzone
            handleChangeBanner={handleChangeBanner}
            reset={reset}
            setReset={setReset}
            setFile={setFile}
          />
          <Controller
            control={control}
            name="date"
            defaultValue={null}
            render={({ onChange, value }) => (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  className={classes.field}
                  id="date-picker-dialog"
                  label="Tanggal Absesi"
                  format="MM/dd/yyyy"
                  size="small"
                  value={value}
                  onChange={(e) => onChange(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  error={!!errors.date}
                  helperText={errors.date?.message}
                />
              </MuiPickersUtilsProvider>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button fullWidth variant="contained" color="primary" type="submit">
            import
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default connect(null, { setAlert, sendImportAbsen })(DialogSocialMedia);
