import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Switch, Button } from "@material-ui/core";
import { useForm } from "react-hook-form";

// Components
import Breadcrumb from "../../../components/UI/Breadcrumb/Breadcrumb";
import Identitas from "./Identitas";
import AlamatKTP from "./AlamatKTP";
import AlamatDomisili from "./AlamatDomisili";
import AlamatSurat from "./AlamatSurat";

// validasi
import { yupResolver } from "@hookform/resolvers/yup";
import validasiSchema from "./validasi";

// Actions - redux
import { sendFormIdentitas } from "../../../store/actions";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
  },
  marLeft: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  question: {
    margin: theme.spacing(3, 1.25, 0, 1.25),
  },
}));

const crumbs = [
  { name: "Dashboard", link: "/" },
  { name: "Form Identitas Diri", link: "" },
];

const FormIdentitas = ({ userData, sendFormIdentitas }) => {
  const classes = useStyles();
  const history = useHistory();

  const [ktp, setKTP] = useState({
    province: "",
    city: "",
    district: "",
    village: "",
    postal_code: "",
    street: "",
  });
  const [domisili, setDomisili] = useState({
    province: "",
    city: "",
    district: "",
    village: "",
    postal_code: "",
    street: "",
  });
  const [mail, setMail] = useState({
    province: "",
    city: "",
    district: "",
    village: "",
    postal_code: "",
    street: "",
  });
  const [switchDomisili, setSwitchDomisili] = useState(false);
  const [switchMail, setSwitchMail] = useState(false);

  const { handleSubmit, register, control, errors, setValue } = useForm({
    resolver: yupResolver(validasiSchema),
  });

  const onSubmit = (value) => {
    sendFormIdentitas(value, userData.user.id, history);
  };

  useEffect(() => {
    if (switchDomisili) {
      if (
        !!ktp.province &&
        !!ktp.city &&
        !!ktp.district &&
        !!ktp.village &&
        !!ktp.postal_code &&
        !!ktp.street
      ) {
        setDomisili({
          province: ktp.province,
          city: ktp.city,
          district: ktp.district,
          village: ktp.village,
          postal_code: ktp.postal_code,
          street: ktp.street,
        });
        setValue("province_domisili", ktp.province);
        setValue("city_domisili", ktp.city);
        setValue("district_domisili", ktp.district);
        setValue("sub_district_domisili", ktp.village);
        setValue("postal_code_domisili", ktp.postal_code);
        setValue("street_domisili", ktp.street);
        return;
      } else {
        return setSwitchDomisili(false);
      }
    }
    setDomisili({
      province: "",
      city: "",
      district: "",
      village: "",
      postal_code: "",
      street: "",
    });
    setValue("province_domisili", "");
    setValue("city_domisili", "");
    setValue("district_domisili", "");
    setValue("sub_district_domisili", "");
    setValue("postal_code_domisili", "");
    setValue("street_domisili", "");
  }, [switchDomisili, ktp, setValue]);

  useEffect(() => {
    if (switchMail) {
      if (
        !!domisili.province &&
        !!domisili.city &&
        !!domisili.district &&
        !!domisili.village &&
        !!domisili.postal_code &&
        !!domisili.street
      ) {
        setMail({
          province: domisili.province,
          city: domisili.city,
          district: domisili.district,
          village: domisili.village,
          postal_code: domisili.postal_code,
          street: domisili.street,
        });
        setValue("province_mail", domisili.province);
        setValue("city_mail", domisili.city);
        setValue("district_mail", domisili.district);
        setValue("sub_district_mail", domisili.village);
        setValue("postal_code_mail", domisili.postal_code);
        setValue("street_mail", domisili.street);
        return;
      } else {
        return setSwitchDomisili(false);
      }
    }
    setMail({
      province: "",
      city: "",
      district: "",
      village: "",
      postal_code: "",
      street: "",
    });
    setValue("province_mail", "");
    setValue("city_mail", "");
    setValue("district_mail", "");
    setValue("sub_district_mail", "");
    setValue("postal_code_mail", "");
    setValue("street_mail", "");
  }, [switchMail, domisili, setValue]);

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Typography variant="h3" className={classes.marLeft}>
        Form Identitas Diri
      </Typography>
      <Breadcrumb crumb={crumbs} />
      <Typography align="center" variant="h4">
        Selamat datang di HCIS
      </Typography>
      <Typography align="center" variant="body1">
        Silahkan mengisikan data diri anda terlebih dahulu
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Card Form Identitas */}
        <Identitas register={register} control={control} errors={errors} />

        {/* Card Form Alamat KTP */}
        <AlamatKTP
          register={register}
          control={control}
          errors={errors}
          value={ktp}
          setValue={setKTP}
        />

        <Typography variant="h5" className={classes.question}>
          Apakah alamat domisili sama dengan alamat KTP ?{" "}
          <Switch
            checked={switchDomisili}
            onChange={(e) => setSwitchDomisili(e.target.checked)}
          />
        </Typography>

        {/* Card Form Alamat Domisili */}
        <AlamatDomisili
          register={register}
          control={control}
          errors={errors}
          value={domisili}
          setValue={setDomisili}
        />

        <Typography variant="h5" className={classes.question}>
          Apakah alamat surat menyurat sama dengan alamat domisili ?{" "}
          <Switch
            checked={switchMail}
            onChange={(e) => setSwitchMail(e.target.checked)}
          />
        </Typography>

        {/* Card Form Surat Menyurat */}
        <AlamatSurat
          register={register}
          control={control}
          errors={errors}
          value={mail}
          setValue={setMail}
        />

        <Button fullWidth variant="contained" color="primary" type="submit">
          Simpan
        </Button>
      </form>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.auth,
  };
};

export default connect(mapStateToProps, { sendFormIdentitas })(FormIdentitas);
