import { SET_ALL_QUOTES, SET_QUOTES } from "./actionTypes";
import axios from "../../axios-orders";
import { setAlert } from "./alert";

export const getAllQuotes = () => async (dispatch) => {
  try {
    const res = await axios.get(`/hcis/quotes`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_ALL_QUOTES,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(setAlert("Can't get data quotes!", "error"));
  }
};

export const sendQuotes = (data) => async (dispatch) => {
  try {
    await axios.post("/hcis/quotes", data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch(setAlert("data berhasil disimpan", "success"));
    dispatch(getAllQuotes());
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};

export const sendEditQuotes = (data, id) => async (dispatch) => {
  try {
    await axios.put(`/hcis/quotes/${id}`, data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch(setAlert("data berhasil diubah", "success"));
    dispatch(getAllQuotes());
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};

export const deleteQuotes = (id) => async (dispatch) => {
  try {
    await axios.delete(`/hcis/quotes/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch(setAlert("data berhasil dihapus", "success"));
    dispatch(getAllQuotes());
  } catch (error) {
    dispatch(setAlert("Something went wrong", "error"));
  }
};

export const getQuotes = () => async (dispatch) => {
  try {
    const res = await axios.get(`/staff/quotes/random`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    dispatch({
      type: SET_QUOTES,
      payload: res.data.data,
    });
  } catch (error) {
    dispatch(setAlert("Can't get data quotes!", "error"));
  }
};
